import React, { Component } from "react";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Card } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Checkbox from '@material-ui/core/Checkbox';
import Configs from '../../Service/Configuration';
import FetchApi from '../../Service/FetchApi';
import Button from "@material-ui/core/Button";

// Need to use a global variable, as state is async and we need to do updates immediately,
// using latest value.
var globalRemoteCohortCreationCheckBox = []

class TorchRemoteCohortMonitoring extends Component {
  constructor(props) {
    super();
    this.state = {
      cohortsAndRemoteCohortsRenderArray: [],
      sitesAndRemoteCohortCreationsInConfig: [],
      sitesAndActiveCohortsDictionary: {},
      selectedSites: [],
    }
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
  }

  componentDidMount() {      
    this.getSitesAndRemoteCohortCreationsInConfig();
    this.getRemoteCreateCohortHistory();
    this.getSitesAndActiveCohortNames();
  }

  getSitesAndActiveCohortNames() {
    const api = FetchApi(Configs.api.getSitesAndActiveCohortNames)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then(response => {
      console.log("getSitesAndActiveCohortNames response", response)
      this.setState({
        sitesAndActiveCohortsDictionary: response
      })
    })

    if (this.props.configType == Configs.configurationEditingPageTypes.createCohortMonitoring) {
      this.setState({
        isLoaded: true
      })
    }
  }

  getRemoteCreateCohortHistory() {
    const api = FetchApi(Configs.api.getRemoteCreateCohortHistory)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then(response => {
      console.log("getRemoteCreateCohortHistory response", response)  
      this.setState({
        remoteCreateCohortHistory: response
      })
    })
  }

  getSitesAndRemoteCohortCreationsInConfig() {
    const api = FetchApi(Configs.api.getSitesAndRemoteCohortCreationsInConfig)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then(response => {
      console.log("getSitesAndRemoteCohortCreationsInConfig response", response)
      this.setState({
        sitesAndRemoteCohortCreationsInConfig: response
      })

      // Create a dictionary storing the booleans if a cohort has been clicked for a site.
      var remoteCohortCreationCheckBoxAllSites = {};
      Object.entries(response).forEach(([cohortKey,cohortValue]) => {
        var dictionaryData = {}
        if (cohortValue != null) {
          for(var i = 0; i < cohortValue.length; i++) {
            dictionaryData[cohortValue[i].cohortName] = false
          }
            remoteCohortCreationCheckBoxAllSites[cohortKey] = dictionaryData
        }
      })
      globalRemoteCohortCreationCheckBox = remoteCohortCreationCheckBoxAllSites    
    })
  }

  removeAllRemoteConfigsAllSites = () => {
    var body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    const api = FetchApi(Configs.api.removeAllRemoteConfigsAllSites);
    api.post(body).then((response) => {
      console.log("removeAllRemoteConfigsAllSites response", response);
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized");

      this.getSitesAndActiveCohortNames();   
      this.getSitesAndRemoteCohortCreationsInConfig();
      this.getRemoteCreateCohortHistory();
  
      // Require a delay before "re-clicking" sites and active cohort configs
      setTimeout(function() {
        this.SharedComponentSiteGroupingCallback(this.state.selectedSites)
      }.bind(this), 1000)

      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err) => {
      console.log("removeAllRemoteConfigsAllSites err", err);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })
  }

  removeSelectedRemoteConfigsSelectedSites = () => {
    var selectedSiteIDAndSelectedConfigCohorts = {}

    // Loop through sites selected and compare against the globalRemoteCohortCreationCheckBox dictionary.
    // Dictionary contains which cohorts have been clicked for which sites.
    // If the value is true, then we insert the siteID (key) and value (cohort, blankstring) into a dictionary
    // to be sent to the API to remove the sites and cohorts from config.
    for(var i = 0; i < this.state.selectedSites.length; i++) {
      var siteID = this.state.selectedSites[i].fhtSiteId      
      if (siteID in globalRemoteCohortCreationCheckBox) {
        var selectedConfigCohorts = {}
        Object.entries(globalRemoteCohortCreationCheckBox[siteID]).forEach(([configCohortName, isCohortCheckBoxSelected]) => {
          if (isCohortCheckBoxSelected == true) {
            selectedConfigCohorts[configCohortName] = "";
          }
        })
        selectedSiteIDAndSelectedConfigCohorts[siteID] = selectedConfigCohorts;
      }
    }
    
    var body = {
      selectedSiteIDSelectedConfigCohortData: selectedSiteIDAndSelectedConfigCohorts,
    }
    const api = FetchApi(Configs.api.removeSelectedRemoteConfigsSelectedSites);
    api.post(body).then((response) => {
      console.log("removeSelectedRemoteConfigsSelectedSites response", response);
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized");
        
      this.getSitesAndActiveCohortNames();   
      this.getSitesAndRemoteCohortCreationsInConfig();
      this.getRemoteCreateCohortHistory();

      // Require a delay before "re-clicking" sites and active cohort configs
      setTimeout(function() {
        this.SharedComponentSiteGroupingCallback(this.state.selectedSites)
      }.bind(this), 1000)

      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );   
    }).catch((err) => {
      console.log("removeSelectedRemoteConfigsSelectedSites err", err);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })
  }

  handleRemoteCohortConfig(event) {
    // Update checkbox clicked for cohort configs in a site.
    var parsedJSONSiteIDAndCohort = JSON.parse(event.target.value);
    var updatedRemoteCohortCheckBox = globalRemoteCohortCreationCheckBox
    updatedRemoteCohortCheckBox[parsedJSONSiteIDAndCohort.siteID][parsedJSONSiteIDAndCohort.cohortName] = !updatedRemoteCohortCheckBox[parsedJSONSiteIDAndCohort.siteID][parsedJSONSiteIDAndCohort.cohortName]
    globalRemoteCohortCreationCheckBox = updatedRemoteCohortCheckBox

    // Required to refresh clicked buttons
    this.SharedComponentSiteGroupingCallback(this.state.selectedSites)
  }

  SharedComponentSiteGroupingCallback = (selectedSites) => {
    console.log("Callback received from site grouping:", selectedSites);
    this.setState({ 
      selectedSites: selectedSites 
    });

    // Erase current render to "refresh" the buttons clicked.
    this.setState({
      cohortsAndRemoteCohortsRenderArray: []
    })

    // Generate the three column renders.
    var renderCohortsAndRemoteCohorts = []
    for (var i = 0; i < selectedSites.length; i++) {
      var allCurrentCohortsForSiteRenderArray = []
      var allCurrentCohortsForSiteArray = []
      if (selectedSites[i].fhtSiteId in this.state.sitesAndActiveCohortsDictionary) {
        Object.keys(this.state.sitesAndActiveCohortsDictionary[selectedSites[i].fhtSiteId]).map(
          x => allCurrentCohortsForSiteArray.push(x)
        )
        Object.keys(this.state.sitesAndActiveCohortsDictionary[selectedSites[i].fhtSiteId]).map(
          x => allCurrentCohortsForSiteRenderArray.push(
          <div key = {x} style = {{fontSize: "10px", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <Grid container justify="center">
              {x}
            </Grid>
          </div>
          )
        )
      }

      var configCohortNamesArray = []
      if (selectedSites[i].fhtSiteId in this.state.sitesAndRemoteCohortCreationsInConfig
        && this.state.sitesAndRemoteCohortCreationsInConfig[selectedSites[i].fhtSiteId] != null) {
        for (var j = 0; j < this.state.sitesAndRemoteCohortCreationsInConfig[selectedSites[i].fhtSiteId].length; j++) {
          var configCohortName = this.state.sitesAndRemoteCohortCreationsInConfig[selectedSites[i].fhtSiteId][j].cohortName
          configCohortNamesArray.push(
            <div key = {configCohortName} style = {{fontSize: "10px", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <Grid container justify="center">
                {allCurrentCohortsForSiteArray.includes(configCohortName)? 
                  <CheckCircleOutlineIcon style={{fill: "green"}} /> : 
                  <ErrorOutlineIcon style={{fill: "red"}} />
                }                  
                {configCohortName}
                <Checkbox
                  checked={globalRemoteCohortCreationCheckBox[selectedSites[i].fhtSiteId][configCohortName]}
                  value={`{\"cohortName\": \"${configCohortName}\", \"siteID\": \"${selectedSites[i].fhtSiteId}\"}`}
                  onChange={(event) => {
                    this.handleRemoteCohortConfig(event)
                  }}
                />
              </Grid>
            </div>
          )
        }
      }

      var renderRemoteCohortsHistory = []
      if (selectedSites[i].fhtSiteId in this.state.remoteCreateCohortHistory) {
        Object.keys(this.state.remoteCreateCohortHistory[selectedSites[i].fhtSiteId]).map(
          x => renderRemoteCohortsHistory.push(
          <div key = {x} style = {{fontSize: "10px", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <Grid container justify="center">
              {allCurrentCohortsForSiteArray.includes(x)? 
                <CheckCircleOutlineIcon style={{fill: "green"}} /> : 
                <ErrorOutlineIcon style={{fill: "red"}} />
              } 
              {x}
            </Grid>
          </div>
          )
        )
      }
      
      renderCohortsAndRemoteCohorts.push(
        <div key = {selectedSites[i].fhtSiteName}>
          <br/>
          <Card>
            <Grid container justify="center">
              <h4>{selectedSites[i].fhtSiteName}</h4>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Grid container justify="center">
                  <h5>Site cohorts</h5>
                </Grid>
                {allCurrentCohortsForSiteRenderArray}
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="center">
                  <h5>Remote cohort history</h5>
                </Grid>
                {renderRemoteCohortsHistory}
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="center">
                  <h5>Remote cohort in config</h5>
                </Grid>
                {configCohortNamesArray}
              </Grid>
            </Grid>
          </Card>
        </div>
      )
      this.setState({
        cohortsAndRemoteCohortsRenderArray: renderCohortsAndRemoteCohorts
      })
    }    
  };

  renderTorchRemoteCohortMonitoring = () => {
    // account
    const { classes } = this.props;

    return (
      <div> <br/>
      <Grid container justify="center" style={{ gap: "8px", width: "100%" }}>
        <Grid item xs={3}>
          {/** Left side of the page **/}
          <SelectSitesGrouping
            SharedComponentSiteGroupingCallback={
              this.SharedComponentSiteGroupingCallback
            }
          />
          <br />
        </Grid>
        <Grid item xs={7}>
          {/** Right side of the page **/}
          <Box
            bgcolor="primary.main"
            style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
            height="50px"
          >
            <Typography variant="h5">
              Remote Cohort Monitoring
            </Typography>
          </Box>
          <div className="nsExtraTallScrollBoxConfigEdit">
            {this.state.cohortsAndRemoteCohortsRenderArray}
          </div><br />

          <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.removeSelectedRemoteConfigsSelectedSites();
              }}
            >
              Remove selected remote cohort configs from selected sites.
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                this.removeAllRemoteConfigsAllSites();
              }}
            >
              Remove all remote cohort configs from all sites.
            </Button> 
          </Grid>
        </Grid>

        </Grid>
      </Grid> <br/>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderTorchRemoteCohortMonitoring()} <br />
      </div>
    )
  }
}

export default TorchRemoteCohortMonitoring;