import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";

class StaffExtractDuplicateIssue extends Component {
  constructor(props) {
    super();    
    this.state = {
      staffExtractCountsData: [],
      TableCol: [
        { title: "Site Name", field: "siteName" },
        { title: "Total staff counts", field: "totalStaffCounts" },
        { title: "Distinct staff counts", field: "distinctStaffCounts" },
      ],
    };
  }

  componentDidMount() {
    this.getStaffExtractCountsData();
  }
  
  getStaffExtractCountsData() {
    console.log("getStaffExtractCountsData")
    const api = FetchApi(Configs.api.getStaffExtractCountsData);
    api.get().then((response) => {
      console.log("getStaffExtractCountsData response", response)
      this.setState({
        staffExtractCountsData: response.staffCountsList
      })
    })
  }

  render() {
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Staff extract duplicate issue</h3></Grid>
        <br/>
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns={this.state.TableCol}
            data={this.state.staffExtractCountsData}
            title=""
          />
        </Grid><br/>
      </div>
    )
  }
}

export default StaffExtractDuplicateIssue;