// Description: This component belongs to the FHTHome.jsx.
// this component displays the summary for each site.
// Caution: THe underlying algorithms are super complicated and brittle, change with caution
// Status: Ready

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Card, CardContent, Divider, CardHeader } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import SiteDetailDialog from "./SiteDetailDialog";
import { useState, useEffect } from "react";
import Configs from "../Service/Configuration";
import SelectSiteGroupingsSelectionOnly from "./SharedComponents/SelectSiteGroupingsSelectionOnly";

const useStyles = makeStyles((theme) => ({
  onHover: {
    color: "black",
    overflow: "hidden",
    "&:hover": {
      color: "green",
      cursor: "pointer",
      overflowWrap: "break-word",
      textDecoration: "underline",
      overflow: "visible",
    },
  },
  viewText: {
    overflow: "hidden",
    "&:hover": {
      overflow: "visible",
    },
  },
  red: {
    fill: "red",
  },
  green: {
    fill: "green",
  },
  orange: {
    fill: "orange",
  },
}));

const validateStatus = (siteInfo) => {
  var currentSiteType = Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType;
  
  if (currentSiteType == Configs.supportSiteType.fhtSupport ||
      currentSiteType == Configs.supportSiteType.fhtTestSupport ||
      currentSiteType == Configs.supportSiteType.SC4CSupport) {

      if (
        siteInfo["POC"] === "fail" ||
        siteInfo["SC4C"] === "fail" ||
        siteInfo["API"] === "fail" ||
        siteInfo["ALG"] === "fail"
      ) {
        return false;
      }
    }
  if (currentSiteType == Configs.supportSiteType.torchSupport ||
      currentSiteType == Configs.supportSiteType.torchTestSupport) {
        if (
          siteInfo["API"] === "fail" ||
          siteInfo["ALG"] === "fail"
        ) {
          return false;
        }
    }
  return true;
};

// Identifies if a site is using heartbeat dummy data, then give it a special site status.
const validateNewSiteStatus = (siteInfo) => {
  var currentSiteType = Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType;

  if (currentSiteType == Configs.supportSiteType.fhtSupport ||
      currentSiteType == Configs.supportSiteType.fhtTestSupport ||
      currentSiteType == Configs.supportSiteType.SC4CSupport) {
      if (
        siteInfo["POC"] === "NA" &&
        siteInfo["API"] === "NA" &&
        siteInfo["ALG"] === "NA"
      ) {
        return true;
      }
    }
  if (currentSiteType == Configs.supportSiteType.torchSupport ||
      currentSiteType == Configs.supportSiteType.torchTestSupport) {
        if (
          siteInfo["API"] === "NA" &&
          siteInfo["ALG"] === "NA"
        ) {
          return true;
        }
    }

  return false;
};

const verifyStatus = (oldStatus, newStatus) => {
  if (oldStatus === newStatus) {
    return oldStatus;
  }
  if (newStatus === "fail" || oldStatus === "fail") {
    return "fail";
  }
  return "active";
};

const combineTwoSites = (oldSite, newSite) => {
  let combinedSite = oldSite;
  combinedSite["ALG"] = verifyStatus(oldSite["ALG"], newSite["ALG"]);
  combinedSite["API"] = verifyStatus(oldSite["API"], newSite["API"]);
  combinedSite["POC"] = verifyStatus(oldSite["POC"], newSite["POC"]);

  // Combining POCs
  try {
    if (combinedSite["POC"] !== "NA") {
      if (oldSite["POCTotalNum"] === undefined) {
        // only newSite is POC
        combinedSite["POCTotalNum"] = newSite["POCTotalNum"];
        combinedSite["POCActiveNum"] = newSite["POCActiveNum"];
        combinedSite["POCLastLog"] = newSite["POCLastLog"];
        combinedSite["siteIsTerminal"] = newSite["siteIsTerminal"];
      } else if (newSite["POCTotalNum"] !== undefined) {
        // both sites are POC
        combinedSite["POCTotalNum"] += newSite["POCTotalNum"];
        combinedSite["POCActiveNum"] += newSite["POCActiveNum"];
        if (newSite["POCLastLog"] > oldSite["POCLastLog"]) {
          combinedSite["POCLastLog"] = newSite["POCLastLog"];
        }
        if (newSite["siteIsTerminal"]) {
          combinedSite["siteIsTerminal"] = newSite["siteIsTerminal"];
        }
      }
      combinedSite["POCVers"] = new Set([
        ...oldSite["POCVers"],
        ...newSite["POCVers"],
      ]);
    }
  } catch {
    // Do nothing, we have null values.
  }

  // TBD: Have not thought about the case of combining two SC4Cs

  if (combinedSite["API"] !== "NA") {
    if (combinedSite["APILastLog"] === undefined) {
      combinedSite["APILastLog"] = newSite["APILastLog"];
      combinedSite["APIVer"] = newSite["APIVer"];
    }
  }

  if (combinedSite["ALG"] !== "NA") {
    if (combinedSite["ALGLastLog"] === undefined) {
      combinedSite["ALGLastLog"] = newSite["ALGLastLog"];
      combinedSite["ALGLastLogSuccess"]= newSite["ALGLastLogSuccess"];
      combinedSite["ALGVer"] = newSite["ALGVer"];
      combinedSite["ALGTestIDs"] = newSite["ALGTestIDs"];
    }
  }

  return combinedSite;
};

const decidingSiteType = (site) => {
  // Currently I assumed only if the object is not SC4C, then check FHT
  if (site["SC4C"] === "active" || site["SC4C"] === "fail") {
    return "SC4C";
  } else if (
    site["ALG"] !== "NA" ||
    site["API"] !== "NA" ||
    site["POC"] !== "NA"
  ) {
    return "FHT";
  } else {
    return "NA";
  }
};

const filteringSites = (siteWiki, type) => {
  let thisType = null;
  let filteredSites = {};
  
  Object.keys(siteWiki).forEach((siteID, index) => {
    // decidingSiteType hides sites with no heartbeat data from being displayed (i.e newly registered sites)
    // May not even be required now since we use SiteTypeIDs.
    thisType = decidingSiteType(siteWiki[siteID]);
    // if (thisType === type) {
      filteredSites[siteID] = siteWiki[siteID];
    // }
  });

  return filteredSites;
};

const combiningSites = (siteWiki) => {
  let siteNames = new Set();
  let combinedSites = {};
  
  Object.keys(siteWiki).forEach((siteID, index) => {
    let thisName = siteWiki[siteID]["siteName"];
    thisName = thisName.replace("POC", "");
    thisName = thisName.replace("Server", "");
    thisName = thisName.replace("Client", "");

    siteNames.add(thisName);
    if (combinedSites[thisName] === undefined) {
      combinedSites[thisName] = siteWiki[siteID];
      combinedSites[thisName]["siteName"] = thisName;
    } else {
      combinedSites[thisName] = combineTwoSites(
        combinedSites[thisName],
        siteWiki[siteID]
      );
    }
  });
  return combinedSites;
};

export default function OverviewCard(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [siteWiki, setSiteWiki] = React.useState(props.siteWiki);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [siteDetail, setSiteDetail] = React.useState(null);

  const [selectedSiteIDs, setSelectedSiteIDs] = React.useState([]);
  const [selectedSiteNames, setSelectedSiteNames] = React.useState([]);
  const [selectedSiteGroupings, setSelectedSiteGroupings] = React.useState(0);

  useEffect(() => {
    // This function is equivalent to componentDidMount
    const filteredSites = filteringSites(props.siteWiki, props.type);
    const combinedSites = combiningSites(filteredSites);
    setSiteWiki(combinedSites);

    var selectedSiteNamesArray = []
    var selectedSiteIDs = []
    for (const [key, value] of Object.entries(combinedSites)) {
        selectedSiteNamesArray.push(key)
        selectedSiteIDs.push(value["siteID"])
    }
    setSelectedSiteIDs(selectedSiteIDs)
    setSelectedSiteNames(selectedSiteNamesArray)

    setIsLoaded(true);
  }, [props.type]);

  const classes = useStyles();

  const handleOpen = (siteInfo) => {
    setSiteDetail(siteInfo);
    setIsOpen(true);
  };

  const SharedComponentSiteGroupingCallback = (siteGroupingsSiteIDs) => {
    if (siteGroupingsSiteIDs != 0) {
      var selectedSiteNamesArray = []
      for (const [key, value] of Object.entries(siteWiki)) {
        if (siteGroupingsSiteIDs.includes(value["siteID"]))
          selectedSiteNamesArray.push(key)
      }
      setSelectedSiteIDs(siteGroupingsSiteIDs)
      setSelectedSiteNames(selectedSiteNamesArray)
    } else {
      var selectedSiteNamesArray = []
      var selectedSiteIDs = []
      for (const [key, value] of Object.entries(siteWiki)) {
          selectedSiteNamesArray.push(key)
          selectedSiteIDs.push(value["siteID"])
      }
      setSelectedSiteIDs(selectedSiteNamesArray)
      setSelectedSiteNames(selectedSiteNamesArray)
    }
  }

  const handleClose = (siteInfo) => {
    setSiteDetail(null);
    setIsOpen(false);
  };
  if (isLoaded === false) {
    return <div>Loading Site Overview...</div>;
  } else {
    // Sort the siteWiki to be in descending site name order.
    var sortedSiteWiki = {}
    Object.keys(siteWiki)
      .sort()
      .forEach(function(v, i) {
          sortedSiteWiki[v] = siteWiki[v]
       });

    // Second push to see changes.

    return (
      <div>
        <Card>
          <CardHeader title={
            <div>
              Site Overview
              <SelectSiteGroupingsSelectionOnly 
                selectedSiteGroupings={selectedSiteGroupings}
                SharedComponentSiteGroupingCallback={SharedComponentSiteGroupingCallback}
                SharedComponentSelectedSiteGroupingIDCallback={setSelectedSiteGroupings}
              />
            </div>
          } className="nsCardHeader" />
          <Divider />
          <CardContent>
            <div>
              <GridList cellHeight={"auto"} spacing={5} cols={3}>
                {siteDetail !== null ? (
                  <SiteDetailDialog
                    type={props.type}
                    siteInfo={siteDetail}
                    ALGTestsInfo={props.ALGTestsInfo}
                    isValidated={validateStatus(siteDetail)}
                    handleClose={handleClose}
                    isOpen={isOpen}
                  />
                ) : null}

                {/* siteID is NOT siteID but the actual site name */}
                {Object.keys(sortedSiteWiki).map((siteID, index) => {
                  if (selectedSiteNames.includes(siteID)) {
                    return (    
                      // Keeping this separate for now, as we may want to re-disable being able to click newly 
                      // registered sites.
                      validateNewSiteStatus(siteWiki[siteID]) ? (
                        <ListItem key={index} 
                        className={classes.onHover}
                        onClick={() => handleOpen(siteWiki[siteID])}
                          >
                        <AddAlertIcon className={classes.orange} />
                        <ListItemText primary={siteWiki[siteID]["siteName"]} />
                        </ListItem>
                      ) :
                      <ListItem
                        className={classes.onHover}
                        key={index}
                        onClick={() => handleOpen(siteWiki[siteID])}
                      >
                        {validateStatus(siteWiki[siteID]) ? (
                          <CheckCircleOutlineIcon className={classes.green} />
                        ) : (
                          <ErrorOutlineIcon className={classes.red} />
                        )}
                        <ListItemText primary={siteWiki[siteID]["siteName"]} />
                      </ListItem>
                    );
                  }
                })}
              </GridList>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
