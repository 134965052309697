import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import SiteMachineSpecificDeactivation from "./SiteMachineSpecificDeactivation";

class SiteMachineDeactivation extends Component {
  constructor(props) {
    super();
    this.state = {
      allSitesAndActiveMachineStatus: [],
      softwareIDToSoftwareName: {}
    }
  }

  componentDidMount() {
    this.getSoftwareIDToSoftwareName();
    this.getSitesAndMachineStatus();
  }

  getSitesAndMachineStatus() {
    const api = FetchApi(Configs.api.getSitesAndMachineStatus);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID
    }
    api.post(body).then((response) => {
      console.log("getSitesAndMachineStatus response", response);
      this.setState({
        allSitesAndActiveMachineStatus: response
      })
    });
  }

  getSoftwareIDToSoftwareName() {
    const api = FetchApi(Configs.api.getSoftwareIDToSoftwareName);
    api.get().then((response) => {
      console.log("getSoftwareIDToSoftwareName response", response)
      this.setState({
        softwareIDToSoftwareName: response
      })
    });
  }

  renderSiteMachineDeactivation = () => {
    return (
      <div style={{ paddingTop: "1REM" }} >
        <Grid container justify="center">
          <MaterialTable
            style={{ width: "99%" }}
            title={<h3>Site Machine Deactivation</h3>}
            columns={[
              { title: 'Site Name', field: 'siteName' },
              { title: 'Site Enabled', field: 'siteEnabled' },
              { title: 'Site Active', field: 'siteActive' },
              { title: 'Active Machines', field: 'activeMachines', type: 'numeric' },
              { title: 'Inactive Machines', field: 'inactiveMachines', type: 'numeric' },
            ]}
            data={this.state.allSitesAndActiveMachineStatus}
            // If we require the table to not expand, and use scroll bar for inner detailPanel.
            options={{
              // maxBodyHeight: 'calc(100vh - 14REM)',
              // pageSize: 20,
              minBodyHeight: "65vh"
            }}
            detailPanel={rowData => {
              return (
                <div>
                  <SiteMachineSpecificDeactivation
                    rowData={rowData}
                    softwareIDToSoftwareName={this.state.softwareIDToSoftwareName}
                    GlobalFunctionDisplaySnackbarMessage = {this.props.GlobalFunctionDisplaySnackbarMessage}
                  />
                </div>
              )
            }}
          />
        </Grid>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderSiteMachineDeactivation()}
      </div>
    )
  }
}

export default SiteMachineDeactivation;