import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";

class ResetPassword extends Component {
  constructor(props) {
    super();   
    this.state = { 
      username: "",
      formErrors: { email: '', password: '', confirmPassword: '' },
      showPassword: false,
      password:"",
      showRePassword: false,
      confirmPassword: "",
      isFHTSupportAccount: false,
      emailValid: false,
      passwordValid: false,
      fhtSupportRoleID: 0,
    }
  }

  handleUserInput = (e) => {
    //update password
    //create account
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value) });
  }

  handleResetPassword = () => {
    //reset password
    if(!this.state.passwordValid || this.state.username === ""){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updatePasswordDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    var data = { 
      Username: this.state.username,
      NewPassword: this.state.password, 
      RetypePassword: this.state.confirmPassword 
    };
    const api = FetchApi(Configs.api.resetAccountPassword);
    api.post(data)
    .then(response => {
      if(response.errorMessage !== undefined){
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.errorMessage,
          Configs.snackbarVariants.error
        );
      }else{
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
        this.reset();
      }     
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });
  }

  reset = () => {
    // handle menuclick, reset password, create account
    this.setState({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      formErrors: { email: '', password: '', confirmPassword: '' },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      showRePassword: false,
      showPassword: false,
      fhtSupportRoleID: 0,
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let passwordMatch = false;
    switch (fieldName) {
        case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
          break;
        case 'password':
          let passwordlength = value.length >= 5;
          passwordMatch = this.state.confirmPassword === value;
          passwordValid = passwordlength && passwordMatch;
          fieldValidationErrors.password = passwordlength ? passwordMatch ? '' : 'Passwords not matching' : ' is too short';
          fieldValidationErrors.confirmPassword = passwordMatch ? '' : 'Passwords not matching';
          break;
        case 'confirmPassword':
          passwordMatch = this.state.password === value;
          passwordValid = passwordMatch;
          fieldValidationErrors.confirmPassword = passwordMatch ? '' : 'Passwords not matching';
          fieldValidationErrors.password = passwordMatch ? '' : 'Passwords not matching';
          break;
        default:
            break;
    }
    let roleValid = this.state.isFHTSupportAccount
                    ? this.state.fhtSupportRoleID > 0
                    : true;
    this.setState({
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        roleValid : roleValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid  && this.state.roleValid});
  }

  renderResetPassword = () => {
    return (
      <div>        
        <br/>
          <Grid container justify="center"><h3>Reset Password</h3></Grid>
        <br/>
        <Grid container justify="center">
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="username"
              label="Username"
              id="username"
              value={this.state.username}
              onChange={(event) => {
                  this.setState({ username: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              error={this.state.formErrors.password.length === 0 ? false : true}
              helperText={this.state.formErrors.password}
              name="password"
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              id="password"
              value={this.state.password}
              onChange={this.handleUserInput}
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={()=>{ this.setState({ showPassword: !this.state.showPassword})}}
                          >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              error={this.state.formErrors.confirmPassword.length === 0 ? false : true}
              helperText={this.state.formErrors.confirmPassword}
              name="confirmPassword"
              label="Confirm Password"
              type={this.state.showRePassword ? 'text' : 'password'}
              id="confirmPassword"
              value={this.state.confirmPassword}
              onChange={this.handleUserInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={()=>{ this.setState({ showRePassword: !this.state.showRePassword})}}
                    >
                      {this.state.showRePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl> 
        </Grid>
        <div style = {{marginLeft: "4.5REM"}}>
          <Button
            variant="contained"
            onClick={() => this.handleResetPassword()}>
              Save
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderResetPassword()}
      </div>
    )
  }
}

export default ResetPassword;