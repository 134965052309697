// Description: This file is resposible for rendering the poc log, autoupdate log and heartbeat extra log for a particular machine.
// Status: Ready

import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
import { utcToLocal, utctoAEDT } from "./HelperFunctions";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

class MachineLogs extends React.Component {
  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      pageSizeOptions: [10, 20, 30],
    };
    this.state = {
      machineLogsTable: [
        { title: "Site Name", field: "SiteName", width: "17%" },
        { title: "Machine", field: "Machine Name" },
        { title: "Log received", field: "Log Received", type: "datetime" },
        { title: "Log Name", field: "Log type" },
        { title: "Text", field: "Log message", width: "48%" }
      ],
      autoUpdateLog: [],
      pocLog: [],
      extraAPILog: [],
      machineLogs: [],
      machineLogdata: []
    }
  }

  renderMachineLog = () => {
    return (
      <MaterialTable
        columns={this.state.machineLogsTable}
        data={this.state.machineLogdata}
        options={this.pageOptions}
        title="FHT Machine Logs"
      ></MaterialTable>
    );
  };

  componentDidMount = async () => {
    const api = FetchApi(Configs.api.getMachineLogs);
    const filterValue = Object.values(this.props.match.params)[0];
    let filterValueSplit = null;
    if (filterValue !== undefined) {
      filterValueSplit = filterValue.split(",");
    }
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      MachineName: filterValueSplit == null ? null : filterValueSplit[0],
      SiteID: filterValueSplit == null ? -1 : parseInt(filterValueSplit[1]),
      SiteName: this.pageOptions.searchText,
    };
    console.log("sending", body);
    await api.post(body).then((response) => {
      
      this.setState({ machineLogs: response });
      this.setState({ machineLogdata: response })
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          {this.renderMachineLog()}
        </Grid>
      </div>

    );

  }
}

export default withStyles(useStyles)(MachineLogs);