import React, { Component } from "react";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class AlgorithmStatusCellRender extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedAlgorithmStatus: 1,
    }
  }

  componentDidMount() {
    this.setState({
      selectedAlgorithmStatus: this.props.cellData.algorithmStatus
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.cellData.algorithmStatus != prevProps.cellData.algorithmStatus) {
      this.setState({
        selectedAlgorithmStatus: this.props.cellData.algorithmStatus
      })
    }
  }

  handleSelectDropdownAlgorithmStatus(e) {
    this.setState({
      selectedAlgorithmStatus: e.target.value
    })

    if (!this.props.changeAllRowsAlgStatus) {
      this.props.CallBackSetCellSpecificAlgStatus(e.target.value, this.props.cellData)
    }

    if (this.props.changeAllRowsAlgStatus) {
      this.props.CallBackSetAlgAllAlgStatus(e.target.value, this.props.cellData);
    }
  }

  selectDropdownAlgorithmStatus() {
    var currentValue = this.state.selectedAlgorithmStatus
    var backgroundColorStatus = "#FF772EFF";
    if (currentValue == Configs.AlgorithmTableStatus.updateAllAlg)
      backgroundColorStatus = "#FF772EFF";
    else if (currentValue == Configs.AlgorithmTableStatus.live)
      backgroundColorStatus = "#4BB83BFF";
    else if (currentValue == Configs.AlgorithmTableStatus.silent)
      backgroundColorStatus = "#A29EA3FF";
    else if (currentValue == Configs.AlgorithmTableStatus.off)
      backgroundColorStatus = "#F74D69FF";

    return (
      <Select
        style={{ backgroundColor: backgroundColorStatus, color: "white", minWidth: "7.5REM", textAlign: "center" }}
        value={this.state.selectedAlgorithmStatus}
        onChange={(e) => this.handleSelectDropdownAlgorithmStatus(e)}
      >
        {this.props.changeAllRowsAlgStatus ?
          <MenuItem
            value={Configs.AlgorithmTableStatus.updateAllAlg}
            style={{
              backgroundColor: "#FF772EFF",
              padding: "0.5REM",
              justifyContent: "center",
              color: "white"
            }}
          >
            Change all
          </MenuItem>
          : <div></div>
        }
        <MenuItem
          value={Configs.AlgorithmTableStatus.live}
          style={{
            backgroundColor: "#4BB83BFF",
            padding: "0.5REM",
            justifyContent: "center",
            color: "white"
          }}
        >
          Live
        </MenuItem>

        {/* Only render Silent option if site is recommendationSearch */}
        {this.props.isAlgorithmTypeRec ?
          <MenuItem
            value={Configs.AlgorithmTableStatus.silent}
            style={{
              backgroundColor: "#A29EA3FF",
              padding: "0.5REM",
              justifyContent: "center",
              color: "white"
            }}
          >
            Silent
          </MenuItem>
          : <div></div>
        }
        <MenuItem
          value={Configs.AlgorithmTableStatus.off}
          style={{
            backgroundColor: "#F74D69FF",
            padding: "0.5REM",
            justifyContent: "center",
            color: "white"
          }}
        >
          Off
        </MenuItem>
      </Select>
    )
  }

  render() {
    return (
      <div>
        {this.selectDropdownAlgorithmStatus()}
      </div>
    )
  }
}

export default AlgorithmStatusCellRender;