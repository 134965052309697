import React, { Component } from "react";
import ReorderListSharedComponent from "../../SharedComponents/ReorderListSharedComponent";
import { Button } from "@material-ui/core";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";

class AlgorithmOrder extends Component {
  constructor(props) {
    super();
    this.state = {
      currentListOrder: [],
      orderingTitle: "Recommendation Order",
    }
    this.setUpdatedListOrder = this.setUpdatedListOrder.bind(this);
  }

  componentDidMount() {
    this.getAlgorithmOrderList();
    this.setOrderingTitle();
  }

  getAlgorithmOrderList() {
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      AlgorithmType: this.props.algorithmTypeID,
    };
    const api = FetchApi(Configs.api.getAlgorithmOrderList)

    api.post(body).then(response => {
      console.log("response", response)
      this.setState({
        currentListOrder: response.algorithmOrderList
      })
    })
  }

  setOrderingTitle() {
    var title = this.state.orderingTitle;
    if (this.props.algorithmTypeID == Configs.RecOrChkSaveType.Chk) {
      title = "Checking Order"
    }
    this.setState({
      orderingTitle: title
    })
  }

  setUpdatedListOrder(updatedListOrder) {
    this.setState({
      currentListOrder: updatedListOrder
    })
  }

  handleUpdateAlgorithmOrder() {
    var body = {
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      AlgorithmTypeID: this.props.algorithmTypeID,
      UpdateAlgorithmOrderList: this.state.currentListOrder,
    }
    console.log("handleUpdateAlgorithmOrder body", body);
    const api = FetchApi(Configs.api.updateAlgorithmOrder)
    api.post(body).then(response => {
      console.log("response", response)
      if (response.status == 400) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Unable to update algorithm order",
          Configs.snackbarVariants.error
        )
        return;
      }
      if (response == "Success") {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Successfully updated algorithm order",
          Configs.snackbarVariants.success
        );
      }
    }).catch (error => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Unable to update algorithm order",
        Configs.snackbarVariants.error
      )
    })
  }

  render() {
    return (
      <div>
        <ReorderListSharedComponent
          reorderListHeading={this.state.orderingTitle}
          listToUse={this.state.currentListOrder}
          setUpdatedListOrder={this.setUpdatedListOrder}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleUpdateAlgorithmOrder()}
          >
            Update configuration order
          </Button>
        </div>
      </div>
    )
  }
}

export default AlgorithmOrder;