import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import Container from "@material-ui/core/Container";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      passwordValid: true,
    };
  }

  onBtnSubmitLogin = (e) => {

      // START604: Use this section if you are using the frontend with the backend data(RELEASE mode)
      e.preventDefault();
      console.log("Attempting to login");
      let body = {
        Username: this.state.username,
        Password: this.state.password,
      };
      const api = FetchApi(Configs.api.userLogin);
      api
        .post(body)
        .then((response) => {
          console.log("response from server:", response);
          if (response === undefined || response === null) {
            alert("No response from FHT server");
            throw response.error;
          } else if (response.error !== undefined) {
            alert("Response contains error");
            throw response.error;
          } else if (response.status == 400) {
            // alert("Unable to login. Wrong username or password entered.");
            throw "Unable to login. Wrong username or password entered.";
          } else {
            localStorage.setItem(Configs.tokenInfo, response.token);

            const api2 = FetchApi(Configs.api.getFHTSupportAccountRole);
            api2
            .get()
              .then((response2) => {              
                if (response2 === Configs.fhtSupportRoleType.fhtSupportDisabledAccount) {
                  this.props.GlobalFunctionDisplaySnackbarMessage(
                    "This account has been disabled",
                    Configs.snackbarVariants.error
                  );
                  localStorage.removeItem(Configs.tokenInfo, response.token);
                } else if (response2 === Configs.fhtSupportRoleType.fhtSiteAccountNoAccess) {
                  this.props.GlobalFunctionDisplaySnackbarMessage(
                    "This account does not have access to this site",
                    Configs.snackbarVariants.error
                  );
                  localStorage.removeItem(Configs.tokenInfo, response.token);
                } else {
                  this.setState({ loading: false });
                  this.props.CallbackLoginDialogSuccess(response.userName);
                  var msg = response.fullName + " is logged in";
                  this.props.GlobalFunctionDisplaySnackbarMessage(
                    msg,
                    Configs.snackbarVariants.success
                  );
                  localStorage.setItem("userRole", response2);
                }
            });       
          }
        })
        .catch((error) => {
          console.log("login error:", error);
          var msg = String(error);
          this.props.GlobalFunctionDisplaySnackbarMessage(
            msg,
            Configs.snackbarVariants.error
          );
        });
    
  };

  render() {
    let vSpace = "5REM";
    let vSmallSpace = "1REM";
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        aria-labelledby="login Dialog"
        open={true}
        fullWidth={true}
      >
        <form onSubmit={(e) => this.onBtnSubmitLogin(e)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div
              style={{
                marginBottom: vSpace,
                marginTop: vSpace,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar style={{ backgroundColor: "#f50057" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                style={{ marginBottom: vSmallSpace, marginTop: vSmallSpace }}
                component="h1"
                variant="h5"
              >
                Please log in
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                disabled={this.state.loading}
                autoFocus={true}
                value={this.state.username || ""}
                onChange={(event) =>
                  this.setState({ username: event.target.value })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={this.state.loading}
                value={this.state.password || ""}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              />
              <div>
                <Button
                  style={{ marginBottom: vSmallSpace, marginTop: vSmallSpace }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </Container>
        </form>
      </Dialog>
    );
  }
}
export default withStyles(useStyles)(Login);
