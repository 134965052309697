import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";


import { compareDateTimes, utcToLocal} from "./HelperFunctions";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

export class FHTLogsSelections extends React.Component {
    constructor(props) {
      super(props);
      this.pageOptions = {
        pageSize: 10,
        search: true,
        searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
        pageSizeOptions: [10, 20, 30],
        filtering: true,
      };
      this.selectionOptions = {
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        selection: true,
      };
  
      this.triggers = {};
      this.snackbarMessage = "";
      this.snackbarVariant = "success";
      this.state = {
        logTable: [
            // { title: 'Site Id', field: 'fhtLogSiteId'},
            { title: "Site Name", field: "fhtSiteName", width:"17%" },
            { title: "Log received", field: "fhtLogDateLogReceivedAESTConverted",
              customSort : (a,b) => compareDateTimes(a.fhtLogDateLogReceived, b.fhtLogDateLogReceived)},
            { title: "Process Start", field: "fhtLogDateProcessStartedAESTConverted",
              customSort : (a,b) => compareDateTimes(a.fhtLogDateProcessStarted, b.fhtLogDateProcessStarted) },
            { title: "Process Finish", field: "fhtLogDateProcessFinishedAESTConverted",
              customSort : (a,b) => compareDateTimes(a.fhtLogDateProcessFinished, b.fhtLogDateProcessFinished) },
            { title: "Total Time (minutes)", field: "totalProcessTime",width:"7%" },
            { title: "Status", field: "successful",width:"6%" },
            { title: "Text", field: "fhtLogTextInfomation", width:"48%"},
          ],
          isLoaded : false,
          rowsPerPage : 10,
          showingLogs : [],
        };
    }

    async getFhtLogs(numDays){
      // Get the logs in the last X days
      //console.log("Getting fht logs...")
      this.setState({isLoaded : false});
      
      const api = FetchApi("GetFhtLogs/Days/" + numDays);
      let body = {
        SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      };
      await api.post(body).then((response)=>{
        response.map(r => {
          r.fhtLogDateLogReceivedAESTConverted = utcToLocal(r.fhtLogDateLogReceived);
          r.fhtLogDateProcessStartedAESTConverted = utcToLocal(r.fhtLogDateProcessStarted);
          r.fhtLogDateProcessFinishedAESTConverted = utcToLocal(r.fhtLogDateProcessFinished);
        })
        this.setState({showingLogs : response});
        //console.log("Logs", response);
      })
      
      this.setState({isLoaded : true});
    }


    componentDidMount = () => {
      this.getFhtLogs(30);
    }
    render() {
      const { classes } = this.props;
      return(
        <div className={classes.root}>
          {this.renderDateSelection()}
          <br/>
          <br/>
          {this.renderLog()}
        </div>
      );
    } 
    
    renderDateSelection = ()=>{
      const { classes } = this.props;
      return(
        <Grid container>
          <Grid item xs={4}>
            <Button className="Button30d" onClick={()=>this.getFhtLogs(30)} variant="contained">
              Last month
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button className="Button90d" onClick = {()=>this.getFhtLogs(90)} variant="contained">
              Last quarter
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button className="Button365d" onClick = {()=>this.getFhtLogs(365)} variant="contained">
              Last year
            </Button>
          </Grid>
        </Grid>
      )
    }
    
    renderLog = ()=>{
      const { classes } = this.props;
      if(this.state.isLoaded){
        return (
          <MaterialTable
            columns={this.state.logTable}
            data={this.state.showingLogs}
            options={this.pageOptions}
            title="FHT Log"
          ></MaterialTable>
        );
      }
      else{
        return(
          <div>
            <CircularProgress></CircularProgress>
            Loading
          </div>
        )
      }
      
    };
}
export default withStyles(useStyles)(FHTLogsSelections);
