import React, { Component } from "react";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class SiteScriptsSummaryCellRender extends Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  siteScriptsSummaryCellRender() {
    var currentValue = this.props.scriptStatus
    var backgroundColorStatus = "#FF772EFF";
    var text = ""
    if (currentValue == Configs.SiteScriptsSummaryStatus.releasedToSite) {
      backgroundColorStatus = "#DED75DFF";
      text = "Released";
    }
    else if (currentValue == Configs.SiteScriptsSummaryStatus.successfullyInstalled) {
      backgroundColorStatus = "#4BB83BFF";
      text = "Success";
    }
    else if (currentValue == Configs.SiteScriptsSummaryStatus.failedToInstall) {
      backgroundColorStatus = "#F74D69FF";
      text = "Fail";
    }
    else if (currentValue == Configs.SiteScriptsSummaryStatus.notReleasedToSite) {
      backgroundColorStatus = "#A29EA3FF";
      text = "Not released";
    }

    return (
      <div style={{ backgroundColor: backgroundColorStatus, padding: "0.5REM", textAlign: "center", color: "white" }}>{text}</div>
    )
  }

  render() {
    return (
      <div>
        {this.siteScriptsSummaryCellRender()}
      </div>
    )
  }
}

export default SiteScriptsSummaryCellRender;