import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import MaterialTable from "material-table";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  utcToLocal,
  compareDateTimes,
  backgroundColourFromLetter,
} from "../HelperFunctions";
import { Card } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CDialog from "Component/CDialog";
import FolderShared from "@material-ui/icons/FolderShared";
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";

class DrqGenerateQuery extends Component {
  constructor(props) {
    super();
    this.state = {
      existingQueries: [],
      saveButtonDisabled: false,
      selectedQuery: 0,
      expandPatientDetailsAccordion: false,
      expandPathologyDetailsAccordion: false,
      expandPatientConditionsAccordion: false,
      expandPatientAllergiesAccordion: false,
      expandPatientRecommendationAccordion: false,
      expandPatientCohortAccordion: false,
      expandMedicationsAccordion: false,

      // Static FHT data
      pathologyTypeList: [],
      algorithmList: [],
      triggerList: [],

      // Patient details
      minAge: 18,
      maxAge: 200,
      maleGenderCheck: false,
      femaleGenderCheck: false,
      otherGenderCheck: false,
      firstNationsCheck: false,
      pregnantCheck: false,
      smokerCheck: false,
      exsmokerCheck: false,
      nonsmokerCheck: false,
      racgpActiveCheck: false,

      // pathology filter details
      // requires ability to add multiple pathology records

      // text file list uploads
      pathologyQueryList: [],
      conditionQueryList: [],
      medicationQueryList: [],
      allergyNameMatch: [],
      // cohort related checks
      cohortNameMatch: [],
      cohortAlgorithmIdList: [],
      recommendationIdList: [],
      triggerIdList: [],

      showDisplaySaveQueryDialogbox: false,
      queryName: "",
    };

    FetchApi("GetDRQPathologyTypes").get().then((response) => {
      this.setState({ pathologyTypeList: response });
      console.log("DRQ pathology types", response);
    });

    FetchApi("GetDRQAlgorithms").get().then((response) => {
      this.setState({ algorithmList: response });
      console.log("DRQ algorithms", response);
    });

    FetchApi("GetDRQTriggers").get().then((response) => {
      this.setState({ triggerList: response });
      console.log("DRQ triggers", response);
    });

    this.InitialiseDrqQueryList();
  }
  InitialiseDrqQueryList = () => {
    this.state.existingQueries.push({ 0: "New query" });
  };
  handleChangeSelectQuery = (e) => {
    this.setState({ selectedQuery: e.target });
  };

  getPatientDetailsFilterText = () => {
    const filterList = [];
    if (this.state.minAge !== undefined)
      filterList.push(`Minimum age: ${this.state.minAge}`);
    if (this.state.maxAge !== undefined)
      filterList.push(`Maximum age: ${this.state.maxAge}`);
    if (this.state.maleGenderCheck)
      filterList.push("Gender: 'Male'");
    if (this.state.femaleGenderCheck)
      filterList.push("Gender: 'Female'");
    if (this.state.otherGenderCheck)
      filterList.push("Gender: 'Other'");
    if (this.state.smokerCheck)
      filterList.push("Smoker");
    if (this.state.exsmokerCheck)
      filterList.push("Ex-smoker");
    if (this.state.nonsmokerCheck)
      filterList.push("Non-smoker");
    if (this.state.pregnantCheck)
      filterList.push("Pregnant");
    if (this.state.racgpActiveCheck)
      filterList.push("RACGP active");
    if (this.state.firstNationsCheck)
      filterList.push("First nations");

    return filterList.join(", ");
  };

  getFilterText = (filterLength, context = null) => {
    if (context === null) {
      if (filterLength === 0) {
        return "No filters";
      } else if (filterLength === 1) {
        return "1 filter";
      } else {
        return `${filterLength} filters`;
      }
    } else {
      if (filterLength === 0) {
        return `No ${context} filters`;
      } else if (filterLength === 1) {
        return `1 ${context} filter`;
      } else {
        return `${filterLength} ${context} filters`;
      }
    }
  };

  btnAddPathologyQuery = () => {
    const pathologyQueryList = this.state.pathologyQueryList || [];
    pathologyQueryList.push({
      pathologyDateMin: new Date(2020, 1, 1).toLocaleDateString('fr-CA'),
      pathologyDateMax: new Date().toLocaleDateString('fr-CA'),
      minValue: null,
      maxValue: null,
      pathologyTypeId: null,
      operationCode: 1,
    });
    this.setState({ pathologyQueryList });
  };

  btnAddConditionQuery = () => {
    const conditionQueryList = this.state.conditionQueryList || [];
    conditionQueryList.push({
      conditionDateMin: new Date(2020, 1, 1).toLocaleDateString('fr-CA'),
      conditionDateMax: new Date().toLocaleDateString('fr-CA'),
      conditionNameMatch: [],
      ignoreConditionResolved: false,
      operationCode: 1,
    });
    this.setState({ conditionQueryList });
  };

  btnAddMedicationQuery = () => {
    const medicationQueryList = this.state.medicationQueryList || [];
    medicationQueryList.push({
      medicationDateFirstPrescribed: new Date(2020, 1, 1).toLocaleDateString('fr-CA'),
      medicationDateLastPrescribed: new Date().toLocaleDateString('fr-CA'),
      medicationNameMatch: [],
      operationCode: 1,
    });
    this.setState({ medicationQueryList });
  };

  btnSaveQueryClicked = () => {
    this.setState({ showDisplaySaveQueryDialogbox: true });
  };
  onBtnSaveQuery = () => {
    console.log("Saving query to:CreateDRQQuery");
    let body = {
      queryName: this.state.queryName,
      minage: this.state.minAge,
      maxage: this.state.maxAge,
      maleGenderCheck: this.state.maleGenderCheck,
      femaleGenderCheck: this.state.femaleGenderCheck,
      otherGenderCheck: this.state.otherGenderCheck,
      firstNationsCheck: this.state.firstNationsCheck,
      pregnantCheck: this.state.pregnantCheck,
      smokerCheck: this.state.smokerCheck,
      exsmokerCheck: this.state.exsmokerCheck,
      nonsmokerCheck: this.state.nonsmokerCheck,
      racgpActiveCheck: this.state.racgpActiveCheck,

      pathologyQueryList: this.state.pathologyQueryList,
      conditionQueryList: this.state.conditionQueryList,
      medicationQueryList: this.state.medicationQueryList,
      allergyNameMatch: this.state.allergyNameMatch,

      cohortNameMatch: this.state.cohortNameMatch,
      cohortAlgorithmIdList: this.state.cohortAlgorithmIdList,
      recommendationIdList: this.state.recommendationIdList,
      triggerIdList: this.state.triggerIdList,
    };
    const api = FetchApi("CreateDRQQuery");
    console.log("submitting body:", body);
    api
      .post(body)
      .then((response) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Query created successfully",
          Configs.snackbarVariants.success
        );
        this.setState({ showDisplaySaveQueryDialogbox: false });
      })
      .catch((error) => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          msg,
          Configs.snackbarVariants.error
        );
      });
  };

  renderPathologyQuery(pathologyQuery, index) {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        lg={4}
      >
        <Paper variant="outlined">
          <Box padding={2}>
            <Box display="flex" flexDirection="row">
              <Typography variant="h6" style={{ flexGrow: 1 }}>Pathology filter #{index + 1}</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={(e) => {
                  const pathologyQueryList = this.state.pathologyQueryList;
                  pathologyQueryList.splice(index, 1);
                  this.setState({ pathologyQueryList });
                }}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <Typography variant="subtitle1" style={{ flexGrow: 1 }} color="textSecondary">
              {this.state.pathologyTypeList.find(x => x.referenceIntValue === pathologyQuery.pathologyTypeId)?.referenceStringValue}
            </Typography>
            <Autocomplete
              fullWidth
              id="pathologytype"
              options={this.state.pathologyTypeList.map(x => ({ label: x.referenceName, id: x.referenceIntValue }))}
              getOptionLabel={(x) => x.label}
              value={this.state.pathologyTypeList.map(x => ({ label: x.referenceName, id: x.referenceIntValue })).find(x => x.id === pathologyQuery.pathologyTypeId) ?? null}
              margin="dense"
              renderInput={(params) => <TextField {...params} label="Pathology Type" />}
              onChange={(e, newValue) => {
                const pathologyQueryList = this.state.pathologyQueryList;
                pathologyQuery.pathologyTypeId = newValue ? newValue.id : null;
                pathologyQueryList[index] = pathologyQuery;
                this.setState({ pathologyQueryList });
              }}
            />
            <TextField
              fullWidth
              id="mindate"
              label="Minimum date"
              type="date"
              value={pathologyQuery.pathologyDateMin}
              margin="dense"
              onChange={(e) => {
                const pathologyQueryList = this.state.pathologyQueryList;
                pathologyQuery.pathologyDateMin = e.target.value;
                pathologyQueryList[index] = pathologyQuery;
                this.setState({ pathologyQueryList });
              }}
            />
            <TextField
              fullWidth
              id="maxdate"
              label="Maximum date"
              type="date"
              value={pathologyQuery.pathologyDateMax}
              margin="dense"
              onChange={(e) => {
                const pathologyQueryList = this.state.pathologyQueryList;
                pathologyQuery.pathologyDateMax = e.target.value;
                pathologyQueryList[index] = pathologyQuery;
                this.setState({ pathologyQueryList });
              }}
            />
            <TextField
              fullWidth
              type="number"
              label="Minimum value"
              value={pathologyQuery.minvalue}
              margin="dense"
              onChange={(e) => {
                const pathologyQueryList = this.state.pathologyQueryList;
                pathologyQuery.minvalue = e.target.value;
                pathologyQueryList[index] = pathologyQuery;
                this.setState({ pathologyQueryList });
              }}
            />
            <TextField
              fullWidth
              type="number"
              label="Maximum value"
              value={pathologyQuery.maxvalue}
              margin="dense"
              onChange={(e) => {
                const pathologyQueryList = this.state.pathologyQueryList;
                pathologyQuery.maxvalue = e.target.value;
                pathologyQueryList[index] = pathologyQuery;
                this.setState({ pathologyQueryList });
              }}
            />
          </Box>
        </Paper>
      </Grid>
    );
  }

  renderConditionQuery(conditionQuery, index) {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        lg={4}
      >
        <Paper variant="outlined">
          <Box padding={2}>
            <Box display="flex" flexDirection="row">
              <Typography variant="h6" style={{ flexGrow: 1 }}>Condition filter #{index + 1}</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={(e) => {
                  const conditionQueryList = this.state.conditionQueryList;
                  conditionQueryList.splice(index, 1);
                  this.setState({ conditionQueryList });
                }}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionQuery.ignoreConditionResolved}
                  onChange={(e) => {
                    const conditionQueryList = this.state.conditionQueryList;
                    conditionQuery.ignoreConditionResolved = e.target.checked;
                    conditionQueryList[index] = conditionQuery;
                    this.setState({ conditionQueryList });
                  }}
                />
              }
              label="Allow unresolved conditions"
            />
            <TextField
              fullWidth
              id="mindate"
              label="Minimum date"
              type="date"
              value={conditionQuery.conditionDateMin}
              margin="dense"
              onChange={(e) => {
                const conditionQueryList = this.state.conditionQueryList;
                conditionQuery.conditionDateMin = e.target.value;
                conditionQueryList[index] = conditionQuery;
                this.setState({ conditionQueryList });
              }}
            />
            <TextField
              fullWidth
              id="maxdate"
              label="Maximum date"
              type="date"
              value={conditionQuery.conditionDateMax}
              margin="dense"
              onChange={(e) => {
                const conditionQueryList = this.state.conditionQueryList;
                conditionQuery.conditionDateMax = e.target.value;
                conditionQueryList[index] = conditionQuery;
                this.setState({ conditionQueryList });
              }}
            />
            {conditionQuery.conditionNameMatch.map((conditionNameMatch, matchIndex) => (
              <Box>
                {matchIndex !== 0 && <Typography align="center">OR</Typography>}
                <Box display="flex" flexDirection="row">
                  <TextField
                    fullWidth
                    id="conditionMatch"
                    label="Condition"
                    value={conditionNameMatch.name}
                    helperText={conditionNameMatch.isExactMatch ? `Exactly matches '${conditionNameMatch.name}'` : `Contains '${conditionNameMatch.name}'`}
                    margin="dense"
                    onChange={(e) => {
                      const conditionQueryList = this.state.conditionQueryList;
                      conditionQuery.conditionNameMatch[matchIndex].name = e.target.value;
                      conditionQueryList[index] = conditionQuery;
                      this.setState({ conditionQueryList });
                    }}
                  />
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <Button
                      onClick={(e) => {
                        const conditionQueryList = this.state.conditionQueryList;
                        conditionQuery.conditionNameMatch[matchIndex].isExactMatch = !conditionNameMatch.isExactMatch;
                        conditionQueryList[index] = conditionQuery;
                        this.setState({ conditionQueryList });
                      }}>
                      {conditionNameMatch.isExactMatch ? "Exact" : "Contains"}
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        const conditionQueryList = this.state.conditionQueryList;
                        conditionQuery.conditionNameMatch.splice(matchIndex, 1);
                        conditionQueryList[index] = conditionQuery;
                        this.setState({ conditionQueryList });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
            <Button
              fullWidth
              type="submit"
              variant="text"
              onClick={(e) => {
                const conditionQueryList = this.state.conditionQueryList;
                conditionQuery.conditionNameMatch.push({
                  isExactMatch: false,
                  name: "",
                });

                conditionQueryList[index] = conditionQuery;
                this.setState({ conditionQueryList });
              }}
            >
              Add Condition Filter
            </Button>
          </Box>
        </Paper>
      </Grid>
    );
  }

  renderAllergyQuery() {
    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
      >
        <Paper variant="outlined">
          <Box padding={2}>
            {this.state.allergyNameMatch.length === 0 && (
              <Box paddingBottom={2}>
                <Typography align="center">No allergy filters</Typography>
              </Box>
            )}
            {this.state.allergyNameMatch.map((allergyNameMatch, index) => (
              <Box>
                {index !== 0 && <Typography align="center">OR</Typography>}
                <Box display="flex" flexDirection="row">
                  <TextField
                    fullWidth
                    id="allergyMatch"
                    label="Allergy"
                    value={allergyNameMatch.name}
                    helperText={allergyNameMatch.isExactMatch ? `Exactly matches '${allergyNameMatch.name}'` : `Contains '${allergyNameMatch.name}'`}
                    margin="dense"
                    onChange={(e) => {
                      const allergyNameMatch = this.state.allergyNameMatch;
                      allergyNameMatch[index].name = e.target.value;
                      this.setState({ allergyNameMatch });
                    }}
                  />
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <Button
                      onClick={(e) => {
                        const allergyNameMatch = this.state.allergyNameMatch;
                        allergyNameMatch[index].isExactMatch = !allergyNameMatch[index].isExactMatch;
                        this.setState({ allergyNameMatch });
                      }}
                    >
                      {allergyNameMatch.isExactMatch ? "Exact" : "Contains"}
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        const allergyNameMatch = this.state.allergyNameMatch;
                        allergyNameMatch.splice(index, 1);
                        this.setState({ allergyNameMatch });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
            <Button
              fullWidth
              type="submit"
              variant="text"
              onClick={(e) => {
                const allergyNameMatch = this.state.allergyNameMatch;
                allergyNameMatch.push({
                  isExactMatch: false,
                  name: "",
                });

                this.setState({ allergyNameMatch });
              }}
            >
              Add Allergy Filter
            </Button>
          </Box>
        </Paper>
      </Grid>
    );
  }

  renderCohortQuery() {
    return (
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Switch
              checked={this.state.cohortQueryUseCohortName}
              onChange={(e) => {
                this.setState({
                  cohortQueryUseCohortName: e.target.checked,
                  cohortNameMatch: [],
                  cohortAlgorithmIdList: []
                });
              }}
            />
          }
          label="Use cohort name"
        />
        {this.state.cohortQueryUseCohortName ?
          <Paper variant="outlined">
            <Box padding={2}>
              {this.state.cohortNameMatch.length === 0 && (
                <Box paddingBottom={2}>
                  <Typography align="center">No cohort filters</Typography>
                </Box>
              )}
              {this.state.cohortNameMatch.map((cohortNameMatch, index) => (
                <Box>
                  {index !== 0 && <Typography align="center">OR</Typography>}
                  <Box display="flex" flexDirection="row">
                    <TextField
                      fullWidth
                      id="cohortMatch"
                      label="Cohort name"
                      value={cohortNameMatch.name}
                      helperText={cohortNameMatch.isExactMatch ? `Exactly matches '${cohortNameMatch.name}'` : `Contains '${cohortNameMatch.name}'`}
                      margin="dense"
                      onChange={(e) => {
                        const cohortNameMatch = this.state.cohortNameMatch;
                        cohortNameMatch[index].name = e.target.value;
                        this.setState({ cohortNameMatch });
                      }}
                    />
                    <Box display="flex" alignItems="center" paddingLeft={1}>
                      <Button
                        onClick={(e) => {
                          const cohortNameMatch = this.state.cohortNameMatch;
                          cohortNameMatch[index].isExactMatch = !cohortNameMatch[index].isExactMatch;
                          this.setState({ cohortNameMatch });
                        }}>
                        {cohortNameMatch.isExactMatch ? "Exact" : "Contains"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
              <Button
                fullWidth
                type="submit"
                variant="text"
                onClick={(e) => {
                  const cohortNameMatch = this.state.cohortNameMatch;
                  cohortNameMatch.push({
                    isExactMatch: false,
                    name: "",
                  });

                  this.setState({ cohortNameMatch });
                }}
              >
                Add Cohort Filter
              </Button>
            </Box>
          </Paper>
          :
          <Autocomplete
            fullWidth
            multiple
            id="cohort-algorithm"
            options={this.state.algorithmList}
            getOptionLabel={x => x?.fhtEnabledAnalysisShortDescription ?? x?.fhtEnabledAnalysisDescription}
            value={this.state.cohortAlgorithmIdList.map(x => this.state.algorithmList.find(y => x === y.fhtEnabledAnalysisTestId))}
            margin="dense"
            renderInput={(params) => <TextField {...params} label="Cohort Algorithms" />}
            onChange={(e, newValue) => {
              const cohortAlgorithmIdList = newValue.map(x => x.fhtEnabledAnalysisTestId);
              this.setState({ cohortAlgorithmIdList });
            }}
          />
        }
      </Grid>
    );
  }

  renderRecommendationQuery() {
    return (
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          fullWidth
          multiple
          id="recommendation-id-list"
          options={this.state.algorithmList}
          getOptionLabel={x => x?.fhtEnabledAnalysisShortDescription ?? x?.fhtEnabledAnalysisDescription}
          value={this.state.recommendationIdList.map(x => this.state.algorithmList.find(y => x === y.fhtEnabledAnalysisTestId))}
          margin="dense"
          renderInput={(params) => <TextField {...params} label="Recommendation IDs" />}
          onChange={(e, newValue) => {
            const recommendationIdList = newValue.map(x => x.fhtEnabledAnalysisTestId);
            this.setState({ recommendationIdList });
          }}
        />
      </Grid>
    );
  }

  renderTriggerQuery() {
    return (
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          fullWidth
          multiple
          id="trigger-id-list"
          options={this.state.triggerList}
          getOptionLabel={x => x?.fhtEnabledTriggersDescription}
          value={this.state.triggerIdList.map(x => this.state.triggerList.find(y => x === y.fhtEnabledTriggersId))}
          margin="dense"
          renderInput={(params) => <TextField {...params} label="Trigger IDs" />}
          onChange={(e, newValue) => {
            const triggerIdList = newValue.map(x => x.fhtEnabledTriggersId);
            this.setState({ triggerIdList });
          }}
        />
      </Grid>
    );
  }

  renderMedicationQuery(medicationQuery, index) {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        lg={4}
      >
        <Paper variant="outlined">
          <Box padding={2}>
            <Box display="flex" flexDirection="row">
              <Typography variant="h6" style={{ flexGrow: 1 }}>Condition filter #{index + 1}</Typography>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={(e) => {
                  const medicationQueryList = this.state.medicationQueryList;
                  medicationQueryList.splice(index, 1);
                  this.setState({ medicationQueryList });
                }}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              id="firstprescribed"
              label="First prescribed date"
              type="date"
              value={medicationQuery.medicationDateFirstPrescribed}
              margin="dense"
              onChange={(e) => {
                const medicationQueryList = this.state.medicationQueryList;
                medicationQuery.medicationDateFirstPrescribed = e.target.value;
                medicationQueryList[index] = medicationQuery;
                this.setState({ medicationQueryList });
              }}
            />
            <TextField
              fullWidth
              id="lastprescribed"
              label="Last prescribed date"
              type="date"
              value={medicationQuery.medicationDateLastPrescribed}
              margin="dense"
              onChange={(e) => {
                const medicationQueryList = this.state.medicationQueryList;
                medicationQuery.medicationDateLastPrescribed = e.target.value;
                medicationQueryList[index] = medicationQuery;
                this.setState({ medicationQueryList });
              }}
            />
            {medicationQuery.medicationNameMatch.map((medicationNameMatch, matchIndex) => (
              <Box>
                {matchIndex !== 0 && <Typography align="center">OR</Typography>}
                <Box display="flex" flexDirection="row">
                  <TextField
                    fullWidth
                    id="medicationMatch"
                    label="Medication"
                    value={medicationNameMatch.name}
                    helperText={medicationNameMatch.isExactMatch ? `Exactly matches '${medicationNameMatch.name}'` : `Contains '${medicationNameMatch.name}'`}
                    margin="dense"
                    onChange={(e) => {
                      const medicationQueryList = this.state.medicationQueryList;
                      medicationQuery.medicationNameMatch[matchIndex].name = e.target.value;
                      medicationQueryList[index] = medicationQuery;
                      this.setState({ medicationQueryList });
                    }}
                  />
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <Button
                      onClick={(e) => {
                        const medicationQueryList = this.state.medicationQueryList;
                        medicationQuery.medicationNameMatch[matchIndex].isExactMatch = !medicationNameMatch.isExactMatch;
                        medicationQueryList[index] = medicationQuery;
                        this.setState({ medicationQueryList });
                      }}>
                      {medicationNameMatch.isExactMatch ? "Exact" : "Contains"}
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" paddingLeft={1}>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        const medicationQueryList = this.state.medicationQueryList;
                        medicationQuery.medicationNameMatch.splice(matchIndex, 1);
                        medicationQueryList[index] = medicationQuery;
                        this.setState({ medicationQueryList });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
            <Button
              fullWidth
              type="submit"
              variant="text"
              onClick={(e) => {
                const medicationQueryList = this.state.medicationQueryList;
                medicationQuery.medicationNameMatch.push({
                  isExactMatch: false,
                  name: "",
                });

                medicationQueryList[index] = medicationQuery;
                this.setState({ medicationQueryList });
              }}
            >
              Add Medication Filter
            </Button>
          </Box>
        </Paper>
      </Grid>
    );
  }

  render() {
    const saveQueryDialogFooter = (
      <Button
        variant="contained"
        fullWidth
        style={{ margin: "24px 0px 16px" }}
        color="primary"
        onClick={(e) => this.onBtnSaveQuery()}
      >
        Save
      </Button>
    );
    let fullsize = 12;
    let smallsize = 2;
    let spacingbetweentickgroups = 1;
    let accordianTypographyStyle = { width: "30%", fontSize: "1.3REM" };
    let borderStyle = { border: "1px solid lightGray", borderSpacing: "10REM" };
    let marginInBox = {
      marginLeft: "1REM",
      marginBottom: "1REM",
      marginTop: "1REM",
    };
    let marginInBoxSmallerItem = {
      marginLeft: "1REM",
      marginBottom: "1REM",
      marginTop: "1REM",
      width: "80%",
    };
    console.log("Sean check:", new Date().toLocaleDateString('fr-CA'));
    return (
      <div>
        <Grid container>
          <div
            style={{
              marginTop: "1REM",
              marginLeft: "1REM",
              marginRight: "1REM",
              width: "100%",
            }}
          >
            <Grid container>
              <Grid item xs={12} style={{ height: "6REM" }}>
                <Typography
                  variant="h3"
                  component="h1"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  Create a site query
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
            >
              <Box style={{ maxWidth: "60REM", minWidth: "60%" }}>
                <Grid item md={fullsize} xs={12} spacing={2} style={{ display: "none" }}>
                  <h4>Create a new query or use existing query as base</h4>
                  <div>
                    <FormGroup>
                      <Select
                        labelId="drq-select-label"
                        id="drq-simple-select"
                        value={this.state.selectedQuery}
                        label="drqQuery"
                        onChange={this.handleChangeSelectQuery}
                      >
                        <MenuItem value={0}>New query</MenuItem>
                      </Select>
                    </FormGroup>
                  </div>
                </Grid>
                <Box>
                  <Accordion
                    expanded={this.state.expandPatientDetailsAccordion}
                  >
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPatientDetailsAccordion: !this.state
                            .expandPatientDetailsAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header1"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient details
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getPatientDetailsFilterText()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        style={{
                          justifyContent: "left",
                          textAlign: "left",
                          gap: "1REM",
                        }}
                      >
                        <Grid
                          item
                          md={smallsize}
                          xs={12}
                          spacing={2}
                          style={borderStyle}
                        >
                          <FormGroup>
                            <FormLabel style={marginInBox}>
                              Patient's age range
                            </FormLabel>
                            <TextField
                              style={marginInBoxSmallerItem}
                              label="Min age"
                              id="outlined-size-normal"
                              variant="outlined"
                              onChange={(e) =>
                                this.setState({ minAge: e.target.value })
                              }
                              value={this.state.minAge}
                            />
                            <TextField
                              style={marginInBoxSmallerItem}
                              label="Max age"
                              id="outlined-size-normal"
                              variant="outlined"
                              onChange={(e) =>
                                this.setState({ maxAge: e.target.value })
                              }
                              value={this.state.maxAge}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          md={spacingbetweentickgroups}
                          xs={12}
                          spacing={2}
                        ></Grid>
                        <Grid
                          item
                          md={smallsize}
                          xs={12}
                          spacing={2}
                          style={borderStyle}
                        >
                          <FormGroup>
                            <FormLabel style={marginInBox}>Gender</FormLabel>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      maleGenderCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      femaleGenderCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Female"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      otherGenderCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Other"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          md={spacingbetweentickgroups}
                          xs={12}
                          spacing={2}
                        ></Grid>
                        <Grid
                          item
                          md={smallsize}
                          xs={12}
                          spacing={2}
                          style={borderStyle}
                        >
                          <FormGroup>
                            <FormLabel style={marginInBox}>Smoker</FormLabel>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      smokerCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Smoker"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      exsmokerCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Ex-smoker"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      nonsmokerCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Never smoked"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          md={spacingbetweentickgroups}
                          xs={12}
                          spacing={2}
                        ></Grid>
                        <Grid
                          item
                          md={smallsize}
                          xs={12}
                          spacing={2}
                          style={borderStyle}
                        >
                          <FormGroup>
                            <FormLabel style={marginInBox}>Others</FormLabel>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      pregnantCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Pregnant"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      racgpActiveCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="RACGP Active"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={marginInBox}
                                  onChange={(e) =>
                                    this.setState({
                                      firstNationsCheck: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label="First Nations"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expandPathologyDetailsAccordion}
                  >
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPathologyDetailsAccordion: !this.state.expandPathologyDetailsAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header2"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Pathology results
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText((this.state.pathologyQueryList ?? []).length)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.state.pathologyQueryList.map((pathologyQuery, index) => this.renderPathologyQuery(pathologyQuery, index))}
                      </Grid>
                      <Button
                        type="submit"
                        variant="text"
                        onClick={() => this.btnAddPathologyQuery()}
                      >
                        Add Pathology Query
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expandPatientConditionsAccordion}
                  >
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPatientConditionsAccordion: !this.state.expandPatientConditionsAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header3"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient conditions
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText((this.state.conditionQueryList ?? []).length)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.state.conditionQueryList.map((conditionQuery, index) => this.renderConditionQuery(conditionQuery, index))}
                      </Grid>
                      <Button
                        type="submit"
                        variant="text"
                        onClick={() => this.btnAddConditionQuery()}
                      >
                        Add Condition Query
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expandPatientAllergiesAccordion}
                  >
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPatientAllergiesAccordion: !this.state
                            .expandPatientAllergiesAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header4"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient allergies
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText((this.state.allergyNameMatch ?? []).length)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.renderAllergyQuery()}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={this.state.expandPatientCohortAccordion}>
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPatientCohortAccordion: !this.state
                            .expandPatientCohortAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header5"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient cohort
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText(((this.state.cohortQueryUseCohortName ? this.state.cohortNameMatch : this.state.cohortAlgorithmIdList) ?? []).length)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.renderCohortQuery()}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={this.state.expandPatientRecommendationAccordion}
                  >
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandPatientRecommendationAccordion: !this.state
                            .expandPatientRecommendationAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header6"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient recommendations
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText((this.state.recommendationIdList ?? []).length, "recommendation")}
                        {', '}
                        {this.getFilterText((this.state.triggerIdList ?? []).length, "trigger")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.renderRecommendationQuery()}
                        {this.renderTriggerQuery()}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={this.state.expandMedicationsAccordion}>
                    <AccordionSummary
                      onClick={() =>
                        this.setState({
                          expandMedicationsAccordion: !this.state.expandMedicationsAccordion,
                        })
                      }
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header7"
                    >
                      <Typography style={{ flexBasis: "30%" }}>
                        Patient medications
                      </Typography>
                      <Typography color="textSecondary">
                        {this.getFilterText((this.state.medicationQueryList ?? []).length)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 2 }}
                      >
                        {this.state.medicationQueryList.map((medicationQuery, index) => this.renderMedicationQuery(medicationQuery, index))}
                      </Grid>
                      <Button
                        type="submit"
                        variant="text"
                        onClick={() => this.btnAddMedicationQuery()}
                      >
                        Add Medication Query
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
            <Grid container style={{ gap: "5REM", width: "100%" }}>
              <Grid container style={{ gap: "5REM", width: "100%" }}>
                <Grid item md={fullsize} xs={12}></Grid>
                <Grid item md={fullsize} xs={12}>
                  <Box marginTop={2} display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={this.state.saveButtonDisabled}
                      onClick={() => this.btnSaveQueryClicked()}
                    >
                      Save Query
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid >
        <CDialog
          header="Save Query"
          headerIcon={<FolderShared />}
          visible={this.state.showDisplaySaveQueryDialogbox}
          footer={saveQueryDialogFooter}
          onSubmit={(e) => this.onBtnSaveQuery()}
          onHide={(e) =>
            this.setState({ showDisplaySaveQueryDialogbox: false })
          }
          maxWidth="xs"
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Query Name"
            multiline
            rows="4"
            fullWidth
            required
            value={this.state.queryName}
            onChange={(e) => this.setState({ queryName: e.target.value })}
            margin="normal"
            variant="outlined"
          />
        </CDialog>
      </div >
    );
  }
}

export default DrqGenerateQuery;
