import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SiteModulesGeneralLayout from "../SharedComponents/SiteModulesGeneralLayout";
import ReorderListGeneralComponent from "./ReorderListGeneralComponent"

class ConfigSiteRecChkEdit extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedAlgos: [],
      selectedSites: [],
      currentAlgoSelectionOrder: [],
      AlgoConfigMap: [],
      recAlgos: [],
      chkAlgos: [],
      uniqueActivatedAlgos: [],
    }
    this.selectedAlgoritmsParentFunction = this.selectedAlgoritmsParentFunction.bind(this);
    this.setRecOrModuleSelectionListOrder = this.setRecOrModuleSelectionListOrder.bind(this);
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.handleClearAllSelectedSites = this.handleClearAllSelectedSites.bind(this);
    this.handleClearAllConfigurationsSelected = this.handleClearAllConfigurationsSelected.bind(this);
  }

  componentDidMount() {
    this.getConfigAlgoMap();
    this.getAlgoMap();
  }

  getAlgoMap = () => {
    //Site Algo
    console.log("getting site algorithm map...");
    const api = FetchApi(Configs.api.getConfigEditAlgoMappingList)

    var RecOrChkSaveType = 0;
    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Chk
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Rec

    let body = {
      RecOrChkSaveType: RecOrChkSaveType,
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then(response => {
      this.setState({ siteAlgoMap: response.algosBySiteID, isLoaded: true });
      console.log("siteAlgoMap", response.algosBySiteID);
    })
  }
  
  // Get all configuration algorithms.
  getConfigAlgoMap = () => {
    //Site Algo
    console.log("getting all algorithm map")
    const api = FetchApi(Configs.api.getConfigAlgoMap);

    var RecOrChkSaveType = 0;
    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Chk
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Rec

    let body = {
      RecOrChkSaveType: RecOrChkSaveType,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then((response) => {
      console.log("getConfigAlgoMap", response);
      // combine the two dictionaries chk and rec
      this.setState({
        AlgoConfigMap: response.algoByDiseaseID,
        recAlgos: response.recAlgos,
        chkAlgos: response.chkAlgos
      });
      console.log("AlgoConfigMap", response.algoByDiseaseID, "recAlgos", this.state.recAlgos);
    });
  }

  handleClearAllSelectedSites() {
    this.setState({
      selectedSites: []
    })
  }

  handleClearAllConfigurationsSelected() {
    console.log("ChandleClearAllConfigurationsSelected");
    this.setState({
      selectedAlgos: []
    })
  }

  SharedComponentSiteGroupingCallback = (selectedSites) => {
    console.log("Callback received from site grouping:", selectedSites);
    this.setState({
      selectedSites: selectedSites
    })
    var algoToDisplay = this.state.siteAlgoMap
    // Loop through each rows and generate a union of algos.
    var unionAlgos = [];

    for (var i = 0; i < selectedSites.length; i++) {
      if (selectedSites[i].tableData.checked && selectedSites[i].fhtSiteId in algoToDisplay) {
        unionAlgos = [...new Set([...unionAlgos, ...algoToDisplay[selectedSites[i].fhtSiteId]])];
      }
    }

    // Generate a dictionary of how many sites have each algorithm activated.
    var algorithmDictCount = {}
    for (var i = 0; i < selectedSites.length; i++) {
      if (selectedSites[i].fhtSiteId in algoToDisplay) {
        for (var j = 0; j < algoToDisplay[selectedSites[i].fhtSiteId].length; j++) {
          var algorithmName = algoToDisplay[selectedSites[i].fhtSiteId][j];
          if (algorithmName in algorithmDictCount == false) {
            algorithmDictCount[algorithmName] = 1
          } else {
            algorithmDictCount[algorithmName] = algorithmDictCount[algorithmName] + 1;
          }
        }
      }
    }
    // Loop through the algorithmDictCount and determine if the total count of activated algorithms is less than selectedSites.
    // This indicates not all sites have the algorithm activated.
    var algosNotActiveInAllSites = [];
    for (const [algorithmName, activationCount] of Object.entries(algorithmDictCount)) {
      if (activationCount < selectedSites.length) {
        if (!algosNotActiveInAllSites.includes(algorithmName))
        algosNotActiveInAllSites.push(algorithmName)
      }
    }

    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig)
      unionAlgos = unionAlgos.filter(x => this.state.chkAlgos.includes(x));
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig)
      unionAlgos = unionAlgos.filter(x => this.state.recAlgos.includes(x));

    this.setState({ uniqueActivatedAlgos: algosNotActiveInAllSites });
    this.setState({ selectedAlgos: unionAlgos });
  };

  selectedAlgoritmsParentFunction(updatedSelectedRecommendations) {
    console.log("selectedAlgoritmsParentFunction updatedSelectedRecommendations", updatedSelectedRecommendations);

    if (this.state.currentAlgoSelectionOrder.length == 0) {
      this.setState({
        selectedAlgos: updatedSelectedRecommendations,
        currentAlgoSelectionOrder: updatedSelectedRecommendations
      })
    } else {
      if (updatedSelectedRecommendations.length >= this.state.currentAlgoSelectionOrder.length) {
        // Append to end of list.
        var differencesBetweenLists = updatedSelectedRecommendations.filter(x => !this.state.currentAlgoSelectionOrder.includes(x));
        var updatedOrderList = JSON.parse(JSON.stringify(this.state.currentAlgoSelectionOrder))
        var combinedArray = updatedOrderList.concat(differencesBetweenLists);
        this.setState({
          selectedAlgos: combinedArray,
          currentAlgoSelectionOrder: combinedArray
        })
      } else {
        // Remove algo from list.
        var differencesBetweenLists = this.state.currentAlgoSelectionOrder.filter(x => updatedSelectedRecommendations.includes(x));
        this.setState({
          selectedAlgos: differencesBetweenLists,
          currentAlgoSelectionOrder: differencesBetweenLists
        })
      }
    }
  }

  setRecOrModuleSelectionListOrder(newRecModuleOrder) {
    this.setState({
      selectedAlgos: newRecModuleOrder,
      currentAlgoSelectionOrder: newRecModuleOrder,
    })
  }

  updateConfigs() {
    var recAlgos = this.state.selectedAlgos;
    //Site Algo
    var selectedSitesAccountIDsArray = []
    var deepCopyCheckBox = JSON.parse(JSON.stringify(this.state.selectedSites))
    deepCopyCheckBox.map(x =>
      selectedSitesAccountIDsArray.push(x.fhtAccountId))

    var body = {}
    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig) {
      body = {
        RecOrChkSaveType: Configs.RecOrChkSaveType.Chk,
        fhtAccountIDs: selectedSitesAccountIDsArray,
        chkAlgos: recAlgos,
        recAlgos: [],
        StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      }
    }
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig) {
      body = {
        RecOrChkSaveType: Configs.RecOrChkSaveType.Rec,
        fhtAccountIDs: selectedSitesAccountIDsArray,
        chkAlgos: [],
        recAlgos: recAlgos,
        StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      }
    }

    console.log("posting", body);

    const api = FetchApi(Configs.api.postSiteAlgoMap);
    api.post(body).then((response) => {
      console.log("postSiteAlgoMap response", response);
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized");
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
      // Refresh the data
      this.getAlgoMap(true);
    }).catch((err) => {
      console.log("postSiteAlgoMap err", err);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })
  }

  renderConfigSiteRecChkEdit = () => {
    // account
    const { classes } = this.props;
    var pageTitle = "";
    var reorderListTitle = "";
    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig) {
      pageTitle = "Site Checks"
      reorderListTitle = "Order of selected checking algorithms"
    }
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig) {
      pageTitle = "Site Recommendations"
      reorderListTitle = "Order of selected recommendation algorithms"
    }

    return (
      <div>
        <SiteModulesGeneralLayout
          selectedSites={this.state.selectedSites}
          selectedAlgoritmsParentFunction={this.selectedAlgoritmsParentFunction}
          AlgoConfigMap={this.state.AlgoConfigMap}
          SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
          uniqueActivatedAlgos={this.state.uniqueActivatedAlgos}
          selectedAlgos={this.state.selectedAlgos}
          handleClearAllConfigurationsSelected={this.handleClearAllConfigurationsSelected}
          pageTitle={pageTitle}
          configType={this.props.configType}
        />
        <Grid container justify="center">
          <Grid container spacing={10} style={{ width: "100%" }}>
            <Grid item xs={4}>
              {/* Empty rendering space for ReorderList to be on right hand side. */}
            </Grid>
            <Grid item xs={7}>
              <ReorderListGeneralComponent
                listToUse={this.state.selectedAlgos}
                reorderListHeading={reorderListTitle}
                reorderListType={"recAlgos"}
                setNewListToUseOrder={this.setRecOrModuleSelectionListOrder}
              />
              {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE
                || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) ?
                <div></div> :
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.updateConfigs();
                    }}
                  >
                    Update configurations
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfigSiteRecChkEdit()} <br />
      </div>
    )
  }
}

export default ConfigSiteRecChkEdit;