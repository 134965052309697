import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarComponent from "../Component/SnackbarComponent";
import MaterialTable from "material-table";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

import Home from "./Home";

import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { utcToLocal, compareDateTimes} from "./HelperFunctions";


import { Link } from "react-router-dom";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
    };
    this.lodgeRecommendationPageOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      searchText: "",
      search: true,
    };

    this.triggers = {};
    this.snackbarMessage = "";
    this.snackbarVariant = "success";
    // this.heartbeats = [];
    this.state = {
      drawerOpen: false,
      selectedMenu: 0,
      overallStatusTable: [
        { title: "Site", field: "fhtSiteName" },
        { title: "Total Updated", field: "updateCount" },
        { title: "Total Requests", field: "totalRequest" },
        { title: "Unique Users", field: "uniqueUser" },
      ],
      localVersionTable: [
        { title: "Machine Id", field: "fhtLocalVersionMachineId" },
        { title: "Machine Name", field: "fhtMachineOnSiteMachineName" },
        { title: "Version ID", field: "fhtLocalVersionCurrentVersionId" },
        { title: "Version Number", field: "fhtLocalVersionCurrentVersion" },
      ],
      logTable: [
        // { title: 'Site Id', field: 'fhtLogSiteId'},
        { title: "Site Name", field: "SiteName", width:"17%" },
        { title: "Log received", field: "FhtLogDateLogReceived" },
        { title: "Process Start", field: "FhtLogDateProcessStarted" },
        { title: "Process Finish", field: "FhtLogDateProcessFinished" },
        { title: "Total Time (mins)", field: "totalProcessTime",width:"7%" },
        { title: "Status", field: "ProcessSuccessful",width:"6%" },
        { title: "Text", field: "FhtLogTextInfomation", width:"48%"},
      ],
      hbTable: [
        { title: "Machine Id", field: "fhtHeartbeatMachineId" },
        { title: "Machine Name", field: "fhtMachineOnSiteMachineName" },
        { title: "Last Log", field: "lastLog" },
      ],
      
      queryTable: [
        { title: "Site", field: "fhtSiteName", editable: "never", width:"15%" },
        {
          title: "Recommendation Description",
          field: "fhtEnabledAnalysisDescription",
          editable: "never", width:"15%"
        },
        { title: "Triggers", field: "fhtTriggerDesc", editable: "never",width:"20%"},
        {
          title: "Query",
          field: "fhtLodgeRecommendationComment",
          editable: "never",
          width:"15%"
        },
        { title: "Resolution", field: "fhtLodgeRecommendationJustification",width:"25%"},
        {
          title: "Status",
          field: "fhtLodgeRecommendationStatus",
          lookup: Configs.queryStatus,
          width:"7%"
        },
        {
          title: "Date",
          field: "fhtLodgeRecommendationAESTDate",
          editable: "never",
          type: 'dateTime',
          width:"15%",
          customSort : (a,b) => compareDateTimes(a.fhtLodgeRecommendationDate, b.fhtLodgeRecommendationDate)
        },
      ],
      overallStatus: [],
      heartbeats: [],
      sites: [],
      systems: [],
      selectedSite: {},
      selectedSystem: {},
      localVersions: [],
      machines: [],
      releases: [],
      releaseVersion: "",
      logs: [],
      recommendationQuery: [],
      recommendationDescription:[],
      UserName:[]
    };
    // Moved to CDM
    // this.getTriggerDesc();
    // this.getPocStatus();
    // this.getSystem();
    // this.getSite();
    // this.getHeartbeat();
  }

  componentDidMount() {
    this.getRecommendationQuery();
    //this.getPocStatus();
    this.getSystem();
    this.getSite();
    // this.getHeartbeat();
  }

  getHeartbeat = (siteId) => {
    console.log(siteId);
    const api = FetchApi(`${Configs.api.getAutoupdaterHeartbeat}/${siteId}`);
    api.get().then((response) => {
      response.map((r) => {
        r.lastLog = utcToLocal(r.fhtHeartbeatLastlog);
        // let utcDate = r.fhtHeartbeatLastlog.toString();
        // let sd = new Date(`${utcDate}Z`);
        // r.lastLog = `${sd.getDate()}/${
        //   sd.getMonth() + 1
        // }/${sd.getFullYear()} ${sd.getHours()}:${sd.getMinutes()}`;
        // return null;
      });
      this.setState({heartbeats: response});
      // this.heartbeats = response;
    });
  };



  copyToClipboard = () => {
    let text = "This is copied text";
    console.log(text);
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    // dummy.setAttribute('id', 'mytext');
    // document.getElementById("mytext").value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  getPocStatus = () => {
    const api = FetchApi(Configs.api.getPocStatus);
    api.get().then((response) => {
      this.setState({ overallStatus: response });
    });
  };

  getSite = () => {
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response = response.sort((a, b) => (a.fhtSiteName > b.fhtSiteName) ? 1 : -1)
      this.setState({ sites: response });
    });
  };

  getSystem = () => {
    const api = FetchApi(Configs.api.getSystem);
    api.get().then((response) => {
      this.setState({ systems: response });
    });
  };

  getLocalVersion = () => {
    const api = FetchApi(Configs.api.getLocalVersion);
    api.get().then((response) => {
      this.setState({ localVersions: response });
    });
  };

  getLasteRelease = () => {
    const api = FetchApi(Configs.api.getLasteRelease);
    api.get().then((response) => {
      this.setState({ releases: response }, () => {});
    });
  };

  getFhtLog = () => {
    //*
    console.log("Fetching logs");
    const api = FetchApi(Configs.api.getFhtLogV2);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response =>{
      this.setState({logs:response});
      console.log("logs", this.state.logs);
    }
    );
    
  };

  getRecommendationQuery = async() => {
    const api = FetchApi(Configs.api.getRecommendationQuery);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      response.map((r) => {
        r.fhtLodgeRecommendationAESTDate = utcToLocal(r.fhtLodgeRecommendationDate);
      });
      this.setState({
        recommendationQuery: response.sort((a, b) => (a.fhtLodgeRecommendationID < b.fhtLodgeRecommendationID) ? 1 : -1),
      });
      console.log("recommendation", response);
    });
  };

  handleSoftwareChange = (e) => {
    this.setState({ selectedSystem: e.target.value }, () => {
      this.filterVersion();
    });
  };

  handleSiteChange = (e) => {
    this.setState({ selectedSite: e.target.value }, () => {
      this.filterVersion();
    });
  };

  handleHeartbeatSiteChange = (e) => {
    this.getHeartbeat(e.target.value.fhtSiteId);
    this.setState({ selectedSite: e.target.value });
  };

  handleUpdateQuery = (data) => {
    console.log("handleUpdateQuery data", data);
    const api = FetchApi(Configs.api.updateRecommendationQuery);
    api.post(data).then((response) => {
      console.log("handleUpdateQuery response", response);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });;
  };

  filterHeartbeat = () => {
    console.log("FIlter hb");
    this.setState({ heartbeat: [] });
    let hb = [];
    let siteId = this.state.selectedSite.fhtSiteId;
    this.heartbeats.map((h) => {
      if (h.fhtHeartbeatSiteId === siteId) {
        hb.push(h);
      }
      return null;
    });
    this.setState({ heartbeat: hb }, () => {
      console.log(this.state.heartbeat);
    });
  };

  filterVersion = () => {
    this.setState({ machines: [] });
    let machine = [];
    let siteId = this.state.selectedSite.fhtSiteId;
    let systemId = this.state.selectedSystem.fhtSystemId;
    if (siteId !== undefined && systemId !== undefined) {
      let release = this.state.releases.find(
        (r) =>
          r.fhtReleasedVersionSiteId === siteId &&
          r.fhtReleasedVersionSystemId === systemId
      );
      if (release === null || release === undefined) {
        this.setState({
          machines: [],
          releaseVersion: "",
        });
      } else {
        this.setState({
          releaseVersion: `${release.fhtReleasedVersionId} - ${release.fhtReleasedVersionReleasedVersion}`,
        });
        this.state.localVersions.map((s) => {
          if (
            s.fhtLocalVersionSiteId === siteId &&
            s.fhtLocalVersionSystemId === systemId
          ) {
            machine.push(s);
          }
          return null;
        });
        this.setState({ machines: machine });
      }
    }
  };

  handleMenuClick = (e, index) => {
    this.resetSelection(index);
    this.setState({ selectedMenu: index });
    switch (index) {
      case 0: // Summary
        this.getPocStatus();
        break;
      case 1: //Current software version
        this.getLasteRelease();
        this.getLocalVersion();
        break;
      case 2: //FhtLog
        // this.getFhtLog();
        window.location = "./FhtLogsSelections"
        break;
      case 3: //Heartbeats
        // this.getHeartbeat();
        break;
      case 4: // Recomendation Query
        this.getRecommendationQuery();
        // this.copyToClipboard();
        break;
      case 5: //Download nightly log
        // Clicked download csv
        window.location = "./CSVDownload"
        break;
      case 6: // MyAccount
        window.location = "./MyAccount"
        break;
      case 7: // AutoUpdaterLog
        window.location = "./POCHeartBeatLastWeek"
        break;
      case 8: 
        window.location = "./TorchTrialStatusSummary"
        break;
      default:
        break;     
      // None : activeAlgorithm
    }
  };

  resetSelection = (index) => {
    if (index !== 1) {
      this.setState({
        selectedSite: {},
        selectedSystem: {},
        machines: [],
        releaseVersion: "",
      });
    }
  };

  changeToRecommendationQueryPageFromSummaryCard(event, fhtLodgeRecommendationDate) {
    this.handleMenuClick(event, 4)
    this.lodgeRecommendationPageOptions.searchText = fhtLodgeRecommendationDate
    window.scrollTo({
      top: 0,
    });
  }

  renderOverall = () => {
    return (
      <div>
        {/* Maintenance */}
        <Home 
          heartbeats={this.state.heartbeats}
          recommendationQueryParentData = {this.state.recommendationQuery} 
          changeDashboardToRecommendationQueryPage = {(event, lodgeRecommendationID) => this.changeToRecommendationQueryPageFromSummaryCard(event, lodgeRecommendationID)}
        />
      </div>
    );
  };

  renderSoftwareVersion = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>Current Software Version</h3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.formDetail}>
              <InputLabel>Site</InputLabel>
              <Select
                label="Site"
                value={this.state.selectedSite}
                onChange={this.handleSiteChange}
              >
                {this.state.sites.map((s) => (
                  <MenuItem key={s.fhtSiteId} value={s}>
                    {s.fhtSiteName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.formDetail}>
              <InputLabel>Software</InputLabel>
              <Select
                label="Software"
                value={this.state.selectedSystem}
                onChange={this.handleSoftwareChange}
              >
                {this.state.systems.map((s) => (
                  <MenuItem key={s.fhtSystemId} value={s}>
                    {s.fhtSystemName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          className={classes.formDetail}
          label="Latest Version"
          variant="outlined"
          value={this.state.releaseVersion}
        />
        {this.state.selectedSite.fhtSiteId &&
          this.state.selectedSystem.fhtSystemId && (
            <MaterialTable
              className={classes.sectionMargin}
              columns={this.state.localVersionTable}
              data={this.state.machines}
              options={this.pageOptions}
              title=""
            ></MaterialTable>
          )}
      </div>
    );
  };

  renderLog = () => {
    return (
      <MaterialTable
        columns={this.state.logTable}
        data={this.state.logs}
        options={this.pageOptions}
        title="FHT Log"
      ></MaterialTable>
    );
  };

  renderHeartbeat = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>Heart beat</h3>
        <FormControl variant="outlined" className={classes.formDetail}>
          <InputLabel>Site</InputLabel>
          <Select
            label="Site"
            value={this.state.selectedSite}
            onChange={this.handleHeartbeatSiteChange}
          >
            {this.state.sites.map((s) => (
              <MenuItem key={s.fhtSiteId} value={s}>
                {s.fhtSiteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.state.selectedSite.fhtSiteId && (
          <MaterialTable
            className={classes.sectionMargin}
            columns={this.state.hbTable}
            data={this.state.heartbeats}
            options={this.pageOptions}
            title=""
          ></MaterialTable>
        )}
      </div>
    );
  };

  renderRecommendationQuery = () => {
    return (
      <MaterialTable
        columns={this.state.queryTable}
        data={this.state.recommendationQuery}
        options={this.lodgeRecommendationPageOptions}
        title="Recommendation Queries"
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
                const data = this.state.recommendationQuery.slice();
                const index = data.indexOf(oldData);
                data[index] = newData;
                console.log("rowData", newData);
                this.setState({ recommendationQuery: data }, () => {
                  this.handleUpdateQuery(newData);
                });
              }, 500);
            }),
        }}
      ></MaterialTable>
    );
  };

  renderMain = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        {this.state.selectedMenu === 0 && this.renderOverall()}
        {this.state.selectedMenu === 1 && this.renderSoftwareVersion()}
        {/* {this.state.selectedMenu === 2 && this.renderLog()} */}
        {this.state.selectedMenu === 3 && this.renderHeartbeat()}
        {this.state.selectedMenu === 4 && this.renderRecommendationQuery()}
      </div>
    );
  };

  renderSideTab = () => {
    const { classes } = this.props;
    return (
      <div className={classes.sideMenu}>
        <List component="nav" aria-label="side menu">
          <ListItem
            button
            selected={this.state.selectedMenu === 0}
            onClick={(event) => this.handleMenuClick(event, 0)}
          >
            <ListItemText primary="Summary"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedMenu === 6}
            onClick={(event) => this.handleMenuClick(event, 6)}
          >
            <ListItemText primary="My Account"></ListItemText>
          </ListItem>
          
          {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport ? 
            <ListItem button component={Link} to={{
                  pathname: "/ALGSummary",
                  state: {
                    Keyword: "active",
                  },
                }} >
              <ListItemText primary="Active Algorithms"></ListItemText>
            </ListItem>
            : <div/>
          }
          
          <ListItem
            button
            selected={this.state.selectedMenu === 1}
            onClick={(event) => this.handleMenuClick(event, 1)}
          >
            <ListItemText primary="Current Software Version"></ListItemText>
          </ListItem>
          <ListItem button component={Link} to={{pathname: "/FhtLogsSelections"}} >
            <ListItemText primary="FHT Log"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedMenu === 3}
            onClick={(event) => this.handleMenuClick(event, 3)}
          >
            <ListItemText primary="Heartbeats"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedMenu === 4}
            onClick={(event) => this.handleMenuClick(event, 4)}
          >
            <ListItemText primary="Recommendation Query"></ListItemText>
          </ListItem>
          
          <ListItem
            button
            selected={this.state.selectedMenu === 5}
            onClick={(event) => this.handleMenuClick(event, 5)}
          >
            <ListItemText primary="Download nightly log data"></ListItemText>
          </ListItem>
          
          {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport ? 
            <ListItem
              button
              selected={this.state.selectedMenu === 7}
              onClick={(event) => this.handleMenuClick(event, 7)}
            >
              <ListItemText primary="POC HeartBeat last week"></ListItemText>
            </ListItem>
            : <div></div>
          }
          
          {/* Torch specific items */}
          {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
            Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) ? 
            <ListItem
              button
              selected={this.state.selectedMenu === 8}
              onClick={(event) => this.handleMenuClick(event, 8)}
            >
              <ListItemText primary="Torch cohort trial status"></ListItemText>
            </ListItem> : <div></div>
          }
        </List>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      // SideMenu here
      <div className={classes.root}>
        <Grid container>
          <Drawer
            className={classes.sideDrawer}
            variant="persistent"
            anchor="left"
            // open={this.state.drawerOpen}
            open={this.props.isDrawerOpen}
          >
            <div className={classes.rightAlign}>
              <IconButton onClick={this.props.toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Box p={5}>
              <Typography variant="h6" gutterBottom>
                <Box p={3} />
                <Tooltip title={"View account profile"}>
                  <IconButton onClick = {()=>{window.location = "./MyAccount"}}>
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                {this.props.username}

              </Typography>
            </Box>
            <Divider />
            <div
              onClick={this.props.toggleDrawer}
              className={classes.rightPadding}
            >
              {this.renderSideTab()}
            </div>
          </Drawer>

          <Grid item md={12} sm={12} xs={12}>
            {this.renderMain()}
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={this.state.openSnackbar}
          autoHideDuration={4000}
          onClose={() => this.setState({ openSnackbar: false })}
        >
          <SnackbarComponent
            onClose={() => this.setState({ openSnackbar: false })}
            variant={this.snackbarVariant}
            message={this.snackbarMessage}
          />
        </Snackbar>
      </div>
    );
  }
}
export default withStyles(useStyles)(Dashboard);
