import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";

class SiteRegister extends Component {
  constructor(props) {
    super();   
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.state = {    
      sites: [],    
      siteTable: [
        { title: "Account Id", field: "fhtAccountId" },
        { title: "Site Name", field: "fhtSiteName" },
        { title: "State", field: "fhtSiteState" },
        { title: "PocStatus", field: "pocSwitch", editable:"never" },
        {title:"PROD", field:"fhtsitedetailsPROD", type: 'boolean',},
        {title:"FHT Champion Name", field:"fhtsitedetailsChampionName"},
        {title:"FHT Champion Phone", field:"fhtsitedetailsChampionPhone"},
        {title:"FHT Champion Email", field:"fhtsitedetailsChampionEmail"},
        {title:"IT provider Org", field:"fhtsitedetailsITproviderOrgname"},
        {title:"IT provider Contact", field:"fhtsitedetailsITproviderContact"},
        {title:"IT provider Phone", field:"fhtsitedetailsITproviderPhone"},
        {title:"IT provider Email", field:"fhtsitedetailsITproviderEmail"},
        {title:"Site url", field:"fhtsitedetailsSiteURL"},
        {title:"Trial Group", field:"fhtsitedetailsTrialGroup"},
        {title:"Site IT setup", field:"fhtsitedetailsSiteITSetup"},
        {title:"EMR Name", field:"fhtsitedetailsEMRName", editable:"never"},
        {title:"Server machine", field:"fhtsitedetailsEMRServerMachine", editable:"never"},
        {title:"EMR DB machine", field:"fhtsitedetailsEMRDBMachine"},
      ],    
    }
  }

  componentDidMount() {
    this.getSiteRegister();
  }

  getSiteRegister = async() => {
    //site register
    const api = FetchApi(Configs.api.getSiteRegister);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    console.log("Fetching site registers...");
    await api.post(body).then(response => {
      this.setState({sites: response});
    });
    console.log("Sites", this.state.sites);
  };
  
  handleSiteUpdate = async(data) => {
    //site register   
    
    // fill the missing rows
    data["SiteTypeIDs"] = [Configs.SiteConfigurationMapping[window.location.hostname].accountCreationSiteTypeID];
    data["fhtSiteEnabled"] = 1;
    data.fhtsitedetailsPROD = data.fhtsitedetailsPROD ? 1 : 0;

    // Update site or create a new site
    const api = FetchApi(Configs.api.updateFhtSite);
    await api
    .post(data)
    .then((response) => {
      if (response.unauthorized != undefined && response.unauthorized == 401) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.unauthorised,
          Configs.snackbarVariants.error
        );
      } else {        
        this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
        );
      }    
      // Refresh
      data.fhtSiteId = response.find(x=>x.fhtAccountId == data.fhtAccountId).fhtSiteId;
      
      // Update site details
      // Rename certain rows
      data.fhtsitedetailsAccountID = data.fhtAccountId;
      data.fhtsitedetailsSiteID = data.fhtSiteId;

      const api2= FetchApi(Configs.api.updateFhtSitedetails);
      api2
      .post(data)
      .then((response2) => {
        if (response2.unauthorized != undefined && response2.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.updateSuccess,
            Configs.snackbarVariants.success
          );
        }        
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateFail,
          Configs.snackbarVariants.error
        );
      });
    })
    .catch((err) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });     
  };
  
  handleSiteDelete = (data) => {
    //site register
    const api = FetchApi(Configs.api.deleteFhtSite);
    api
      .del(data.fhtSiteId)
      .then((response) => {
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.deleteSuccess,
            Configs.snackbarVariants.success
          );
        }
      })
      .catch((error) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          `${data.fhtSiteName} ${Configs.snackbarMessages.deleteInUsedFail}`,
          Configs.snackbarVariants.error
        );
      });
      this.getSiteRegister();
      const api2= FetchApi(Configs.api.deleteFhtSitedetails);
      if(data.fhtsitedetailsID !== undefined)
      {
      api2.del(data.fhtsitedetailsID)
      .then((response)=>{
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.deleteSuccess,
            Configs.snackbarVariants.success
          );
        }
    })
    .catch((error) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        `${data.fhtSiteName} ${Configs.snackbarMessages.deleteInUsedFail}`,
        Configs.snackbarVariants.error
      );
      })
    }
      this.getSiteRegister();
  };

  renderSite = () => {
    //site regster
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Site Register</h3></Grid>
        <br/>
        <Grid container justify="center">
          <div style = {{width: "90%"}}>
            <MaterialTable 
              columns={this.state.siteTable}
              data={this.state.sites}
              options={this.pageOptions}
              title=""
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      let data = this.state.sites.map((x) =>
                        Object.assign({}, x)
                      );
                      if (
                        newData.fhtAccountId === undefined ||
                        newData.fhtAccountId.trim() === "" ||
                        newData.fhtSiteState === undefined ||
                        newData.fhtSiteState.trim() === "" ||
                        newData.fhtSiteName === undefined ||
                        newData.fhtSiteName.trim() === ""
                      ) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.siteDetails,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      if (isNaN(newData.fhtAccountId)) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.accountIdType,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      let index = this.state.sites.findIndex(
                        (c) => c.fhtAccountId === newData.fhtAccountId
                      );
                      if (index > -1) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.duplicateAccountId,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      resolve();
                      newData.fhtsitedetailsAccountID= newData.fhtAccountId;
                      newData.fhtsitedetailsSiteID= newData.fhtSiteId;
                      data.push(newData);
                      this.setState({ sites: data }, () => {
                        this.handleSiteUpdate(newData);
                      });
                    }, 500);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const data = this.state.sites.slice();
                      //console.log(newData.fhtAccountId);
                      if (
                        newData.fhtAccountId === undefined ||
                        newData.fhtAccountId === "" ||
                        newData.fhtSiteState === undefined ||
                        newData.fhtSiteState.trim() === "" ||
                        newData.fhtSiteName === undefined ||
                        newData.fhtSiteName.trim() === "" 
                      ) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.siteDetails,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      if (isNaN(newData.fhtAccountId)) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.accountIdType,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      const index = data.indexOf(oldData);
                      let ind = this.state.sites.findIndex(
                        (c) => c.fhtAccountId === newData.fhtAccountId
                      );
                      if (ind > -1 && ind !== index) {
                        this.props.GlobalFunctionDisplaySnackbarMessage(
                          Configs.snackbarMessages.duplicateAccountId,
                          Configs.snackbarVariants.error
                        );
                        reject();
                        return;
                      }
                      resolve();
                      data[index] = newData;
                      this.setState({ sites: data }, () => {
                        this.handleSiteUpdate(newData);
                      });
                    }, 500);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve();
                      const data = this.state.sites.slice();
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      this.setState({ sites: data }, () => {
                        this.handleSiteDelete(oldData);
                      });
                    }, 500);
                  }),
              }}
            ></MaterialTable>
          </div>
        </Grid>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderSite()}<br/>
      </div>
    )
  }
}

export default SiteRegister;