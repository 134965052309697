import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

class SoftwareMapping extends Component {
  constructor(props) {
    super();   
    this.selectionOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      selection: true,
    }; 
    this.state = {      
        mappingTable: [
            { title: "Mapping Status", field: "mapped", lookup: Configs.mappingStatus},
            { title: "Machine Id", field: "machineId" },
            { title: "Machine Name", field: "machineName" },
        ],
        software: [],
        sites: [],
        selectedSoftware: -1,
        selectedSite: -1,
        softwareMappingData: [],
        filePath: "c:\\fht\\",
    };
  }

  componentDidMount() {
    this.getSoftware();
    this.getSite();
  }
  getSoftware = () => {
    // Software mappping, software
    const api = FetchApi(Configs.api.getSystem);
    api.get().then((response) => {
      this.setState({ software: response }, () => {});
    });
  };
  getSite = async() =>{
    //softwqare mapiing, site grouping, machineOnsite
    console.log("Fetching sites...")
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then(response =>{
      this.setState({sites : response});
      console.log("sites", response);
    })
  }
  getSoftwareMapping = ()=>{
    //SoftwareMapping
    console.log("fetching software mapping...");
    const api = FetchApi(Configs.api.getSoftwareMapping+ "/" + this.state.selectedSite.fhtSiteId + "/" + this.state.selectedSoftware.fhtSystemId); //Under construction
    api.get().then((response)=>{
      this.setState({softwareMappingData : response});
      console.log("software mapping", response);
    });
  }

  handleMappingSiteChange = (e) => {
    //Software Mapping
    this.setState({ selectedSite: e.target.value }, ()=>{
      console.log("software", this.state.selectedSoftware.fhtSystemId, "site", this.state.selectedSite.fhtSiteName);
      if(this.state.selectedSoftware.fhtSystemId > 0 && this.state.selectedSite.fhtSiteId > 0){
        this.getSoftwareMapping();
      }
    })
  }
  handleMappingSoftwareChange = (e) => {
    //Software Mapping
    this.setState({ selectedSoftware: e.target.value }, ()=>{
      console.log("software", this.state.selectedSoftware.fhtSystemId, "site", this.state.selectedSite.fhtSiteName );
      if(this.state.selectedSoftware.fhtSystemId > 0 && this.state.selectedSite.fhtSiteId > 0){
        this.getSoftwareMapping();
      }
    })
  };
  
  handleMappingSystem = (isMap) => {
    //Software Mapping
    let checkedData = [];
    let mappingData = this.state.softwareMappingData;
    console.log("mapping data",mappingData);
    mappingData.map(a => {
      if(a.tableData.checked){
        // checkedData.push(a);
        let data = {
          fhtLocalSystemSiteId: a["siteId"],
          fhtLocalSystemMachineId: a["machineId"],
          fhtLocalSystemSystemId: this.state.selectedSoftware.fhtSystemId,
          fhtLocalSystemFilePath: this.state.filePath,
        };
        checkedData.push(data);
      }
    });
    if(this.state.selectedSoftware.fhtSystemId === undefined || checkedData.length === 0){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.mappingDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    let api; 
    if(isMap){
      //to map
      api = FetchApi(Configs.api.updateSystemMapping);
    }
    else {
      //to unmap
      api = FetchApi(Configs.api.deleteSystemMapping);
    }

    api
      .post(checkedData)
      .then((response) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );

        //refresh data
        this.getSoftwareMapping();
        
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateFail,
          Configs.snackbarVariants.error
        );
      });
  }

  renderSystemMapping = () => {
    //Software mapping
    return (
      <div>
        <div style = {{paddingLeft: "5vw"}}>
          <h3>Software Mapping</h3>
        </div> 
        <Grid container justify="center">
          <FormControl variant="outlined"            
              style = {{
                  marginBottom: 20,
                  width: "90%",
                  textAlign: "left"
              }}>
            <InputLabel>Software</InputLabel>
            <Select
              label="Software"
              value={this.state.selectedSoftware}
              onChange={this.handleMappingSoftwareChange}
            >
              {this.state.software.map((s) => (
                <MenuItem key={s.fhtSystemId} value={s}>
                  {s.fhtSystemName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl 
              variant="outlined" 
              style = {{
                  marginBottom: 20,
                  width: "90%",
                  textAlign: "left"
              }}>
            <InputLabel>Site</InputLabel>
            <Select
              label="Site"
              value={this.state.selectedSite}
              onChange={this.handleMappingSiteChange}
            >
              {this.state.sites.map((s) => (
                <MenuItem key={s.fhtSiteId} value={s}>
                  {s.fhtSiteName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style = {{width: "90%"}}>
            {this.renderMappingTable()}
          </div>
          <div
              style = {{   
                  marginBottom: 20,
                  marginTop: 20,
                  width: "90%",
                  textAlign: "left"
              }}
          >
            <Button
              style = {{marginLeft: 20}}
              variant="contained"
              onClick={() => this.handleMappingSystem(true)}>
                map
            </Button>
            <Button
              style = {{marginLeft: 20}}
              variant="contained"
              onClick={() => this.handleMappingSystem(false)}>
                Unmap
            </Button>
          </div>
        </Grid>
      </div>
    );
  };

  renderMappingTable = () => {
    //Software ampping
    return (
      <MaterialTable
        columns={this.state.mappingTable}
        data={this.state.softwareMappingData}
        options={this.selectionOptions}
        title=""
      ></MaterialTable>
    );
  };
  
  render() {
    return (
      <div><br/>
          {this.renderSystemMapping()} <br/>
      </div>
    )
  }
}

export default SoftwareMapping;