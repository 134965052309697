import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as _ from 'underscore';
import Button from "@material-ui/core/Button";

// Much more simplified version of SelectSitesGrouping where we just want the SiteIDs within the SiteGroupings.
class SelectSiteGroupingsSelectionOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteGroupingsList: [],
    };
    this.onComponentInitialise();
    this.handleThrottleSelectSites = _.debounce(this.handleSelectSites, 500, false);
  }

  onComponentInitialise = () => {
    this.getSiteGroupings();
    this.getSites();
  };

  getSiteGroupings() {
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);
    let body = {
      SiteTypeIDs:
        Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response[0].text = "Select site grouping";
      this.setState({
        siteGroupingsList: response,
      });
    });
  }

  getSites = (keepSiteSelection = false) => {
    //Site Algo
    const api = FetchApi(Configs.api.getConfigEditSiteList);
    let body = {
      SiteTypeIDs:
        Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      if (!keepSiteSelection) this.setState({ siteCheckBox: response.sites });
    });
  };

  handleSelectSites = (selectedRows) => {
    console.log("selectedRows", selectedRows)
    this.props.SharedComponentSiteGroupingCallback(selectedRows);
  };

  updateCommonSelectedSiteGroupings = (event) => {
    this.props.SharedComponentSelectedSiteGroupingIDCallback(event.target.value);

    var selectedSitesFromSiteGroup = JSON.parse(
      this.state.siteGroupingsList.filter(
        (a) => a.key == String(event.target.value)
      )[0].siteIDs
    );
    var selectedSiteIDsArray = [];
    for (var i = 0; i < selectedSitesFromSiteGroup.length; i++) {
      try {
        selectedSiteIDsArray.push(selectedSitesFromSiteGroup[i].fhtSiteGroupingsSiteID)
      } catch {
        // Site doesn't exist in the siteCheckBox variable now.
        // Could have been removed, disabled.
      }
    }
    this.handleThrottleSelectSites(selectedSiteIDsArray);
  };

  render() {
    return (
      <div>
        <Select
          label="Select grouping"
          value={this.props.selectedSiteGroupings}
          onChange={(event) => {
            this.updateCommonSelectedSiteGroupings(event);
          }}
        >
          {this.state.siteGroupingsList.map((grouping) => (
            <MenuItem
              key={Number(grouping.key)}
              value={Number(grouping.key)}
              style={{ color: "black" }}
            >
              {grouping.text}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}
export default SelectSiteGroupingsSelectionOnly;
