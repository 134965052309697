import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FetchApi from "../../../Service/FetchApi";
import Configs from "../../../Service/Configuration";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as _ from "underscore";

class UsersToCohortsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    userToCohortMappingList: [],
      cohortList: [],
      siteTable: [
        { title: "Cohort Name", field: "cohortName" },
        {
          title: "valid",
          field: "valid",
          hidden: true,
          width: 0,
        },
      ],
    };
    this.pageOptions = {
      pageSize: 10,
      search: true,
      paging: false,
      selection: true,
      showSelectAllCheckbox: false,
      selectionProps: (rowData) => {
        if (rowData.valid == true)
        {return ({disabled:false});}
        
        else
        {return ({disabled:true});}
      },
      rowStyle: (rowData) => {
        if (rowData.valid == true)
        return {
          color: "black",
        };
        
        else
        return {
          color: "gray",
          fontStyle: "italic"
        }
      },      
    };
    //this.onComponentInitialise();
    this.handleThrottleSelectCohorts = _.debounce(
        this.handleSelectCohorts,
        500,
        false
      );
  }

    clearCohortSelection = () => {
        let updatedMappedCohortList = this.state.cohortList;
        updatedMappedCohortList.forEach ((updateCohortItem)=>{
                updateCohortItem.tableData.checked = false;
                updateCohortItem.valid = true;
        });
        this.setState({
            cohortList: updatedMappedCohortList
        });
    }

  getCohortsForUsers = () => {
    this.clearCohortSelection();
    if (
        this.props.parentState == null ||
        this.props.parentState.selectedSites == null ||
        this.props.parentState.selectedSites.length == 0
      ) {
          console.log("getCohortsForUsers - No site.");
          this.props.parentState.selectedUsers = [];
        this.setState({ cohortList: [] });
      } 
    else if (
      this.props.parentState == null ||
      this.props.parentState.selectedUsers == null ||
      this.props.parentState.selectedUsers.length == 0
    ) {
      console.log("getCohortsForUsers - No users.");
    } else {
        console.log("getCohortsForUsers - Default.");
      let usernameArray = [];
      this.props.parentState.selectedUsers.forEach((userRecord) => {
        usernameArray.push(userRecord.userName);
      });
      let siteIdArray = [];
      this.props.parentState.selectedSites.forEach((siteRecord) => {
        siteIdArray.push(parseInt(siteRecord.fhtSiteId));
      });
      const api = FetchApi("GetAWSClinicPatientsToCohortList");
      let body = {
        usernameArray: usernameArray,
        siteIdArray: siteIdArray
      };

      api.post(body).then((response) => {
        //data structure only contains mapping information. Only the cohort name ticks and red cohort names should update.
        console.log ("response:",response);
        let updateCohortList = this.state.cohortList;
        let selectedRows = [];
        updateCohortList.forEach((record)=>{
            record.valid = true;
            response.forEach((responseRecord)=>{
                if (record.cohortName == responseRecord.cohortName) {
                    if (responseRecord.enabled == 1)
                    {
                        record.tableData.checked = true;
                        record.valid = true;
                        selectedRows.push(record.cohortName);
                    }
                    else if (responseRecord.enabled == 2)
                        record.valid = false;
                    else
                    {
                        record.tableData.checked = false;
                        record.valid = true;
                    }

                }
            })
        });
        
        this.setState({ cohortList: updateCohortList}, () =>{
          this.props.UsersToCohortsCallback(selectedRows, false);
        });
        
      });
    }
  };

  updateSelection = () => {
    let updatedMappedCohortList = this.state.cohortList;
    console.log ("this.state.userToCohortMappingList:",this.state.userToCohortMappingList);
    console.log ("updatedMappedCohortList",updatedMappedCohortList);
    this.state.userToCohortMappingList.forEach ((userToCohortMapping)=>{
        //all records here means there is a valid mapping for a user to a cohort
        updatedMappedCohortList.forEach ((updateCohortItem)=>{
            if (updateCohortItem.clinicCohortListCohortID == userToCohortMapping.clinicUserToCohortTrialID) {
                console.log ("Found - checking box");
                updateCohortItem.tableData.checked = true;
            }
        });
        //we will need to differentiate if a specific cohort exists for all users - otherwise we need to color code it differently and prevent saving.
    });

    this.setState({
        cohortList: updatedMappedCohortList
    });
  }

  handleSelectCohorts = (selectedRows) => {
    this.props.UsersToCohortsCallback(selectedRows, true);
  };

  render() {
    if (this.props.parentState.RequeryCohortsForClinicAPI == true) {
      this.props.parentState.RequeryCohortsForClinicAPI = false;
      console.log ("Cohort -> render assigning cohorts to site list");
      this.setState({ cohortList: this.props.parentState.fullCohortList }, ()=>{
        this.getCohortsForUsers();
      });
      
    }

    return (
      <div>
        <Grid container style={{ gap: "1REM", width: "100%" }}>
          <Grid item xs={12}>
            <Box
              bgcolor="primary.main"
              style={{ color: "white" }}
              height="4REM"
            >
              <div>
                <MaterialTable style={{height:"44REM", overflow:"auto", whiteSpace:"nowrap"}}
                  columns={this.state.siteTable}
                  data={this.state.cohortList}
                  options={this.pageOptions}
                  title=""
                  onSelectionChange={(selectedRows) => {
                    this.handleThrottleSelectCohorts(selectedRows);
                  }}
                ></MaterialTable>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default UsersToCohortsComponent;
