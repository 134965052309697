import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Chip from '@material-ui/core/Chip';
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { ThreeDRotationSharp } from "@material-ui/icons";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  buttonMargin: {
    marginLeft: 20,
  },
  tip: {
    fontSize: "smaller",
    textAlign: "left",
    marginButtom: 10,
    color: "#cccccc",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
  },
  radioLayout: {
    display: 'inline',
  }
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Release extends React.Component {
  constructor(props) {
    super(props);
    this.software = [];
    this.localSystems = [];
    this.allSite = [
      {
        siteId: 0,
        siteName: "All",
      },
    ];
    this.site = [];
    this.machines = [];
    this.buildVersion = [];
    this.buildFileUrl = "";
    this.machine = [];
    this.snackbarMessage = "";
    this.snackbarVariant = "success";
    this.downloadUrl = "";
    this.pageOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
    };
    this.state = {
      publishTable: [
        { title: "Site", field: "siteName" },
        { title: "Software", field: "softwareName" },
        { title: "Version", field: "version" },
        {
          title: "File URL",
          field: "softwareBuildAvailableUrl",
          editable: "never",
        },
      ],
      buildTable: [
        { title: "Version Id", field: "softwareBuildAvailableVersionId" },
        {
          title: "Version Number",
          field: "softwareBuildAvailableVersionNumber",
        },
        { title: "File URL", field: "softwareBuildAvailableUrl" },
        { title: "Update Date", field: "updateDate" },
      ],
      sites: [],
      systems: [],
      releases: [],
      selectedSystem: {},
      selectedSite: {},
      selectedSites: [],
      selectedBuild: {},
      selectedMachine: {},
      builds: [],
      machineOnSite: [],
      selectedMenu: 0,
      fileUrl: "",
      versionNumber: "",
      versionId: "",
      installSites: [],
      availableSites: [],
      siteIDsWithMariaDB: [],
      siteGroupingDropdownOptions: [],
      currentDropDownID: ""
    };
    this.getSystem();
    this.getSite();
    this.getSiteIDsWithMariaDB();
    this.getLocalSystem();
    this.handleSiteGroupingsChanges = this.handleSiteGroupingsChanges.bind(this);
  }

  getSystem = () => {
    const api = FetchApi(Configs.api.getSystem);
    api.get().then((response) => {
      this.setState({ systems: response }, () => {
        console.log(this.state.systems);
      });
    });
  };

  getSite = () => {
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response = response.sort((a, b) => (a.fhtSiteName > b.fhtSiteName) ? 1 : -1)
      this.setState({ 
        sites: response, 
        availableSites: response,
      });
    });
  };
  getSiteIDsWithMariaDB = () => { //not used
    const api = FetchApi(Configs.api.getSiteIDsWithMariaDB);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response = response.sort((a, b) => (a.fhtSiteName > b.fhtSiteName) ? 1 : -1)
      this.setState({ 
        siteIDsWithMariaDB: response, 
      });
    });
  };

  getBuildVersion = () => {
    const api = FetchApi(Configs.api.getBuildVersion);
    api.get().then((response) => {
      response.map((r) => {
        let d = new Date(r.softwareBuildAvailableUpdateDate);
        r.updateDate =
          d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
        return null;
      });
      this.buildVersion = response;
    });
  };

  getReleasedSoftware = () => {
    const api = FetchApi(Configs.api.getReleasedSoftware);
    api.get().then((response) => {
      response.map((r) => {
        r.version = `${r.softwareBuildAvailableVersionId}<${r.softwareBuildAvailableVersionNumber}>`;
        return null;
      });
      this.setState({ releases: response });
    });
  };


  getLocalSystem = () => {
    const api = FetchApi(Configs.api.getSystemMapping);
    api.get().then((response) => {
      this.localSystems = response;
      // this.setState({localSystems: response});
    });
  };

  snackbarWrapper = (variant, msg) => {
    this.snackbarVariant = variant;
    this.snackbarMessage = msg;
    this.setState({ openSnackbar: true });
  };

  handleMenuClick = (e, index) => {
    if (index !== this.state.selectedMenu) {
      this.getSite();
      this.reset();
    }
    this.setState({
      selectedMenu: index,
    });
    switch (index) {
      case 0:
        this.getSystem();
        break;
      case 1:
        break;
      default:
        break;
    }
  };

  handleSoftwareChange = (e) => {
    this.setState({ selectedSystem: e.target.value });
    this.filterSite(e.target.value);
  };

  handleSiteChange = (e) => {
    this.setState({ selectedSites: e.target.value });
  };

  handleMachineChange = (e) => {
    this.setState({ selectedMachine: e.target.value });
  };

  handleUrlChange = (e) => {
    let fileServer = Configs.fileServer;
    this.downloadUrl = `${fileServer}${e.target.value}`;
    this.setState({ fileUrl: e.target.value });
  };

  handleVersionChange = (e) => {
    this.setState({ versionNumber: e.target.value });
  };

  handleRelease = () => {
    if(this.state.versionNumber === "" || this.state.fileUrl === "" 
    || this.state.selectedSystem.fhtSystemId === undefined || this.state.selectedSites.length === 0){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.releaseDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    let releaseData = [];
    this.state.selectedSites.map(s => {
      let data = {
        FhtReleasedVersionSiteId: s.fhtSiteId,
        FhtReleasedVersionSystemId: this.state.selectedSystem.fhtSystemId,
        FhtReleasedVersionReleasedVersion: this.state.versionNumber,
        FhtReleasedVersionFileUrl: this.state.fileUrl,
        FhtReleasedVersionUpdateDate: new Date(),
      };
      releaseData.push(data);
    });
    const api = FetchApi(Configs.api.releaseUpdateVersion);
    api
      .post(releaseData)
      .then((response) => {
        let msg = `${this.state.selectedSystem.fhtSystemName} ${Configs.snackbarMessages.releaseSuccess}`;
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            msg,
            Configs.snackbarVariants.success
          );
        }
        this.downloadUrl = "";
        this.setState({
          selectedSites: [],
          selectedSystem: {},
          fileUrl: "",
          versionNumber: "",
          currentDropDownID: ""
        });
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.releaseFail,
          Configs.snackbarVariants.error
        );
      });
  };

  
  handleReleaseAutoupdater = () => {
    if(this.state.versionNumber === "" || this.state.fileUrl === "" || this.state.versionId === "" || this.state.selectedSites.length === 0){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.releaseDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    let releaseData = [];
    this.state.selectedSites.map(s => {
      let data = {
        FhtAutoUpdaterReleasedVersionVersionId: this.state.versionId,
        FhtAutoUpdaterReleasedVersionSiteId: s.fhtSiteId,
        FhtAutoUpdaterReleasedVersionNumber: this.state.versionNumber,
        FhtAutoUpdaterReleasedVersionFileLocation: this.state.fileUrl,
        FhtAutoUpdaterReleasedVersionUpdateDate: new Date(),
      };
      releaseData.push(data);
    });
    console.log(releaseData);
    const api = FetchApi(Configs.api.releaseAutoupdater);
    api
      .post(releaseData)
      .then((response) => {
        let msg = `FhtAutoupdater ${Configs.snackbarMessages.releaseSuccess}`;
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            msg,
            Configs.snackbarVariants.success
          );
        }
        this.downloadUrl = "";
        this.reset();
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.releaseFail,
          Configs.snackbarVariants.error
        );
      });
  };


  handleReleaseSql = () => {
    if(this.state.fileUrl === "" || this.state.selectedSites.length === 0){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.releaseDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    let releaseSites = [];
    this.state.selectedSites.map(s => {
      let data = {
        fhtScriptSiteId: s.fhtSiteId,
        fhtScriptMachineId: 0,
        fhtScriptDatabase: "unuse",
        fhtScriptFileLocation: this.state.fileUrl,
        fhtScriptStatus: 0,
        fhtScriptCreateDate: new Date(),
        fhtScriptUpdateDate: new Date(),
      };
      releaseSites.push(data);
    });
    const api = FetchApi(Configs.api.releaseSqlScript);
    api
      .post(releaseSites)
      .then((response) => {
        let msg = `SQL Script ${Configs.snackbarMessages.releaseSuccess}`;
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            msg,
            Configs.snackbarVariants.success
          );
        }
        this.downloadUrl = "";
        this.setState({
          selectedSite: {},
          selectedSites: [],
          selectedMachine: {},
          fileUrl: "",
          currentDropDownID: ""
        });

      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.releaseSqlFail,
          Configs.snackbarVariants.error
        );
      });
  };

  filterSite = (software) => {
    //only site that has this software mapped to machines will be displayed
    let filteredSites = this.localSystems.filter(a => a.fhtLocalSystemSystemId === software.fhtSystemId);
    let obj = {};
    let installSites = [];
    filteredSites.map(a => {
      if(obj[a.fhtLocalSystemSiteId] === undefined){
        obj[a.fhtLocalSystemSiteId] = true;
        let site = this.state.sites.find(b => b.fhtSiteId === a.fhtLocalSystemSiteId);
        if(site != undefined){
          installSites.push(site);
        }
      }
    });
    installSites = installSites.sort((a, b) => (a.fhtSiteName > b.fhtSiteName) ? 1 : -1)
    this.setState({installSites: installSites});
  }


  reset = () => {
    this.setState({
      selectedSite: {},
      selectedSites: [],
      selectedSystem: {},
      selectedMachine: {},
      versionNumber: "",
      versionId: "",
      fileUrl: "",
      currentDropDownID: ""
    });
  };

  renderSiteGroupings() {
    const { classes } = this.props;
    var disableSiteGroupingDropdown = false;
    if (this.state.selectedMenu === 0
        && this.state.selectedSystem.fhtSystemId === undefined) {
          disableSiteGroupingDropdown = true
    } else {
      disableSiteGroupingDropdown = false
    }
    return (
      <div>
        <FormControl 
          className={classes.formDetail}
          disabled={disableSiteGroupingDropdown} 
          style = {{width: "100%"}} 
          variant="outlined">
            <InputLabel>Site Groupings</InputLabel>
            <Select
                value={this.state.siteGroupingDropdownOptions[this.state.currentDropDownID] || ''}
                onChange={this.handleSiteGroupingsChanges}
                label = "Site Groupings"
            >
                {this.state.siteGroupingDropdownOptions.map((s) => (
                    <MenuItem
                        key={s["key"]}
                        value={s}
                    >
                        {s["text"]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
      </div>
    )
  }

  renderRelease = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>Release new software version</h3>
        <FormControl variant="outlined" className={classes.formDetail}>
          <InputLabel>Software</InputLabel>
          <Select
            label="Software"
            value={this.state.selectedSystem}
            onChange={this.handleSoftwareChange}
          >
            {this.state.systems.map((s) => (
              <MenuItem key={s.fhtSystemId} value={s}>
                {s.fhtSystemName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {this.renderSiteGroupings()}

        <FormControl variant="outlined" className={classes.formDetail} disabled={this.state.selectedSystem.fhtSystemId === undefined}>
          <InputLabel id="Site">Site</InputLabel>
          <Select labelId="Site" label="Site"
            multiple
            value={this.state.selectedSites}
            onChange={this.handleSiteChange}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value.fhtSiteId} label={value.fhtSiteName} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            >
            {this.state.installSites.map(s => (
              <MenuItem key={s.fhtSiteId} value={s}>
                {s.fhtSiteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

          <div>
            <TextField
              className={classes.formDetail}
              label="Version Number"
              variant="outlined"
              value={this.state.versionNumber}
              placeholder="1.0.0.0"
              onChange={this.handleVersionChange}
            />
            <TextField
              className={classes.formDetail}
              label="File URL"
              variant="outlined"
              value={this.state.fileUrl}
              placeholder="FhtPointofCare/SIAMOONEEPONDS/fhtpointofcare.exe"
              onChange={this.handleUrlChange}
            />
            <div className={classes.formDetail}>
              <Button
                variant="contained"
                onClick={() => this.handleRelease()}
              >
                Release
              </Button>
              <Button
                variant="contained"
                className={classes.buttonMargin}
                download
                href={this.downloadUrl}
              >
                Test Download file
              </Button>
            </div>
          </div>
      </div>
    );
  };

  renderReleasedVersion = () => {
    return (
      <MaterialTable
        columns={this.state.publishTable}
        data={this.state.releases}
        options={this.pageOptions}
        title="Current Release Software Version"
      ></MaterialTable>
    );
  };

  componentDidMount() {
    this.getSiteGroupings();
  }
  
  getSiteGroupings() {
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);    
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
        // Slice 1 to remove the default "empty" site group.
        console.log("response", response.slice(1))
        this.setState({
            siteGroupingDropdownOptions: response.slice(1)
        })
    });
  }
  
  handleSiteGroupingsChanges(e) {
    // Get the correct drop down menu as currently selected.
    for (var i = 0; i < this.state.siteGroupingDropdownOptions.length; i++) {
        if (this.state.siteGroupingDropdownOptions[i]["key"] == e.target.value["key"]) {
            this.setState({
                currentDropDownID: i
            })
            break
        }
    }
    
    var dropDownSiteGroupJSON = JSON.parse(this.state.siteGroupingDropdownOptions[i].siteIDs)

    // As there is three different release components:
    // 1. this.renderRelease for software releases
    // 2. this.renderSql for SQL releases
    // 3. this.renderReleaseAutoupdater for autoupdater releases
    // We need to use different sites available to account for this when choosing which
    // sites to "auto-select".
    var siteDataToUse = []
    if (this.state.selectedMenu === 0) {
      siteDataToUse = this.state.installSites
    } else if (this.state.selectedMenu === 1) {
      siteDataToUse = this.state.siteIDsWithMariaDB
    } else {
      siteDataToUse = this.state.availableSites
    }

    var siteGroupingIDsInInstallSitesArray = []

    for (var i = 0; i < dropDownSiteGroupJSON.length; i++) {
      for (var j = 0; j < siteDataToUse.length; j++) {
        if (dropDownSiteGroupJSON[i].fhtSiteGroupingsSiteID == siteDataToUse[j].fhtSiteId) {
          siteGroupingIDsInInstallSitesArray.push(siteDataToUse[j])
          break
        }
      }
    }
    this.setState({
      selectedSites: siteGroupingIDsInInstallSitesArray
    })    
  }

  renderSql = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>Release SQL Script</h3>
        {this.renderSiteGroupings()}
        <FormControl variant="outlined" className={classes.formDetail}>
          <InputLabel>Site</InputLabel>
          <Select labelId="Site" label="Site"
            multiple
            value={this.state.selectedSites}
            onChange={this.handleSiteChange}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value.fhtSiteId} label={value.fhtSiteName} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            >
            {this.state.siteIDsWithMariaDB.map(s => (
              <MenuItem key={s.fhtSiteId} value={s}>
                {s.fhtSiteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div>
          <TextField
            className={classes.formDetail}
            label="File URL"
            variant="outlined"
            value={this.state.fileUrl}
            placeholder="FhtPointofCare/SQL/sqlscript.sql"
            onChange={this.handleUrlChange}
          />
          <div className={classes.formDetail}>
            <Button variant="contained" onClick={() => this.handleReleaseSql()}>
              Release
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderReleaseAutoupdater = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>Release New FhtAutoupdater version</h3>
        {this.renderSiteGroupings()}
        <FormControl variant="outlined" className={classes.formDetail}>
          <InputLabel>Site</InputLabel>
          <Select labelId="Site" label="Site"
            multiple
            value={this.state.selectedSites}
            onChange={this.handleSiteChange}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value.fhtSiteId} label={value.fhtSiteName} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            >
            {this.state.availableSites.map(s => (
              <MenuItem key={s.fhtSiteId} value={s}>
                {s.fhtSiteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div>
          <TextField
            className={classes.formDetail}
            label="Version Id"
            variant="outlined"
            value={this.state.versionId}
            placeholder="17"
            onChange={(e) => this.setState({versionId: e.target.value})}
          />
          <TextField
            className={classes.formDetail}
            label="Version Number"
            variant="outlined"
            value={this.state.versionNumber}
            placeholder="1.0.0.0"
            onChange={this.handleVersionChange}
          />
          <TextField
            className={classes.formDetail}
            label="File URL"
            variant="outlined"
            value={this.state.fileUrl}
            placeholder="Autoupdater/universal/fhtautoupdater.zip"
            onChange={this.handleUrlChange}
          />
          <div className={classes.formDetail}>
            <Button
              variant="contained"
              onClick={() => this.handleReleaseAutoupdater()}
            >
              Release
            </Button>
            <Button
              variant="contained"
              className={classes.buttonMargin}
              download
              href={this.downloadUrl}
            >
              Test Download file
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderSideTab = () => {
    const { classes } = this.props;
    return (
      <div className={classes.sideMenu}>
        <List component="nav" aria-label="side menu">
          <ListItem
            button
            selected={this.state.selectedMenu === 0}
            onClick={(event) => this.handleMenuClick(event, 0)}
          >
            <ListItemText primary="Release Software Version"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={this.state.selectedMenu === 1}
            onClick={(event) => this.handleMenuClick(event, 1)}
          >
            <ListItemText primary="SQL Script"></ListItemText>
          </ListItem>
          {/* <ListItem
            button
            selected={this.state.selectedMenu === 2}
            onClick={(event) => this.handleMenuClick(event, 2)}
          >
            <ListItemText primary="Release FhtAutoupdater Version"></ListItemText>
          </ListItem> */}
        </List>
      </div>
    );
  };

  renderMain = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        {this.state.selectedMenu === 0 && this.renderRelease()}
        {this.state.selectedMenu === 1 && this.renderSql()}
        {/* {this.state.selectedMenu === 2 && this.renderReleaseAutoupdater()} */}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={3}>
            {this.renderSideTab()}{" "}
          </Grid>
          <Grid item xs={9}>
            {this.renderMain()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(Release);
