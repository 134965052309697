import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import MaterialTable from "material-table";
import { utcToLocal, compareDateTimes, backgroundColourFromLetter} from "../HelperFunctions";
import { Grid } from "@material-ui/core";
import { Card } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ResponsiveLine } from '@nivo/line';

import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: red[500],
  },
}));

// Dummy update.
class TorchTrialStatusSummary extends Component {
  constructor(props) {
    super();    
    this.state = {      
      isTorchStatusSummaryLoaded: false,
      torchStatusSummaryTableColumns: [
        { 
          title: "Site Name", 
          field: "fhtSiteName" 
        },
      ],
      torchStatusSiteNamesAndSummaryCountsData: [],
      renderSiteCohortSpecificTrialData: false,
      selectedFhtSiteID: null,
      selectedFhtSiteName: null,
      totalNumberOfRecords: 0,
      activePage: 1,
      currentSiteSearchTerm: "",
      raiseCardForSiteGrid: false,
      currentSiteCardIndexToRaise: null,
    };
    this.timeout =  0;
  }

  searchForTorchTrialStatusSites(event){
    var searchText = event.target.value;
    if (this.timeout) {
      clearTimeout(this.timeout);
    } 
    // Wait 1 second after user has finished typing before initating search.
    this.timeout = setTimeout(() => {
      console.log("searchText", searchText)
      this.setState({
        currentSiteSearchTerm: searchText,
        isTorchStatusSummaryLoaded: false,
        activePage: 1,
      })  
      // Due to async nature, wait 250ms before sending query to have currentSiteSearchTerm. 
      setTimeout(function() {
        this.GetAWSTorchLoggingTrialStatusSiteSummaryCounts();
      }.bind(this), 250)
    }, 1000);
  }

  componentDidMount = async () => {
    this.GetAWSTorchLoggingTrialStatusSiteSummaryCounts();
  }

  GetAWSTorchLoggingTrialStatusSiteSummaryCounts() {
    console.log("Fetching GetAWSTorchLoggingTrialStatusSiteSummaryCounts");
    let body = {
      siteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      currentPage: this.state.activePage,
      currentSiteSearchTerm: this.state.currentSiteSearchTerm
    };    
    var api = FetchApi(Configs.api.getAWSTorchLoggingTrialStatusSiteSummaryCounts);
    api.post(body).then((response)=>{
      if (response.totalCountOfSitesWithTorchStatusLogging == 0) {
        throw new Error("Could not find any sites.")
      }
      console.log("response", response)
      var numberOfSitesPerPage = 12;
      var roundedUpTotalPages = Math.ceil(response.totalCountOfSitesWithTorchStatusLogging/numberOfSitesPerPage)
      this.setState({
        torchStatusSiteNamesAndSummaryCountsData: response.siteTorchStatusSummaryCountDictionary, 
        totalNumberOfRecords: roundedUpTotalPages,       
        isTorchStatusSummaryLoaded: true
      })
    })
    .catch((error) => {
      var msg = String(error);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );      
      this.setState({     
        isTorchStatusSummaryLoaded: true,
        torchStatusSiteNamesAndSummaryCountsData: [], 
        totalNumberOfRecords: 0,  
      })
    });
  }

  // When a site is clicked, render a Dialog pop up to show site specific 
  // cohort trial status summary data.
  renderCohortTrialStatus = cohortData => {
    this.setState({
      selectedFhtSiteID: cohortData.fhtSiteID,
      selectedFhtSiteName: cohortData.fhtSiteName,
      renderSiteCohortSpecificTrialData: true,
    })
  }  
  
  renderCohortSpecificTrialStatus() {
    return (
      <Dialog  
        fullWidth = {true}
        maxWidth = "xl"
        open = {this.state.renderSiteCohortSpecificTrialData}
        onClose = {() => this.setState({ renderSiteCohortSpecificTrialData: false })}
      >
      <div>
        <CohortDetailPanel
          siteName = {this.state.selectedFhtSiteName}
          siteID = {this.state.selectedFhtSiteID}
        />
        </div>
      </Dialog>
    );
  }

  onTorchStatusSummaryPageChange = (event, activePage) => (
    console.log("onTorchStatusSummaryPageChange activePage", activePage),
    this.setState({ activePage }),
    this.setState({ isTorchStatusSummaryLoaded: false }),
    // Introduce delay before requerying DB, given async nature of setState for activePage.
    setTimeout(function() {
      this.GetAWSTorchLoggingTrialStatusSiteSummaryCounts()
    }.bind(this), 250)
  )

  toggleRaisedCardForSiteGrid(index) {
    this.setState({
      raiseCardForSiteGrid: true,
      currentSiteCardIndexToRaise: index
    })
  }

  unToggleRaisedCardForSiteGrid() {
    this.setState({
      raiseCardForSiteGrid: false,
      currentSiteCardIndexToRaise: null
    })
  }

  renderAllSitesGrid() {
    return(      
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {this.state.torchStatusSiteNamesAndSummaryCountsData.map((cohortData, index) => (
        <Grid style = {{padding: "1%"}} item xs={2} sm={2} md={2} key={index}>
          <Card 
            style = {{padding: "0.5%"}}
            onMouseOver = {() => this.toggleRaisedCardForSiteGrid(index)}
            onMouseOut={() => this.unToggleRaisedCardForSiteGrid()} 
            onClick = {()=> this.renderCohortTrialStatus(cohortData)}
            raised = {this.state.raiseCardForSiteGrid && this.state.currentSiteCardIndexToRaise == index}
          > 
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" style = {{backgroundColor: backgroundColourFromLetter(cohortData.fhtSiteName.charAt(0).toUpperCase())}}>
                      {cohortData.fhtSiteName.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title = {cohortData.fhtSiteName}
                />
              </Grid>
              <Grid item xs={12}>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Total cohorts: {cohortData.fhtCohortTotalCount} <br/>
                    Total actions performed: {cohortData.fhtTotalActionsTaken}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
    )
  }

  render() {
    const { isTorchStatusSummaryLoaded } = this.state;
    return (
      <div>
        <div>
        {this.renderCohortSpecificTrialStatus()}
        </div>

        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}      >
          <h2>Torch Trial Status Summary</h2>
        </div>

        <div style={{display: 'flex', justifyContent:'right', alignItems:'center', paddingRight: "2.5%"}}>
          <TextField 
            id="standard-basic" 
            label="Search (clinic name)" 
            onChange={event => this.searchForTorchTrialStatusSites(event)}
          />
        </div>

        {isTorchStatusSummaryLoaded == true? this.renderAllSitesGrid() :         
          <div style = {{display: 'flex', justifyContent:'center', alignItems:'center'}}>
            Loading sites
            <CircularProgress />
          </div>
        }

        <div style = {{display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{position: "absolute", bottom: "-10%"}}>
            <Pagination 
              count={this.state.totalNumberOfRecords} 
              onChange = {(event, pageNumber) => this.onTorchStatusSummaryPageChange(event, pageNumber)}
            />
          </div>
        </div> <br/>
      </div>
    );
  }
}

class CohortDetailPanel extends Component {
  constructor(props) {
    super();    
    this.state = {      
      isCohortDetailPanelLoaded: false,
      torchCohortColumns: [
        // Dummy column to house the drop down button.
        { 
          title: "", 
          field: "",
          width: "5%"
        },
        { 
          title: "Cohort Name", 
          field: "fhtCohortName",
        },
        { 
          title: "Date Received", 
          field: "DateReceivedAESTConverted",
          customSort : (a,b) => compareDateTimes(a.DateReceived, b.DateReceived)
        },
        { 
          title: "To Be Reviewed", 
          field: "ToBeReviewedCount",
        },
        { 
          title: "Invitation Sent", 
          field: "InvitationSent",
        },
        { 
          title: "Excluded", 
          field: "ExcludedCount",
        },
        { 
          title: "Declined", 
          field: "DeclinedCount",
        },
        { 
          title: "Referred", 
          field: "ReferredCount",
        },
        { 
          title: "Enrolled", 
          field: "EnrolledCount",
        },
        { 
          title: "Reviewed But Invitation Not Sent", 
          field: "ReviewedButInvitationNotSentCount",
        },
      ],
      torchCohortTrialStatusSummaryData: []
    };       
    
    this.torchCohortPageOptions = {
      search: true,
      pageSize: 30,
      pageSizeOptions: [10, 30, 60],
      sorting: true,
      tableLayout: 'fixed' 
    }
  }

  componentDidMount = async () => {
    console.log("Fetching this.props.siteID", this.props.siteID);
    console.log("Fetching this.props.siteName", this.props.siteName);
    this.getAWSTorchLoggingTrialStatusCohortSummaryCounts(this.props.siteID);
  }

  getAWSTorchLoggingTrialStatusCohortSummaryCounts = (FhtSiteID) =>  {
    console.log("Fetching getAWSTorchLoggingTrialStatusCohortSummaryCounts", FhtSiteID);
    console.log("Fetching getAWSTorchLoggingTrialStatusCohortSummaryCounts");
    var data = {
      FhtSiteID : FhtSiteID
    }
    var api = FetchApi(Configs.api.getAWSTorchLoggingTrialStatusCohortSummaryCounts);
    api.post(data).then((response)=>{
      console.log("response",response);      
      response.cohortSummaryCountsDictionaryList.map(r => {
        r.DateReceivedAESTConverted = utcToLocal(r.DateReceived);
      })
      this.setState({
        torchCohortTrialStatusSummaryData: response.cohortSummaryCountsDictionaryList,        
        isCohortDetailPanelLoaded: true
      })
    });
  }

  render() {
    const { isCohortDetailPanelLoaded } = this.state;
    if (isCohortDetailPanelLoaded) {
      return (
        <div style = {{padding: "20px"}}>
          <MaterialTable
            title = {this.props.siteName + " cohorts"}
            columns={this.state.torchCohortColumns}
            data={this.state.torchCohortTrialStatusSummaryData}
            options={this.torchCohortPageOptions}
            detailPanel ={[
              {
                tooltip : "See trial status history",
                render : (rowData) =>{
                  console.log(rowData)
                  return(
                  <CohortTorchTrialStatusHistory
                    siteID = {rowData["fhtSiteID"]}
                    cohortID = {rowData["fhtCohortID"]}
                    cohortName = {rowData["fhtCohortName"]}
                  />
                  )
                }
              }
            ]}
            onRowClick={(event, rowData, togglePanel) => {
              togglePanel()
            }}
          />          
        </div>
      );
    } else {
      return (
        <div style = {{display: 'flex', justifyContent:'center', alignItems:'center', height: "10REM"}}>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }
}

class CohortTorchTrialStatusHistory extends Component{
  constructor(props){
    super();
    this.state = {
      isCohortTorchTrialStatusHistoryLoaded: false,
      CohortTorchTrialStatusColumns: [        
        //{ title: "Site Name", field: "SiteName" },
        //{ title: "Cohort Name", field: "CohortName"},
        { 
          title: "Date Received", 
          field: "DateReceivedAESTConverted",
          customSort : (a,b) => compareDateTimes(a.DateReceived, b.DateReceived)
        },
        { 
          title: "To Be Reviewed", 
          field: "ToBeReviewedCount" 
        },
        { 
          title: "Invitation", 
          field: "InvitationSent", 
        },
        { 
          title: "Excluded", 
          field: "ExcludedCount",
        },
        { 
          title: "Declined", 
          field: "DeclinedCount",
        },
        { 
          title: "Referred", 
          field: "ReferredCount",
        },
        { 
          title: "Enrolled", 
          field: "EnrolledCount",
        },
        { 
          title: "Reviewed But Invitation Not Sent", 
          field: "ReviewedButInvitationNotSentCount",
        }
      ],
      cohortTorchTrialStatusData: [],
      nivoCohortTrialStatusHistoryData: []
    }

    this.cohortTorchTrialStatusPageOptions = {
      search: true,
      pageSize: 30,
      pageSizeOptions: [10, 30, 60],
      exportButton: true,
      exportAllData: true,
      sorting:true,
      showTitle: false,
      tableLayout: 'fixed', 

      // May need in future if we limit amount of data 
      // paging: false,
      
      headerStyle: {
        maxWidth: 160,
        maxHeight: 200,
        whiteSpace: "nowrap",
        backgroundColor: '#EEE',
        overflow: "hidden",
      },
    };
  }

  componentDidMount = async() =>{
    this.getAWSTorchLoggingCohortStatusHistory(this.props.siteID, this.props.cohortID);
  }

  getAWSTorchLoggingCohortStatusHistory = (FhtSiteID, FhtCohortID) =>{
    console.log("Loading site detail : " + FhtSiteID);
    console.log("Loading site detail : " + FhtCohortID);
    const api = FetchApi(Configs.api.getAWSTorchLoggingCohortStatusHistory);
    var data = {
      FhtSiteID : FhtSiteID,
      FhtCohortID : FhtCohortID
    }
    api.post(data).then((response)=>{
      console.log("getAWSTorchLoggingCohortStatusHistory response", response)
      response.cohortSpecificTrialStatusList.map(r => {
        r.DateReceivedAESTConverted = utcToLocal(r.DateReceived);
      })
      console.log("getAWSTorchLoggingCohortStatusHistory response.nivoCohortTrialStatusHistoryData", JSON.stringify(response.nivoCohortTrialStatusHistoryData))
      this.setState({
        cohortTorchTrialStatusData: response.cohortSpecificTrialStatusList,
        nivoCohortTrialStatusHistoryData: response.nivoCohortTrialStatusHistoryData,
        isCohortTorchTrialStatusHistoryLoaded: true
      })
    })
  }

  render() {
    var cohortNameToRender = this.props.cohortName == null? "No cohort name" : this.props.cohortName + " cohort trial status"
    if(this.state.isCohortTorchTrialStatusHistoryLoaded){
      return(
        <div style = {{padding: "50px"}}>
          <div style={{ height: "400px" }}>
          <ResponsiveLine
            data={this.state.nivoCohortTrialStatusHistoryData}
            margin={{ top: 50, right: 110, bottom: 90, left: 80 }}
            xScale={{ type: 'point' }}
            enableSlices="x" 
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                legend: 'Dates',
                legendPosition: 'middle',
                tickRotation: -45,
                legendOffset: 60,
            }}
            axisLeft={{
                orient: 'left',
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 110,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 20,
                    symbolShape: 'square',           
                }
            ]}
        />
        </div><hr />
        <MaterialTable
          // title = {cohortNameToRender}
          columns = {this.state.CohortTorchTrialStatusColumns}
          data = {this.state.cohortTorchTrialStatusData}
          options = {this.cohortTorchTrialStatusPageOptions}
          style = {{backgroundColor: '#EEE'}}
        />
        </div>
      )
    } else {
      return(
        <div> 
          <CircularProgress />
          Loading... 
        </div>
      )
    }
  }
}

export default TorchTrialStatusSummary;