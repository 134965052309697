import React from "react";
import Configs from "../Service/Configuration";
import FetchApi from "../Service/FetchApi";

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

class RestartServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      selectedSiteName: null,
      manageServicesIndex: null,
      manageServicesDialogOpen: false,
      manageServicesAddServiceName: "custom",
      manageServicesAddServiceNameCustom: "",
    }

    this.pageOptions = {
      filtering: true,
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };

    this.restartServicesCol = [
      { title: "Site Id", field: "FhtSiteId", hidden : true, width : 0},
      { title: "Site Name", field: "fhtSiteName"},
      { title: "Machine Id", field: "fhtMachineOnSiteId", hidden : true, width : 0},
      { title: "Machine Name", field: "fhtMachineOnSiteMachineName"},
      { title: "Pending Services", field: "restartServicesPending"},
      { title: "Manage Services", field: "", tooltip: "",
        render: rowData => 
          <Button 
            variant="contained"
            color="default"
            onClick = {() => {
              console.log(rowData);
              this.setState({ manageServicesIndex: rowData.tableData.id, manageServicesDialogOpen: true });
            }}
          >
            Manage Services
          </Button>
      },
    ];
  }

  componentDidMount() {
    this.getRestartServices();
  }

  getRestartServices() {
    const api = FetchApi("GetRestartServices");
    const body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };

    api.post(body).then(response => {
      response.forEach(x => {
        x["restartServicesPending"] = x.restartServices.filter(x => x.fhtRestartServicesStatus === 0).length;
      });

      console.log("Restart services:");
      console.log(response);
      this.setState({ restartServices: response });
      this.checkIsLoaded();
    });
  }

  deleteRestartService(siteId, machineId, serviceName) {
    const api = FetchApi(`DeleteRestartService/${siteId}/${machineId}`);
    const body = {
      RestartServicesService: serviceName
    };

    api.post(body).then(response => {
      // Success message 
      this.props.GlobalFunctionDisplaySnackbarMessage(
        response.Message 
          ? response.Message
          : Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );

      this.getRestartServices();
      this.setState({ manageServicesDialogOpen: false });
    }).catch((error)=>{
      // Error message
      console.log(error)
      this.props.GlobalFunctionDisplaySnackbarMessage(
        error.Message 
          ? error.Message
          : Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });
  }

  addRestartService(siteId, machineId, serviceName) {
    if (serviceName === null || serviceName === "") {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Please enter a service name",
        Configs.snackbarVariants.error
      );

      return;
    }

    const api = FetchApi(`SetRestartService/${siteId}/${machineId}`);
    const body = {
      RestartServicesService: serviceName
    };

    api.post(body).then(response => {
      // Success message 
      this.props.GlobalFunctionDisplaySnackbarMessage(
        response.Message 
          ? response.Message
          : Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );

      this.getRestartServices();
      this.setState({
        manageServicesDialogOpen: false,
        manageServicesAddServiceName: "custom",
        manageServicesAddServiceNameCustom: ""
      });
    }).catch((error)=>{
      // Error message
      console.log(error)
      this.props.GlobalFunctionDisplaySnackbarMessage(
        error.Message 
          ? error.Message
          : Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });
  }

  checkIsLoaded = () => {
    if (this.state.restartServices !== undefined) {
      this.setState({ isLoaded : true });
    }
  }
  
  renderServicesDialog() {
    let siteData = null;

    if (this.state.manageServicesIndex !== null) {
      siteData = this.state.restartServices[this.state.manageServicesIndex];
    }

    const serviceName = this.state.manageServicesAddServiceName === "custom" ?
      this.state.manageServicesAddServiceNameCustom :
      this.state.manageServicesAddServiceName;

    return (
      <Dialog open={this.state.manageServicesDialogOpen} onClose={() => this.setState({ manageServicesDialogOpen: false })}>
        <DialogTitle>{siteData ? siteData.fhtSiteName : ""}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">{siteData ? siteData.fhtMachineOnSiteMachineName : ""}</Typography>
          {siteData && siteData.restartServices.length > 0 &&
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Service Status</TableCell>
                    <TableCell>Restart Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {siteData && siteData.restartServices.map(service => (
                    <TableRow key={service.fhtRestartServicesService}>
                      <TableCell>{service.fhtRestartServicesService}</TableCell>
                      <TableCell>
                        {(() => {
                          switch (service.fhtRestartServicesStatus) {
                            case 0:
                              return "Pending";
                            case 1:
                              return "Success";
                            case 2:
                              return "Error";
                            default:
                              return "Unknown";
                          }
                        })()}
                      </TableCell>
                      <TableCell>{service.fhtRestartServicesDate}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="secondary" onClick={() =>
                          this.deleteRestartService(siteData.fhtSiteId, siteData.fhtMachineOnSiteId, service.fhtRestartServicesService)
                        }>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <Box marginTop={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Select
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.manageServicesAddServiceName}
                onChange={(event) => this.setState({ manageServicesAddServiceName: event.target.value })}
              >
                <MenuItem value="custom">
                  <em>Custom Service</em>
                </MenuItem>
                {Configs.FhtServiceNames.map(x => (
                  <MenuItem key={x} value={x}>{x}</MenuItem>
                ))}
              </Select>
              {this.state.manageServicesAddServiceName !== "custom" &&
                <>
                  <Box paddingLeft={1} />
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={serviceName === null || serviceName === ""}
                    onClick={() => this.addRestartService(siteData.fhtSiteId, siteData.fhtMachineOnSiteId, serviceName)}
                  >
                    Add
                  </Button>
                </>
              }
            </Box>
            {this.state.manageServicesAddServiceName === "custom" &&
              <Box display="flex" flexDirection="row" alignItems="center" paddingTop={1}>
                <TextField
                  fullWidth
                  label="Service Name"
                  value={this.state.manageServicesAddServiceNameCustom}
                  onChange={(event) => this.setState({ manageServicesAddServiceNameCustom: event.target.value })}
                />
                <Box paddingLeft={1} />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={serviceName === null || serviceName === ""}
                  onClick={() => this.addRestartService(siteData.fhtSiteId, siteData.fhtMachineOnSiteId, serviceName)}
                >
                  Add
                </Button>
              </Box>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ manageServicesDialogOpen: false })}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div>
          <br/>
            <Grid container justifyContent="center"><h2>Restart Services</h2></Grid>
          <br/>
          <CircularProgress/>
          <br/>
          Loading...
        </div>
      )
    }

    return (
      <div>
        <Grid container justifyContent="center"><h3>Configure autoupdater restart services</h3></Grid>
        <Grid container justifyContent="center">
          <MaterialTable
            style={{width: "90%"}}
            columns={this.restartServicesCol}
            data={this.state.restartServices}
            options={this.pageOptions}
            title="Restart services"
          ></MaterialTable>
        </Grid>
        {this.renderServicesDialog()}
      </div>
    )
  }
}

export default (RestartServices);
