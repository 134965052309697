import React from 'react';

import { withStyles } from '@material-ui/core/styles/';

const useStyles = theme => ({
	root:{
		height: "100vh",
		flexGrow: 1
	},
	sideMenu: {
		height: "100%",
		padding: 20,
		// borderRight: "1px solid #ddd"
	},
	content: {
		padding: 20,
		flexGrow: 1,
	}
});

class Report extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
	}

	render(){
		return (
			<div>
				Report
			</div>
		)
	}



}

export default withStyles(useStyles)(Report);