// Copied and pasted from FHTHome, removing some cards that are specific to FHT.

import React, { Component } from "react";
import {
  Card,
  Divider,
} from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar'
import { Link } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from "@material-ui/core/styles";
import FHTSummarySiteOverviewCard from "./FHTSummarySiteOverviewCard"

const styles = {
  minHeight: {
    minHeight: 360,
  },

  tooltipBright: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.85)",
    // boxShadow: theme.shadows[2],
    color: "red",
    fontSize: 20,
  },

  contentHeight: {
    height: "37%",
  },
  greenFill: {
    fill: "green",
  },
  redFill: {
    fill: "red",
  },
  blueFill: {
    fill: "blue",
  },

  onHover: {
    color: "black",
    overflow: "hidden",
    "&:hover": {
      color: "green",
      cursor: "pointer",
      overflowWrap: "break-word",
      textDecoration: "underline",
      overflow: "visible",
    }
  }
};

const useStyles = makeStyles((theme) => (styles));

const lineGraphSettings = {
  theme: {
    fontSize: '14px',
    textColor: 'black',
    grid: {
      line: {
        stroke: "black",
      }
    },
    axis: {
      ticks: {
        line: {
          stroke: "black"
        },
      }
    },
  },
};

class FHTSummarySiteOverview extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  renderSiteOverviewSiteStatusCounts() {
    return (
      <div>
        <br /><Divider /><br />
        Uptime today<br /><br />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Link to="/APISummary/status/active"> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon style={styles.greenFill} />
            {"Active: " + this.props.siteOverviewActiveSites}
          </div>
          {/* </Link>  */}
          <div style={{ paddingLeft: "0.5REM", paddingRight: "0.5REM" }}></div>
          {/* <Link to="/APISummary/status/fail"> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon style={styles.redFill} />
            {"Inactive: " + this.props.siteOverviewInactiveSites}
          </div>
          {/* </Link> */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{
        position: "relative",
        width: "100%",
      }}>
        {/* Hiding graph for now */}
        <div style = {{display: 'flex',  justifyContent:'center'}}>
        <Card style = {{          
          zIndex: 1,
          position: "absolute",
          backgroundColor: this.props.siteOverviewGraphBackgroundColour,
          width: "50%",
          top: "-2.5REM"
          }}
        >
          <div style = {{width: "90%", height: 250}}>
          <ResponsiveBar
                  data={this.props.softwareStatusWeeklyData}
                  keys={[
                      'Active Sites',
                      'Inactive Site',
                  ]}
                  indexBy="Date"
                  colors={['#00ff00', '#ff0000']}
                  enableLabel={false}
                  margin={{ top: 20, right: 0, bottom: 80, left: 50 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  groupMode="grouped"
                  borderColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'darker',
                              1.6
                          ]
                      ]
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: 32
                  }}
                  axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: -40,
                      format: e => Math.floor(e) === e && e
                  }}
                  theme={lineGraphSettings.theme}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'darker',
                              1.6
                          ]
                      ]
                  }}
                  tooltip={(input) => {
                    return (
                      <div style = {{backgroundColor: "white", padding: "0.25REM"}}>
                        <span style={{fontWeight: "bold"}}>{input.id}:</span> {input.value}
                      </div>
                  )}}
                  legends={[]}
              />
          </div>
        </Card>
        </div>

        <Card style={{
          flex: 1,
          width: "100%",
          position: "relative",
          padding: "1REM",
          left: "-1REM"
        }}>
          {/* Blank padding for summary to be at bottom of graph. 
                Must be hard-coded REM not precentage */}
          {/* Hiding graph space padding */}
          <div style = {{      
            height: "12REM"
          }}>
          </div>
          <div>
            <br />
            <div style={{ fontSize: 24, fontWeight: 'bold' }}>
              Site overview
            </div>
            <div style = {{padding: "0.1REM", fontSize: 18}}>Site weekly uptime</div>
            {this.renderSiteOverviewSiteStatusCounts()}
            <br /><Divider /><br />
            <div style={{ zIndex: 1 }}>
              <FHTSummarySiteOverviewCard
                type={"FHT"}
                siteWiki={this.props.siteWiki}
                selectedSiteIDs={this.props.selectedSiteIDs}
                ALGTestsInfo={this.props.ALGTestsInfo}
              ></FHTSummarySiteOverviewCard>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default FHTSummarySiteOverview;
