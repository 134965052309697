import React, { Component } from "react";
import { ResponsiveLine } from '@nivo/line'

class ALGSummaryHistoryGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    var nivoGraphDataProps = this.props.nivoGraphData;

    // Change background of graph if they have a considerable change.
    var backgroundColorCheck = "rgba(149, 212, 173, 0.2)";
    if (nivoGraphDataProps.recCountChangeOfConcern == true)
      backgroundColorCheck = "rgb(193, 66, 66, 0.2)";

    return (
      <div>
        <div style={{
          height: "150px",
          width: "200px",
          backgroundColor: backgroundColorCheck,
        }}>
          <ResponsiveLine
            data={[nivoGraphDataProps]}
            margin={{ right: 10, top: 10, left: 45, bottom: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            colors={() => 'black'}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickRotation: -45,
              legend: '',
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              legend: '',
              legendPosition: 'middle'
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={2}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={0}
            isInteractive={true}
            useMesh={true}
            legends={[]}
          />
        </div>
      </div>
    )
  }
}

export default ALGSummaryHistoryGraph;