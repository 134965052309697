import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EncryptDecryptString from "./EncryptDecryptString";
import Grid from "@material-ui/core/Grid";

class GenerateInstallCommand extends Component {
  constructor(props) {
    super();   
    this.state = {
      username: "",
      password:"",
      fhtpath: "",
      command: "",
    }
  }

  handleGenerateInstallCommand = () => {
    if(this.state.username === "" || this.state.password === ""){

      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.commandGenerateDetails,
        Configs.snackbarVariants.error
      );
      return;
    }

    //const api = FetchApi(`${Configs.api.generateInstallCommand}/${this.state.password}`);
    const api = FetchApi(`${Configs.api.generateInstallCommand}`);
    let body = {"password":this.state.password};
    /*
    api.get().then((response) => {
      console.log(response);
      let encrypedPassword = response.pwd;
      console.log(encrypedPassword);
      let command = `autoupdatersetup.exe /VERYSILENT /fhtuser=${this.state.username} /fhtpwd=${encrypedPassword}`
      if(this.state.fhtpath.trim() !== ""){
        command = `${command} /fhtpath="${this.state.fhtpath}"`;
      }
      this.setState({
        command: command
      });
    })
      .catch(err => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          err,
          Configs.snackbarVariants.error
        );
      });
  */
  //this.state.password
    api.post(body).then((response) => {
      //console.log(response);
      let encrypedPassword = response.pwd;
      //console.log(encrypedPassword);
      let command = `autoupdatersetup.exe /VERYSILENT /fhtuser=${this.state.username} /fhtpwd=${encrypedPassword}`
      if(this.state.fhtpath.trim() !== ""){
        command = `${command} /fhtpath="${this.state.fhtpath}"`;
      }
      this.setState({
        command: command
      });
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        err,
        Configs.snackbarVariants.error
      );
    });  
  }

  renderGenerateInstallCommand = () => {
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Generate install command</h3></Grid>
        <br/>
        <Grid container justify="center">
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="fhtuser"
              value={this.state.username}
              onChange={(event) => {
                  this.setState({ username: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="fhtpwd"
              value={this.state.password}
              onChange={(event) => {
                  this.setState({ password: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              fullWidth
              label="fhtpath"
              value={this.state.fhtpath}
              onChange={(event) => {
                  this.setState({ fhtpath: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField 
              variant="outlined"
              multiline={true}
              value={this.state.command}
              label="Command"
            />
          </FormControl>
        </Grid>
        <div style = {{marginLeft: "4.5REM"}}>
          <Button
            variant="contained"
            onClick={() => this.handleGenerateInstallCommand()}>
              generate command
          </Button>
        </div> <br/><br/>
        <EncryptDecryptString />
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderGenerateInstallCommand()}
      </div>
    )
  }
}

export default GenerateInstallCommand;