import React, { Component } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import MaterialTable, { MTableBody } from "material-table";

import Configs from "../Service/Configuration";
import FetchApi from "../Service/FetchApi";

import { Add, CheckCircle, RemoveCircle } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";

class TorchManageLimitedUsers extends Component {
  userTableOptions = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 30],
    selection: true,
    showSelectAllCheckbox: false,
    minBodyHeight: 632,
    maxBodyHeight: 632,
    selectionProps: (rowData) => {
      const existsOnAllSites = rowData.existsOnAllSites;
      return { disabled: !existsOnAllSites };
    }
  };

  userTableColumns = [{
    title: "Username",
    field: "userName",
    render: (rowData) => {
      const isNewUser = this.state.newUsers.some(x => x.userName === rowData.userName);
      const isDisabledUser = this.state.disabledUsers.some(x => x.userName === rowData.userName);
      const existsOnAllSites = rowData.existsOnAllSites;

      return (
        <Box
          display="flex"
          color={isNewUser ? "green" : (existsOnAllSites ? (isDisabledUser ? "red" : "black") : "gray")}
          fontStyle={existsOnAllSites ? "normal" : "italic"}
        >
          {rowData.userName}
        </Box>
      );
    }
  }];

  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      search: true,
      paging: false,
      selection: true,
    };

    this.state = {
      selectedSites: [],
      selectedSitesWithCommands: [],
      selectedSiteGroupings: 0,
      siteGroupingsList: [],
      siteTable: [
        { title: "Site Name", field: "fhtSiteName" },
        { title: "Site ID", field: "fhtSiteId", hidden: true, width: 0 }
      ],

      torchUsers: [],
      selectedUsers: [],

      dialogAddUserVisible: false,
      dialogAddUserName: "",
      dialogAddFirstName: "",
      dialogAddLastName: "",
      dialogSubmitVisible: false,
      dialogSubmitError: "",
      dialogSubmitMessage: "",

      newUsers: [],
      disabledUsers: [],
    };

    this.updateCommonSelectedSiteGroupings = this.updateCommonSelectedSiteGroupings.bind(this);
  }

  componentDidMount() {
    this.handleClearAllSelectedSites();
    this.handleClearAllConfigurationsSelected();

    // These are general routes for all config edit pages.
    this.getSiteGroupings();
    this.getSites();

    // Get torch user data
    this.getTorchLimitedUsers();
  }

  getSiteGroupings() {
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };

    api.post(body).then((response) => {
      response[0].text = "Select site grouping";
      this.setState({
        siteGroupingsList: response,
      });
    });
  }

  getSites = (keepSiteSelection = false) => {
    //Site Algo
    console.log("getting site algorithm map...");
    const api = FetchApi(Configs.api.getConfigEditSiteList);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      if (!keepSiteSelection) this.setState({ siteCheckBox: response.sites, });
      console.log("siteCheckBox", response.sites);
    });
  };

  getTorchLimitedUsers(selectedRows) {
    const api = FetchApi("FindLimitedUsersForSites");
    const body = (selectedRows || []).map(x => x.fhtSiteId);
    api.post(body).then(response => {
      const siteUsers = Object.keys(response).map(userName => ({
        "userName": userName,
        "sites": response[userName].siteIds,
        "existsOnAllSites": response[userName].siteIds.length === selectedRows.length,
        "enabled": response[userName].enabled
      }));

      this.setState({
        torchUsers: siteUsers,
        disabledUsers: siteUsers.filter(x => !x.enabled),
        newUsers: [],
        selectedUsers: [],
      });
    });
  }

  handleSelectSites = (selectedRows) => {
    this.setState({ selectedSites: selectedRows });
    // Get torch user data
    this.getTorchLimitedUsers(selectedRows);
    // Find sites with commands
    const api = FetchApi("GetSitesWithLimitedUserCommand");
    const body = (selectedRows || []).map(x => x.fhtSiteId);
    api.post(body).then(response => {
      this.setState({ selectedSitesWithCommands: (response || []).map(x => x.fhtSiteName) });
    });
  };

  handleClearAllConfigurationsSelected = () => {

  };

  handleClearAllSelectedSites = () => {
    if (this.state.siteCheckBox != undefined) {
      var siteCheckBox = this.state.siteCheckBox;
      siteCheckBox.map(checkBox => {
        checkBox.tableData.checked = false;
      });
      this.setState({
        siteCheckBox: siteCheckBox,

        selectedSites: [],
        torchUsers: [],
        selectedUsers: [],

        newUsers: [],
        disabledUsers: [],
      });
    }
  };

  handleSelectUsers = (selectedRows) => {
    this.setState({ selectedUsers: selectedRows });
  };

  updateCommonSelectedSiteGroupings = (event) => {
    this.handleClearAllSelectedSites();
    this.handleClearAllConfigurationsSelected();

    this.setState({
      selectedSiteGroupings: event.target.value
    });

    var selectedSitesFromSiteGroup = JSON.parse(this.state.siteGroupingsList.filter(a => a.key == String(event.target.value))[0].siteIDs);
    var checkedBoxSelectedSitesArray = [];
    for (var i = 0; i < selectedSitesFromSiteGroup.length; i++) {
      try {
        const currentIndex = i;
        var siteCheckBoxFiltered = this.state.siteCheckBox.filter(a => a.fhtSiteId == selectedSitesFromSiteGroup[currentIndex].fhtSiteGroupingsSiteID)[0];
        siteCheckBoxFiltered.tableData.checked = true;
        checkedBoxSelectedSitesArray.push(siteCheckBoxFiltered);
      } catch {
        // Site doesn't exist in the siteCheckBox variable now.
        // Could have been removed, disabled.
      }
    }

    this.handleSelectSites(checkedBoxSelectedSitesArray);
  };

  createUserSubmit(e) {
    e.preventDefault();

    const currentNewUsers = [...this.state.newUsers];

    // Add user
    currentNewUsers.push({
      userName: this.state.dialogAddUserName,
      firstName: this.state.dialogAddFirstName,
      lastName: this.state.dialogAddLastName,
      existsOnAllSites: true,
    });

    this.setState({ newUsers: currentNewUsers, dialogAddUserVisible: false });
  }

  enableSelectedUsers() {
    let currentDisabledUsers = [...this.state.disabledUsers];
    currentDisabledUsers = currentDisabledUsers.filter(x => !this.state.selectedUsers.some(user => user.userName === x.userName));
    this.setState({ disabledUsers: currentDisabledUsers });
  }

  disableSelectedUsers() {
    let currentDisabledUsers = [...this.state.disabledUsers];
    let currentNewUsers = [...this.state.newUsers];
    this.state.selectedUsers.forEach(x => {
      if (currentDisabledUsers.some(user => user.userName === x.userName)) {
        return;
      }

      if (this.state.newUsers.some(user => user.userName === x.userName)) {
        return;
      }

      currentDisabledUsers.push(x);
    });

    currentNewUsers = currentNewUsers.filter(x => !this.state.selectedUsers.some(user => user.userName === x.userName));
    this.setState({ disabledUsers: currentDisabledUsers, newUsers: currentNewUsers });
  }

  submitChanges(e) {
    e.preventDefault();

    const modifiedDisabledUsers = this.state.disabledUsers.filter(x => x.enabled);
    const modifiedEnabledUsers = this.state.torchUsers.filter(x => !x.enabled && !this.state.disabledUsers.some(user => user.userName === x.userName));

    const api = FetchApi("SubmitLimitedUserChanges");
    const body = {
      siteIds: this.state.selectedSites.map(x => x.fhtSiteId),
      newUsers: this.state.newUsers.map(x => ({ userName: x.userName, firstName: x.firstName, lastName: x.lastName })),
      disabledUsers: modifiedDisabledUsers.map(x => x.userName),
      enabledUsers: modifiedEnabledUsers.map(x => x.userName),
    };

    api.post(body).then(response => {
      if (response.ok === false) {
        throw new Error(response.status);
      } else {
        this.setState({ dialogSubmitVisible: false, dialogSubmitMessage: "Successfully submitted command" });
      }
    }).catch(error => {
      this.setState({ dialogSubmitError: "Error submitting command for site, please check that the site does not have a pending command" });
    });
  }

  renderSiteGroupingSelection() {
    return (
      <FormControl variant="outlined">
        <Select
          label="Select grouping"
          value={this.state.selectedSiteGroupings}
          style={{ color: "white" }}
          onChange={(event) => {
            this.updateCommonSelectedSiteGroupings(event);
          }}
        >
          {this.state.siteGroupingsList.map((grouping) => (
            <MenuItem
              key={Number(grouping.key)}
              value={Number(grouping.key)}
              style={{ color: "black" }}
            >
              {grouping.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderIndividualSiteSelection() {
    return (
      <MaterialTable
        columns={this.state.siteTable}
        data={this.state.siteCheckBox}
        options={this.pageOptions}
        title=""
        onSelectionChange={(selectedRows) => {
          this.handleSelectSites(selectedRows);
        }}
      ></MaterialTable>
    );
  }

  render() {
    const tableUsers = this.state.torchUsers
      .concat(this.state.newUsers);

    const isAllSelectedDisabled = this.state.selectedUsers.every(x => this.state.disabledUsers.some(user => user.userName === x.userName));
    const isAllSelectedEnabled = this.state.selectedUsers.every(x => !this.state.disabledUsers.some(user => user.userName === x.userName));

    const modifiedDisabledUsers = this.state.disabledUsers.filter(x => x.enabled);
    const modifiedEnabledUsers = this.state.torchUsers.filter(x => !x.enabled && !this.state.disabledUsers.some(user => user.userName === x.userName));
    const newUsers = this.state.newUsers.map(x => x.userName);
    const disabledUsers = modifiedDisabledUsers.map(x => x.userName);
    const enabledUsers = modifiedEnabledUsers.map(x => x.userName);

    return (
      <Grid container justify="center">
        <div style={{ width: "90%" }}><br />
          <Grid container justify="center">
            <Typography variant="h3" component="h1" gutterBottom>Manage Torch Limited Users</Typography>
          </Grid><br />
          <Grid container spacing={1} style={{ paddingBottom: 8 }}>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => this.setState({ dialogAddUserVisible: true })}
              >
                Add New User
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<CheckCircle />}
                disabled={this.state.selectedUsers.length === 0 || isAllSelectedEnabled}
                onClick={() => this.enableSelectedUsers()}
              >
                Enable Users
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RemoveCircle />}
                disabled={this.state.selectedUsers.length === 0 || isAllSelectedDisabled}
                onClick={() => this.disableSelectedUsers()}
              >
                Disable Users
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ gap: "8px", width: "120%" }}>
            <Grid item xs={3} >
              {/** Left side of the page **/}
              <Box
                bgcolor="primary.main"
                style={{ color: "white" }}
                height="50px"
              >
                {this.renderSiteGroupingSelection()}
              </Box>
              <div className="nsExtraTallScrollBox">
                {this.renderIndividualSiteSelection()}
              </div>
              <br />
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={this.handleClearAllSelectedSites}
                >
                  Clear Site selections
                </Button>
                <br /><br />
              </Box>
            </Grid>
            <Grid item xs={7}>
              {/** Right side of the page **/}
              <Box display="flex" flexDirection="column" height="100%">
                {this.state.selectedSites.length > 0 ?
                  <MaterialTable
                    data={tableUsers}
                    columns={this.userTableColumns}
                    options={this.userTableOptions}
                    title="Limited Torch Users"
                    onSelectionChange={(selectedRows) => {
                      this.handleSelectUsers(selectedRows);
                    }}
                  /> :
                  <Paper elevation={2}>
                    <Box height={749} padding={2} display="flex" alignItems="center" justifyContent="center">
                      <Typography variant="body1">
                        Please select a site to manage users
                      </Typography>
                    </Box>
                  </Paper>
                }
                <Paper elevation={2} style={{ marginTop: "1rem", marginLeft: "auto", height: "fit-content" }}>
                  <Box padding={1} textAlign="right">
                    <Typography variant="body1">
                      <strong>Black</strong>: Enabled existing user
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ color: "red" }}>Red</strong>: Disabled existing user
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ color: "green" }}>Green</strong>: New user (disable to remove)
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ color: "gray", fontStyle: "italic" }}>Grey</strong>: User does not exist across all sites (not selectable)
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          {this.state.selectedSitesWithCommands.length > 0 &&
            <Typography variant="body1" align="center" gutterBottom>Selected sites with pending commands: {this.state.selectedSitesWithCommands.join(", ")}</Typography>
          }
          <Box marginTop={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ dialogSubmitVisible: true })}
              disabled={newUsers.length === 0 && enabledUsers.length === 0 && disabledUsers.length === 0}
            >
              Submit Changes
            </Button>
          </Box>

          <Dialog open={this.state.dialogAddUserVisible} onClose={() => this.setState({ dialogAddUserVisible: false })}>
            <DialogTitle>Create User</DialogTitle>
            <DialogContent>
              <form onSubmit={(e) => this.createUserSubmit(e)}>
                <DialogContentText>
                  Create a new torch limited user for the selected sites.
                </DialogContentText>
                <TextField
                  autoFocus
                  id="username"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="standard"
                  margin="dense"
                  value={this.state.dialogAddUserName}
                  onChange={(e) => this.setState({ dialogAddUserName: e.currentTarget.value })}
                />
                <TextField
                  id="firstname"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  margin="dense"
                  value={this.state.dialogAddFirstName}
                  onChange={(e) => this.setState({ dialogAddFirstName: e.currentTarget.value })}
                />
                <TextField
                  id="lastname"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  margin="dense"
                  value={this.state.dialogAddLastName}
                  onChange={(e) => this.setState({ dialogAddLastName: e.currentTarget.value })}
                />
                <Box paddingTop={1}>
                  <Grid container style={{ gap: "8px" }} justify="flex-end">
                    <Grid item>
                      <Button onClick={() => this.setState({ dialogAddUserVisible: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit">Create</Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog open={this.state.dialogSubmitVisible} onClose={() => this.setState({ dialogAddUserVisible: false })}>
            <DialogTitle>Submit Changes</DialogTitle>
            <DialogContent>
              {this.state.dialogSubmitError && <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {this.state.dialogSubmitError}
              </Alert>}
              <Typography variant="body1" color="textSecondary" gutterBottom>It can take up to 15 minutes for a command to be processed for a site</Typography>
              <form onSubmit={(e) => this.submitChanges(e)}>
                {newUsers.length > 0 &&
                  <>
                    <Typography variant="h6" gutterBottom>New Users ({newUsers.length})</Typography>
                    <Typography variant="body1" gutterBottom>{newUsers.join(", ")}</Typography>
                  </>
                }

                {enabledUsers.length > 0 &&
                  <>
                    <Typography variant="h6" gutterBottom>Enabled Users ({enabledUsers.length})</Typography>
                    <Typography variant="body1" gutterBottom>{enabledUsers.join(", ")}</Typography>
                  </>
                }

                {disabledUsers.length > 0 &&
                  <>
                    <Typography variant="h6" gutterBottom>Disabled Users ({disabledUsers.length})</Typography>
                    <Typography variant="body1" gutterBottom>{disabledUsers.join(", ")}</Typography>
                  </>
                }
                <Box paddingTop={1}>
                  <Grid container style={{ gap: "8px" }} justify="flex-end">
                    <Grid item>
                      <Button onClick={() => this.setState({ dialogSubmitVisible: false, dialogSubmitError: "" })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit">Submit</Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
          <Snackbar open={this.state.dialogSubmitMessage} autoHideDuration={6000} onClose={() => this.setState({ dialogSubmitMessage: "" })}>
            <Alert variant="filled" onClose={() => this.setState({ dialogSubmitMessage: "" })} severity="success">
              {this.state.dialogSubmitMessage}
            </Alert>
          </Snackbar>
        </div>
      </Grid>
    );
  }
}

export default TorchManageLimitedUsers;
