// This file stores the fetch functions, acts like a middle ware
// Status: Ready
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

export const getTestDescriptions = async () => {
  const api = FetchApi("getAllTests");
  const fetched = await api.get();
  return fetched;
};

export const getSummary = async () => { //Not used
  const api = FetchApi("getServerSummary");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  const fetched = await api.post(body);
  return fetched;
};

export const getFHTAlgorithmHistory = async () => {
  const api = FetchApi("getFHTAlgorithmHistory");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  const fetched = await api.post(body);
  return fetched;
};

export const getckdstats= async() =>{ //Not used
  const api = FetchApi("GetloggingCKDstats");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  const fetched = await api.post(body);
  return fetched;
}

export const getMachines= async() =>{
  const api = FetchApi("GetMachineOnSite");
  const fetched = await api.get();
  return fetched;
}

export const getDemographics = async() =>{ // not used
  const api = FetchApi("Getloggingdemographic");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  const fetched = await api.post(body);
  console.log(fetched)
  return fetched;
}

export const getCohorts = async() =>{ // not used
  const api = FetchApi("Getloggingccohortstats");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  const fetched = await api.post(body);
  return fetched;
}

export const getHeartbeats = async () => {
  const api = FetchApi("getHeartbeats");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
  };
  api.post(body).then((response) => {
    return response;
  });
};

export const getPOCversions = async () =>{
  const api = FetchApi("GetLocalVersion");
  const fetched = await api.get();
  let pocVer_filter= fetched.filter(vr=>vr.fhtLocalVersionSystemId===3);
  return pocVer_filter;
}

export const getVersions = async () => {
  let machineVers = {};
  let siteVers = {};
  let webAPISiteVers = {};
  try {
    const api = FetchApi("GetLocalVersion");
    const fetched = await api.get();

    fetched.forEach((machineVer) => {
    // MachineID and WebAPI version number
    if (machineVer.fhtLocalVersionSystemId == 2) {
      machineVers[machineVer["fhtLocalVersionMachineId"]] =
      machineVer["fhtLocalVersionCurrentVersion"];
    }

    // SiteID and WebAPI version number
    if (machineVer.fhtLocalVersionSystemId == 2) {
      webAPISiteVers[machineVer["fhtLocalVersionSiteId"]] =
      machineVer["fhtLocalVersionCurrentVersion"];
    }

    // SiteID and Algorithm version number
    if (machineVer.fhtLocalVersionSystemId == 1) {
    siteVers[machineVer["fhtLocalVersionSiteId"]] =
      machineVer["fhtLocalVersionCurrentVersion"];
    }
    });
  } catch (error) {
    console.log("Something went wrong when fetching the local versions");
  }

  return { machineVers: machineVers, siteVers: siteVers, webAPISiteVers: webAPISiteVers};
};

// If a SiteID is provided, we query just for the one site.
// Pass as an Int or Null.
export const getSitesAlgorithmCountIssuesData = async (SiteID) => {
  const api = FetchApi("GetSitesAlgorithmCountIssuesData");
  let body = {
    SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    SiteID: SiteID,
  };
  const fetched = await api.post(body);
  return fetched;
};
