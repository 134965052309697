import Configs from "./Configuration";

const FetchApi = (endpoint) => {
    const awsUrl = Configs.SiteConfigurationMapping[window.location.hostname].awsUrl;
  
    // START603: Use this section if you are using the frontend with the backend data(RELEASE mode)
    const authorizeHeader = Object.assign(
      { Authorization: "Bearer " + localStorage.getItem(Configs.tokenInfo) },
      Configs.urlHeader.headers
    );
    const defaultHeader = authorizeHeader;
    const customFetch = (
      url,
      method = "GET",
      body = false,
      headers = defaultHeader
    ) => {
      const options = {
        method,
        headers,
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const res = fetch(url, options)
        .then((response) => {
          console.log ("fetchapi response:",response);
          if (!response.ok) {
            return response;
            //throw new Error("Bad request");
          }
          return response.json();
        })
        .catch((err) => {
          throw new Error(err);
        });

      return res;
    };

    const get = (id) => {
      const url = `${awsUrl}${endpoint}${id ? `/${id}` : ""}`;
      return customFetch(url);
    };

    const post = (body = false) => {
      if (!body) throw new Error("failed");
      const url = `${awsUrl}${endpoint}`;
      return customFetch(url, "POST", body);
    };

    const put = (id = false, body = false) => {
      if (!id || !body) {
        console.log("no body");
        throw new Error("failed");
      }
      // console.log(endpoint, id, body);
      const url = `${awsUrl}${endpoint}/${id}`;
      return customFetch(url, "PUT", body);
    };

    const del = (id = false) => {
      if (!id) {
        throw new Error("failed");
      }
      const url = `${awsUrl}${endpoint}/${id}`;
      return customFetch(url, "DELETE");
    };

    return { get, post, put, del };
    // END603: Use this section if you are using the frontend with the backend data(RELEASE mode)
  
};

export default FetchApi;
