import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ReorderListGeneralComponent from "./ReorderListGeneralComponent";
import SelectedModuleConfigurations from "../SharedComponents/SelectedModuleConfigurations";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";

class ConfigSiteAccountModuleEdit extends Component {
  constructor(props) {
    super();
    this.state = {
      // Site modules      
      updateSiteModuleConfigCheckboxList: [],
      siteModuleListOrder: [],
      selectedSites: [],
    }
    this.setRecOrModuleSelectionListOrder = this.setRecOrModuleSelectionListOrder.bind(this);
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.handleClearAllConfigurationsSelected = this.handleClearAllConfigurationsSelected.bind(this);
  }
  
  componentDidMount() {
    this.getModuleAndActiveMap()
  }

  getModuleAndActiveMap(keepSiteSelection) {
    //Module map
    const api = FetchApi(Configs.api.getFhtConfigModuleAndActiveModuleMap)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      console.log("getModuleAndActiveMap response", response)
      if (keepSiteSelection == true) {
        this.setState({
          sitesAndActiveModulesDictionary: response.sitesAndActiveModulesDictionary,
          updateSiteModuleConfigCheckboxList: response.updateSiteModuleConfigCheckboxList,
          isLoaded: true
        })
        this.SharedComponentSiteGroupingCallback(this.state.selectedSites)
      } else {
        this.setState({
          sitesAndActiveModulesDictionary: response.sitesAndActiveModulesDictionary,
          updateSiteModuleConfigCheckboxList: response.updateSiteModuleConfigCheckboxList,
          isLoaded: true
        })
      }
    })
  }
  
  // Send data to API to update all sites selected with what modules are active.
  updateMultipleSiteModuleConfigs() {
    // Module map
    var body = {
      "siteModuleConfigUpdates": this.state.siteModuleListOrder,
      "siteModuleSelectedSites": this.state.selectedSites
    };
    // body.SiteTypeIDs = Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID;
    const api = FetchApi(Configs.api.updateMultipleSiteModuleConfigs);
    api.post(body).then((response) => {
      console.log("updateMultipleSiteModuleConfigs response", response);
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized");
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
      // Refresh data
      this.getModuleAndActiveMap(true);
    }).catch((err) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })
  }

  updateCommonModuleCheckbox = (event) => {
    var moduleSelectionUpdateCheckArray = []
    var descriptionKey = ""
    var reorderListArray = []
    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      moduleSelectionUpdateCheckArray = JSON.parse(JSON.stringify(this.state.updateSiteModuleConfigCheckboxList))
      descriptionKey = "fhtConfigModuleMapFileName"
    } else if (this.props.configType == Configs.configurationEditingPageTypes.pocModuleConfig) {
      moduleSelectionUpdateCheckArray = JSON.parse(JSON.stringify(this.state.pocModuleActiveInactive))
      descriptionKey = "pocModuleName"
    }

    var updatedCheckBoxForPOCModule = JSON.parse(JSON.stringify(moduleSelectionUpdateCheckArray))
    var checkBoxToUpdate = updatedCheckBoxForPOCModule.filter(a => a[descriptionKey] == event.target.value)[0]
    checkBoxToUpdate.checkedBox = event.target.checked

    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      var userSelectedSiteModuleOrder = JSON.parse(JSON.stringify(this.state.siteModuleListOrder))
      var unTickedModuleArray = moduleSelectionUpdateCheckArray.filter(a => a.checkedBox == false)
      reorderListArray = userSelectedSiteModuleOrder

      // Push new value if it's clicked on else pop the last element to remove it.
      if (unTickedModuleArray.filter(a => a.fhtConfigModuleMapFileName == event.target.value).length > 0) {
        reorderListArray.push(event.target.value)
      } else {
        reorderListArray = reorderListArray.filter(x => x !== event.target.value);
      }
    }

    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      this.setState({
        updateSiteModuleConfigCheckboxList: updatedCheckBoxForPOCModule,
        siteModuleListOrder: reorderListArray
      })
    } else if (this.props.configType == Configs.configurationEditingPageTypes.pocModuleConfig) {
      this.setState({
        pocModuleActiveInactive: updatedCheckBoxForPOCModule
      })
    }
  }

  setRecOrModuleSelectionListOrder(newRecModuleOrder) {
    this.setState({
      siteModuleListOrder: newRecModuleOrder
    })
  }

  SharedComponentSiteGroupingCallback = (selectedSites) => {
    // Create deep copy of state object.
    var configModulesToUpdate = JSON.parse(JSON.stringify(this.state.updateSiteModuleConfigCheckboxList))
    var allModulesToActive = []

    // Loop through our dictionary and identify all modules that will be active.
    for (var i = 0; i < selectedSites.length; i++) {
      if (Object.hasOwn(this.state.sitesAndActiveModulesDictionary, selectedSites[i].fhtSiteId)) {
        allModulesToActive = [...new Set([...allModulesToActive, ...this.state.sitesAndActiveModulesDictionary[selectedSites[i].fhtSiteId]])];
      }
    }

    // Loop through our config checkbox to update checkbox with active modules.
    for (var i = 0; i < configModulesToUpdate.length; i++) {
      if (allModulesToActive.includes(configModulesToUpdate[i].fhtConfigModuleMapFileName)) {
        configModulesToUpdate[i].checkedBox = true
      } else {
        configModulesToUpdate[i].checkedBox = false
      }
    }

    // Identify the order of modules for module order list. 
    var updatedSiteModuleOrderList = []
    for (var i = 0; i < allModulesToActive.length; i++) {
      if (configModulesToUpdate.filter(a => a.fhtConfigModuleMapFileName == allModulesToActive[i]).length > 0)
        updatedSiteModuleOrderList.push(configModulesToUpdate.filter(a => a.fhtConfigModuleMapFileName == allModulesToActive[i])[0]["fhtConfigModuleMapFileName"])
    }

    this.setState({
      updateSiteModuleConfigCheckboxList: configModulesToUpdate,
      selectedSites: selectedSites,
      siteModuleListOrder: updatedSiteModuleOrderList
    })
  };

  handleClearAllConfigurationsSelected() {
    if (this.state.updateSiteModuleConfigCheckboxList != undefined) {
      var updateSiteModuleConfigCheckboxList = this.state.updateSiteModuleConfigCheckboxList;
      updateSiteModuleConfigCheckboxList.map(checkBox => {
        checkBox.checkedBox = false;
      });
      this.setState({
        updateSiteModuleConfigCheckboxList: updateSiteModuleConfigCheckboxList,
        siteModuleListOrder: [],
      });
    }
  }

  renderConfigSiteAccountModuleEdit = () => {
    var descriptionKey = "fhtConfigModuleMapFileName";
    return (
      <div> <br/>
        <Grid container justify="center" style={{ gap: "8px", width: "100%" }}>
          <Grid item xs={3}>
            {/** Left side of the page **/}
            <SelectSitesGrouping
              SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
            />
            <br />
          </Grid>
          <Grid item xs={7}>
            {/** Right side of the page **/}
            <Box
              bgcolor="primary.main"
              style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
              height="50px"
            >
              <Typography variant="h5">
                {"Site Modules"}
              </Typography>
            </Box>
            <div className="nsExtraTallScrollBoxConfigEdit">
              <SelectedModuleConfigurations
                descriptionKey={descriptionKey}
                commonSiteModuleCheckboxList={this.state.updateSiteModuleConfigCheckboxList}
                updateCommonModuleCheckbox={this.updateCommonModuleCheckbox}
              />
            </div><br />
            <Button
              variant="contained"
              onClick={this.handleClearAllConfigurationsSelected}
            >
              Clear Algo selections
            </Button>
          </Grid>
        </Grid> <br />
        <Grid container justify="center">
          <Grid container spacing={10} style={{ width: "100%" }}>
            <Grid item xs={4}>
              {/* Empty rendering space for ReorderList to be on right hand side. */}
            </Grid>
            <Grid item xs={7}>
              <ReorderListGeneralComponent
                listToUse={this.state.siteModuleListOrder}
                reorderListHeading={"Order of selected modules"}
                reorderListType={"siteModulesReorder"}
                setNewListToUseOrder={this.setRecOrModuleSelectionListOrder}
              />
              <Grid container justify="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    this.updateMultipleSiteModuleConfigs();
                  }}
                >
                  Update modules
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfigSiteAccountModuleEdit()} <br />
      </div>
    )
  }
}

export default ConfigSiteAccountModuleEdit;