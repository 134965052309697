import React from "react";
import Configs from "../Service/Configuration";
import FetchApi from "../Service/FetchApi";
import { withStyles } from "@material-ui/core/styles/";
import { utcToLocal, compareDateTimes} from "./HelperFunctions";
import xml2js from "xml2js";
import moment from "moment";

import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MaterialTable from "material-table";
import Tab from '@material-ui/core//Tab';
import Tabs from '@material-ui/core//Tabs';

import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";



const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  buttonMargin: {
    marginLeft: 20,
  },
  tip: {
    fontSize: "smaller",
    textAlign: "left",
    marginButtom: 10,
    color: "#cccccc",
  }, 
});

class TSLogDropDown extends React.Component{
  /*-- init --*/
  constructor(props) {
    super(props);
    this.state = {
      isLoaded : false,
      siteName : props.siteName,
      XmlData : null,
      XmlString : props.XmlString,
    }
    this.errorXmlMessage = 
      <div>
        <p>Error when parsing Xml, please check the database</p>
      </div>
    ;
    this.pageOptions = {
      filtering: true,
      paging : false,
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
      headerStyle: {
        backgroundColor: '#EEE',
      },
    };
    this.XmlCol = [
      { title: "Id", field: "Id", hidden : true, width : 0},
      { title: "Time Created", field: "TimeCreated_converted", type:"datetime",
        customSort : (a,b) => compareDateTimes(a.TimeCreated, b.TimeCreated)
      },
      { title: "TaskDisplayName", field: "TaskDisplayName" },
      { title: "Description", field: "Description"},
    ];

  }

  parseXml = ()=>{
    var parser = new xml2js.Parser();
    parser.parseString(
      this.state.XmlString, 
      (error,result) => {
        if(error){
          this.setState({XmlData : this.errorXmlMessage});
        }
        else{
          //Extract the value from the data element
          var data = result['TaskScheduler']['Log'];
          if (data === undefined || data === null) {
            this.setState({XmlData : []});
          } else {
            try {
              data.map(d=>{
                let outTime = new Date(d.TimeCreated[0].replace("+11:00",""));
                outTime = outTime.toLocaleDateString('en-GB') + ", " + outTime.toLocaleTimeString();
                d.TimeCreated_converted = outTime;
              })
              this.setState({XmlData : data});
              // console.log("parsed", this.state.XmlData);
            } catch {
              // If data does not contain enough data, set XmlData to be empty array.
              this.setState({XmlData : []});
            }
          }
        }
      }
    );
    this.setState({isLoaded : true});
  }

  componentDidMount(){
    this.parseXml();
  }

  render(){
    return(
      <div>
        {!this.state.isLoaded ?
          <CircularProgress/>
          :
          (
            this.state.XmlData == this.errorXmlMessage ? 
            this.errorXmlMessage
            :
            <MaterialTable
              columns= {this.XmlCol}
              data=    {this.state.XmlData}
              options= {this.pageOptions}
              title=   {this.state.siteName}
              style = {{backgroundColor: '#EEE'}}
            ></MaterialTable>
          )
        }
      </div>
    )
  }
}

class EVLogDropDown extends React.Component{
  /*-- init --*/
  constructor(props) {
    super(props);
    this.state = {
      isLoaded : false,
      siteName : props.siteName,
      XmlData : null,
      XmlString : props.XmlString,
    }
    this.errorXmlMessage = 
      <div>
        <p>Error when parsing Xml, please check the database</p>
      </div>
    ;
    this.pageOptions = {
      filtering: true,
      paging : false,
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
      headerStyle: {
        backgroundColor: '#EEE',
      },
    };
    this.XmlCol = [
      { title: "Id", field: "Id", hidden : true, width : 0},
      { title: "Record Id", field: "RecordId" },
      { title: "Time Created", field: "TimeCreated_converted", type:"datetime",
        customSort : (a,b) => compareDateTimes(a.TimeCreated, b.TimeCreated)
      },
      { title: "Provider Name", field: "ProviderName" },
      { title: "Description", field: "Description"},
    ];

  }

  parseXml = ()=>{
    // parse the xmlstring
    // set state of xmlData as a component to be shown in dialog

    var parser = new xml2js.Parser();
    parser.parseString(
      this.state.XmlString, 
      (error,result) => {
        if(error){
          this.setState({XmlData : this.errorXmlMessage});
        }
        else{
          // If XML does not contain enough data it won't be mappable.
          try {
            //Extract the value from the data element
            var data = result['EventLogs']['Application'][0]['Log'];
  
            data.map(d=>{
              let outTime = new Date(d.TimeCreated[0].replace("+11:00",""));
              outTime = outTime.toLocaleDateString('en-GB') + ", " + outTime.toLocaleTimeString();
              d.TimeCreated_converted = outTime;
            })

            this.setState({XmlData : data});
            // console.log("parsed", this.state.XmlData);
          } catch {
            // If the XML data does not contain enough data, we set XmlData to be empty.
            this.setState({XmlData : []});
          }
        }
      }
    );
    this.setState({isLoaded : true});
  }

  componentDidMount(){
    this.parseXml();
  }

  render(){
    return(
      <div>
        {!this.state.isLoaded ?
          <CircularProgress/>
          :
          (
            this.state.XmlData == this.errorXmlMessage ? 
            this.errorXmlMessage
            :
            <MaterialTable
              columns= {this.XmlCol}
              data=    {this.state.XmlData}
              options= {this.pageOptions}
              title=   {this.state.siteName}
              style = {{backgroundColor: '#EEE'}}
            ></MaterialTable>
          )
        }
      </div>
    )
  }

}

class EventViewerLogAndTaskSchedulerLog extends React.Component {
  /*-- init --*/
  constructor(props) {
    super(props);

    this.state = {
      isLoaded : false,
      selectedSiteName : null,
      XmlDialogIsOpen : false,
      XmlDialogIsLoaded : false,
      XmlData : null,
      XmlString : null,
      tabValue : 0,
      //eventLogs : [],
      //taskSchedulerLogs : [],
      //logPullConfigs : [],
    }

    this.errorXmlMessage = 
      <div>
        <p>Error when parsing Xml, please check the database</p>
      </div>
    ;

    this.enableMode = {
      DISABLED : 0,
      ONCE : 1,
      ALWAYS : 2
    }


    this.pageOptions = {
      filtering: true,
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };

    this.eventLogCol = [
      { title: "Log Id", field: "fhtEventLogId", hidden : true, width : 0},
      { title: "Log date", field: "fhtEventLogDate_converted",
        customSort : (a,b) => compareDateTimes(a.fhtEventLogDate, b.fhtEventLogDate)
      },

      { title: "Site Id", field: "fhtEventLogSiteId" },
      { title: "Site Name", field: "fhtSiteName" },
      { title: "Account Id", field: "fhtEventLogAccountId", hidden : true, width : 0 },

      { title: "Machine Id", field: "fhtEventLogMachineId" },
      { title: "Machine Name", field: "fhtEventLogMachineName" },
      { title: "SoftwareName", field: "fhtEventLogSoftwareName" },
      { title: "Version", field: "fhtEventLogVersionNumber" },
    ];

    this.taskSchedulerLogCol = [
      { title: "Log Id", field: "fhtTaskSchedulerLogId", hidden : true, width : 0},
      { title: "Log date", field: "fhtTaskSchedulerLogDate_converted",
        customSort : (a,b) => compareDateTimes(a.fhtTaskSchedulerLogDate, b.fhtTaskSchedulerLogDate)
      },

      { title: "Site Id", field: "fhtTaskSchedulerLogSiteId" },
      { title: "Site Name", field: "fhtSiteName" },
      { title: "Account Id", field: "fhtTaskSchedulerLogAccountId", hidden : true , width : 0},

      { title: "Machine Id", field: "fhtTaskSchedulerLogMachineId" },
      { title: "Machine Name", field: "fhtTaskSchedulerLogMachineName" },
      { title: "SoftwareName", field: "fhtTaskSchedulerLogSoftwareName" },
      { title: "Version", field: "fhtTaskSchedulerLogVersionNumber" },
    ];

    this.XmlCol = [
      { title: "Id", field: "Id", hidden : true, width : 0},
      { title: "Record Id", field: "RecordId" },
      { title: "Time Created", field: "TimeCreated_converted",
        customSort : (a,b) => compareDateTimes(a.TimeCreated, b.TimeCreated)
      },
      { title: "Provider Name", field: "ProviderName" },
      { title: "Description", field: "Description"},
    ];

    this.logPullConfigCol = [
      { title: "Extract date", field: "fhtPullLogsEnabledExtractDate_converted", tooltip:"The last time autoupdater sent EventViewer & TaskScheduler logs",
        customSort : (a,b) => compareDateTimes(a.fhtPullLogsEnabledExtractDate, b.fhtPullLogsEnabledExtractDate)
      },
      { title: "site Id", field: "FhtSiteId", hidden : true, width : 0},
      { title: "Site name", field: "fhtSiteName"},
      { title: "Machine Id", field: "fhtMachineOnSiteId", hidden : true, width : 0},
      { title: "Machine Name", field: "fhtMachineOnSiteMachineName"},
      { title: "Disable", field: "fhtPullLogsEnabledMode",tooltip:"The autoupdater stops sending EventViewer & TaskScheduler logs",
        render: rowData => 
          <Button 
            //disabled = {rowData.fhtPullLogsEnabledMode==this.enableMode.DISABLED}
            variant = {rowData.fhtPullLogsEnabledMode===this.enableMode.DISABLED ? "contained" : "text"}
            color = {rowData.fhtPullLogsEnabledMode===this.enableMode.DISABLED ? "primary" : "default"}
            onClick = {() => {
              if(rowData.fhtPullLogsEnabledMode!==this.enableMode.DISABLED)
                this.postEnableChange(rowData.fhtSiteId, rowData.fhtMachineOnSiteId, this.enableMode.DISABLED)
            }}
          > Disable </Button>
      },
      { title: "Once", field: "fhtPullLogsEnabledMode", tooltip:"The autoupdater sends EventViewer & TaskScheduler logs once and disables this setting",
        render: rowData => 
        <Button 
          //disabled = {rowData.fhtPullLogsEnabledMode==this.enableMode.DISABLED}
          variant = {rowData.fhtPullLogsEnabledMode===this.enableMode.ONCE ? "contained" : "text"}
          color = {rowData.fhtPullLogsEnabledMode===this.enableMode.ONCE ? "primary" : "default"}
          onClick = {() => {
            if(rowData.fhtPullLogsEnabledMode!==this.enableMode.ONCE)
              this.postEnableChange(rowData.fhtSiteId, rowData.fhtMachineOnSiteId, this.enableMode.ONCE)
          }}
        > Once </Button>
      },
      { title: "Always", field: "fhtPullLogsEnabledMode", tooltip:"The autoupdater will keep sending EventViewer & TaskScheduler logs",
        render: rowData => 
        <Button 
          //disabled = {rowData.fhtPullLogsEnabledMode==this.enableMode.DISABLED}
          variant = {rowData.fhtPullLogsEnabledMode===this.enableMode.ALWAYS ? "contained" : "text"}
          color = {rowData.fhtPullLogsEnabledMode===this.enableMode.ALWAYS ? "primary" : "default"}
          onClick = {() => {
            if(rowData.fhtPullLogsEnabledMode!==this.enableMode.ALWAYS)  
              this.postEnableChange(rowData.fhtSiteId, rowData.fhtMachineOnSiteId, this.enableMode.ALWAYS)
          }}
        > Always </Button>
      },      
    ];

    this.tab = {
      eventViewerLog:{
        title : "Event viewer log",
        id : 0
      },
      taskSchedulerLog:{
        title : "Task scheduler log",
        id : 1
      },
    }
  }

  componentDidMount(){
    this.getEventLogs();
    this.getTaskSchedulerLogs();
    this.getLogPullConfigs();
  }

  /* -- Fetch functions ---*/
  getEventLogs(){
    const api = FetchApi("GetFhtEventLogs");
    const body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response=>{
      response.map(r=>{
        r.fhtEventLogDate_converted = utcToLocal(r.fhtEventLogDate);
        return;
      })
      this.setState({
        eventLogs : response,
      })
      this.checkIsLoaded();
    })
  }

  getTaskSchedulerLogs(){
    const api = FetchApi("GetFhtTaskSchedulerLog");
    const body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response=>{
      response.map(r=>{
        r.fhtTaskSchedulerLogDate_converted = utcToLocal(r.fhtTaskSchedulerLogDate);
        return;
      })
      this.setState({
        taskSchedulerLogs : response,
      });
      this.checkIsLoaded();
    })
  }

  getLogPullConfigs(){
    const api = FetchApi("GetFhtPullLogsEnabled");
    const body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response=>{
      console.log("enables",response);
      response.map(r=>{
        if(r.fhtPullLogsEnabledExtractDate !== null)
          r.fhtPullLogsEnabledExtractDate_converted = utcToLocal(r.fhtPullLogsEnabledExtractDate);
          return;
      })
      this.setState({
        logPullConfigs : response,
        }
      );
      this.checkIsLoaded();
    })
  }

  /*-- Post functions --*/
  postEnableChange(siteId, machineId, enableMode){
    const api = FetchApi("SetSendLogsCheck");
    const body = {
      SiteId: siteId,
      MachineOnSiteId : machineId,
      EventLogEnabledMode : enableMode
    };
    api.post(body).then(response=>{
      //Success message 
      this.props.GlobalFunctionDisplaySnackbarMessage(
        response.Message 
          ? response.Message
          : Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
      // update setting
      var newLogPullConfigs = JSON.parse(JSON.stringify(this.state.logPullConfigs)); //deep copy
      for(var iRow=0; iRow<newLogPullConfigs.length; iRow++) { // locate the entry

        if(newLogPullConfigs[iRow].fhtMachineOnSiteId == machineId) {
          newLogPullConfigs[iRow].fhtPullLogsEnabledMode = enableMode;
          this.setState({logPullConfigs : newLogPullConfigs});
          return;
        }

      }      
    }).catch((error)=>{
      //fail message
      console.log(error)
      this.props.GlobalFunctionDisplaySnackbarMessage(
        error.Message 
          ? error.Message
          : Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    })
  }

  /*-- helper functions --*/
  checkIsLoaded = ()=>{
    //console.log("Check", this.state.eventLogs, this.state.taskSchedulerLogs , this.state.logPullConfigs);
    if(this.state.eventLogs !== undefined
      && this.state.taskSchedulerLogs !== undefined
      && this.state.logPullConfigs !== undefined){
        this.setState({isLoaded : true});
      }

  }

  parseXml = ()=>{
    // parse the xmlstring
    // set state of xmlData as a component to be shown in dialog
    this.setState({XmlDialogIsLoaded : false});

    var parser = new xml2js.Parser();
    parser.parseString(
      this.state.XmlString, 
      (error,result) => {
        if(error){
          this.setState({XmlData : this.errorXmlMessage});
        }
        else{
          try {
            //Extract the value from the data element
            var data = result['EventLogs']['Application'][0]['Log'];
            data.map(d=>{
              d.TimeCreated_converted = utcToLocal(d.TimeCreated);
            })
            this.setState({XmlData : data});
            // console.log("parsed", this.state.XmlData);
          } catch {
            // If data does not contain enough parsed XML data, set XMLData to be an empty array.
            this.setState({XmlData : []});
          }
        }
      }
    );

    this.setState({XmlDialogIsLoaded : true});
  }



  /* -- renders ---*/
  render(){
    if(!this.state.isLoaded){
      return(
        <div>
          <br/>
            <Grid container justify="center"><h2>Event viewer and Task scheduler</h2></Grid>
          <br/>
          <CircularProgress/>
          <br/>
          Loading...
        </div>
      )
    }
    if(this.state.configureLogPullIsOpen){
      return(
        <div>
          <br/>
            <Grid container justify="center"><h2>Event viewer and Task scheduler</h2></Grid>
          <br/>
          {this.renderConfigureLogPull()}
        </div>
      )
    }
    else{
      return(
        <div>
          <br/>
            <Grid container justify="center"><h2>Event viewer and Task scheduler</h2></Grid>
          <br/>
          <Grid container justify="center">
          <Button
            variant = "contained"
            onClick = {()=>{this.setState({configureLogPullIsOpen : true})}}
          >
            Configure autupdater log pull
          </Button>          
          </Grid>
          <br/>
          <Box style = {{marginLeft: "4.5REM"}}>
            <Tabs 
              value = {this.state.tabValue} 
              onChange={(event, newVal)=>this.setState({tabValue : newVal})}
              indicatorColor= "primary"
            >
              <Tab label={this.tab.eventViewerLog.title} value={this.tab.eventViewerLog.id} />
              <Tab label={this.tab.taskSchedulerLog.title}  value={this.tab.taskSchedulerLog.id} />
            </Tabs>
          </Box>
          {{//Choose page to render
            0 : this.renderEventLog(),
            1 : this.rendertaskSchedulerLog()
          }[this.state.tabValue]}
        </div>
      )
    }
  }

  renderEventLog = ()=>{
    return(
      <div>
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns= {this.eventLogCol}
            data=    {this.state.eventLogs}
            options= {this.pageOptions}
            title="Event viewer log" 
            onRowClick={(event, rowData, togglePanel) => {
                this.setState({selectedRow : rowData.tableData.id});
                togglePanel()
              }}
              detailPanel={[{
                tooltip : "See Logs",
                render : (rowData) =>{
                  return(
                    <EVLogDropDown
                      siteName = {rowData.fhtSiteName}
                      XmlString = {rowData.fhtEventLogXml}
                    ></EVLogDropDown>
                  )
                }
              }]}
          ></MaterialTable>
        </Grid><br/>
      </div>
    )
  }

  rendertaskSchedulerLog = ()=>{
    return(
      <div>
        <Grid container justify="center">
        <MaterialTable
          style = {{width: "90%"}}
          columns= {this.taskSchedulerLogCol}
          data=    {this.state.taskSchedulerLogs}
          options= {this.pageOptions}
          title="Task scheduler log" 
          onRowClick={(event, rowData, togglePanel) => {
            this.setState({selectedRow : rowData.tableData.id});
            togglePanel()
            }}
            detailPanel={[{
              tooltip : "See Logs",
              render : (rowData) =>{
                return(
                  <TSLogDropDown
                    siteName = {rowData.fhtSiteName}
                    XmlString = {rowData.fhtTaskSchedulerLogXml}
                  ></TSLogDropDown>
                )
              }
            }]}
        ></MaterialTable>
        </Grid><br/>
      </div>
    )
  }

  renderConfigureLogPull = ()=>{
    return(
      <div>
        <br/>
          <Grid container justify="center"><h3>Configure autoupdater log pulling</h3></Grid>
        <br/>
          <Grid container justify="center">
            <MaterialTable
              style = {{width: "90%"}}
              columns= {this.logPullConfigCol}
              data=    {this.state.logPullConfigs}
              options= {this.pageOptions}
              title="Log pull configurations" 
            ></MaterialTable>
          </Grid>
        <br/>
        <Button
          style = {{marginLeft: "4.5REM"}}
          variant = "contained"
          onClick = {()=>{this.setState({configureLogPullIsOpen : false})}}
        >
          Return
        </Button> <br/><br/>
      </div>
    )
  }

}

export default withStyles(useStyles)(EventViewerLogAndTaskSchedulerLog);