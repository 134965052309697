import React, { Component } from "react";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class AlgorithmStatusSimpleCellRender extends Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  algorithmStatusSimpleCellRender() {
    var currentValue = this.props.algorithmStatus
    var backgroundColorStatus = "#FF772EFF";
    var text = ""
    if (currentValue == Configs.AlgorithmTableStatus.updateAllAlg) {
      backgroundColorStatus = "#FF772EFF";
    }
    else if (currentValue == Configs.AlgorithmTableStatus.live) {
      backgroundColorStatus = "#4BB83BFF";
      text = "Live";
    }
    else if (currentValue == Configs.AlgorithmTableStatus.silent) {
      backgroundColorStatus = "#A29EA3FF";
      text = "Silent";
    }
    else if (currentValue == Configs.AlgorithmTableStatus.off) {
      backgroundColorStatus = "#F74D69FF";
      text = "Off";
    }

    return (
      <div style={{ backgroundColor: backgroundColorStatus, padding: "0.5REM", textAlign: "center", color: "white" }}>{text}</div>
    )
  }

  render() {
    return (
      <div>
        {this.algorithmStatusSimpleCellRender()}
      </div>
    )
  }
}

export default AlgorithmStatusSimpleCellRender;