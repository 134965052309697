import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { utcToLocal, compareDateTimes } from "./HelperFunctions";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  buttonMargin: {
    marginLeft: 20,
  },
  tip: {
    fontSize: "smaller",
    textAlign: "left",
    marginButtom: 10,
    color: "#cccccc",
  },
});

class NewMachinesOnSite extends React.Component {
  constructor(props) {
    super(props);
    this.user = props.user;
    this.software = [];
    this.site = [];
    this.machines = [];
    this.snackbarMessage = "";
    this.snackbarVariant = "success";
    this.localSystems = [];
    this.pageOptions = {
      pageSize: 10,
      search: true,
      searchText:
        props.location === undefined
          ? null
          : props.location.state.Keyword,
      pageSizeOptions: [10, 20, 30],
    };
    this.selectionOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      selection: true,
    };

    this.state = {
      newMachineTable: [
        { title: "Machine Id", field: "fhtMachineOnSiteId" },
        { title: "Machine Name", field: "fhtMachineOnSiteMachineName" },
        { title: "Site", field: "fhtSiteName" },
        {
          title: "Register Date",
          field: "convertDate",
          customSort: (a, b) => compareDateTimes(a.fhtMachineOnSiteRegisterDate, b.fhtMachineOnSiteRegisterDate)
        },
      ],
      newMachines: [],
    };
  }

  renderNewMachine = () => {
    const { classes } = this.props;
    return (
      <div>
        <h3>New Machines</h3>
        <MaterialTable
          columns={this.state.newMachineTable}
          data={this.state.newMachines}
          options={this.pageOptions}
          title=""
        ></MaterialTable>
      </div>
    );
  }

  componentDidMount = async () => {
    const api = FetchApi(Configs.api.getNewMachines);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response.map(r => {
        r.convertDate = utcToLocal(r.fhtMachineOnSiteRegisterDate);
      })
      this.setState({ newMachines: response });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderNewMachine()}
      </div>
    );
  }
}
export default withStyles(useStyles)(NewMachinesOnSite);