// Description: All the summary cards(3+1currently) displayed on the the main page
// Status: Ready

import React from "react";

import {
  Card,
  CardContent,
  Divider,
  Typography,
  CardHeader,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import "./ns.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Dialog from "@material-ui/core/Dialog";
import PeopleIcon from "@material-ui/icons/People";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

import {
  POCCardTooltip,
  APICardTooltip,
  ALGCardTooltip,
  SC4CCardTooltip,
  RecommendationQueriesTooltip,
  FHTLogsTooltip
} from "./Tooltips";
import Tooltip from "@material-ui/core/Tooltip";
import { utcToLocal } from "./HelperFunctions";
import { Grid } from "@material-ui/core";

const styles = {
  minHeight: {
    minHeight: 360,
  },

  tooltipBright: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.85)",
    // boxShadow: theme.shadows[2],
    color: "red",
    fontSize: 20,
  },

  contentHeight: {
    height: "37%",
  },
  greenFill: {
    fill: "green",
  },
  redFill: {
    fill: "red",
  },
  blueFill: {
    fill: "blue",
  },

  onHover: {
    color: "black",
    overflow: "hidden",
    "&:hover": {
      color: "green",
      cursor: "pointer",
      overflowWrap: "break-word",
      textDecoration: "underline",
      overflow: "visible",
    }
  }
};

const useStyles = makeStyles((theme) => (styles));

export function POCCard(props) {
  const [pocDeactiveSitesDialogIsOpen, setPocDeactiveSitesDialogIsOpen] = React.useState(false);
  const [pocConfigIssueSitesDialogIsOpen, setPocConfigIssueSitesDialogIsOpen] = React.useState(false);

  return (
    <div>
      <Card style={styles.minHeight}>
        <Link to="/POCSummary" className="nsLink">
          <CardHeader title={"POC Summary"} className="nsCardHeader" />
        </Link>
        <Divider />

        <CardContent style={styles.contentHeight}>
          <Tooltip title={POCCardTooltip}>
            <Typography>
              <Link to="/POCSummary/status/active" className="nsLink">
                <CheckCircleOutlineIcon style={styles.greenFill} />
                {"Active Sites: " + props.POCActiveNum}
              </Link>
              <br />
              <Link to="/POCSummary/status/fail" className="nsLink">
                <ErrorOutlineIcon style={styles.redFill} />
                {"Inactive Sites: " + props.POCInactiveNum}
              </Link>
            </Typography>
          </Tooltip>

          <Typography>
            <Link
              className="nsLink"
              onClick={(event) => setPocDeactiveSitesDialogIsOpen(true)}
            >
              <ErrorOutlineIcon style={styles.redFill} />
              {"Deactivated Sites: " + props.POCDeactivatedNum}
            </Link>
          </Typography>

          <Typography>
            <Link
              className="nsLink"
              onClick={(event) => setPocConfigIssueSitesDialogIsOpen(true)}
            >
              <ErrorOutlineIcon style={styles.redFill} />
              {"POC configuration issue: " + props.POCConfigParsingIssueNum}
            </Link>
          </Typography>

          <br />
          <div className="nsScrollBox">
            {Object.keys(props.POCVer).map((verID, index) => {
              return (
                <Link
                  key={index}
                  to={"/POCSummary/version/" + verID}
                  className="nsLink"
                >
                  {props.POCVer[verID] + " machines on " + verID}
                  <br />
                </Link>
              );
            })}
          </div>
          <Typography>
            <PeopleIcon style={styles.blueFill} />
            Total users:{props.numUniqueUsers}
            <br />
            <PeopleIcon style={styles.blueFill} />
            Popup today:{props.numDayUsers}
            <br />
            <PeopleIcon style={styles.blueFill} />
            Popup this week:{props.numWeekUsers}
            <br />
          </Typography>
        </CardContent>
      </Card>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={pocDeactiveSitesDialogIsOpen}
        onBackdropClick={(event) => setPocDeactiveSitesDialogIsOpen(false)}
      >
        <div style={{ whiteSpace: 'pre-line', padding: "5%" }}>
          {props.siteIDsThatAreDeactivedInPOCConfig == "" ? "No deactivated sites" : props.siteIDsThatAreDeactivedInPOCConfig}
        </div>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={pocConfigIssueSitesDialogIsOpen}
        onBackdropClick={(event) => setPocConfigIssueSitesDialogIsOpen(false)}
      >
        <div style={{ padding: "5%" }}>
          {props.siteIDsWithPOCConfigIssues == "" ? "No sites with configuration issues" : props.siteIDsWithPOCConfigIssues}
        </div>
      </Dialog>
    </div>
  )
};

const APICard = (props) => (
  <Card style={styles.minHeight}>
    <Link to="/APISummary" className="nsLink">
      <CardHeader title={"WebAPI summary"} className="nsCardHeader" />
    </Link>
    <Divider />
    <CardContent style={styles.contentHeight}>
      <Tooltip title={APICardTooltip}>
        <Typography>
          <Link to="/APISummary/status/active" className="nsLink">
            <CheckCircleOutlineIcon style={styles.greenFill} />
            {"Active Sites: " + (props.numAPISites - props.numAPIAlert)}
          </Link>
          <br />
          <Link to="/APISummary/status/fail" className="nsLink">
            <ErrorOutlineIcon style={styles.redFill} />
            {"Inactive Sites: " + props.numAPIAlert}
          </Link>
          <br />
        </Typography>
      </Tooltip>

      <br />
      <div className="nsScrollBox">
        {Object.keys(props.APIVer).map((verID, index) => {
          return (
            <Link
              key={index}
              to={"/APISummary/version/" + verID}
              className="nsLink"
            >
              {props.APIVer[verID] + " machines on " + verID}
              <br />
            </Link>
          );
        })}
      </div>
    </CardContent>
  </Card>
);


const ALGCard = (props) => (
  <Card style={styles.minHeight}>
    <Link to="/ALGSummaryHistory" className="nsLink">
      <CardHeader title={"Algorithms summary"} className="nsCardHeader" />
    </Link>
    <Divider />
    <CardContent style={styles.contentHeight}>
      <Tooltip title={ALGCardTooltip}>
        <Typography>
          <Link to="/ALGSummaryHistory/status/active" className="nsLink">
            <CheckCircleOutlineIcon style={styles.greenFill} />
            {"Active Sites: " + props.numActiveALG}
          </Link>
          <br />
          <Link to="/ALGSummaryHistory/status/fail" className="nsLink">
            <ErrorOutlineIcon style={styles.redFill} />

            {"Inactive Sites: " + (props.numALGRecords - props.numActiveALG)}
            <br />
          </Link>
          <br />
        </Typography>
      </Tooltip>

      <div className="nsScrollBox">
        {Object.keys(props.ALGVer).map((verID, index) => {
          return (
            <Link
              key={index}
              to={"/ALGSummaryHistory/version/" + verID}
              className="nsLink"
            >
              {props.ALGVer[verID] + " machines on " + verID}
              <br />
            </Link>
          );
        })}
      </div>
    </CardContent>
  </Card>
);

const SC4CCard = (props) => (
  <Card style={styles.minHeight}>
    <Link to="/SC4CSummary" className="nsLink">
      <CardHeader title={"SC4C Summary"} className="nsCardHeader" />
    </Link>
    <Divider />

    <CardContent style={styles.contentHeight}>
      <Tooltip title={APICardTooltip}>
        <Typography>
          <Link to="/SC4CSummary/status/active" className="nsLink">
            <CheckCircleOutlineIcon style={styles.greenFill} />
            {"Active Sites: " + props.SC4CActiveNum}
          </Link>
          <br />
          <Link to="/SC4CSummary/status/fail" className="nsLink">
            <ErrorOutlineIcon style={styles.redFill} />
            {"Inactive Sites: " + (props.length - props.SC4CActiveNum)}
          </Link>
          <br />
        </Typography>
      </Tooltip>

      <br />
      <div className="nsScrollBox">
        {Object.keys(props.SC4CVer).map((verID, index) => {
          return (
            <Link
              key={index}
              to={"/SC4CSummary/version/" + verID}
              className="nsLink"
            >
              {props.SC4CVer[verID] + " machines on " + verID}
              <br />
            </Link>
          );
        })}
      </div>
      <Typography>
        <PeopleIcon style={styles.blueFill} />
        Total users:{props.numUniqueUsers}
        <br />
        <PeopleIcon style={styles.blueFill} />
        Popup today:{props.numDayUsers}
        <br />
        <PeopleIcon style={styles.blueFill} />
        Popup this week:{props.numWeekUsers}
        <br />
      </Typography>
    </CardContent>
  </Card>
);

function changeToRecommendationQueryPage(event, props, recommendationQuerySearchTerm) {
  props.changeDashboardToRecommendationQueryPage(event, recommendationQuerySearchTerm)
}

const RecommendationQueries = (props) => (
  <div style={{ height: "5REM" }}>
    <Grid container>
      <Grid item xs={3}>
        <Link
          style={{ colour: "black" }}
          onClick={(event) => changeToRecommendationQueryPage(event, props, "")}
        >
          Queries
        </Link>
      </Grid>
      <Grid item xs={9}>
        <Link
          onClick={(event) => changeToRecommendationQueryPage(event, props, "Open")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ErrorIcon style={styles.redFill} fontSize = {"small"} />
          {"Open: " + props.recommendationQueryParentData.filter(a => a.fhtLodgeRecommendationStatus == 1).length}
        </Link>
      </Grid>
    </Grid>
    <div className="nsScrollBox" style={{ height: "75%" }}>
      {(props.recommendationQueryParentData.filter(a => a.fhtLodgeRecommendationStatus == 1)).map((openRecQuery, index) => {
        var dateOnlyLogRecommendationDate = openRecQuery.fhtLodgeRecommendationAESTDate.split(", ")[0]
        return (
          <Link
            key={index}
            onClick={(event) => changeToRecommendationQueryPage(event, props, openRecQuery.fhtLodgeRecommendationComment)}
            className="nsLink"
          >
            <p
              style={{
                fontSize: "0.8REM",
                width: "100%",
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word'
              }}
            >
              {dateOnlyLogRecommendationDate} {openRecQuery.fhtSiteName} - {openRecQuery.fhtEnabledAnalysisDescription}
            </p>
          </Link>
        );
      })}
    </div>
  </div>
);

export { APICard, ALGCard, SC4CCard, RecommendationQueries };

export function LatestFhtLogCard(props) {

  const classes = useStyles();

  const [LogDetailDialogIsOpen, setLogDetailDialogIsOpen] = React.useState(false);
  const [LogDetail, setLogLogDetail] = React.useState(null);

  const OpenLogDetailDialog = (log) => {
    setLogLogDetail(log);
    setLogDetailDialogIsOpen(true);

  }
  const CloseLogDetailDialog = () => {
    setLogDetailDialogIsOpen(false);
  }

  var failedFHTLogs = props.latestFhtLog
  const splitFhtLatestLog = Math.floor(failedFHTLogs.length / 2);
  var firstHalfFailedFHTLogs = []
  var secondHalfFailedFHTLogs = []

  if (splitFhtLatestLog == 0) {
    var firstHalfFailedFHTLogs = failedFHTLogs.slice(splitFhtLatestLog)
  } else {
    var firstHalfFailedFHTLogs = failedFHTLogs.slice(0, splitFhtLatestLog)
    var secondHalfFailedFHTLogs = failedFHTLogs.slice(splitFhtLatestLog)
  }

  return (
    <Card style={{ height: "20REM" }}>
      <Link to="/FhtLogsSelections" className="nsLink">
        <CardHeader title={"Algorithm failure logs"} className="nsCardHeader" />
      </Link>
      <Divider />
      <CardContent style={styles.contentHeight}>
        <Tooltip title={FHTLogsTooltip}>
          <Typography>
            <ErrorOutlineIcon style={styles.redFill} />
            {"Failure count: " + failedFHTLogs.length}
            <br />
          </Typography>
        </Tooltip>
        <br />

        <div className="nsTallScrollBox" style={{ height: "150%" }}>
          <Grid container spacing={2} style={{ padding: "2.5%", width: "100%", align: "center" }}>
            <Grid item xs={6}>
              {Object.values(firstHalfFailedFHTLogs).map((log, index) => {
                var dateOnlyLogReceived = log["FhtLogDateLogReceived"].split(" ")[0]
                return (
                  <Typography style={styles.onHover}>
                    <ListItem
                      className={classes.onHover}
                      onClick={() => OpenLogDetailDialog(log)}
                      style={{ height: "2.3REM" }}
                    >
                      <p
                        key={index}
                        style={{
                          fontSize: "0.7REM",
                          width: "100%",
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word'
                        }}
                      >
                        {dateOnlyLogReceived} {log["SiteName"]}
                      </p>
                    </ListItem>
                  </Typography>
                );
              })}
            </Grid>

            <Grid item xs={6}>
              {Object.values(secondHalfFailedFHTLogs).map((log, index) => {
                var dateOnlyLogReceived = log["FhtLogDateLogReceived"].split(" ")[0]
                return (
                  <Typography style={styles.onHover}>
                    <ListItem
                      className={classes.onHover}
                      onClick={() => OpenLogDetailDialog(log)}
                      style={{ height: "2.3REM" }}
                    >
                      <p
                        key={index}
                        style={{
                          fontSize: "0.7REM",
                          width: "100%",
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word'
                        }}
                      >
                        {dateOnlyLogReceived} {log["SiteName"]}
                      </p>
                    </ListItem>
                  </Typography>
                )
              })}
            </Grid>
          </Grid>
        </div>
        <Dialog
          open={LogDetailDialogIsOpen}
          onBackdropClick={CloseLogDetailDialog}
        >
          {LogDetail == null ? "No detail" : (
            <Typography
              style={{ padding: "5%" }}>
              {LogDetail["SiteName"]} ({LogDetail["Site ID"]}) <br />
              {LogDetail["FhtLogDateLogReceived"]}
              <br></br>
              {LogDetail["FhtLogTextInfomation"].split(",").map(text => {
                return (
                  <Typography
                    style={{ padding: "5%" }}>
                    {text}
                    <br />
                  </Typography>
                )
              })}
              <br />
            </Typography>
          )}
        </Dialog>

      </CardContent>
    </Card>

  );
}
