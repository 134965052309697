import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable, { MTableToolbar } from "material-table";
import FetchApi from "../../Service/FetchApi";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';

class ManageAlgorithmClassNames extends Component {
  constructor(props) {
    super();
    this.pageOptions = {
      pageSize: 20,
      search: true,
      pageSizeOptions: [20, 50, 100],
      maxBodyHeight: 'calc(100vh - 15REM)',
    };
    this.state = {
      manageAlgorithmsTable: [
        { title: "Test ID", field: "fhtAlgorithmClassNamesId", type: "number", editable: "always" },
        // { title: "Type ID", field: "fhtAlgorithmClassNamesTypeId", type: "number", editable: "always" },
        { title: "Class Name", field: "fhtAlgorithmClassNamesClassName", type: "number", editable: "always" },
        { title: "Class Name Description", field: "fhtAlgorithmClassNamesClassNameDescription", type: "number", editable: "always" },
        {
          title: "Disease ID", field: "fhtAlgorithmClassNamesDiseaseId", type: "number", editable: "always",
          render: rowData => this.renderDiseaseName(rowData),
          lookup: Configs.DiseaseIDToName
        },
        {
          title: "Status ID", field: "fhtAlgorithmClassNamesCurrentStatusId", editable: "always",
          render: rowData => this.renderCurrentStatusID(rowData),
          lookup: Configs.AlgorithmStatusIDs,
        },
      ],
      algorithmClassNameData: null,
      algorithmClassNameDataOriginal: null,
      recommendationTypeID: Configs.RecommendationTypeID.recommendation,
    }
  }

  renderDiseaseName = (rowData) => {
    if (rowData.fhtAlgorithmClassNamesDiseaseId in Configs.DiseaseIDToName) {
      return (
        <div>{Configs.DiseaseIDToName[rowData.fhtAlgorithmClassNamesDiseaseId]}</div>
      )
    }
    return (
      <div>Uncategorised disease {rowData.fhtAlgorithmClassNamesDiseaseId}</div>
    )
  }

  renderCurrentStatusID = (rowData) => {
    if (rowData.fhtAlgorithmClassNamesCurrentStatusId == 0) {
      return (
        <div>Archived</div>
      )
    }
    return (
      <div>Active</div>
    )
  }

  componentDidMount() {
    this.getAlgorithmClassNamesData()
  }

  getAlgorithmClassNamesData = () => {
    const api = FetchApi(Configs.api.getAlgorithmClassNamesData);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then((response) => {
      this.setState({
        algorithmClassNameData: response.filter(x => x.fhtAlgorithmClassNamesTypeId == this.state.recommendationTypeID),
        algorithmClassNameDataOriginal: response,
      });
    })
  }

  updateAlgorithmClassName = (oldData, data) => {
    data.fhtAlgorithmClassNamesTypeId = this.state.recommendationTypeID;
    data.fhtAlgorithmClassNamesSoftwareType = Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType;
    var body = {
      oldAlgClassNameData: oldData,
      updatedAlgClassNameData: data,
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    const api = FetchApi(Configs.api.updateAlgorithmClassName);
    api.post(body).then((response) => {
      if (response.status == 400)
        throw response;

      this.setState({
        algorithmClassNameData: response.filter(x => x.fhtAlgorithmClassNamesTypeId == this.state.recommendationTypeID),
        algorithmClassNameDataOriginal: response,
      });
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update successful",
        Configs.snackbarVariants.success
      );
    }).catch((error) => {
      error.json().then(errorMsg => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          errorMsg.message,
          Configs.snackbarVariants.error
        );
      })
    })
  }

  insertNewAlgorithmClassName = (data) => {
    data.fhtAlgorithmClassNamesTypeId = this.state.recommendationTypeID;
    data.fhtAlgorithmClassNamesSoftwareType = Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType;
    var body = {
      algClassNameData: data,
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    const api = FetchApi(Configs.api.insertNewAlgorithmClassName);
    api.post(body).then((response) => {
      if (response.status == 400)
        throw response;

      this.setState({
        algorithmClassNameData: response.filter(x => x.fhtAlgorithmClassNamesTypeId == this.state.recommendationTypeID),
        algorithmClassNameDataOriginal: response,
      });
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "New algorithm created successfully",
        Configs.snackbarVariants.success
      );
    }).catch(error => {
      error.json().then(errorMsg => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          errorMsg.message,
          Configs.snackbarVariants.error
        );
      })
    })
  }

  deleteAlgorithmClassName = (data) => {
    const api = FetchApi(Configs.api.deleteAlgorithmClassName);
    var body = {
      algClassNameData: data,
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    api.post(body).then((response) => {
      if (response.status == 400)
        throw response;

      this.setState({
        algorithmClassNameData: response.filter(x => x.fhtAlgorithmClassNamesTypeId == this.state.recommendationTypeID),
        algorithmClassNameDataOriginal: response,
      });
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Successfully deleted",
        Configs.snackbarVariants.success
      );
    }).catch(error => {
      error.json().then(errorMsg => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          errorMsg.message,
          Configs.snackbarVariants.error
        );
      })
    })
  }
  // DiseaseID and CurrentStatusID can be null, back-end will set it as
  // uncategorised or Active.
  checkEssentialData = (newData) => {
    if (newData.fhtAlgorithmClassNamesClassName == null
      || newData.fhtAlgorithmClassNamesId == null
      || newData.fhtAlgorithmClassNamesClassNameDescription == null)
      return true;
    return false;
  }

  showFailureToAddRowMessage() {
    this.props.GlobalFunctionDisplaySnackbarMessage(
      "You need to provide ClassNameID, ClassName, and ClassNameDescription.",
      Configs.snackbarVariants.error
    );
  }

  handleChangeAlgorithmTypeID = (e) => {
    this.setState({
      algorithmClassNameData: this.state.algorithmClassNameDataOriginal.filter(x => x.fhtAlgorithmClassNamesTypeId == e.target.value),
      recommendationTypeID: e.target.value
    })
  }

  renderAlgorithmTypeIDSelection = () => {
    return (
      <div>
        <Select
          native
          value={this.state.recommendationTypeID}
          onChange={(e) => this.handleChangeAlgorithmTypeID(e)}
        >
          <option value={Configs.RecommendationTypeID.recommendation}>Recommendations</option>
          <option value={Configs.RecommendationTypeID.check}>Checks</option>
        </Select>
      </div>
    )
  }

  renderManageAlgorithmClassNames = () => {
    // Wait for fetching
    if (this.state.algorithmClassNameData == null) return (<div><CircularProgress />Loading...</div>)
    else return (
      <div>
        <Grid container justify="center">
          <MaterialTable
            style={{ width: "90%" }}
            columns={this.state.manageAlgorithmsTable}
            data={this.state.algorithmClassNameData}
            options={this.pageOptions}
            title="Algorithm Class Names"
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...this.state.algorithmClassNameData];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    this.setState({ algorithmClassNameData: dataUpdate });
                    this.updateAlgorithmClassName(oldData, dataUpdate[index]);
                    resolve();
                  }, 1000);
                }),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (this.checkEssentialData(newData)) {
                      reject(this.showFailureToAddRowMessage());
                    } else {
                      this.insertNewAlgorithmClassName(newData);
                      resolve();
                    }
                  }, 1000);
                }),
              onRowDelete: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    this.deleteAlgorithmClassName(newData);
                    resolve();
                  }, 1000);
                }),

            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ position: "absolute", left: "20%", top: 15, zIndex: 1 }}>
                    {this.renderAlgorithmTypeIDSelection()}
                  </div>
                </div>
              )
            }}
          />
        </Grid>
        <div style={{ fontSize: 14, position: "absolute", left: "8%", bottom: "5vh", zIndex: 1 }}>
          You need to provide a Test ID, Class Name and Class Name Description when inserting a new algorithm. DiseaseID amd StatusID is optional.
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <br />
        {this.renderManageAlgorithmClassNames()}
        <br />
      </div>
    )
  }
}

export default ManageAlgorithmClassNames;