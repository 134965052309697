import React, { Component } from "react";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AlgorithmStatusCellRender from "./AlgorithmStatusCellRender";
import AlgorithmStatusSimpleCellRender from "./AlgorithmStatusSimpleCellRender";
import Button from "@material-ui/core/Button";
import AlgorithmOrder from "./AlgorithmOrder";
import Dialog from '@material-ui/core/Dialog';
import LoadingRender from "../../SharedComponents/LoadingRender";

class AlgorithmStatusTable extends Component {
  constructor(props) {
    super();
    this.state = {
      tableColumns: [],
      originalTableData: [],
      tableData: [],
      selectedSiteIDs: [],
      selectedSiteGroupings: 0,
      activeAlgorithmTableTitle: "",
      showAlgorithmOrderDialogue: false,
      algorithmTypeID: 0,
      isLoading: true,
      disableWriteFunctionalities: false,
    }
    this.pageOptions = {
      search: true,
      pageSize: 20,
      pageSizeOptions: [10, 20, 50],
      exportButton: true,
      exportAllData: true,
      grouping: false,
      sorting: true,
      maxBodyHeight: 'calc(100vh - 11.5REM)',
      // selection: true,
      headerStyle: {
        zIndex: 200,
      },
      // tableLayout: 'auto'
    };
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.SharedComponentSelectedSiteGroupingIDCallback = this.SharedComponentSelectedSiteGroupingIDCallback.bind(this);
    this.CallBackSetAlgAllAlgStatus = this.CallBackSetAlgAllAlgStatus.bind(this);
    this.CallBackSetCellSpecificAlgStatus = this.CallBackSetCellSpecificAlgStatus.bind(this);
    this.handleShowAlgorithmOrderDialogue = this.handleShowAlgorithmOrderDialogue.bind(this);
  }

  componentDidMount() {
    if (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE
      || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) {
      this.setState({
        disableWriteFunctionalities: true
      })
    }
    if (Configs.SiteConfigurationMapping[window.location.hostname].DisableAlgorithmStatusTable == "true") {
      this.setState({
        disableWriteFunctionalities: true
      })
    }
    this.getActiveAlgorithmTableData();
  }

  formatTableDataToCellRenderTable(tableData) {
    var tableDataToUpdate = JSON.parse(JSON.stringify(tableData))
    var isAlgorithmTypeRec = false;
    if (window.location.search.includes(Configs.algorithmStatusTableSearch.recommendationSearch)) {
      isAlgorithmTypeRec = true;
    }
    for (var i = 0; i < tableDataToUpdate.length; i++) {
      var siteID = 0;
      for (const [key, value] of Object.entries(tableDataToUpdate[i])) {
        if (key == "SiteName")
          continue;
        if (key == "SiteID") {
          siteID = value
          continue
        }

        var cellData = {
          algorithmClassName: value.algorithmClassName,
          algorithmClassNameDescription: value.algorithmClassNameDescription,
          algorithmID: value.algorithmID,
          algorithmStatus: value.algorithmStatus,
          siteID: siteID,
        }

        // Only give read functionality if we are disabling.
        if (Configs.SiteConfigurationMapping[window.location.hostname].DisableAlgorithmStatusTable == "true") {
          tableDataToUpdate[i][key] = <AlgorithmStatusSimpleCellRender
            algorithmStatus={value.algorithmStatus}
          />
          continue;
        }

        // Determine if this table should have write cellRender (admin only - to edit configs)
        // or simple cell render (both I&E accounts - read only algorithm status)
        if (isAlgorithmTypeRec && !this.state.disableWriteFunctionalities) {
          tableDataToUpdate[i][key] = <AlgorithmStatusCellRender
            CallBackSetCellSpecificAlgStatus={this.CallBackSetCellSpecificAlgStatus}
            cellData={cellData}
            isAlgorithmTypeRec={isAlgorithmTypeRec}
            changeAllRowsAlgStatus={false}
          />
        } else {
          tableDataToUpdate[i][key] = <AlgorithmStatusSimpleCellRender
            algorithmStatus={value.algorithmStatus}
          />
        }
      }
    }
    return tableDataToUpdate;
  }

  updateAlgorithmStatus(updateAllAlgStatus, updatedAlgStatusID, cellData, tableData) {
    var deepCopyOriginalTableData = JSON.parse(JSON.stringify(tableData));
    for (var i = 0; i < deepCopyOriginalTableData.length; i++) {
      for (const [key, value] of Object.entries(deepCopyOriginalTableData[i])) {
        if (key == "SiteName")
          continue;
        if (key == "SiteID") {
          if (updateAllAlgStatus) {
            if (this.state.selectedSiteGroupings != 0 && !this.state.selectedSiteIDs.includes(value))
              break
          } else {
            if (value != cellData.siteID)
              break
          }
          continue
        }
        // Update the selected sites with the updatedAlgStatusID
        if (String(key) == String(cellData.algorithmClassNameDescription)) {
          value.algorithmStatus = updatedAlgStatusID;
        }
      }
    }
    return deepCopyOriginalTableData;
  }

  formatHeaderStyling(styleInt) {
    var headerStyling = {
      width: styleInt,
      maxWidth: styleInt
    }
    return headerStyling;
  }

  SharedComponentSiteGroupingCallback(siteGroupingsSiteIDs) {
    this.setState({
      selectedSiteIDs: siteGroupingsSiteIDs
    })

    // If we select a site grouping, ensure we show only those sites selected.
    if (this.state.selectedSiteGroupings != 0) {
      var filteredTableData = [];
      var tableDataArray = this.formatTableDataToCellRenderTable(JSON.parse(JSON.stringify(this.state.originalTableData)));
      for (var i = 0; i < tableDataArray.length; i++) {
        if (siteGroupingsSiteIDs.includes(tableDataArray[i].SiteID)) {
          // tableDataArray[i].tableData.checked = true;
          filteredTableData.push(tableDataArray[i])
        }
      }
      this.setState({
        tableData: filteredTableData
      })
    } else {
      // If no site grouping selected, we need to reconstruct the front-end render using originalTableData.
      this.setState({
        tableData: this.formatTableDataToCellRenderTable(JSON.parse(JSON.stringify(this.state.originalTableData)))
      })
    }
  }

  SharedComponentSelectedSiteGroupingIDCallback(siteGroupingID) {
    this.setState({
      selectedSiteGroupings: siteGroupingID
    })
  }

  CallBackSetCellSpecificAlgStatus(updatedAlgStatusID, cellData) {
    // Update the material-table tableData.
    this.setState({
      originalTableData: this.updateAlgorithmStatus(!Configs.AlgorithmTableUpdateAllSites, updatedAlgStatusID, cellData, this.state.originalTableData)
    })
  }

  CallBackSetAlgAllAlgStatus(updatedAlgStatusID, cellData) {
    // Do not set alg status with Configs.AlgorithmTableStatus.updateAllAlg
    // This status is just for group header.
    if (updatedAlgStatusID == Configs.AlgorithmTableStatus.updateAllAlg)
      return;

    // Set all alg status dependent on if we have selected a site grouping or not.
    var deepCopyOriginalTableData = this.updateAlgorithmStatus(Configs.AlgorithmTableUpdateAllSites, updatedAlgStatusID, cellData, this.state.originalTableData)

    // Remaking the entire front-end render dropdown.
    var tableDataToUpdate = JSON.parse(JSON.stringify(deepCopyOriginalTableData))
    // Filter front-end render if site grouping selected.
    if (this.state.selectedSiteGroupings != 0) {
      tableDataToUpdate = tableDataToUpdate.filter(x => this.state.selectedSiteIDs.includes(x.SiteID))
    }

    // Update the material-table tableData.
    this.setState({
      tableData: this.formatTableDataToCellRenderTable(tableDataToUpdate),
      originalTableData: deepCopyOriginalTableData
    })
  }

  getActiveAlgorithmTableData = () => {
    var currentURL = window.location.search;

    // Default to Recs if nothing chosen.
    var AlgorithmTypeID = Configs.RecOrChkSaveType.Rec;
    var isAlgorithmTypeRec = false;
    var tableTitle = "";

    if (currentURL.includes(Configs.algorithmStatusTableSearch.recommendationSearch)) {
      AlgorithmTypeID = Configs.RecOrChkSaveType.Rec;
      isAlgorithmTypeRec = true;
      tableTitle = "Recommendation Algorithm Status";
    } else if (currentURL.includes(Configs.algorithmStatusTableSearch.checkingSearch)) {
      AlgorithmTypeID = Configs.RecOrChkSaveType.Chk;
      tableTitle = "Checking Algorithm Status";
    }
    this.setState({
      algorithmTypeID: AlgorithmTypeID,
      activeAlgorithmTableTitle: tableTitle
    })

    const api = FetchApi(Configs.api.getSiteAlgorithmStatusTableData)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      AlgorithmTypeID: AlgorithmTypeID,
    };
    api.post(body).then(response => {
      console.log("response", response)
      // Generate columns header with specific rendering.
      for (var i = 0; i < response.tableColumnsList.length; i++) {
        if (response.tableColumnsList[i]["field"] == "SiteName") {
          response.tableColumnsList[i]["cellStyle"] = {
            width: 300,
            minWidth: 300,
            position: "sticky",
            left: "0REM",
            background: "white",
            fontSize: 12,
            zIndex: 100,
          };
        } else {
          response.tableColumnsList[i]["cellStyle"] = this.formatHeaderStyling(150);
          response.tableColumnsList[i]["headerStyle"] = this.formatHeaderStyling(150);
          var headerTitle = response.tableColumnsList[i]["title"]
          var additionalData = response.tableColumnsList[i].additionalData
          var cellData = {
            algorithmID: additionalData.algorithmID,
            algorithmClassName: additionalData.algorithmClassName,
            algorithmClassNameDescription: additionalData.algorithmClassNameDescription,
            algorithmStatus: 0,
            siteID: 0,
          }
          response.tableColumnsList[i]["title"] = <div>
            {headerTitle}
            {!this.state.disableWriteFunctionalities ?
              <AlgorithmStatusCellRender
                cellData={cellData}
                isAlgorithmTypeRec={isAlgorithmTypeRec}
                changeAllRowsAlgStatus={true}
                CallBackSetAlgAllAlgStatus={this.CallBackSetAlgAllAlgStatus}
              />
              : <div></div>
            }
          </div>
        }
      }
      var sortedTableData = response.tableRowDataList.sort(function (a, b) {
        return a.SiteName.localeCompare(b.SiteName)
      })
      this.setState({
        tableColumns: response.tableColumnsList,
        originalTableData: JSON.parse(JSON.stringify(sortedTableData)),
        tableData: this.formatTableDataToCellRenderTable(sortedTableData),
        isLoading: false,
      })
    })
  }

  handleUpdateSitesAlgConfigStatus(updateAllSites) {
    var siteArrayStatus = []
    var tableDataToLoop = this.state.tableData;
    if (updateAllSites)
      tableDataToLoop = this.state.originalTableData

    for (var i = 0; i < tableDataToLoop.length; i++) {
      // If we are only updating selected sites, make sure the row is checked.
      if (!updateAllSites && !tableDataToLoop[i].tableData.checked)
        continue;

      // If we are updating all sites, ensure no site groupings are chosen.
      if (updateAllSites && this.state.selectedSiteGroupings != 0 && !this.state.selectedSiteIDs.includes(this.state.originalTableData[i].SiteID))
        continue;

      // Format data for back-end parsing.
      // algorithmStatus dictionary stores Key as AlgorithmStatus, and Value as count of AlgorithmStatus.
      var siteDictionaryData = {
        algorithmStatus: {}
      }
      for (const [key, value] of Object.entries(this.state.originalTableData[i])) {
        if (key == "SiteName") {
          continue;
        }
        if (key == "SiteID") {
          siteDictionaryData[key] = value;
          continue;
        }
        if (value.algorithmStatus in siteDictionaryData.algorithmStatus) {
          siteDictionaryData.algorithmStatus[value.algorithmStatus].push(value.algorithmClassName)
        } else {
          siteDictionaryData.algorithmStatus[value.algorithmStatus] = [value.algorithmClassName]
        }
      }
      siteArrayStatus.push(siteDictionaryData)
    }
    var body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      AlgorithmTypeID: this.state.algorithmTypeID,
      SiteArrayAlgConfigStatus: siteArrayStatus,
    }
    const api = FetchApi(Configs.api.updateAlgorithmStatusTableConfigs)
    console.log("updateAlgorithmStatusTableConfigs body", body)
    api.post(body).then(response => {
      console.log("handleUpdateSitesAlgConfigStatus response", response)
      if (response.status == 400) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Unable to update configs",
          Configs.snackbarVariants.error
        )
        return;
      }
      if (response == "Success") {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Successfully updated site configs",
          Configs.snackbarVariants.success
        );
      }
    }).catch(error => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Unable to update configs",
        Configs.snackbarVariants.error
      )
    })
  }

  renderAlgTableButtonActions() {
    if (this.state.disableWriteFunctionalities) {
      return (
        <div></div>
      )
    }
    return (
      <div>
        <Button
          variant="contained"
          color="inherit"
          onClick={(e) => this.handleShowAlgorithmOrderDialogue(e)}
        >
          Change algorithm order
        </Button>
        {/* Not needed since we don't need to select individual sites.
            Commenting out encase we want this functionality.         
        <span> &nbsp; </span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.handleUpdateSitesAlgConfigStatus(!Configs.AlgorithmTableUpdateAllSites)}
        >
          Update selected sites
        </Button> 
        */}
        <span> &nbsp; </span>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.handleUpdateSitesAlgConfigStatus(Configs.AlgorithmTableUpdateAllSites)}
        >
          Update all sites
        </Button>
      </div>
    )
  }

  handleShowAlgorithmOrderDialogue(event) {
    event.preventDefault();
    this.setState({
      showAlgorithmOrderDialogue: !this.state.showAlgorithmOrderDialogue
    })
  }

  renderAlgorithmOrderDialogue() {
    return (
      <div>
        <Dialog fullWidth={true} onClose={this.handleShowAlgorithmOrderDialogue} aria-labelledby="simple-dialog-title" open={this.state.showAlgorithmOrderDialogue}>
          <div style={{ padding: "1REM" }} >
            <AlgorithmOrder
              GlobalFunctionDisplaySnackbarMessage={this.props.GlobalFunctionDisplaySnackbarMessage}
              algorithmTypeID={this.state.algorithmTypeID}
            />
          </div>
        </Dialog>
      </div>
    )
  }

  render() {
    if (this.state.isLoading) {
      return (
        <LoadingRender />
      )
    }
    return (
      <div>
        {this.renderAlgorithmOrderDialogue()}
        <MaterialTable
          options={this.pageOptions}
          columns={this.state.tableColumns}
          data={this.state.tableData}
          title={this.state.activeAlgorithmTableTitle}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ position: "absolute", left: "35%", top: 20, zIndex: 1 }}>
                  <SelectSiteGroupingsSelectionOnly
                    selectedSiteGroupings={this.state.selectedSiteGroupings}
                    SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
                    SharedComponentSelectedSiteGroupingIDCallback={this.SharedComponentSelectedSiteGroupingIDCallback}
                  />
                </div>
                <div style={{ position: "absolute", left: "50%", top: 20, zIndex: 1 }}>
                  {this.renderAlgTableButtonActions()}
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default AlgorithmStatusTable;