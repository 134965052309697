// Description: This component is very similar to FHTHome.jsx, but this component is for SC4C
// Status: Ready

import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { SC4CCard, POCCard } from "./summaryCards";
import OverviewCard from "./OverviewCard";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  countPCs,
  countValues,
  verifyWeekly,
  verifyDaily,
  manageVersions,
} from "./HelperFunctions";

class SC4CHome extends Component {
  constructor(props) {
    super();
    this.state = { isLoaded: false };
  }

  componentDidMount = () => {
    const heartbeats = this.props.heartbeats;
    const machineVersions = manageVersions(this.props.localVersion).machineVers;

    let SC4CInfo = {};

    let SC4CActive = {};
    let SC4CTotal = {};
    let SC4CVer = {};
    let SC4CRecords = [];

    let uniqueUsers = new Set();
    let popupDayUsers = new Set();
    let popupWeekUsers = new Set();

    let machineVer = null;

    let siteID = null;
    let isWeekly = null;
    let isDaily = null;

    heartbeats.forEach((record) => {
      siteID = record["fhtHeartbeatSiteId"];
      isWeekly = verifyWeekly(record["fhtHeartbeatLastlog"]);
      isDaily = verifyDaily(record["fhtHeartbeatLastlog"]);

      // constructing the site wiki
      if (!(siteID in SC4CInfo)) {
        let tempSite = {};
        tempSite["siteName"] = record["fhtSiteName"];
        tempSite["siteIsTerminal"] = false;
        tempSite["siteController"] = "John doe";
        tempSite["siteContact"] = "04XX XXX XXX";
        tempSite["siteLocation"] = "XXXXXXXXXXXXXX";
        tempSite["siteID"] = siteID;

        // SC4CHere
        tempSite["SC4C"] = "NA";
        tempSite["SC4CTotalNum"] = 0;
        tempSite["SC4CActiveNum"] = 0;
        tempSite["SC4CVers"] = new Set();

        SC4CInfo[siteID] = tempSite;
      }

      if (record["fhtSiteTerminalServer"] === 1) {
        SC4CInfo[siteID]["siteIsTerminal"] = true;
      }

      if (record["fhtHeartbeatSoftwareName"] === "SC4C") {
        // Assign machine version
        if (machineVersions[record["fhtHeartbeatMachineId"]] !== undefined) {
          machineVer = machineVersions[record["fhtHeartbeatMachineId"]];
        } else {
          machineVer = record["fhtHeartbeatVersionNumber"];
        }

        if (!(siteID in SC4CActive)) {
          SC4CActive[siteID] = new Set();
          SC4CTotal[siteID] = new Set();
          SC4CInfo[siteID]["SC4C"] = "fail";
          SC4CInfo[siteID]["SC4CLastLog"] = record["fhtHeartbeatLastlog"];
        }

        uniqueUsers.add(record["fhtHeartbeatEmrUserName"]);
        if (isWeekly && record["fhtHeartbeatWeeklyLogCount"] > 0) {
          SC4CActive[siteID].add(record["fhtHeartbeatMachineId"].toString());
          SC4CInfo[siteID]["SC4C"] = "active";
          popupWeekUsers.add(record["fhtHeartbeatEmrUserName"]);
          if (isDaily && record["fhtHeartbeatDailyLogCount"] > 0) {
            popupDayUsers.add(record["fhtHeartbeatEmrUserName"]);
          }
        }
        // END

        if (SC4CVer[machineVer] === undefined) {
          SC4CVer[machineVer] = 0;
        }
        SC4CVer[machineVer]++;

        SC4CInfo[siteID]["SC4CVers"].add(record["fhtHeartbeatVersionNumber"]);

        // Update SC4C lastlog
        if (record["fhtHeartbeatLastlog"] > SC4CInfo[siteID]["SC4CLastLog"]) {
          SC4CInfo[siteID]["SC4CLastLog"] = record["fhtHeartbeatLastlog"];
        }
      }
    });

    let SC4CActiveNum = countValues(Object.values(SC4CActive));
    let activeSC4CInfo = countPCs(SC4CActive);
    let totalSC4CInfo = countPCs(SC4CTotal);
    this.assignSC4CStatus(SC4CInfo, activeSC4CInfo, totalSC4CInfo);
    localStorage.setItem("SC4CRecords", JSON.stringify(SC4CRecords));

    this.setState({
      numUniqueUsers: uniqueUsers.size,
      numDayUsers: popupDayUsers.size,
      numWeekUsers: popupWeekUsers.size,

      SC4CVer: SC4CVer,
      SC4CActive: SC4CActive,
      SC4CActiveNum: SC4CActiveNum,
      SC4CInfo: SC4CInfo,
      isLoaded: true,
    });
  };

  assignSC4CStatus = (SC4CInfo, dictActive, dictTotal) => {
    Object.keys(SC4CInfo).forEach((siteID) => {
      // A try..catch can be considered here
      SC4CInfo[siteID]["SC4CActiveNum"] = dictActive[siteID];
      SC4CInfo[siteID]["SC4CTotalNum"] = dictTotal[siteID];
    });
  };

  render() {
    const {
      SC4CActive,
      SC4CActiveNum,
      SC4CVer,
      SC4CInfo,
      numUniqueUsers,
      numDayUsers,
      numWeekUsers,
      isLoaded,
    } = this.state;

    if (isLoaded) {
      return (
        <div>
          <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={3} xs={12}>
              <SC4CCard
                numUniqueUsers={numUniqueUsers}
                numDayUsers={numDayUsers}
                numWeekUsers={numWeekUsers}
                length={Object.keys(SC4CActive).length}
                SC4CActiveNum={SC4CActiveNum}
                SC4CVer={SC4CVer}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={8} xs={12}>
              <OverviewCard
                type={"SC4C"}
                siteWiki={SC4CInfo}
                ALGTestsInfo={null}
              ></OverviewCard>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
  }
}

export default SC4CHome;
