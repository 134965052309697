// DG1
// Description: The Entry Point to the web report, all homepage's fetches are performed here
// Recommendation: I would prefer keep this file clean, just for navigation only
// Caution: The FHT/SC4C switchTab is more complicated than it looks, adjust with caution.
// Status: Deliver ready(03/03)

import React, { Component } from "react";

import { Grid } from "@material-ui/core";
import FetchApi from "../Service/FetchApi";
import CircularProgress from "@material-ui/core/CircularProgress";

import SwitchTab from "./SwitchTab";
import SC4CHome from "./SC4CHome";
import FHTHome from "./FHTHome";

import Paper from "@material-ui/core/Paper";

import Configs from "../Service/Configuration";
import TorchHome from "./TorchPages/TorchHome";
import FHTHomeDashboard from "./FHTPages/FHTHomeDashboard";

class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      switchTabMode: 0,
      isLoaded: false,
      loadingMessage: "Fetching data from the server...",
    };
  }

  fetchAll = async () => {
    // TBD: can consider refer to configurations for constant naming conventions
    try {
      const api = FetchApi("getHeartbeats");
      var heartbeatsResult = [];
      let body = {
        SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      };
      await api.post(body).then((response) => {
        heartbeatsResult = response;
      });

      const api2 = FetchApi("getServerSummary");
      var serverSummaryResult = []
      await api2.post(body).then((response) => {
        serverSummaryResult = response;
      });

      const api3 = FetchApi("GetLocalVersion");
      var localVersionResult = []
      await api3.get().then((response) => {
        localVersionResult = response;
      });

      const api4 = FetchApi("getAllTests");
      var allTestsResult = []
      await api4.get().then((response) => {
        allTestsResult = response;
      });

      const api5 = FetchApi("getFailedFhtLogs");
      var fhtLogResults = []  
      let logResultsBody = {
        SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      };
      await api5.post(logResultsBody).then((response) => {
        fhtLogResults = response;
      });
      console.log("getFailedFhtLogs fhtLogResults", fhtLogResults)

      this.setState({
        heartbeats: heartbeatsResult,
        serverSummary: serverSummaryResult,
        localVersion: localVersionResult,
        allTests: allTestsResult,
        latestFhtLog : fhtLogResults
      });
    } catch (e) {
      console.log("Fetching error:" + e);
      return false;
    }
    return true;
  };

  componentDidMount = async () => {
    const isFetched = await this.fetchAll();
    if (isFetched) {
      this.setState({ isLoaded: true });
    }
  };


  render() {
    const {
      isLoaded,
      loadingMessage,
      switchTabMode,
      localVersion,
      heartbeats,
      latestFhtLog,
      allTests,
      serverSummary,
      serverSummaryHistory,
      CKDStats,
      DemoStats,
      cohortStats
    } = this.state;

    console.log("Home window.location.hostname", window.location.hostname)

    if (isLoaded) {
      switch (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType) {
        case Configs.supportSiteType.SC4CSupport:
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: "25%" }}>
                  <Paper square>
                    <div style={{ padding: "5%", textAlign: "center" }}>SC4C</div>
                  </Paper>
                </div>
              </div> <br/>
              <SC4CHome heartbeats={heartbeats} localVersion={localVersion} />
            </div>
          )
          break;
        case Configs.supportSiteType.fhtSupport:
          return (
            <div>
              <FHTHomeDashboard
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                recommendationQueryParentData = {this.props.recommendationQueryParentData} 
                changeDashboardToRecommendationQueryPage = {this.props.changeDashboardToRecommendationQueryPage}
                latestFhtLog = {latestFhtLog}
              />
            </div>
          );
          break;
        case Configs.supportSiteType.torchSupport:
          return (
            <div>
              <TorchHome
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                recommendationQueryParentData = {this.props.recommendationQueryParentData} 
                changeDashboardToRecommendationQueryPage = {this.props.changeDashboardToRecommendationQueryPage}
                latestFhtLog = {latestFhtLog}
              />
            </div>
          );
          break;
        case Configs.supportSiteType.fhtTestSupport:
          return(
          <div>
              <FHTHomeDashboard
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                recommendationQueryParentData = {this.props.recommendationQueryParentData} 
                changeDashboardToRecommendationQueryPage = {this.props.changeDashboardToRecommendationQueryPage}
                latestFhtLog = {latestFhtLog}
              />
          </div>
          )
          break;
        case Configs.supportSiteType.torchTestSupport:
          return(
            <div>
              <TorchHome
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                recommendationQueryParentData = {this.props.recommendationQueryParentData} 
                changeDashboardToRecommendationQueryPage = {this.props.changeDashboardToRecommendationQueryPage}
                latestFhtLog = {latestFhtLog}
              />
            </div>
          )
          break;
        default:
          return(
            <div>
              Check in Home.jsx and configs, wrong site type
            </div>
            )
            break;
      }
      // Render FHT support website
      /*
      if (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType === Configs.supportSiteType.fhtSupport) {
        if (switchTabMode === 1) {
          return (
            <div>
              {this.renderSwitchTab()}
              <SC4CHome heartbeats={heartbeats} localVersion={localVersion} />
            </div>
          );
        } else if (switchTabMode === 0) {
          return (
            <div>
              {this.renderSwitchTab()}
              <FHTHome
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                // latestFhtLog = {latestFhtLog}
              />
            </div>
          );
        }
      } 
      // Render Torch support website
      else if (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType === Configs.supportSiteType.torchSupport) {
        return (
          <div>  
            <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <div style = {{width: "25%"}}>  
                <Paper square>
                  <div style = {{padding: "5%", textAlign: "center"}}>TORCH</div>
                </Paper>
              </div>
            </div>               
            <div>
              <FHTHome
                heartbeats={heartbeats}
                allTests={allTests}
                serverSummary={serverSummary}
                localVersion={localVersion}
                // latestFhtLog = {latestFhtLog}
              />
            </div>
          </div>
        );
      }
      */
    } else {
      return (
        <div>
          <CircularProgress />
          {loadingMessage}
        </div>
      );
    }
  }
}

export default Home;