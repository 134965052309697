// Copied and pasted from FHTHome, removing some cards that are specific to FHT.

import React, { Component } from "react";
import {
  Card,
  Divider,
} from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar'
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from "@material-ui/core/Grid";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CancelIcon from '@material-ui/icons/Cancel';
import ExtensionIcon from '@material-ui/icons/Extension';
const styles = {
  minHeight: {
    minHeight: 360,
  },

  tooltipBright: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.85)",
    // boxShadow: theme.shadows[2],
    color: "red",
    fontSize: 20,
  },

  contentHeight: {
    height: "37%",
  },
  greenFill: {
    fill: "green",
  },
  redFill: {
    fill: "red",
  },
  blueFill: {
    fill: "blue",
  },

  onHover: {
    color: "black",
    overflow: "hidden",
    "&:hover": {
      color: "green",
      cursor: "pointer",
      overflowWrap: "break-word",
      textDecoration: "underline",
      overflow: "visible",
    }
  }
};

const useStyles = makeStyles((theme) => (styles));

const lineGraphSettings = {
  theme: {
    fontSize: '14px',
    textColor: 'black',
    grid: {
      line: {
        stroke: "black",
      }
    },
    axis: {
      ticks: {
        line: {
          stroke: "black"
        },
      }
    },
  },
};

class FHTSummarySoftwareStatus extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  render() {
    console.log("this.props.softwareStatusVersionsOnSite", this.props.softwareStatusVersionsOnSite)
    return (
      <div style={{
        position: "relative",
        width: "100%",
      }}>
        {/* Disabling graph for now */}
        {/* Graph rendering */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{
            zIndex: 1,
            position: "absolute",
            backgroundColor: this.props.softwareStatusGraphBackgroundColour,
            width: "90%",
            top: "-2.5REM",
          }}
          >
            <div style={{ width: "90%", height: 250, }}>
              <ResponsiveBar
                data={this.props.softwareStatusWeeklyData}
                keys={[
                  'Active Sites',
                  'Inactive Site',
                ]}
                indexBy="Date"
                colors={['#00ff00', '#ff0000']}
                enableLabel={false}
                margin={{ top: 20, right: 0, bottom: 80, left: 50 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                groupMode="grouped"
                borderColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6
                    ]
                  ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                  format: e => Math.floor(e) === e && e
                }}
                theme={lineGraphSettings.theme}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6
                    ]
                  ]
                }}
                tooltip={(input) => {
                  return (
                    <div style={{ backgroundColor: "white", padding: "0.25REM" }}>
                      <span style={{ fontWeight: "bold" }}>{input.id}:</span> {input.value}
                    </div>
                  )
                }}
                legends={[]}
              />
            </div>
          </Card>
        </div>

        {/* Summary information */}
        <Card style={{
          // Positioning of card
          flex: 1,
          position: "relative",
          bottom: 0,
          left: "-1REM",

          // Dimensions
          width: "100%",
          height: "100%",
          padding: "1REM",
        }}>
          <div>
            {/* Blank padding for summary to be at bottom of graph. 
                Must be hard-coded REM not precentage */}
            {/* Hiding padding for graphs. */}
            <div style={{
              height: "12REM"
            }}>
            </div><br />
            <Link style={{ fontSize: 24, fontWeight: 'bold' }} to={`/${this.props.softwareStatusURL}`} className="nsLink">
              {this.props.softwareStatusTitle} summary
            </Link>
            <div style={{ padding: "0.1REM", fontSize: 18 }}>{this.props.softwareStatusTitle} uptime</div>
            <br /><Divider /><br />

            <Grid container spacing={2} style={{ width: "95%" }}>
              <Grid item xs={6}>
                Uptime today<br /><br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Link to={`/${this.props.softwareStatusURL}/status/active`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleIcon style={styles.greenFill} />
                      {"Active: " + this.props.softwareStatusActiveNumber}
                    </div>
                  </Link> <div style={{ paddingLeft: "0.5REM", paddingRight: "0.5REM" }}></div>
                  <Link to={`/${this.props.softwareStatusURL}/status/fail`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ErrorIcon style={styles.redFill} />
                      {"Inactive: " + this.props.softwareStatusInactiveNumber}
                    </div>
                  </Link>
                </div>
              </Grid>

              {/* Right side for additional data, not in use for now due to additional work required to pass/filter data */}
              {/* <Grid item xs={6}>
                <div style={{
                  // textAlign: "center",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}>
                Additional information
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ paddingLeft: "1REM" }} ><PeopleAltIcon /> 2114</span>
                    <span style={{ paddingLeft: "1REM" }} ><TodayIcon /> 659</span>
                    <span style={{ paddingLeft: "1REM" }} ><DateRangeIcon /> 7597</span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ paddingLeft: "1REM" }}  ><CancelIcon /> 0</span>
                    <span style={{ paddingLeft: "1REM" }}  ><ExtensionIcon /> 0</span>
                  </div>
                </div>
              </Grid> */}
            </Grid>

            <br /><Divider /><br />
            Versions on site<br /><br />

            {/* Previous implementation to center grids cut off some software versions, doing paddingLeft for timebeing. */}
            <div className="nsScrollBox" style={{ paddingRight: "1REM", paddingLeft: "1REM" }}>
              <Grid container spacing={2} style={{ width: "95%" }}>
                {Object.keys(this.props.softwareStatusVersionsOnSite).map((verID, index) => {
                  return (
                    <Grid item xs={3}>
                      <Card>
                        <Link
                          key={index}
                          to={`/${this.props.softwareStatusURL}/version/` + verID}
                          className="nsLink"
                        >
                          <Grid container justify="center">
                            <div style={{
                              // Text overflow for long version names.
                              overflow: "hidden",
                              whiteSpace: "noWrap",
                              textOverflow: "ellipsis",
                              fontSize: 18,
                              fontWeight: 'bold'
                            }}>
                              {verID}
                            </div>
                          </Grid>
                          <Grid container justify="center">
                            {this.props.softwareStatusVersionsOnSite[verID]}
                          </Grid>
                        </Link>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default FHTSummarySoftwareStatus;
