import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import { Card, CircularProgress, Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { ReOrderableItem, ReOrderableList } from 'react-reorderable-list'
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MaterialTable from "material-table";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class ReorderListSharedComponent extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  renderAdminLevelUserReorderableList() {
    return (
      <div>
        <ReOrderableList
          name='SiteCheckingAlgorithmReorderList'
          list={this.props.listToUse}
          onListUpdate={(newList) => this.props.setUpdatedListOrder(newList)}
          style={{
            width: '300px'
          }}
        >
          {this.props.listToUse.map((data, index) => (
            <ReOrderableItem key={index}>
              <div
                style={{
                  border: '1px solid black'
                }}
              >
                <Grid container justify="center">
                  {data}
                </Grid>
              </div>
            </ReOrderableItem>
          ))}
        </ReOrderableList>
        <Grid container justify="center">
          <p style={{ fontSize: "10px" }}>You are able to drag and drop each configuration item to re-arrange them.</p>
        </Grid>
      </div>
    )
  }

  renderIandELevelUserList() {
    return (
      <div>
        {this.props.listToUse.map((data, index) => (
          <Box
            key={index}
            bgcolor="white"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography variant="subtitle1" style={{ border: "black", borderStyle: "solid", width: "100%", borderWidth: "1px" }}>
              <Grid container justify="center">
                {String(data)}
              </Grid>
            </Typography>
          </Box>
        ))}
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{ display: 'center', gap: '20px' }}>
          <Box
            bgcolor="primary.main"
            style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
            height="50px"
          >
            <Typography variant="h5">
              {this.props.reorderListHeading}
            </Typography>
          </Box>
          {((parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE
            || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
              this.renderIandELevelUserList()
            )}
          {parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin && (
            this.renderAdminLevelUserReorderableList()
          )}
        </div> <br />
      </div>
    )
  }
}

export default ReorderListSharedComponent;