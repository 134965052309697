import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";

class SelectedModuleConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }  
  }

  render() {
    return (
      <div>
        <Grid container justify="center">
          <FormControl component="fieldset" >
            <FormGroup>
              <Grid container style={{ padding: "2REM" }}>
                {this.props.commonSiteModuleCheckboxList.map((individualModule) => {
                  return (
                    <Grid key={individualModule[this.props.descriptionKey]} item xs={6}>
                      <FormControlLabel
                        style={{ display: "table" }}
                        control={
                          <div style={{ display: "table-cell" }}>
                            <Checkbox
                              disabled = {this.state.disableCheckbox}
                              value={individualModule[this.props.descriptionKey]}
                              checked={individualModule.checkedBox}
                              onChange={(event) =>
                                this.props.updateCommonModuleCheckbox(event)
                              }
                            /></div>}
                        label={
                          <p style={{ fontSize: "100%" }}>{individualModule[this.props.descriptionKey]}</p>
                        }
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
      </div>
    )
  }
}
export default SelectedModuleConfigurations;
