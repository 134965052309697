import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class SiteTypes extends Component {
  constructor(props) {
    super();   
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.state = {    
      siteTypeTable:[
        {title: "Site Name", field:"fhtSiteName", editable:"never"},
        {title: "Site ID", field:"fhtSiteId", hidden : true, width : 0,editable:"never"},
        {title: "FHT", field:"fhtSiteTypeTypeIds[1]", type:"boolean", editable:"always"},
        {title: "Torch", field:"fhtSiteTypeTypeIds[2]", type:"boolean", editable:"always"},
        {title: "SC4C", field:"fhtSiteTypeTypeIds[3]", type:"boolean", editable:"always"},
        {title: "FHT Test", field:"fhtSiteTypeTypeIds[4]", type:"boolean", editable:"always"},
        {title: "Torch Test", field:"fhtSiteTypeTypeIds[5]", type:"boolean", editable:"always"},
      ],
      siteTypeSites : null,   
      siteTypeSites_old : null, 
    }
  }

  componentDidMount() {
    this.getSiteTypeSites()
  }

  getSiteTypeSites = ()=>{
    // Site Type
    const api = FetchApi("GetSiteTypeIds");
    api.get().then((response)=>{
      console.log("Site types", response);
      var old = JSON.parse(JSON.stringify(response));
      
      this.setState({
        siteTypeSites: response,
        siteTypeSites_old : old,
      });
    })
  }

  updateSiteTypes = (data)=>{
    // Site Type
    console.log("sending", data);
    const api = FetchApi("PutSiteTypeIds")
    api.put(data.fhtSiteId, data).then(response=>{
      if(response.errorMessage !== undefined){
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.errorMessage,
          Configs.snackbarVariants.error
        );
      }else{
        //Success message 
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
      }
    })
  }

  renderSiteTypeMapping = ()=>{
    // Wait for fetching
    if(this.state.siteTypeSites == null) return(<div><CircularProgress/>Loading...</div>)
    else return(
      <div>
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns={this.state.siteTypeTable}
            data={this.state.siteTypeSites}
            option = {this.pageOptions}
            title="Site Types"
            editable = {{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                      const dataUpdate = [...this.state.siteTypeSites];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      this.setState({siteTypeSites : dataUpdate});
                      this.updateSiteTypes(dataUpdate[index]);
                      resolve();
                  }, 1000);
                }),
            }}
          />
        </Grid>
      </div>
    )
  }

  render() {
    return (
      <div> <br/>
        {this.renderSiteTypeMapping()} <br/>
      </div>
    )
  }
}

export default SiteTypes;