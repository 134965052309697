import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import Configs from "../../Service/Configuration";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FetchApi from "../../Service/FetchApi";
import { Card } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';

class SiteMachineSpecificDeactivation extends Component {
  constructor(props) {
    super();
    this.state = {
      machinesOnSiteCurrentSoftwareStatus: [],
      machinesAndSoftwareToUninstall: {},
      currentRowDataToUninstall: [],
      showSelectedActionDialog: false,
      currentActionID: 0,
    }
    this.handleShowActionDialog = this.handleShowActionDialog.bind(this);
  }

  componentDidMount() {
    this.getMachinesOnSiteCurrentSoftwareStatus();
  }

  getMachinesOnSiteCurrentSoftwareStatus() {
    const api = FetchApi(Configs.api.getMachinesOnSiteCurrentSoftwareStatus);
    api.post(this.props.rowData.siteID).then((response) => {
      console.log("getMachinesOnSiteCurrentSoftwareStatus response", response)

      // Loop through response to update data for rendering purposes.
      for (var i = 0; i < response.length; i++) {
        response[i]["alg"] = this.renderSoftwareStatus(response[i]["alg"], response[i]["machineID"])
        response[i]["api"] = this.renderSoftwareStatus(response[i]["api"], response[i]["machineID"])
        response[i]["autoupdater"] = this.renderSoftwareStatus(response[i]["autoupdater"], response[i]["machineID"])
        response[i]["clientCertificate"] = this.renderSoftwareStatus(response[i]["clientCertificate"], response[i]["machineID"])
        response[i]["heidiSQL"] = this.renderSoftwareStatus(response[i]["heidiSQL"], response[i]["machineID"])
        response[i]["poc"] = this.renderSoftwareStatus(response[i]["poc"], response[i]["machineID"])
        response[i]["netCore"] = this.renderSoftwareStatus(response[i]["netCore"], response[i]["machineID"])
      }
      this.setState({
        machinesOnSiteCurrentSoftwareStatus: response
      })
    });
  }

  handleMachineSpecificSoftwareUninstall(e, softwareData, machineID) {
    var isCheckboxChecked = e.target.checked;
    var softwareToUninstallLocalVar = JSON.parse(JSON.stringify(this.state.machinesAndSoftwareToUninstall))
    if (isCheckboxChecked) {
      if (machineID in softwareToUninstallLocalVar == false) {
        softwareToUninstallLocalVar[machineID] = [
          softwareData.softwareIDToUninstall
        ]
      } else {
        softwareToUninstallLocalVar[machineID].push(softwareData.softwareIDToUninstall)
      }
    } else {
      if (machineID in softwareToUninstallLocalVar) {
        softwareToUninstallLocalVar[machineID] = softwareToUninstallLocalVar[machineID].filter(function (item) {
          return item !== softwareData.softwareIDToUninstall
        })
      }
    }
    this.setState({
      machinesAndSoftwareToUninstall: softwareToUninstallLocalVar
    })
  }

  handleShowActionDialog(event, rowData, actionID) {
    event.preventDefault();
    var updateActionDialog = !this.state.showSelectedActionDialog
    this.setState({
      showSelectedActionDialog: updateActionDialog,
      currentRowDataToUninstall: rowData,
      currentActionID: actionID,
    })
  }

  closeShowActionDialog() {
    this.setState({
      showSelectedActionDialog: false,
      currentRowDataToUninstall: [],
      currentActionID: 0,
    })
  }

  handleUninstallSelectedSoftwareMachine(machineID, siteID) {
    var body = {
      machineID: machineID,
      siteID: siteID,
      softwareToUninstall: this.state.machinesAndSoftwareToUninstall[machineID]
    }
    const api = FetchApi(Configs.api.uninstallSelectedSoftwareMachine);
    api.post(body).then((response) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Successfully queued uninstalllation of selected software.",
        Configs.snackbarVariants.success
      );
      this.getMachinesOnSiteCurrentSoftwareStatus();
      this.closeShowActionDialog();
    }).catch((error) => {
      var msg = String(error);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
  }

  handleDeactivateSelectedMachine(machineID, siteID) {
    const api = FetchApi(Configs.api.deactivateSelectedMachine);
    var body = {
      machineID: machineID,
      siteID: siteID,
    }
    api.post(body).then((response) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Successfully queued deactivation of machine.",
        Configs.snackbarVariants.success
      );
      this.getMachinesOnSiteCurrentSoftwareStatus();
      this.closeShowActionDialog();
    }).catch((error) => {
      var msg = String(error);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
  }

  handleDeactivateSelectedSite(siteID) {
    const api = FetchApi(Configs.api.deactivateSelectedSite);
    api.post(siteID).then((response) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Successfully queued deactivation of site.",
        Configs.snackbarVariants.success
      );
      this.getMachinesOnSiteCurrentSoftwareStatus();
      this.closeShowActionDialog();
    }).catch((error) => {
      var msg = String(error);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
  }

  handleUninstallAutoupdatersSelectedSite(siteID) {
    const api = FetchApi(Configs.api.uninstallAutoupdatersSelectedSite);
    api.post(siteID).then((response) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Successfully queued deactivation of site.",
        Configs.snackbarVariants.success
      );
      this.getMachinesOnSiteCurrentSoftwareStatus();
      this.closeShowActionDialog();
    }).catch((error) => {
      var msg = String(error);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
  }
  
  renderSoftwareStatus(softwareData, machineID) {
    var softwareStatusRender = Configs.SoftwareStatusIDs[softwareData.softwareUninstalledStatus];
    if (softwareData.softwareInstalledStatus == false) {
      return (
        <div>Not installed</div>
      )
    }
    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12}>
            Deactivate:
            <Checkbox
              onClick={(e) => this.handleMachineSpecificSoftwareUninstall(e, softwareData, machineID)}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ color: softwareStatusRender.statusColour }}>
              {softwareStatusRender.currentStatus}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderSoftwareIDToSoftwareName(softwareID) {
    if (softwareID in this.props.softwareIDToSoftwareName) {
      return (
        <Grid container justify="center">
          <span style={{ fontWeight: "bold" }}>
            {this.props.softwareIDToSoftwareName[softwareID]}
          </span>
          (ID: {softwareID})
        </Grid>
      )
    } else {
      return (
        <div>
          Software not found.
        </div>
      )
    }
  }

  renderMachineDeactivationActions(machineID, siteID) {
    if (this.state.currentActionID == 1) {
      if (machineID in this.state.machinesAndSoftwareToUninstall) {
        return (
          <div style={{ paddingTop: "1REM" }}>
            <div>
              {this.state.machinesAndSoftwareToUninstall[machineID].map(softwareID =>
                this.renderSoftwareIDToSoftwareName(softwareID)
              )}
            </div>
            <Grid container justify="center" style={{ paddingTop: "1REM" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.handleUninstallSelectedSoftwareMachine(machineID, siteID)}
              >
                Uninstall software
              </Button>
            </Grid>
          </div>
        )
      } else {
        return (
          <div style={{ paddingTop: "1REM" }}>
            You have not selected any software to action for this machine.
          </div>
        )
      }
    } else if (this.state.currentActionID == 2) {
      return (
        <div>
          <Grid container justify="center" style={{ paddingTop: "1REM" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.handleDeactivateSelectedMachine(machineID, siteID)}
            >
              Deactivate machine
            </Button>
          </Grid>
        </div>
      )
    }
  }

  renderActionText() {
    if (this.state.currentActionID == 1) {
      return (
        <div>
          Please confirm you want to uninstall the following software:
          {this.renderMachineDeactivationActions(
            this.state.currentRowDataToUninstall.machineID,
            this.state.currentRowDataToUninstall.siteID,
          )}
        </div>
      )
    } else if (this.state.currentActionID == 2) {
      return (
        <div>
          Please confirm you want to deactivate the selected machine. This will:
          <div>
            1. Uninstall all software.
          </div>
          <div>
            2. Remove all software mappings.
          </div>
          <div>
            3. Disable selected machine.
          </div>
          {this.renderMachineDeactivationActions(
            this.state.currentRowDataToUninstall.machineID,
            this.state.currentRowDataToUninstall.siteID,
          )}
        </div>
      )
    } else if (this.state.currentActionID == 3) {
      return (
        <div>
          Please confirm you want to deactivate the entire site. For the selected site, this will:
          <div>
            1. Uninstall all software (except autoupdater) for all machines.
          </div>
          <div>
            2. Remove all software mappings for all machines.
          </div>
          <div>
            3. Disable all machines.
          </div>
          <div>
            4. Disable selected site.
          </div>
          <Grid container justify="center" style={{ paddingTop: "1REM" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.handleDeactivateSelectedSite(this.props.rowData.siteID)}
            >
              Deactivate site
            </Button>
          </Grid>
        </div>
      )
    } else if (this.state.currentActionID == 4) {
      return (
        <div>
          Please confirm you want to uninstall all autoupdaters for the selected site.
          <Grid container justify="center" style={{ paddingTop: "1REM" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.handleUninstallAutoupdatersSelectedSite(this.props.rowData.siteID)}
            >
              Uninstall all autoupdaters
            </Button>
          </Grid>
        </div>
      )
    }
  }

  renderSiteMachineActionDialog() {
    if (this.state.currentActionID == 1
      || this.state.currentActionID == 2) {
      return (
        <div>
          <Dialog onClose={this.handleShowActionDialog} aria-labelledby="simple-dialog-title" open={this.state.showSelectedActionDialog}>
            <Card style={{ padding: "2REM" }}>
              <Grid container justify="center">
                <div>
                  {this.state.currentRowDataToUninstall.siteName} ({JSON.stringify(this.state.currentRowDataToUninstall.siteID)})
                </div>
              </Grid>
              <Grid container justify="center">
                <div>
                  {this.state.currentRowDataToUninstall.machineName} ({JSON.stringify(this.state.currentRowDataToUninstall.machineID)})
                </div>
              </Grid>
              <div style={{ paddingTop: "1REM" }}>
                {this.renderActionText()}
              </div>
            </Card>
          </Dialog>
        </div>
      )
    } else {
      return (
        <div>
          <Dialog onClose={this.handleShowActionDialog} aria-labelledby="simple-dialog-title" open={this.state.showSelectedActionDialog}>
            <Card style={{ padding: "2REM" }}>
              <Grid container justify="center">
                <div>
                  {this.props.rowData.siteName} ({this.props.rowData.siteID})
                </div>
              </Grid>
              <div style={{ paddingTop: "1REM" }}>
                {this.renderActionText()}
              </div>
            </Card>
          </Dialog>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <div>
          <Grid container justify="center">
            <MaterialTable
              title="Software status"
              columns={[
                {
                  title: "Actions",
                  render: (rowData) => {
                    const button = (
                      <div>
                        <Grid container justify="center">
                          <Grid item xs={6}>
                            <Tooltip title="Uninstall selected software">
                              <IconButton
                                onClick={(event) => {
                                  this.handleShowActionDialog(event, rowData, 1)
                                }}
                              >
                                <UpdateIcon
                                  style={{ color: "orange" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={6}>
                            <Tooltip title="Deactivate selected machine">
                              <IconButton
                                onClick={(event) => {
                                  this.handleShowActionDialog(event, rowData, 2)
                                }}
                              >
                                <UpdateIcon
                                  style={{ color: "red" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    );
                    return button;
                  }
                  , width: 15
                },
                {
                  title: 'Machine Name', field: 'machineName', width: 40
                },
                {
                  title: 'Machine Enabled', field: 'machineEnabled', width: 15,
                },
                {
                  title: 'Autoupdater Active', field: 'autoupdaterActive', width: 15,
                },
                {
                  title: 'Server', field: 'server', width: 15,
                },
                {
                  title: 'HeidiSQL', field: 'heidiSQL', width: 25
                },
                {
                  title: 'API', field: 'api', width: 25,
                },
                {
                  title: 'ALG', field: 'alg', width: 25,
                },
                {
                  title: 'Certificate', field: 'clientCertificate', width: 25,
                },
                {
                  title: 'POC', field: 'poc', width: 25,
                },
                {
                  title: 'NetCore', field: 'netCore', width: 25,
                },
                {
                  title: 'Autoupdater', field: 'autoupdater', width: 25,
                },
              ]}
              data={this.state.machinesOnSiteCurrentSoftwareStatus}
              options={{
                search: true,
                pageSize: 5,
                tableLayout: "fixed",
              }}
              components={{
                Toolbar: props => (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <MTableToolbar {...props} />
                      </div>
                      <Button
                        style={{ fontSize: 10, width: "15%" }}
                        color="secondary"
                        variant="contained"
                        onClick={(event) => this.handleShowActionDialog(event, [], 3)}
                      >
                        Deactivate site
                      </Button>
                      <Button
                        style={{ fontSize: 10, width: "25%" }}
                        color="secondary"
                        variant="contained"
                        onClick={(event) => this.handleShowActionDialog(event, [], 4)}
                      >
                        Uninstall all autoupdaters
                      </Button>
                      <Button
                        style={{ paddingLeft: "1REM", fontSize: 10, width: "15%" }}
                        color="primary"
                        variant="contained"
                        onClick={() => this.getMachinesOnSiteCurrentSoftwareStatus()}
                      >
                        Refresh status
                      </Button>
                    </div>
                  </div>
                )
              }}
            />
            {this.renderSiteMachineActionDialog()}
          </Grid>
        </div>
      </div>
    )
  }
}

export default SiteMachineSpecificDeactivation;