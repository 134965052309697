// Description: This file stores some hard coded information
// Status: Ready

export const POCCardTooltip =
  "A POC site is active if there is at least one user logged on within a week";

export const ALGCardTooltip =
  "A FHT Algorithms site is active if its last log was received within the last 24 hours(~1day(s)), and it contains no fail messages.";

export const APICardTooltip =
  "A FHT API site is active if its last log was received within the last 26 hours(~1day).";

export const SC4CCardTooltip =
  "A SC4C site is active if there is at least one user logged on within a week";

export const RecommendationQueriesTooltip =
  "Click here to update any open recommendation queries.";

export const FHTLogsTooltip =
  "A FHT log is considered successful if FHT algorithms had run successfully on the day it had ran.";