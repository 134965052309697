import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";
import SelectedRecChkDisplayConfigurations from "./SelectedRecChkDisplayConfigurations";
import Box from '@material-ui/core/Box';
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SelectSitesGrouping from "./SelectSitesGrouping";
import ActiveAlgorithmsTableSummary from "../GeneralPages/ActiveAlgorithmsTableSummary/ActiveAlgorithmsTableSummary";
import Dialog from "@material-ui/core/Dialog";

class SiteModulesGeneralLayout extends React.Component {
  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      search: true,
      paging: false,
      selection: true,
    };
    this.state = {
      selectedSiteGroupings: 0,
      siteGroupingsList: [],
      siteTable: [
        { title: "Site Name", field: "fhtSiteName" },
        { title: "Site ID", field: "fhtSiteId", hidden: true, width: 0 }
      ],
      showActiveAlgorithmSummary: false,
    }
    this.handleShowActiveAlgorithmSummary = this.handleShowActiveAlgorithmSummary.bind(this);
  }
  componentDidMount() {
    this.getSites();
    this.getSiteGroupings();
  }
  getSiteGroupings() {
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      console.log("getSiteGroupings siteGroupingsList", response)
      response[0].text = "Select site grouping"
      this.setState({
        siteGroupingsList: response,
      })
    });
  }

  updateCommonSelectedSiteGroupings = (event) => {
    this.handleClearAllSelectedSites();
    this.props.handleClearAllConfigurationsSelected();

    this.setState({
      selectedSiteGroupings: event.target.value
    });
    var selectedSitesFromSiteGroup = JSON.parse(this.state.siteGroupingsList.filter(a => a.key == String(event.target.value))[0].siteIDs)
    var checkedBoxSelectedSitesArray = []
    for (var i = 0; i < selectedSitesFromSiteGroup.length; i++) {
      try {
        var siteCheckBoxFiltered = this.state.siteCheckBox.filter(a => a.fhtSiteId == selectedSitesFromSiteGroup[i].fhtSiteGroupingsSiteID)[0]
        siteCheckBoxFiltered.tableData.checked = true
        checkedBoxSelectedSitesArray.push(siteCheckBoxFiltered)
      } catch {
        // Site doesn't exist in the siteCheckBox variable now.
        // Could have been removed, disabled.
      }

    }
    this.handleSelectSites(checkedBoxSelectedSitesArray);
  }
  getSites = (keepSiteSelection = false) => {
    //Site Algo
    console.log("getting site algorithm map...");
    const api = FetchApi(Configs.api.getConfigEditSiteList)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      if (!keepSiteSelection) this.setState({ siteCheckBox: response.sites, })
      console.log("siteCheckBox", response.sites);
    })
  }
  renderIndividualSiteSelection = () => {
    return (
      <MaterialTable
        columns={this.state.siteTable}
        data={this.state.siteCheckBox}
        options={this.pageOptions}
        title=""
        onSelectionChange={(selectedRows) => {
          this.handleSelectSites(selectedRows);
        }}
      ></MaterialTable>
    )
  }


  renderSiteGroupingSelection = () => {
    return (
      <Grid container justify="center">
        <FormControl variant="outlined" >
          <Select
            label="Select grouping"
            value={this.state.selectedSiteGroupings}
            style={{ color: "white" }}
            onChange={(event) => {
              this.updateCommonSelectedSiteGroupings(event);
            }}
          >
            {this.state.siteGroupingsList.map((grouping) => (
              <MenuItem
                key={Number(grouping.key)}
                value={Number(grouping.key)}
                style={{ color: "black" }}
              >
                {grouping.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  handleShowActiveAlgorithmSummary() {
    this.setState({
      showActiveAlgorithmSummary: !this.state.showActiveAlgorithmSummary
    })
  }

  renderActiveAlgorithmSummaryButton() {
    // If configType is not Rec/Chk/Display then do not display this button.
    if (this.props.configType == undefined) {
      return (
        <div></div>
      )
    }

    return (
      <div>        
        <span> &nbsp; </span>
        <Button
          variant="contained"
          onClick={this.handleShowActiveAlgorithmSummary}
        >
          Active algorithms summary
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div> <br />
        <Grid container justify="center" style={{ gap: "8px", width: "100%" }}>
          <Grid item xs={3}>
            {/** Left side of the page **/}
            <SelectSitesGrouping
              SharedComponentSiteGroupingCallback={
                this.props.SharedComponentSiteGroupingCallback
              }
            />
            <br />
          </Grid>
          <Grid item xs={7}>
            {/** Right side of the page **/}
            <Box
              bgcolor="primary.main"
              style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
              height="50px"
            >
              <Typography variant="h5">
                {this.props.pageTitle}
              </Typography>
            </Box>
            <div className="nsExtraTallScrollBoxConfigEdit">
              <SelectedRecChkDisplayConfigurations
                selectedSites={this.props.selectedSites}
                selectedAlgoritmsParentFunction={this.props.selectedAlgoritmsParentFunction}
                AlgoConfigMap={this.props.AlgoConfigMap}
                uniqueActivatedAlgos={this.props.uniqueActivatedAlgos}
                selectedAlgos={this.props.selectedAlgos}
              />
            </div><br />
            {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE
              || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) ?
              <div></div> :
              <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', }}>
                <Button
                  variant="contained"
                  onClick={this.props.handleClearAllConfigurationsSelected}
                >
                  Clear Algo selections
                </Button>
                {this.renderActiveAlgorithmSummaryButton()}
              </div>
            }
          </Grid>
        </Grid> <br />
        <Dialog
          fullWidth
          maxWidth="l"
          open={this.state.showActiveAlgorithmSummary}
          onBackdropClick={(event) => this.handleShowActiveAlgorithmSummary()}
        >
          <ActiveAlgorithmsTableSummary
            configType={this.props.configType}
          />
        </Dialog>
      </div>
    );
  }
}
export default SiteModulesGeneralLayout;
