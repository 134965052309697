import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable from "material-table";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import {
  utcToLocal,
  compareDateTimes,
  backgroundColourFromLetter,
} from "../../HelperFunctions";
import { Card } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import SelectSitesGrouping from "../../SharedComponents/SelectSitesGrouping";
import UsersInClinicsComponent from "./UsersInClinicsComponent";
import UsersToCohortsComponent from "./UsersToCohortsComponent";
import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class MapClinicUserToCohorts extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedSites: [],
      selectedUsers: [],
      selectedCohorts: [],
      fullCohortList: [],
      saveButtonDisabled : true,
      originalCohortTableBeforeUserSelection: [],
      RequeryUsersForClinicAPI: false,
      RequeryCohortsForClinicAPI: false,
    };
    //Binds an async callback to the sitegrouping component
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(
      this
    );
    this.UsersInClinicCallback = this.UsersInClinicCallback.bind(this);
    this.UsersToCohortsCallback = this.UsersToCohortsCallback.bind(this);
  }

  //For the callbacks, the moment a site is selected, there are two types of data to retrieve
  //1) The users & cohorts that exist for a site
  //2) The users & cohorts that are mapped with each other
  //All users retrieved initially are always active users
  //All cohorts retrieved initially are not mapped until the user ticks the user list.
  SharedComponentSiteGroupingCallback = (selectedSites) => {
    this.setState(
      {
        selectedSites: selectedSites,
        RequeryUsersForClinicAPI: true,
        RequeryCohortsForClinicAPI: true,
      },
      () => {
        this.GetCohortListForSite();
      }
    );
    console.log("Callback received from site grouping:", selectedSites);
  };
  UsersInClinicCallback = (selectedUsers) => {
    this.setState({
      selectedUsers: selectedUsers,
      RequeryCohortsForClinicAPI: true,
    });
    console.log("Callback received from selected users:", selectedUsers);
  };
  GetCohortListForSite = () => {
    console.log("getting site cohorts.");
    const api = FetchApi("GetAWSClinicCohortList");
    let siteIdArray = [];
    if (
      this.state.selectedSites == null ||
      this.state.selectedSites.length == 0
    ) {
      this.setState({ fullCohortList: [] }, () =>
        this.setState({ RequeryCohortsForClinicAPI: true })
      );
    } else {
      this.state.selectedSites.forEach((siteRecord) => {
        siteIdArray.push(parseInt(siteRecord.fhtSiteId));
      });
      let body = siteIdArray;
      api.post(body).then((response) => {
        this.setState({ fullCohortList: response }, () =>
          this.setState({ RequeryCohortsForClinicAPI: true })
        );
      });
    }
  };
  UsersToCohortsCallback = (selectedCohorts, userClicked) => {
    if (userClicked == true )
    {
    this.setState({
      selectedCohorts: selectedCohorts, saveButtonDisabled : false
    });
    }
    else {
      this.setState({
        selectedCohorts: selectedCohorts
      });
    }
    console.log(
      "Callback received from selected selectedCohorts:",
      selectedCohorts
    );
  };

  btnSavePatientToCohortMapping = () => {
    console.log ("this.state.originalCohortTableBeforeUserSelection:",this.state.originalCohortTableBeforeUserSelection);
    if ((this.state.selectedSites.length == 0) || (this.state.selectedUsers.length == 0)){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "No sites or users selected",
        Configs.snackbarVariants.error
      );
      return;
    }
    let usernameArray = [];
    this.state.selectedUsers.forEach((userRecord) => {
      usernameArray.push(userRecord.userName);
    });

    let fullCohortnameArray = [];
    this.state.fullCohortList.forEach((cohortRecord) => {
      if (cohortRecord.valid == true)
        fullCohortnameArray.push(cohortRecord.cohortName);
    });

    let enabledCohortnameArray = [];
    this.state.selectedCohorts.forEach((cohortRecord) => {
      if (cohortRecord.tableData.checked == true)
        enabledCohortnameArray.push(cohortRecord.cohortName);
    });
       
    let siteIdArray = [];
    this.state.selectedSites.forEach((siteRecord) => {
      siteIdArray.push(parseInt(siteRecord.fhtSiteId));
    });

    const api = FetchApi("SaveAWSClinicPatientsToCohortList");
    let body = {
      usernameArray: usernameArray,
      siteIdArray: siteIdArray,
      fullCohortnameArray:fullCohortnameArray,
      enabledCohortnameArray:enabledCohortnameArray,
    };

    api.post(body).then((response) => {

        console.log ("response:",response);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response,
          Configs.snackbarVariants.success
        );

    /*
     {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        response.status+":"+response.statusText,
        Configs.snackbarVariants.error
      );
    }
*/

      
    });
  };
  onLayoutChange = () => {
    console.log("Layout has changed");
  };
  render() {
    return (

        <ResponsiveReactGridLayout
          key="mapClinicToUserMainLayout"
          className="mapClinicToUserMainLayoutClass"
          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={45}
          onLayoutChange={(layout) => this.onLayoutChange(layout)}
        >
          <div
            key="TitleLayout"
            data-grid={{
              w: 4,
              h: 1,
              x: 5,
              y: 0,
              static: true,
            }}
          >
            <div>
              <h1>Map clinic users to trials</h1>
            </div>
          </div>

          <div
            key="SaveButtonLayout"
            data-grid={{
              w: 2,
              h: 1,
              x: 5,
              y: 15,
              static: true,
            }}
          >
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.saveButtonDisabled}
                onClick={() => this.btnSavePatientToCohortMapping()}
              >
                Save patient to cohort mapping
              </Button>
            </div>
          </div>
          <div
            key="InstructionSitesLayout"
            data-grid={{
              w: 2,
              h: 1,
              x: 0.3,
              y: 1,
              static: true,
            }}
          >
            <div>
              <h4>1) Select site(s)</h4>
            </div>
          </div>
          <div
            key="UserLayout"
            data-grid={{
              w: 3.3,
              h: 1,
              x: 4,
              y: 1,
              static: true,
            }}
          >
            <div>
              <h4>
                2) Select user(s). If multiple sites are selected, users with
                similar names are grouped.
              </h4>
            </div>
          </div>
          <div
            key="InstructionCohortLayout"
            data-grid={{
              w: 3.3,
              h: 1,
              x: 8,
              y: 1,
              static: true,
            }}
          >
            <div>
              <h4>
                3) Select trial(s) which will be viewable to the users within
                the clinic. Click the save button below to save your changes.
              </h4>
            </div>
          </div>
          <div
            key="InstructionLegendLayout"
            data-grid={{
              w: 4,
              h: 1,
              x: 8,
              y: 13,
              static: true,
            }}
          >
            <div>
              <h4><i>
                Legend: Gray color = Is not consistent across selected records and cannot be selected
                </i>
              </h4>
            </div>
          </div>
          <div
            key="ThreeListsLayout"
            data-grid={{
              w: 12,
              h: 12,
              x: 1,
              y: 2.1,
              static: true,
            }}
          >
            <div>
              <Paper>
                <ResponsiveReactGridLayout
                  key="mapClinicToUserMainLayout2"
                  className="mapClinicToUserMainLayoutClass2"
                  breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                  cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                  rowHeight={45}
                  onLayoutChange={(layout) => this.onLayoutChange(layout)}
                >
                  <div
                    key="siteGroupingLayout"
                    data-grid={{
                      w: 3.8,
                      h: 12,
                      x: 0,
                      y: 0,
                      static: true,
                    }}
                  >
                    <div>
                      <SelectSitesGrouping
                        SharedComponentSiteGroupingCallback={
                          this.SharedComponentSiteGroupingCallback
                        }
                      />
                    </div>
                  </div>

                  <div
                    key="userLayout"
                    data-grid={{
                      w: 3.8,
                      h: 10,
                      x: 4,
                      y: 0,
                      static: true,
                    }}
                  >
                    <div>
                      <UsersInClinicsComponent
                        UsersInClinicCallback={this.UsersInClinicCallback}
                        parentState={this.state}
                      />
                    </div>
                  </div>
                  <div
                    key="cohortLayout"
                    data-grid={{
                      w: 3.8,
                      h: 10,
                      x: 8,
                      y: 0,
                      static: true,
                    }}
                  >
                    <div>
                      <UsersToCohortsComponent
                        UsersToCohortsCallback={this.UsersToCohortsCallback}
                        parentState={this.state}
                      />
                    </div>
                  </div>
                </ResponsiveReactGridLayout>
              </Paper>
            </div>
          </div>
        </ResponsiveReactGridLayout>
    );
  }
}

export default MapClinicUserToCohorts;
