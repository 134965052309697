import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";


import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

class FHTlogs extends React.Component {
    constructor(props) {
      super(props);
      this.pageOptions = {
        pageSize: 10,
        search: true,
        searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
        pageSizeOptions: [10, 20, 30],
      };
      this.selectionOptions = {
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        selection: true,
      };
  
      this.triggers = {};
      this.snackbarMessage = "";
      this.snackbarVariant = "success";
      this.state = {
        logTable: [
            // { title: 'Site Id', field: 'fhtLogSiteId'},
            { title: "Site Name", field: "SiteName", width:"17%" },
            { title: "Log received", field: "FhtLogDateLogReceived" },
            { title: "Process Start", field: "FhtLogDateProcessStarted" },
            { title: "Process Finish", field: "FhtLogDateProcessFinished" },
            { title: "Total Time (mins)", field: "totalProcessTime",width:"7%" },
            { title: "Status", field: "ProcessSuccessful",width:"6%" },
            { title: "Text", field: "FhtLogTextInfomation", width:"48%"},
          ],
          fhtLog: [],
          isLoaded : false,
          rowsPerPage : 10,
        };
    }

    

    LoadRows = (startRow, endRow) =>{
      if(startRow < 0)startRow = 0;
      console.log("Loading rows ", startRow + " " + endRow);
      let body_row = {
        SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        startRow : startRow,
        endRow : endRow,
      }
      const api_page = FetchApi("GetFhtLogPage");
      api_page.post(body_row).then(response => {
        console.log("Logs fetched", response);
        this.InsertLogs(startRow, response);
      })
    }
    
    InsertLogs = (startRow, data) =>{
      let fhtLog = [...this.state.fhtLog];
      var index = startRow;
      data.map(entry =>{
        fhtLog[index] = entry;
        index += 1;
      })
      this.setState({fhtLog : fhtLog});
      console.log(this.state.fhtLog);
    }
    

    componentDidMount = () => {
      
      
      const api_rows = FetchApi("GetFhtLogV2EntryNum");
      let body_row = {
        SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      }
      //build placeholder rows
      api_rows.post(body_row).then(numRows=>{
        var newRows = Array.from({length:numRows}, (_, i) => {
          return({id : i, FhtLogTextInfomation : "Loading"})
        });
        this.setState({fhtLog : newRows});
        console.log("Empty rows", this.state.fhtLog);
      });
      //Get first page
      var startRow = 0;
      var endRow = 20;
      this.LoadRows(startRow,endRow);
      this.setState({isLoaded : true});
    }
    render() {

      const { classes } = this.props;
      {
        if(this.state.isLoaded){
          return(
            <div className={classes.root}>
              
              <Grid container>
                  {this.renderLog()}
              </Grid>
            </div>
          );
        }
        else{
          return(
            <div>
              <CircularProgress></CircularProgress>
              Loading
            </div>
          )
        }
      }
      
    } 
    renderLog = ()=>{
      const {
        isLoaded,
        fhtLog,
      } = this.state;
      const { classes } = this.props;
      return (
        <MaterialTable
          columns={this.state.logTable}
          data={fhtLog}
          options={this.pageOptions}
          title="FHT Log"
          onChangePage={(page)=>{
            this.LoadRows( (page)*this.state.rowsPerPage, (page + 2)*this.state.rowsPerPage );
            
          }}
          onChangeRowsPerPage={(pageSize)=>{
            this.setState({rowsPerPage : pageSize});
          }}
        ></MaterialTable>
      );
    };
}
export default withStyles(useStyles)(FHTlogs);
