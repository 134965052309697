import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";

class UserManagement extends Component {
  constructor(props) {
    super();       
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.state = { 
      userAccountRolesTable: [
        { title: "Account Id", field: "userID", editable: 'never' },
        { title: "User name", field: "userName", editable: 'never' },
        { title: "First name", field: "firstName", editable: 'never' },
        { title: "Last Name", field: "lastName", editable: 'never' },
        { title: "Role ID", field: "roleID", lookup:{}, type: 'numeric' },
      ],
      userAccountRolesData: [],
    }
    this.initialiseAccountRolesDropdown();
  }

  // Obtain data before rendering
  componentDidMount=()=>{
    this.getUserRolesData();
  }

  getUserRolesData = async() => {
    // user management
    const api = FetchApi(Configs.api.getUserAccountRoles);
     await api.get().then((response) => {
        this.setState({
         userAccountRolesData: response
        });
      });
  };

  initialiseAccountRolesDropdown = () => {
    //user management
    let updatedAccountRolesData = this.state.userAccountRolesTable
    var roleColumns = {
      "1": "Admin",
      "2": "IandE",
      "3": "Disabled",
      "4": "IandE Coordinator"
    };
    updatedAccountRolesData[4].lookup = roleColumns
    this.setState({
      userAccountRolesTable: updatedAccountRolesData
    })
  }

  handleUserRoleUpdate = (data) => {
    //User Management
    let updatedUserRole = {
      userID: data.userID,
      userRoleID: data.roleID,
    };
    const api = FetchApi(Configs.api.updateAccountRoles);
    api.post(updatedUserRole)
      .then((response) => {
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.updateSuccess,
            Configs.snackbarVariants.success
          );
        }
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateFail,
          Configs.snackbarVariants.error
        );
      });
  };

  renderUserManagement = () => {
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>FHT support user management</h3></Grid>
        <br/>     
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns={this.state.userAccountRolesTable}
            data={this.state.userAccountRolesData}
            options={this.pageOptions}
            title=""
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (
                      newData.roleID === undefined ||
                      newData.roleID.trim() === 0
                    ) {
                      this.props.GlobalFunctionDisplaySnackbarMessage(
                        Configs.snackbarMessages.systemDetails,
                        Configs.snackbarVariants.error
                      );
                      reject();
                      return;
                    }
                    resolve();
                    const data = this.state.userAccountRolesData.slice();
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.setState({ userAccountRolesData: data }, () => {
                      this.handleUserRoleUpdate(newData);
                    });
                  }, 500);
                })
            }}
        ></MaterialTable>
        </Grid>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderUserManagement()} <br/>
      </div>
    )
  }
}

export default UserManagement;