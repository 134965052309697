import React, { Component } from "react";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

class ActiveAlgorithmsTableSummary extends Component {
  constructor(props) {
    super();
    this.state = {
      tableColumns: [],
      originalTableData: [],
      tableData: [],
      selectedSiteIDs: [],
      selectedSiteGroupings: 0,
      activeAlgorithmTableTitle: "",
    }
    this.pageOptions = {
      search: true,
      pageSize: 20,
      pageSizeOptions: [10, 20, 50],
      exportButton: true,
      exportAllData: true,
      grouping: false,
      sorting: true,
      maxBodyHeight: 'calc(100vh - 13REM)',
    };
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.SharedComponentSelectedSiteGroupingIDCallback = this.SharedComponentSelectedSiteGroupingIDCallback.bind(this);
  }

  componentDidMount() {
    this.getActiveAlgorithmTableData();
    this.setActiveAlgorithmTableTitle();
  }

  SharedComponentSiteGroupingCallback(siteGroupingsSiteIDs) {
    this.setState({
      selectedSiteIDs: siteGroupingsSiteIDs
    })
    if (this.state.selectedSiteGroupings != 0) {
      var filteredTableData = [];
      var tableDataArray = this.state.tableData;
      for (var i = 0; i < tableDataArray.length; i++) {
        if (siteGroupingsSiteIDs.includes(tableDataArray[i].SiteID)) {
          filteredTableData.push(tableDataArray[i])
        }
      }
      this.setState({
        tableData: filteredTableData
      })
    } else {
      this.setState({
        tableData: this.state.originalTableData
      })
    }
  }

  SharedComponentSelectedSiteGroupingIDCallback(siteGroupingID) {
    this.setState({
      selectedSiteGroupings: siteGroupingID
    })
  }

  setActiveAlgorithmTableTitle = () => {
    var tableTitle = "";
    if (this.props.configType == Configs.configurationEditingPageTypes.accountDisplayAlgConfig) {
      tableTitle = "Active display algorithms"
    } else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig) {
      tableTitle = "Active recommendation algorithms"
    } else if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig) {
      tableTitle = "Active check algorithms"
    }
    this.setState({
      activeAlgorithmTableTitle: tableTitle
    })
  }

  getActiveAlgorithmTableData = () => {
    //Site Algo
    const api = FetchApi(Configs.api.getActiveAlgorithmTableData)
    var RecOrChkSaveType = 0;
    if (this.props.configType == Configs.configurationEditingPageTypes.accountChkAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Chk
    else if (this.props.configType == Configs.configurationEditingPageTypes.accountRecAlgConfig)
      RecOrChkSaveType = Configs.RecOrChkSaveType.Rec
    else
      RecOrChkSaveType = Configs.RecOrChkSaveType.DisplayRec

    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
      RecOrChkType: RecOrChkSaveType,
    };
    api.post(body).then(response => {
      // Ensure SiteName column is stickied.
      var siteNameColumn = response.tableColumnsList.filter(x => x.title == "SiteName")
      if (siteNameColumn.length > 0) {
        siteNameColumn[0]["cellStyle"] = {
          position: "sticky",
          left: "0",
          background: "white",
          fontSize: 12,
          zIndex: 1,
        }
      }

      var sortedTableData = response.tableRowDataList.sort(function (a, b) {
        return a.SiteName.localeCompare(b.SiteName)
      })
      
      this.setState({
        tableColumns: response.tableColumnsList,
        tableData: sortedTableData,
        originalTableData: sortedTableData,
      })
    })
  }

  render() {
    return (
      <div>
        <MaterialTable
          options={this.pageOptions}
          columns={this.state.tableColumns}
          data={this.state.tableData}
          title={this.state.activeAlgorithmTableTitle}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ position: "absolute", left: "40%", top: 20, zIndex: 1 }}>
                  <SelectSiteGroupingsSelectionOnly
                    selectedSiteGroupings={this.state.selectedSiteGroupings}
                    SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
                    SharedComponentSelectedSiteGroupingIDCallback={this.SharedComponentSelectedSiteGroupingIDCallback}
                  />
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default ActiveAlgorithmsTableSummary;