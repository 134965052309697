import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FetchApi from "../../../Service/FetchApi";
import Configs from "../../../Service/Configuration";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as _ from "underscore";
class UsersInClinicsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //selectedSiteGroupings: 0,
      //siteGroupingsList: [],
      userList: [],
      siteTable: [
        { title: "User Name", field: "userName" },
        {
          title: "valid",
          field: "valid",
          hidden: true,
          width: 0,
        },
      ],
    };
    this.pageOptions = {
      pageSize: 10,
      search: true,
      paging: false,
      selection: true,
      showSelectAllCheckbox: false,
      selectionProps: (rowData) => {
        if (rowData.valid == true)
        {return ({disabled:false});}
        
        else
        {return ({disabled:true});}
      },
      rowStyle: (rowData) => {
        if (rowData.valid == true)
        return {
          color: "black",
        };
        
        else
        return {
          color: "red",
        }
      },
      
    };
    
    this.handleThrottleSelectUsers = _.debounce(
      this.handleSelectUsers,
      500,
      false
    );
  }
  clearUserSelection = () => {
    let updatedUserList = this.state.userList;
    updatedUserList.forEach ((updateUserItem)=>{
      updateUserItem.tableData.checked = false;
      updateUserItem.valid = true;
    });
    this.setState({
      userList: updatedUserList
    });
    this.props.UsersInClinicCallback([]);
}
  getUsersForClinic = () => {
    this.clearUserSelection();
    if (
      this.props.parentState == null ||
      this.props.parentState.selectedSites == null ||
      this.props.parentState.selectedSites.length == 0
    ) {
      console.log("No sites selected. Remove all users from list.");
      this.setState({ userList: [] });
    } else {
      let siteIdArray = [];
      this.props.parentState.selectedSites.forEach((siteRecord) => {
        siteIdArray.push(parseInt(siteRecord.fhtSiteId));
      });
      const api = FetchApi("GetAWSClinicPatientsList");
      let body = siteIdArray;
      api.post(body).then((response) => {
        console.log ("response GetAWSClinicPatientsList:",response);
        this.setState({userList:response})
      });
    }
  };

  handleSelectUsers = (selectedRows) => {
    this.props.UsersInClinicCallback(selectedRows);
  };

  render() {
    if (this.props.parentState.RequeryUsersForClinicAPI == true) {
      this.props.parentState.RequeryUsersForClinicAPI = false;
      this.getUsersForClinic();
    } else {
    }
    return (
      <div>
        <Grid container style={{ gap: "1REM", width: "100%" }}>
          <Grid item xs={12}>
            <Box
              bgcolor="primary.main"
              style={{ color: "white" }}
              height="4REM"
            >
              <div>
                <MaterialTable  style={{height:"44REM", overflow:"auto", whiteSpace:"nowrap"}}
                  columns={this.state.siteTable}
                  data={this.state.userList}
                  options={this.pageOptions}
                  title=""
                  onSelectionChange={(selectedRows) => {
                    this.handleThrottleSelectUsers(selectedRows);
                  }}
                ></MaterialTable>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default UsersInClinicsComponent;
