// Description: This file is resposible for rendering the poc log, autoupdate log and heartbeat extra log for a particular machine.
// Status: Ready

import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
import { compareDateTimes, utcToLocal} from "./HelperFunctions";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

class POCHeartBeatLastWeek extends React.Component {
  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      pageSizeOptions: [10, 20, 30],
      rowStyle: rowData => ({
        backgroundColor: (rowData.IsActive) ? '#EFE' : '#FEE'
      })
    };
    this.state = {
      LogsTable: [
        
        { title: "Site Id", field: "fhtHeartbeatHistorySiteId", hidden : true, width : 0},
        { title: "Site Name", field: "fhtSiteName" },
        { title: "Machine Id", field: "fhtHeartbeatHistoryMachineId" , hidden : true, width : 0},
        { title: "Machine Name", field: "fhtHeartbeatHistoryMachineName" },
        { title: "Version", field: "fhtHeartbeatHistoryVersionNumber" },
        { title: "Last Log", field: "fhtHeartbeatHistoryLogDate_convert",
        customSort : (a,b) => compareDateTimes(a.fhtHeartbeatHistoryLogDate, b.fhtHeartbeatHistoryLogDate) },
        { title: "Daily Log Count", field: "fhtHeartbeatHistoryDailyLogCount" },
        { title: "Daily NoRec Log Count", field: "fhtHeartbeatHistoryDailyNoRecLogCount" },

        
      ],
      Logdata: []
    }
  }

  renderPOCLog = () => {
    return (
      <MaterialTable
        columns={this.state.LogsTable}
        data={this.state.Logdata}
        options={this.pageOptions}
        title="POC Logs last week"
      ></MaterialTable>
    );
  };

  componentDidMount = async () => {
    const api = FetchApi("GetPOCHeartBeartLastWeek");
    const filterValue = Object.values(this.props.match.params)[0];
    let filterValueSplit = null;
    if (filterValue !== undefined) {
      filterValueSplit = filterValue.split(",");
    }
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      console.log(response);
      response.map(r=>{
        r.fhtHeartbeatHistoryLogDate_convert = utcToLocal(r.fhtHeartbeatHistoryLogDate);
      });
      this.setState({ Logdata: response })
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.center}>
          Green highlights the sites that has either daily log count or daily noRec log count more than one over the past week.
          <br/>
          If not, all rows of the site are marked as red(inactive).
        </div>
        <Grid container>
          {this.renderPOCLog()}
        </Grid>
      </div>

    );

  }
}

export default withStyles(useStyles)(POCHeartBeatLastWeek);