import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import FormGroup from '@material-ui/core/FormGroup';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Card } from "@material-ui/core";

class SiteGroupings extends Component {
  constructor(props) {
    super();   
    this.state = {   
      siteCheckedBoxArray: [],
      fhtSiteArrayData: [],
      siteGroupingName: "",
      siteGroupingUpdatedName: "",
      currentDropDownID: 0,
      renderUpdateButton: false,
      siteGroupingDropdownOptions: [],
      sites: [],
    }
    
    this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
    this.handleUpdateGroupNameChange = this.handleUpdateGroupNameChange.bind(this);
    this.handleDeleteSiteGrouping = this.handleDeleteSiteGrouping.bind(this);
    this.handleCheckBoxSiteChanges = this.handleCheckBoxSiteChanges.bind(this);
    this.handleSiteGroupingsDropdownChanges = this.handleSiteGroupingsDropdownChanges.bind(this);
    this.handleUpdatingSiteGroupings = this.handleUpdatingSiteGroupings.bind(this);
    this.handleNewSiteGroupingsSubmission = this.handleNewSiteGroupingsSubmission.bind(this);
    
  }

  componentDidMount() {
    this.getSiteGroupings(false);
    // The getSite API is integrated into this response to use the response data
    // immediately for setState.
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
        response = response.sort((a, b) => (a.fhtSiteName > b.fhtSiteName) ? 1 : -1)

        var storeSiteCheckedBoxUpdatesArray = []
        var siteCheckedBoxLocalVarArray = response
        for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
            siteCheckedBoxLocalVarArray[i]["Checked"] = false
            siteCheckedBoxLocalVarArray[i].key = i
            storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
        }
        this.setState({
            siteCheckedBoxArray: storeSiteCheckedBoxUpdatesArray,
            fhtSiteArrayData: response
        })
    });
  }

  handleGroupNameChange(e) {
    //Site groupings
    this.setState({
      siteGroupingName: e.target.value
    })
  }
  handleUpdateGroupNameChange(e) {
    //Site groupings
    this.setState({
      siteGroupingUpdatedName: e.target.value
    })
  }
  handleNewSiteGroupingsSubmission(event) {
    //Site groupings
    event.preventDefault();
    var arrayToSendData = []
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray

    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
      if (siteCheckedBoxLocalVarArray[i]["Checked"] == true) {
        // This is to "remove" Checked and key value pairs.
        // Delete alters the entire array state, not local variable.
        arrayToSendData.push({
            FhtSiteId: siteCheckedBoxLocalVarArray[i].fhtSiteId,
            FhtSiteName: siteCheckedBoxLocalVarArray[i].fhtSiteName,
            FhtSiteState: siteCheckedBoxLocalVarArray[i].fhtSiteState,
            FhtAccountId: siteCheckedBoxLocalVarArray[i].fhtAccountId,
            FhtSiteTerminalServer: siteCheckedBoxLocalVarArray[i].fhtSiteTerminalServer,
        })
      }
    }

    const api = FetchApi(Configs.api.saveNewSiteGroupings);
    let body = {
      "NewGroupingSiteIDs": arrayToSendData,
      "SiteGroupingName": this.state.siteGroupingName,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };

    api.post(body).then((response) => {
      if (response.unauthorized != undefined && response.unauthorized == 401) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.unauthorised,
          Configs.snackbarVariants.error
        );
      } else if (response.status == 400) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.duplicateSiteGroupingName,
            Configs.snackbarVariants.error
        );
      } else {
        // Pass value to determine when siteGroupings is being called 
        // (i.e initialisation, or after creating/updating values)
        // Only call when successful
        this.getSiteGroupings(true)
        this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.successfulCreateNewSiteGroup,
            Configs.snackbarVariants.success
        );
      }
    })
    .catch(err => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.failedCreateNewSiteGroup,
            Configs.snackbarVariants.error
        );
    });
  }
  handleUpdatingSiteGroupings(event) {
    //Site groupings
    event.preventDefault();
    var arrayToSendData = []
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray
    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
        if (siteCheckedBoxLocalVarArray[i]["Checked"] == true) {
            // This is to "remove" Checked and key value pairs.
            // Delete alters the entire array state, not local variable.
            arrayToSendData.push({
                FhtSiteId: siteCheckedBoxLocalVarArray[i].fhtSiteId,
                FhtSiteName: siteCheckedBoxLocalVarArray[i].fhtSiteName,
                FhtSiteState: siteCheckedBoxLocalVarArray[i].fhtSiteState,
                FhtAccountId: siteCheckedBoxLocalVarArray[i].fhtAccountId,
                FhtSiteTerminalServer: siteCheckedBoxLocalVarArray[i].fhtSiteTerminalServer,
            })
        }
    }

    const api = FetchApi(Configs.api.updateSiteGroupings);
    let body = {
        "NewGroupingSiteIDs": arrayToSendData,
        "SiteGroupingName": this.state.siteGroupingDropdownOptions[this.state.currentDropDownID].text,
        "SiteGroupingUpdatedName": this.state.siteGroupingUpdatedName,
    };

    api.post(body).then((response) => {
      this.getSiteGroupings(false);    
      if (response.unauthorized != undefined && response.unauthorized == 401) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.unauthorised,
          Configs.snackbarVariants.error
        );
      } else if (response.status == 400) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.duplicateSiteGroupingName,
          Configs.snackbarVariants.error
        );
      } else {      
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
      }
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });
  }
  handleDeleteSiteGrouping(event) {
    //Site groupings
    event.preventDefault();
    const api = FetchApi(Configs.api.deleteSiteGrouping);
    let body = {
      "SiteGroupingName": this.state.siteGroupingDropdownOptions[this.state.currentDropDownID].text,
    };

    api.post(body).then((response) => {
      // After deletion, refresh siteGroupings and reset currentDropDownID back to create site groupings.
      this.getSiteGroupings(false);    
      this.setState({
        currentDropDownID: 0,
        renderUpdateButton: false,
        siteGroupingName: "",
      })
      this.setCheckBoxSiteChangeDeactive();
      if (response.unauthorized != undefined && response.unauthorized == 401) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.unauthorised,
          Configs.snackbarVariants.error
        );
      } else if (response.status == 400) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.duplicateSiteGroupingName,
          Configs.snackbarVariants.error
        );
      } else {      
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
      }
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateFail,
        Configs.snackbarVariants.error
      );
    });
  }

  handleSiteGroupingsDropdownChanges(e) {
    //site groupings
    var currentlySelectedID = 0;
    for (var i = 0; i < this.state.siteGroupingDropdownOptions.length; i++) {
      if (this.state.siteGroupingDropdownOptions[i]["key"] == e.target.value["key"]) {
        currentlySelectedID = i
        this.setState({
            currentDropDownID: i
        })
        break
      }
    }
    if (e.target.value["key"] == "0") {
      this.setState({
        renderUpdateButton: false,
      })
    } else {
      this.setState({
        renderUpdateButton: true
      })
    }

    // Erase any existing checks
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray
    var storeSiteCheckedBoxUpdatesArray = []
    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
      siteCheckedBoxLocalVarArray[i]["Checked"] = false
      storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
    }
    this.setState({
      siteCheckedBoxArray: storeSiteCheckedBoxUpdatesArray
    })

    // Set checks to True for specific sites.
    var chosenSiteIDs = JSON.parse(e.target.value["siteIDs"])
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray
    var storeSiteCheckedBoxUpdatesArray = []
    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
      var resultInDictionary = false
      for (var j = 0; j < chosenSiteIDs.length; j++) {
        if (siteCheckedBoxLocalVarArray[i].fhtSiteId == chosenSiteIDs[j]["fhtSiteGroupingsSiteID"]) {
            siteCheckedBoxLocalVarArray[i]["Checked"] = true
            storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
            resultInDictionary = true
        }
      }
      if (resultInDictionary == false) {
        storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
      }
    }
    
    this.setState({
      siteCheckedBoxArray: storeSiteCheckedBoxUpdatesArray
    })

    if (e.target.value["key"] == "0") {
      this.setState({
        siteGroupingName: "",
      })
    } else {
      var selectedSiteSiteName = this.state.siteGroupingDropdownOptions[currentlySelectedID].text;
      this.setState({
        siteGroupingName: selectedSiteSiteName,
        siteGroupingUpdatedName: selectedSiteSiteName
      })
    }
  } 

  handleCheckBoxSiteChanges(e) {
    //Site groupings
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray
    var storeSiteCheckedBoxUpdatesArray = []

    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
      if (siteCheckedBoxLocalVarArray[i].fhtSiteId == e.target.id) {
        if (siteCheckedBoxLocalVarArray[i]["Checked"] == true) {
            siteCheckedBoxLocalVarArray[i]["Checked"] = false
            storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
        } else {
            siteCheckedBoxLocalVarArray[i]["Checked"] = true
            storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
        }
      } else {
        storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
      }
    }

    this.setState({
      siteCheckedBoxArray: storeSiteCheckedBoxUpdatesArray
    })
  }

  setCheckBoxSiteChangeDeactive() {
    var siteCheckedBoxLocalVarArray = this.state.siteCheckedBoxArray
    var storeSiteCheckedBoxUpdatesArray = []

    for (var i = 0; i < siteCheckedBoxLocalVarArray.length; i++) {
      siteCheckedBoxLocalVarArray[i]["Checked"] = false
      storeSiteCheckedBoxUpdatesArray.push(siteCheckedBoxLocalVarArray[i])
    }

    this.setState({
      siteCheckedBoxArray: storeSiteCheckedBoxUpdatesArray
    })
  }

  getSiteGroupings(refreshDataRequired) {
    // site grouping
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      console.log("getSiteGroupings", response);
      this.setState({
          siteGroupingDropdownOptions: response, //Module map , site Algo, site groupings
      })

      if (refreshDataRequired == true) {
        var currentlySelectedSiteName = response[response.length - 1].text
        this.setState({
          currentDropDownID: response.length - 1,
          renderUpdateButton: true,
          siteGroupingUpdatedName: currentlySelectedSiteName
        })
      }
    });
  }
  getSite = async() =>{
    //softwqare mapiing, site grouping, machineOnsite
    console.log("Fetching sites...")
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then(response =>{
      this.setState({sites : response});
      console.log("sites", response);
    })
  }

  renderUpdateOrSaveSiteGroupings() {
    //site groupiings
    if (this.state.renderUpdateButton == false) {
      return (
        <div>
          <TextField
            value={this.state.siteGroupingName}
            required
            label="Grouping name"
            onChange={this.handleGroupNameChange}
          /> <br /><br />
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNewSiteGroupingsSubmission}
          >
            Create new site groupings
          </Button><br /><br />
        </div>
      )
    } else {
      return (
        <div>
          <TextField
            value={this.state.siteGroupingUpdatedName}
            helperText="You can update the site grouping name here."
            onChange={this.handleUpdateGroupNameChange}
          /> <br /><br />
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleUpdatingSiteGroupings}
          >
            Update this site groupings
          </Button> {" "}
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleDeleteSiteGrouping}
          >
            Delete this site grouping
          </Button><br /><br />
        </div>
      )
    }
  }

  renderSiteGroupings = () => {
    //Site groupngs
    const { classes } = this.props;
    console.log("renderSiteGRoupings")
    return(
        <div style={{ textAlign: "center" }}>
            <h3>Site Groupings</h3>
            <Grid container justify="center">
              <Card style = {{width: "90%"}}>
                  <br />
                  <FormControl 
                    style = {{width: "90%",    
                              marginBottom: 20,
                              textAlign: "left"
                            }} 
                    variant="outlined"
                  >
                    <InputLabel>Grouping Selection</InputLabel>
                    <Select
                        value={this.state.siteGroupingDropdownOptions[this.state.currentDropDownID] || ''}
                        onChange={this.handleSiteGroupingsDropdownChanges}
                        label = "Grouping Selection"
                    >
                        {this.state.siteGroupingDropdownOptions.map((s) => (
                            <MenuItem
                                key={s["key"]}
                                value={s}
                            >
                                {s["text"]}
                            </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl component="fieldset">
                      <FormGroup>
                          <Grid container style={{ padding: "2.5REM" }}>
                              {this.state.siteCheckedBoxArray.map((s) => (
                                  <Grid key={s.key} item xs={3}>
                                      <FormControlLabel
                                          control={<Checkbox
                                              checked={s["Checked"]}
                                              onChange={this.handleCheckBoxSiteChanges}
                                              name={s["fhtSiteName"]}
                                              id={s["fhtSiteId"].toString()}
                                          />}
                                          label={
                                              <p style={{ wordWrap: "break-word", width: "8REM" }}>{s["fhtSiteName"]}</p>
                                          }
                                      />
                                  </Grid>
                              ))}
                          </Grid>
                      </FormGroup>
                  </FormControl>
                  {this.renderUpdateOrSaveSiteGroupings()}
              </Card>              
            </Grid>
        </div>
  )}

  render() {
    return (
      <div>
        <br />
        {this.renderSiteGroupings()}<br />
      </div>
    )
  }
}

export default SiteGroupings;