import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10%"
      }
      }>
        <CircularProgress />
        Loading...
      </div >
    );
  }
}
export default LoadingRender;
