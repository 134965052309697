import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import MaterialTable from "material-table";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  utcToLocal,
  compareDateTimes,
  backgroundColourFromLetter,
} from "../HelperFunctions";
import { Card } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CDialog from "Component/CDialog";
import FolderShared from "@material-ui/icons/FolderShared";

class DrqQueryToSite extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedSites: [],
      selectedQuery:-1,
      DRQqueryList: [],
      saveButtonDisabled: true,
      displayConfirmQuery: false,
    };
    //Binds an async callback to the sitegrouping component
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(
      this
    );
    //initial loading of DRQ query list
    this.LoadDRQlist();
  }

  //For the callbacks, the moment a site is selected, there are two types of data to retrieve
  //1) The users & cohorts that exist for a site
  //2) The users & cohorts that are mapped with each other
  //All users retrieved initially are always active users
  //All cohorts retrieved initially are not mapped until the user ticks the user list.
  SharedComponentSiteGroupingCallback = (selectedSites) => {
    this.setState(
      {
        selectedSites: selectedSites,
      },
      () => {
        //update other components as needed
      }
    );
    console.log("Callback received from site grouping:", selectedSites);
  };
  LoadDRQlist = () => {
    const api = FetchApi("GetDRQQueryList");
    api
      .get()
      .then((response) => {
        console.log("DRQ list received: ", response);
        this.setState({ DRQqueryList: response });
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Retrieve query list failed",
          Configs.snackbarVariants.error
        );
      });
  };

  onBtnSaveQueryToSiteMapping = () => {
    console.log("Save query to site mapping");
    let DRQSiteList = [];
    this.state.selectedSites.forEach((site)=>{
      DRQSiteList.push(site.fhtSiteId);
    });
    
    let body = {
      "SiteIdList":DRQSiteList,
      "QueryId":this.state.selectedQuery
    };
    const api = FetchApi("CreateDRQQueryToSiteMapping");
    api
      .post(body)
      .then(response => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Sites mapped to query successfully',
          Configs.snackbarVariants.success
        );
        this.setState({ displayConfirmQuery: false });
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
    });
      
  };

  radioQueryClicked = (e,drqItem) =>{
    this.setState({selectedQuery:drqItem.drqQueryId});
  }
  handleUpdateApplyToCohortsSimilarTrials() {
    var currentApplyToCohortsSimilarTrials = this.state
      .applyToCohortsSimilarTrials;
    this.setState({
      applyToCohortsSimilarTrials: !currentApplyToCohortsSimilarTrials,
    });
  }

  onLayoutChange = () => {
    console.log("Layout has changed");
  };
  render() {
    let mediumSize = 3;

    return (
      <Grid container justify="center">
        <div style={{ marginLeft: "1REM", marginRight: "1REM", width: "90%" }}>
          <Grid container>
            <Grid item xs={12} style={{ height: "6REM" }}>
              {" "}
              <br />
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                Assign query to clinic(s)
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ gap: "5REM", width: "120%" }}>
            <Grid item md={mediumSize} xs={12} spacing={1}>
              <h4>1) Select query</h4>
            </Grid>
            <Grid item md={mediumSize} xs={12}>
              <h4>2) Select site(s)</h4>
            </Grid>
            <Grid item md={mediumSize} xs={12}>
              <h4>3) Run the query</h4>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ gap: "5REM", height: "90REM", width: "120%" }}
          >
            <Grid item md={mediumSize} xs={12}>
              <div>
              <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="None"
        name="radio-buttons-group"
        value={this.state.selectedQuery}
      >
        {this.state.DRQqueryList.map((drqItem) => {
          return (
            <FormControlLabel
              value={drqItem.drqQueryId}
              control={<Radio />}
              label={drqItem.drqQueryName}
              onClick={e => this.radioQueryClicked(e,drqItem)}
            />
          );
        })}
      </RadioGroup>
              </div>
            </Grid>
            <Grid item md={mediumSize} xs={12}>
              <div>
                <SelectSitesGrouping
                  SharedComponentSiteGroupingCallback={
                    this.SharedComponentSiteGroupingCallback
                  }
                />
              </div>
            </Grid>
            <Grid item md={mediumSize} xs={12}>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.state.selectedQuery== -1 || this.state.selectedSites.length == 0}
                  onClick={() => this.setState({ displayConfirmQuery: true })}
                >
                  Execute query on selected sites
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <CDialog
          header="Execute Query"
          headerIcon={<FolderShared />}
          visible={this.state.displayConfirmQuery}
          footer={
            <Button
              variant="contained"
              fullWidth
              style={{ margin: "24px 0px 16px" }}
              color="primary"
              onClick={(e) => this.onBtnSaveQueryToSiteMapping()}
            >
              Run query on selected sites
            </Button>
          }
          onSubmit={(e) => this.onBtnSaveQueryToSiteMapping()}
          onHide={(e) => this.setState({ displayConfirmQuery: false })}
          maxWidth="xs"
        >
          Please confirm - execute this query on {this.state.selectedSites.length} site(s)
        </CDialog>
      </Grid>
    );
  }
}

export default DrqQueryToSite;
