// Description: This file is responsible for rendering the POC/SC4C/webapi summary page.
// Status: Ready

import React, { Component } from "react";
import Box from '@material-ui/core/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../Service/Configuration";
import Dialog from "@material-ui/core/Dialog";
import FetchApi from "../Service/FetchApi";
import MaterialTable from "material-table";
import Tab from '@material-ui/core//Tab';
import Tabs from '@material-ui/core//Tabs';

const styling = {
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
};

class CustomDetailPanel extends Component{
  //Drop down of machine by user for each site
  constructor(props) {
    super();
    
    this.state={      
      SC4COrPOCDetailPanelData: window.location.href.includes("POC") === true
        ? "FHTPOC"
        : "SC4C",
      siteSummaryData : {},
      siteSummaryCol :[
        /*0*/{ title: "User", field: "userName" },
        /*1*/{ title: "MachineName", field :"machineName"},
        /*2*/{ title: "Server/client", field:"serverClient"},
        /*3*/{ title: "Popup Today", field: "dailyPopup" },
        /*4*/{ title: "Popup This Week", field: "weeklyPopup" },
        /*4*/{ title: "Daily no recommendation count", field: "dailyNoRecCount" },
        /*5*/{ title: "Last User Heartbeat", field: "lastLog", type:"datetime"},
        /*6*/{ title: "Last Machine Autoupdater Heartbeat", field: "autoUpdaterLastLog", type:"datetime"},
        /*7*/{ title: "POC Version", field: "version"},
        /*8*/{title: "API Status", field: "apiStat"},
        /*9*/{title: "SSL Certificate", field: "sslCert"},
        /*10*/{title: "Http Connection", field: "httpStat"}
      ],

      isLoaded : false,
      machineDetailDialogIsOpen : false,
    };
    
    this.secondaryPageOptions = {
      search: true,
      grouping: true,
      exportButton: true,
      exportAllData: true,
      filtering: true,
      paging : false,
      headerStyle: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        backgroundColor: '#EEE',
      },
      
    };
    
  }

  componentDidMount = async () => {
    this.getSitePOCSummary(this.props.siteId);
  }

  getSitePOCSummary = async(siteId) =>{
    console.log("fetching site", siteId);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };

    const apiPOC = FetchApi(Configs.api.getSystemSummary + "/" + this.state.SC4COrPOCDetailPanelData + "/Site/" + siteId); //FHTPOC
    await apiPOC.post(body).then(response => {
      this.setState({siteSummaryData : response, isLoaded : true});
      console.log("Response poc", response);
    }).catch(()=>{
      console.log("Error when fetching site " + siteId);
    })
  
  }

  
  
  render(){
    if(!this.state.isLoaded){
      return(
        <div> 
          <CircularProgress />
          Loading... 
        </div>
      )
    }else{
      return(
        <div>
          <MaterialTable
          title = {this.props.siteName}
          columns = {this.state.siteSummaryCol}
          data = {this.state.siteSummaryData}
          options = {this.secondaryPageOptions}
          style = {{backgroundColor: '#EEE'}}
          actions={[{
            icon: 'search',
            tooltip: 'View row data',
            onClick: (event, rowData) => {
              this.setState({
                machineDetailDialogIsOpen:true,
                machineDetailDialogData:rowData
              })
            }
          }]}
          />
          {this.renderDialog()}
        </div>
        
      )
    }
  }

  renderDialog = ()=>{
    return(
      <Dialog
          open ={this.state.machineDetailDialogIsOpen}
          onBackdropClick = {()=>this.setState({machineDetailDialogIsOpen : false})}
          >
            
            {(this.state.machineDetailDialogIsOpen) ? 
              ( 
                <div>
                  User : {this.state.machineDetailDialogData.userName}
                  <br/>
                  MachineName : {this.state.machineDetailDialogData.machineName}
                  <br/>
                  Server/client : {this.state.machineDetailDialogData.systemId}
                  <br/>
                  Popup Today : {this.state.machineDetailDialogData.dailyPopup}
                  <br/>
                  Popup This Week : {this.state.machineDetailDialogData.weeklyPopup}
                  <br/>
                  PoC Heartbeat Last Received : {this.state.machineDetailDialogData.lastLog}
                  <br/>
                  Autoupdater Heartbeat Last Received : {this.state.machineDetailDialogData.autoUpdaterLastLog}
                  <br/>
                  SoftwareVersionTitle : {this.state.machineDetailDialogData.version}
                  <br/>
                  API Status : {this.state.machineDetailDialogData.apiStat}
                  <br/>
                  SSL Certificate {this.state.machineDetailDialogData.sslCert}
                  <br/>
                  Http Connection : {this.state.machineDetailDialogData.httpStat}
                </div>
              ):(
                <div></div>
              )
            }
          </Dialog>
    )
  }

}

class POCSummary extends Component {
  constructor(props) {

    super();
    this.propKeyword={
      status:["active", "fail"],
    }
    this.summaryMode = {
      BY_SITE : 0,
      BY_VERSION : 1,
    }

    this.state = {
      SC4COrPOCData: window.location.href.includes("POC") === true
      ? "FHTPOC"
      : "SC4C",
      summaryData : null,
      siteSummaryData : {},
      summaryCol : [
        /*0*/{ title: "Site Id",         field: "siteId", wdith:0, hidden:true},
        /*1*/{ title: "Site Name",       field: "siteName"},
        /*2*/{ title : "Status",         field: "status",
          defaultFilter :  props.match.params.Status},
        /*3*/{ title: "Unique Users",    field: "uniqueUsers" },
        /*4*/{ title: "Popup Today",     field: "popups.Item1" },
        /*5*/{ title: "Popup This Week", field: "popups.Item2"},
        /*5*/{ title: "Daily no recommendation count", field: "popups.Item4"},
        /*6*/{ title: "Last Site Heartbeat ", field: "popups.Item3", type:"datetime"},
      ],
      summaryAllCol:[
        /*0*/{ title: "Site Id",         field: "siteId", wdith:0, hidden:true},
        /*1*/{ title: "Site Name",       field: "siteName"},
        /*2*/{ title: "MachineName", field :"machineName"},
        /*3*/{ title: "Version", field: "version", 
          defaultFilter    : props.match.params.Number},
        /*4*/{ title: "Server/client", field:"serverClient",},
        /*5*/{ title: "Popup Today", field: "dailyPopup" },
        /*6*/{ title: "Popup This Week", field: "weeklyPopup" },
        { title: "Daily no recommendation count", field: "dailyNoRecCount" },
        /*7*/{ title: "Last Machine Heartbeat", field: "lastLog", type:"datetime"},
        /*8*/{ title: "Last Machine Autoupdater Heartbeat", field: "autoUpdaterLastLog", type:"datetime"},
        /*9*/{title: "API Status", field: "apiStat"},
        /*10*/{title: "SSL Certificate", field: "sslCert"},
        /*11*/{title: "Http Connection", field: "httpStat"},
        
      ],

      machineDetailDialogIsOpen : false,
      machineDetailDialogData : null,
      isLoaded: false,
      selectedRow:-1,
      fetchingSiteQue:[],
      summaryMode : this.selectInitialMode(props.match),
    };

    this.pageOptions = {
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      grouping: true,
      exportButton: true,
      exportAllData: true,
      filtering: true,
      pageSize: 10,
      pageSizeOptions: [10, 30, 100],
      headerStyle: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      rowStyle: rowData => ({
        backgroundColor: (this.state.selectedRow === rowData.tableData.id) ? '#009688' : '#FFF'
      })
    };

    this.secondaryPageOptions = { //For dropdown tables
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      grouping: true,
      exportButton: true,
      exportAllData: true,
      filtering: true,
      pageSize: 5,
      pageSizeOptions: [5, 10, 20],
      headerStyle: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      rowStyle: {
        backgroundColor: '#EEE',
      },
    };

    
  }

  selectInitialMode = (match)=>{
    if(match.params.Number != null) return this.summaryMode.BY_VERSION;
    return this.summaryMode.BY_SITE;
        
  }

  componentDidMount = async () => {
    this.handleSiteModeChange(null, this.state.summaryMode);
    
  };

  getSummaryAll = () =>{
    console.log("Fetching all summary");
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    
    var api = FetchApi(Configs.api.getSystemSummaryAll + "/" + this.state.SC4COrPOCData);
    api.post(body).then((response)=>{
      console.log("by version",response);
      this.setState({
        summaryData : response,
        isLoaded : true,
      });
    });
    
  }



  getSummaryBySite = async() =>{
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    
    var api = FetchApi(Configs.api.getSystemSummary + "/" + this.state.SC4COrPOCData); //FHTPOC
    await api.post(body).then(response => {
      this.setState({
        summaryData : response,
        isLoaded:true
      });
      console.log("Response FHTPOC", response);
    })
    
  }

  

  renderSummaryByVersion = ()=>{
    if(this.state.isLoaded){
      return(
        <MaterialTable
          columns={this.state.summaryAllCol}
          data={this.state.summaryData}
          options={this.pageOptions}
          title={"POC Summary"}
        ></MaterialTable>
      )
    }else{
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }

  renderSummaryBySite = ()=>{
    if(this.state.isLoaded){
      return(
        <MaterialTable
            columns={this.state.summaryCol}
            data={this.state.summaryData}
            options={this.pageOptions}
            title={"POC Summary"}

            onRowClick={(event, rowData, togglePanel) => {
              this.setState({selectedRow : rowData.tableData.id});
              togglePanel()
            }}
            
            detailPanel={[{
              tooltip : "See site summary",
              render : (rowData) =>{
                return(
                  <CustomDetailPanel
                    siteName = {rowData.siteName}
                    siteId = {rowData.siteId}
                  ></CustomDetailPanel>
                )
              }
            }]}
          />
      )
    }else{
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }

  renderStatusDescription = ()=>{
    return(
      <div>
        *Status: A Site/PC is active if there is at least one user logged on
        within a week
      </div>
    )
  }

  handleSiteModeChange = (event, newVal) =>{
    this.setState({
      summaryMode : newVal,
      isLoaded : false
    })
    switch(newVal){
      case this.summaryMode.BY_SITE:
        this.getSummaryBySite();
        break;
      case this.summaryMode.BY_VERSION:
        var newCol = this.state.summaryAllCol;
        if(this.props.match.params.Number == null) newCol[3].defaultGroupOrder = 0; //Version
        this.setState({summaryAllCol : newCol});
        this.getSummaryAll();
        break;
    }
  }

  render() {
    const {
      isLoaded,
    } = this.state;
    if (isLoaded) {
      return (
        <div>
          <div style={styling.center}>
            {this.renderStatusDescription()}
          </div>
          <Box >
            <Tabs 
            value = {this.state.summaryMode} 
            onChange={this.handleSiteModeChange}
            indicatorColor= "primary"
            >
              <Tab label="By site" value={this.summaryMode.BY_SITE} />
              <Tab label="By version" value={this.summaryMode.BY_VERSION} />
            </Tabs>
          </Box>
          {{//Choose page to render
            0 : this.renderSummaryBySite()
            ,
            1 : this.renderSummaryByVersion()
            
          }[this.state.summaryMode]}
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }

  }
}

export default POCSummary;
