import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";

class Accounts extends Component {
  constructor(props) {
    super();   
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.state = { 
      accountTable: [
        { title: "Account Id", field: "id" },
        { title: "Username", field: "userName" },
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "firstName" },
      ],
      accounts: [],
    }
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () => {
    //Accounts
    const api = FetchApi(Configs.api.getAccounts);
    api.get().then((response) => {
      this.setState({accounts: response});
    });
  }

  renderAccount = () => {
    // account
    const { classes } = this.props;
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Accounts</h3></Grid>
        <br/>
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns={this.state.accountTable}
            data={this.state.accounts}
            options={this.pageOptions}
            title=""
          ></MaterialTable>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderAccount()} <br/>
      </div>
    )
  }
}

export default Accounts;