// Copied and pasted from FHTHome, removing some cards that are specific to FHT.

import React, { Component } from "react";
import {
  Card,
  Divider,
} from "@material-ui/core";

import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
});

class TorchSummaryStatisticCard extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  renderStatisticCardIcon() {
    if (this.props.summaryCardIcon == "totalSites") {
      return (
        <EmojiTransportationIcon color = {"primary"} fontSize = {"inherit"}/>
      )
    } else if (this.props.summaryCardIcon == "totalPatients") {
      return (
        <AccountBoxIcon color = {"primary"} fontSize = {"inherit"}/>
      )
    } else if (this.props.summaryCardIcon == "activeTrials") {
      return (
        <HourglassEmptyIcon color = {"primary"} fontSize = {"inherit"}/>
      )
    } else if (this.props.summaryCardIcon == "activeRecruitments") {
      return (
        <QuestionAnswerIcon color = {"primary"} fontSize = {"inherit"}/>
      )
    }    
  }

  render() {
    return (
      <div style = {{
        position: "relative", 
        // Height needs to be hard coded due to parent div not having a height.
        height: "11REM", 
        width: "100%",
        paddingRight: "2REM"
      }}>
        <Card style = {{
          // Position of background around image
          zIndex: 1, 
          position: "absolute",
          left: "-1.5REM", 

          // Dimensions of background
          height: "4.5REM", 
          width: "4.5REM", 
          backgroundColor: this.props.summaryCardIconBackgroundColour, 
          fontSize: 48,

          // Centering the image
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <ThemeProvider theme={theme}>
            {this.renderStatisticCardIcon()}
          </ThemeProvider>
        </Card>
        <Card style = {{
          flex: 1,
          width: "100%", 
          bottom: 0, right: 0, 
          position: "absolute",
          padding: "1REM"
        }}>
          <div>
            <div style = {{
              display: 'flex', 
              justifyContent: 'flex-end'
            }}>
              <div style = {{
                padding: "0.1REM", 
                textAlign: "right",
                fontSize: 18,
                // Allows for text to wrap when screen gets smaller.
                width: "80%"
              }}>
                {this.props.summaryCardTitle}
              </div>
            </div>
            <div style = {{
              padding: "0.1REM", 
              textAlign: "right",
              fontSize: 24,
              fontWeight: 'bold'
            }}>
              {this.props.summaryCardNumber}
            </div>
            <br/><Divider /><br/>
            <div style = {{
              padding: "0.1REM", 
              textAlign: "left",
              fontSize: 18
            }}>
              {this.props.summaryCardImprovementNumberColor == "-ve"?
              <a style = {{
                color: "red" ,
                fontWeight: 'bold'
              }}>
                -{this.props.summaryCardImprovementNumber}
              </a>:
              this.props.summaryCardImprovementNumberColor == "+ve"?
              <a style = {{
                color: "green", 
                fontWeight: 'bold'
              }}>
                +{this.props.summaryCardImprovementNumber}
              </a>:
              <a style = {{
                color: "grey", 
                fontWeight: 'bold'
              }}>
                {this.props.summaryCardImprovementNumber}
              </a>} since last week
            </div>        
          </div>
        </Card>
      </div>
    );
  }
}

export default TorchSummaryStatisticCard;
