// Copied and pasted from FHTHome, removing some cards that are specific to FHT.

import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import FHTSummarySiteOverview from "./FHTDashboardComponents/FHTSummarySiteOverview";
import FHTSummaryStatisticCard from "./FHTDashboardComponents/FHTSummaryStatisticCard";
import FHTSummarySoftwareStatus from "./FHTDashboardComponents/FHTSummarySoftwareStatus";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import {
  utcToAESTLocal,
  changeGraphDataDateToAEST,
  utcToLocal
} from "../HelperFunctions";
import SelectSiteGroupingsSelectionOnly from "../SharedComponents/SelectSiteGroupingsSelectionOnly";
import { RecommendationQueries } from "../summaryCards";

class FHTHomeDashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      heartbeats: props.heartbeats,
      allTests: props.allTests,
      localVersion: props.localVersion,
      serverSummary: props.serverSummary,
      latestFhtLog: props.latestFhtLog,
      serverHistory: [],
      numDayUsers: 0,
      numWeekUsers: 0,
      numUniqueUsers: 0,
      POCActiveNum: 0,
      POCActive: [],
      isLoaded: false,
      numActiveALG: 0,
      numALGRecords: 0,
      loadingMessage: "Setting up FHT...",
      algorithmSummaryGraphDataList: [],
      apiSummaryGraphDataList: [],
      pocSummaryGraphDataList: [],
      siteOverviewSummaryGraphDataList: [],
      siteOverviewUptimeData: [],
      POCSummary: null,
      recommendationQuery: [],

      selectedSiteGroupings: 0,
      selectedSiteIDs: [],
      isLoadingDashboard: true,
      FHTInfo: {}
    };
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.SharedComponentSelectedSiteGroupingIDCallback = this.SharedComponentSelectedSiteGroupingIDCallback.bind(this);
  }

  SharedComponentSiteGroupingCallback(siteGroupingsSiteIDs) {
    this.setState({
      selectedSiteIDs: siteGroupingsSiteIDs
    })
    this.getAllDashboardData();
  }

  SharedComponentSelectedSiteGroupingIDCallback(siteGroupingID) {
    this.setState({
      selectedSiteGroupings: siteGroupingID
    })
  }

  getPOCDeactiveNum = () => {
    const api = FetchApi(Configs.api.getNumPOCDeactivatedSites)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      console.log("deactiveNum", response);
      this.setState({
        numPOCDeactivatedSites: response.numDeactiveSites,
        numPOCConfigParsingIssue: response.numPOCConfigIssues,
        siteIDsWithPOCConfigIssues: response.siteIDsWithPOCConfigIssues,
        siteIDsThatAreDeactivedInPOCConfig: response.siteIDsThatAreDeactivated
      });
    })
  }

  getAllDashboardData = async () => {
    console.log("getAllDashboardData")
    this.setState({
      isLoadingDashboard: true
    })

    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      SelectedSiteIDs: this.state.selectedSiteIDs
    }
    // Query the graphing data first due to time it takes to obtain data.
    const api6 = FetchApi(Configs.api.getALGAPIPOCSiteOverviewHistoricalGraphData);
    await api6.post(body).then((response) => {
      var reformattedALGData = changeGraphDataDateToAEST(response.algorithmSummaryGraphDataList);
      var reformattedAPIData = changeGraphDataDateToAEST(response.apiSummaryGraphDataList);
      var reformattedPOCData = changeGraphDataDateToAEST(response.pocSummaryGraphDataList);
      var reformattedSiteOverviewData = changeGraphDataDateToAEST(response.siteOverviewSummaryGraphDataList);

      var siteOverviewUptimeData = response.siteOverviewGraphDataUptimeToday;
      if (siteOverviewUptimeData == null)
        siteOverviewUptimeData = {
          "Active Sites": 0,
          "Date": "N/A",
          "Inactive Site": 0
        }

      this.setState({
        algorithmSummaryGraphDataList: reformattedALGData,
        apiSummaryGraphDataList: reformattedAPIData,
        siteOverviewSummaryGraphDataList: reformattedSiteOverviewData,
        siteOverviewUptimeData: siteOverviewUptimeData,
        pocSummaryGraphDataList: reformattedPOCData,
      })
    });

    const apiPOCSummary = FetchApi("GetPOCSummaryRefactor");
    apiPOCSummary.post(body).then(response => {
      console.log("GetPOCSummaryRefactor response", response);
      this.setState({ POCSummary: response });
    })

    const api2 = FetchApi(Configs.api.getSummaryWebAPIData);
    api2.post(body).then((response) => {
      this.setState({
        APIVer: response.apiVersionCount,
        numAPISites: response.totalSites,
        numAPIAlert: response.totalInactiveSites,
      })
    })

    const api3 = FetchApi(Configs.api.getSummaryALGData);
    api3.post(body).then((response) => {
      console.log("getSummaryALGData response", response)
      this.setState({
        ALGVer: response.algorithmVersionCount,
        numALGRecords: response.maxLocalVersionAlgList,
        numActiveALG: response.activeSitesList.length
      })
    })

    const api5 = FetchApi(Configs.api.getAlgorithmDescriptions);
    api5.post(body).then((response) => {
      this.setState({
        ALGTestsInfo: response.fhtRecommendationDescriptions,
      });
    });

    const api4 = FetchApi(Configs.api.getSiteSummaryData);
    api4.post(body).then((response) => {
      this.setState({
        FHTInfo: response.fhtInfo,
      })
    })
    setTimeout(() => {
      console.log("this.state.FHTInfo", this.state.FHTInfo)
      this.setState({
        isLoaded: true,
        isLoadingDashboard: false
      })
    }, 250)
  }

  componentDidMount = async () => {
    this.getAllDashboardData();
    this.getRecommendationQuery();
  };

  getRecommendationQuery = async () => {
    const api = FetchApi(Configs.api.getRecommendationQuery);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      response.map((r) => {
        r.fhtLodgeRecommendationAESTDate = utcToLocal(r.fhtLodgeRecommendationDate);
      });
      this.setState({
        recommendationQuery: response.sort((a, b) => (a.fhtLodgeRecommendationID < b.fhtLodgeRecommendationID) ? 1 : -1),
      });
      console.log("recommendation", response);
    });
  };

  changeToRecommendationQueryPageFromSummaryCard(event, fhtLodgeRecommendationDate) {
    this.handleMenuClick(event, 4)
    this.lodgeRecommendationPageOptions.searchText = fhtLodgeRecommendationDate
    window.scrollTo({
      top: 0,
    });
  }

  render() {
    const {
      numUniqueUsers,
      numDayUsers,
      numWeekUsers,
      POCActive,
      POCActiveNum,
      POCVer,
      APIVer,
      numAPISites,
      numAPIAlert,
      ALGTestsInfo,
      numActiveALG,
      numALGRecords,
      ALGVer,
      FHTInfo,
      loadingMessage,
      latestFhtLog,
      isLoaded,
      POCSummary,
    } = this.state;

    if (isLoaded && APIVer !== undefined && ALGVer !== undefined) {
      return (
        <div style={{ position: "relative", top: "-4REM" }}>
          <h1
            // Required when graphs are enabled.
            style={{ paddingBottom: "2.5REM" }}
          >
            FHT Dashboard
            <SelectSiteGroupingsSelectionOnly
              selectedSiteGroupings={this.state.selectedSiteGroupings}
              SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
              SharedComponentSelectedSiteGroupingIDCallback={this.SharedComponentSelectedSiteGroupingIDCallback}
            />
          </h1>
          {/* Hide summary cards for now */}
          {/* <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={3} xs={6}>
              <FHTSummaryStatisticCard
                summaryCardTitle={"Total trial sites"}
                summaryCardNumber={"85"}
                summaryCardImprovementNumber={"20"}
                summaryCardImprovementNumberColor={"+ve"}
                summaryCardIcon={"totalSites"}
                summaryCardIconBackgroundColour={"#666666"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FHTSummaryStatisticCard
                summaryCardTitle={"Total patients"}
                summaryCardNumber={"840"}
                summaryCardImprovementNumber={"55"}
                summaryCardImprovementNumberColor={"-ve"}
                summaryCardIcon={"totalPatients"}
                summaryCardIconBackgroundColour={"#065535"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FHTSummaryStatisticCard
                summaryCardTitle={"Active trials"}
                summaryCardNumber={"5"}
                summaryCardImprovementNumber={"0"}
                summaryCardImprovementNumberColor={"-"}
                summaryCardIcon={"activeTrials"}
                summaryCardIconBackgroundColour={"#6897bb"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FHTSummaryStatisticCard
                summaryCardTitle={"Active recruitments"}
                summaryCardNumber={"8"}
                summaryCardImprovementNumber={"2"}
                summaryCardImprovementNumberColor={"+ve"}
                summaryCardIcon={"activeRecruitments"}
                summaryCardIconBackgroundColour={"#800000"}
              />
            </Grid>
          </Grid> */}

          {this.state.isLoadingDashboard ? <div><CircularProgress /> Updating dashboard</div>
            :
            <div>
              <Grid container spacing={10} alignItems="center" justify="center">
                <Grid item md={4} xs={12}>
                  {POCSummary == null ?
                    <div>
                      <CircularProgress />
                      Loading POC
                    </div> :
                    <FHTSummarySoftwareStatus
                      softwareStatusVersionsOnSite={POCSummary.numMachineByVersion}
                      softwareStatusActiveNumber={POCSummary.numActiveSite}
                      softwareStatusInactiveNumber={POCSummary.numInactiveSite}
                      softwareStatusTitle={"POC"}
                      softwareStatusURL={"POCSummary"}
                      softwareStatusGraphBackgroundColour={"#FFC6ADFF"}
                      softwareStatusWeeklyData={this.state.pocSummaryGraphDataList}
                    />
                  }
                </Grid>
                <Grid item md={4} xs={12}>
                  <FHTSummarySoftwareStatus
                    softwareStatusVersionsOnSite={APIVer}
                    softwareStatusActiveNumber={numAPISites - numAPIAlert}
                    softwareStatusInactiveNumber={numAPIAlert}
                    softwareStatusTitle={"WebAPI"}
                    softwareStatusURL={"APISummary"}
                    softwareStatusGraphBackgroundColour={"#D6DDFFFF"}
                    softwareStatusWeeklyData={this.state.apiSummaryGraphDataList}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FHTSummarySoftwareStatus
                    softwareStatusVersionsOnSite={ALGVer}
                    softwareStatusActiveNumber={numActiveALG}
                    softwareStatusInactiveNumber={numALGRecords - numActiveALG}
                    softwareStatusTitle={"Algorithms"}
                    softwareStatusURL={"ALGSummaryHistory"}
                    softwareStatusGraphBackgroundColour={"#D2FFCCFF"}
                    softwareStatusWeeklyData={this.state.algorithmSummaryGraphDataList}
                  />
                  <div style={{ position: "absolute", top: 480, left: "75vw", zIndex: 100 }}>
                    <RecommendationQueries
                      ALGTestsInfo={ALGTestsInfo}
                      numActiveALG={numActiveALG}
                      numALGRecords={numALGRecords}
                      ALGVer={ALGVer}
                      recommendationQueryParentData={this.state.recommendationQuery}
                      changeDashboardToRecommendationQueryPage={this.props.changeDashboardToRecommendationQueryPage}
                    ></RecommendationQueries>
                  </div>
                </Grid>
              </Grid>


              {Object.keys(FHTInfo).length == 0 ?
                <div>
                  <CircularProgress />
                  Loading FHT site data
                </div> :
                <div style={{ paddingTop: "5REM" }}>
                  <FHTSummarySiteOverview
                    siteOverviewGraphBackgroundColour={"#FFD1E8FF"}
                    siteOverviewActiveSites={this.state.siteOverviewUptimeData["Active Sites"]}
                    siteOverviewInactiveSites={this.state.siteOverviewUptimeData["Inactive Site"]}
                    type={"FHT"}
                    siteWiki={FHTInfo}
                    selectedSiteIDs={this.state.selectedSiteIDs}
                    ALGTestsInfo={ALGTestsInfo}
                    softwareStatusWeeklyData={this.state.siteOverviewSummaryGraphDataList}
                  />
                </div>
              }

            </div>
          }
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          {loadingMessage}
        </div>
      );
    }
  }
}

export default FHTHomeDashboard;
