import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import moment from 'moment';

import { Typography } from "@material-ui/core";

import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import '@inovua/reactdatagrid-community/index.css'
import Dialog from "@material-ui/core/Dialog";
const gridStyle = { minHeight: 550 }
window.moment = moment
var filterValue = [
  // { name: 'SiteName', type: 'string', operator: 'startsWith', value: '' },
  // Client side to obtain totalProcessTime. 
  // Can filter later if requested.
  //{ name: 'totalProcessTime', type: 'number', operator: 'eq'},
  {
    name: 'FhtLogDateLogReceived',
    type: 'date',
    operator: "beforeOrOn"
  },
  {
    name: 'FhtLogDateProcessStarted',
    type: 'date',
    operator: "beforeOrOn"
  },
  {
    name: 'FhtLogDateProcessFinished',
    type: 'date',
    operator: "beforeOrOn"
  },
  { name: 'ProcessSuccessful', type: 'number', operator: 'eq', value: null },
];

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    textAlign: "left",
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  sectionMargin: {
    marginTop: 30,
  },
  buttonMargin: {
    marginLeft: 20,
  },

  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  sideDrawer: {
    width: "50%",

    flexShrink: 0,
  },
  rightPadding: {
    padding: 30,
  },
});

class FHTlogs extends React.Component {
  constructor(props) {
    super(props);
    this.pageOptions = {
      pageSize: 10,
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      pageSizeOptions: [10, 20, 30],
    };
    this.selectionOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      selection: true,
    };

    this.triggers = {};
    this.snackbarMessage = "";
    this.snackbarVariant = "success";
    this.state = {
      logTable: [
        { header: "FhtLog ID", name: "FhtLogID", defaultWidth: 100 },
        // { title: 'Site Id', field: 'fhtLogSiteId'},
        { header: "Site Name", name: "SiteName", defaultWidth: 200, },
        {
          header: "Log received", name: "FhtLogDateLogReceived", defaultWidth: 100,
          filterEditor: DateFilter,
          filterEditorProps: (props, { index }) => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              dateFormat: 'DD/MM/YYYY',
              cancelButton: false,
              highlightWeekends: false,
              placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
            }
          }
        },
        {
          header: "Received time", name: "logRecievedTime", defaultWidth: 60,
        },
        {
          header: "Process Start", name: "FhtLogDateProcessStarted", defaultWidth: 100,
          filterEditor: DateFilter,
          filterEditorProps: (props, { index }) => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              dateFormat: 'DD/MM/YYYY',
              cancelButton: false,
              highlightWeekends: false,
              placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
            }
          }
        },
        {
          header: "Start time", name: "processStartTime", defaultWidth: 60,
        },
        {
          header: "Process finish", name: "FhtLogDateProcessFinished", defaultWidth: 100,
          filterEditor: DateFilter,
          filterEditorProps: (props, { index }) => {
            // for range and notinrange operators, the index is 1 for the after field
            return {
              dateFormat: 'DD/MM/YYYY',
              cancelButton: false,
              highlightWeekends: false,
              placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
            }
          }
        },
        {
          header: "Finish time", name: "processFinishTime", defaultWidth: 60,
        },
        // Client side to obtain totalProcessTime. 
        // Can filter later if requested.
        {
          header: "Total Time (mins)",
          name: "totalProcessTime",
          defaultWidth: 120,
          // type: 'number', 
          // filterEditor: NumberFilter
        },
        { header: "Status", name: "ProcessSuccessful", defaultWidth: 70, type: 'number', filterEditor: NumberFilter },
        { header: "Text", name: "FhtLogTextInfomation", defaultWidth: 250, },
      ],
      fhtLog: [],
      isLoaded: false,
      rowsPerPage: 10,
      cellSelected: {},
      openLogInformationPopup: false,
      selectedSiteInformation: null,
      currentPage: 0,
      maximumPageValue: 0
    };
    this.currentlySelectedCell = this.currentlySelectedCell.bind(this);
  }

  LoadRows = (startRow, endRow) => {
    if (startRow < 0) startRow = 0;
    console.log("Loading rows", startRow + " " + endRow);
    let body_row = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      startRow: startRow,
      endRow: endRow,
    }
    const api_page = FetchApi("GetFhtLogPageFilterQuery");
    api_page.post(body_row).then(response => {
      console.log("Logs fetched", response);
      this.InsertLogs(startRow, response);
    })
  }

  InsertLogs = (startRow, data) => {
    console.log("InsertLogs");
    let fhtLog = [...this.state.fhtLog];
    console.log("InsertLogs fhtLog", fhtLog);
    var index = startRow;
    data.map(entry => {
      fhtLog[index] = entry;
      index += 1;
    })
    this.setState({ fhtLog: fhtLog });
    console.log("InsertLogs Last", fhtLog);
  }


  componentDidMount = () => {
    const api_rows = FetchApi("GetFhtLogV2EntryNum");
    let body_row = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    //build placeholder rows
    api_rows.post(body_row).then(numRows => {
      var newRows = Array.from({ length: numRows }, (_, i) => {
        return ({
          FhtLogTextInfomation: "Loading",
          SiteName: "Loading",
          totalProcessTime: "Loading",
          FhtLogDateProcessStarted: "Loading",
          FhtLogDateProcessFinished: "Loading",
          FhtLogDateLogReceived: "Loading",

          processFinishTime: "Loading",
          processStartTime: "Loading",
          logRecievedTime: "Loading",

          ProcessSuccessful: 0,
          'Site ID': 0,
          FhtLogID: i,
        })
      });
      this.setState({ fhtLog: newRows });
      console.log("Empty rows", this.state.fhtLog);
    });
    //Get first page
    var startRow = 0;
    var endRow = 20;
    this.LoadRows(startRow, endRow);
    this.setState({ isLoaded: true });
  }
  render() {

    const { classes } = this.props;
    {
      if (this.state.isLoaded) {
        return (
          <div className={classes.root}>

            <Grid container>
              {this.renderLog()}

            </Grid>
          </div>
        );
      }
      else {
        return (
          <div>
            <CircularProgress></CircularProgress>
            Loading
          </div>
        )
      }
    }

  }

  currentlySelectedCell(event) {
    try {
      this.setState({
        cellSelected: event
      })
      if (Object.keys(event)[0].includes("FhtLogTextInfomation")) {
        var selectedCellArray = Object.keys(event)[0].split(",")
        var currentlySelectedCellLogInformation = this.state.fhtLog.filter(a => a.FhtLogID == Number(selectedCellArray[0]))
        this.setLogTextInformationPopUp(true)
        this.setState({
          selectedSiteInformation: currentlySelectedCellLogInformation[0]
        })
      }
    } catch {
      // Do nothing
    }
  }

  setLogTextInformationPopUp(openOrCloseBoolean) {
    this.setState({
      openLogInformationPopup: openOrCloseBoolean
    })
  }

  currentlySelectedFilters(event) {
    filterValue = event
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      filtersToSearch: filterValue,
      currentPage: 0,
    }
    const api = FetchApi(Configs.api.queryFHTLogsFilterSearch);
    api.post(body).then(response => {

      // Dummy data to show all rows.
      var newRows = Array.from({ length: response.totalValues }, (_, i) => {
        return ({
          FhtLogTextInfomation: "Loading",
          SiteName: "Loading",
          totalProcessTime: "Loading",
          FhtLogDateProcessStarted: response.data[0].FhtLogDateProcessStarted,
          FhtLogDateProcessFinished: response.data[0].FhtLogDateProcessFinished,
          FhtLogDateLogReceived: response.data[0].FhtLogDateLogReceived,

          processFinishTime: "Loading",
          processStartTime: "Loading",
          logRecievedTime: "Loading",

          ProcessSuccessful: response.data[0].ProcessSuccessful,
          'Site ID': 0,
          FhtLogID: i,
        })
      });
      this.setState({
        fhtLog: newRows,
        maximumPageValue: response.totalValues
      });
      this.InsertLogs(this.state.currentPage, response.data)
    });
  }

  currentlySelectedPages(event) {
    // Stop querying before we reach maximum amount of queried data.
    // Value is determined based on how many already queried rows we currently have.
    if (this.state.currentPage + 30 >= this.state.maximumPageValue && this.state.maximumPageValue != 0) {
      // Can be missing data at the end due to time differences.
      // Difficult to fix due front-end sending date only, backend using both date and time for filtering.
      this.LoadRows(event, this.state.maximumPageValue)
      this.setState({
        currentPage: event
      })
    }

    // Equality check prevents a continuous loop.
    if (event < 0) {
      // Do nothing
    } else {
      this.LoadRows(event, event + 20)
      this.setState({
        currentPage: event
      })
    }
  }

  renderLog = () => {
    const {
      isLoaded,
      fhtLog,
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <div style={{ width: "75REM" }}>
          <ReactDataGrid
            cellSelection={this.state.cellSelected}
            onCellSelectionChange={(event) => this.currentlySelectedCell(event)}
            defaultFilterValue={filterValue}
            idProperty="FhtLogID"
            style={gridStyle}
            dataSource={fhtLog}
            columns={this.state.logTable}
            pagination
            multiSelect={false}
            onFilterValueChange={(event) => this.currentlySelectedFilters(event)}
            onSkipChange={(event) => this.currentlySelectedPages(event)}
          />
        </div>

        <Dialog
          open={this.state.openLogInformationPopup}
          onBackdropClick={(event) => this.setLogTextInformationPopUp(false)}
        >
          {this.state.selectedSiteInformation == null ? "No detail" : (
            <Typography
              style={{ padding: "5%" }}>
              {JSON.stringify(this.state.selectedSiteInformation.FhtLogTextInfomation).replaceAll("\\n", " ").replaceAll("\"", "")}
              <br />
            </Typography>
          )}
        </Dialog>
      </div>
    );
  };
}
export default withStyles(useStyles)(FHTlogs);
