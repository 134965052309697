import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../../Service/Configuration";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable from "material-table";
import {
  utcToLocal,
  compareDateTimes,
  backgroundColourFromLetter,
} from "../../HelperFunctions";
import { Card } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import SelectSitesGrouping from "../../SharedComponents/SelectSitesGrouping";
import UsersInClinicsComponent from "./UsersInClinicsComponent";
import UsersToCohortsComponent from "./UsersToCohortsComponent";
import { Responsive, WidthProvider } from "react-grid-layout";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class MapClinicUserToCohortsGrid extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedSites: [],
      selectedUsers: [],
      selectedCohorts: [],
      fullCohortList: [],
      saveButtonDisabled : true,
      originalCohortTableBeforeUserSelection: [],
      RequeryUsersForClinicAPI: false,
      RequeryCohortsForClinicAPI: false,
      applyToCohortsSimilarTrials: false,
    };
    //Binds an async callback to the sitegrouping component
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(
      this
    );
    this.UsersInClinicCallback = this.UsersInClinicCallback.bind(this);
    this.UsersToCohortsCallback = this.UsersToCohortsCallback.bind(this);
  }

  componentDidMount() {
    if (Configs.SiteConfigurationMapping[window.location.hostname].ApplyAllTorchCohortSimilarTrials == "true") {
      this.setState({
        applyToCohortsSimilarTrials: true,
      })
    }
  }

  //For the callbacks, the moment a site is selected, there are two types of data to retrieve
  //1) The users & cohorts that exist for a site
  //2) The users & cohorts that are mapped with each other
  //All users retrieved initially are always active users
  //All cohorts retrieved initially are not mapped until the user ticks the user list.
  SharedComponentSiteGroupingCallback = (selectedSites) => {
    this.setState(
      {
        selectedSites: selectedSites,
        RequeryUsersForClinicAPI: true,
        RequeryCohortsForClinicAPI: true,
      },
      () => {
        this.GetCohortListForSite();
      }
    );
    console.log("Callback received from site grouping:", selectedSites);
  };
  UsersInClinicCallback = (selectedUsers) => {
    this.setState({
      selectedUsers: selectedUsers,
      RequeryCohortsForClinicAPI: true,
    });
    console.log("Callback received from selected users:", selectedUsers);
  };
  GetCohortListForSite = () => {
    console.log("getting site cohorts.");
    const api = FetchApi("GetAWSClinicCohortList");
    let siteIdArray = [];
    if (
      this.state.selectedSites == null ||
      this.state.selectedSites.length == 0
    ) {
      this.setState({ fullCohortList: [] }, () =>
        this.setState({ RequeryCohortsForClinicAPI: true })
      );
    } else {
      this.state.selectedSites.forEach((siteRecord) => {
        siteIdArray.push(parseInt(siteRecord.fhtSiteId));
      });
      let body = siteIdArray;
      api.post(body).then((response) => {
        this.setState({ fullCohortList: response }, () =>
          this.setState({ RequeryCohortsForClinicAPI: true })
        );
      });
    }
  };
  UsersToCohortsCallback = (selectedCohorts, userClicked) => {
    if (userClicked == true )
    {
    this.setState({
      selectedCohorts: selectedCohorts, saveButtonDisabled : false
    });
    }
    else {
      this.setState({
        selectedCohorts: selectedCohorts
      });
    }
    console.log(
      "Callback received from selected selectedCohorts:",
      selectedCohorts
    );
  };

  btnSavePatientToCohortMapping = () => {
    console.log ("this.state.originalCohortTableBeforeUserSelection:",this.state.originalCohortTableBeforeUserSelection);
    if ((this.state.selectedSites.length == 0) || (this.state.selectedUsers.length == 0)){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "No sites or users selected",
        Configs.snackbarVariants.error
      );
      return;
    }
    let usernameArray = [];
    this.state.selectedUsers.forEach((userRecord) => {
      usernameArray.push(userRecord.userName);
    });

    let fullCohortnameArray = [];
    this.state.fullCohortList.forEach((cohortRecord) => {
      if (cohortRecord.valid == true)
        fullCohortnameArray.push(cohortRecord.cohortName);
    });

    let enabledCohortnameArray = [];
    this.state.selectedCohorts.forEach((cohortRecord) => {
      if (cohortRecord.tableData.checked == true)
        enabledCohortnameArray.push(cohortRecord.cohortName);
    });
       
    let siteIdArray = [];
    this.state.selectedSites.forEach((siteRecord) => {
      siteIdArray.push(parseInt(siteRecord.fhtSiteId));
    });

    var apiToFetch = "SaveAWSClinicPatientsToCohortList";
    if (Configs.SiteConfigurationMapping[window.location.hostname].ApplyAllTorchCohortSimilarTrials == "true") {
      apiToFetch = "SaveAWSClinicPatientsToCohortListRelatedTorchTrials";
    }

    const api = FetchApi(apiToFetch);
    let body = {
      usernameArray: usernameArray,
      siteIdArray: siteIdArray,
      fullCohortnameArray:fullCohortnameArray,
      enabledCohortnameArray:enabledCohortnameArray,
    };
    
    if (Configs.SiteConfigurationMapping[window.location.hostname].ApplyAllTorchCohortSimilarTrials == "true") {
      body["applyToCohortsSimilarTrials"] = this.state.applyToCohortsSimilarTrials
    }

    api.post(body).then((response) => {

        console.log ("response:",response);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response,
          Configs.snackbarVariants.success
        );
          this.GetCohortListForSite();

    /*
     {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        response.status+":"+response.statusText,
        Configs.snackbarVariants.error
      );
    }
*/

      
    });
  };

  handleUpdateApplyToCohortsSimilarTrials() {
    var currentApplyToCohortsSimilarTrials = this.state.applyToCohortsSimilarTrials
    this.setState({
      applyToCohortsSimilarTrials: !currentApplyToCohortsSimilarTrials
    })
  }

  onLayoutChange = () => {
    console.log("Layout has changed");
  };
  render() 
{
  let mediumSize=3;
    return (
      <Grid container justify="center">
      <div style={{marginLeft:"1REM", marginRight:"1REM", width: "90%"}}>
        <Grid container>
        <Grid item xs={12} style={{height:"6REM"}}> <br/>
      <Typography variant="h3" component="h1" gutterBottom style={{textAlign:"center"}}>Map clinic users to trials</Typography>
      </Grid>
      </Grid>
      <Grid container style={{ gap:"5REM", width:"120%" }}>
      <Grid item md={mediumSize}xs={12} spacing={1}>
        <h4>1) Select site(s)</h4>
        </Grid>
        <Grid item md={mediumSize}xs={12}>
        <h4>2) Select user(s). If multiple sites are selected, users with
                similar names are grouped.</h4>
        </Grid>
        <Grid item md={mediumSize}xs={12}>
        <h4>3) Select trial(s) which will be viewable to the users within
                the clinic. Click the save button below to save your changes.
                </h4>
        </Grid>
      </Grid>
      <Grid container style={{ gap:"5REM",  height:"90REM", width:"120%" }}>

        <Grid item md={mediumSize}xs={12}>
        <div>
                      <SelectSitesGrouping
                        SharedComponentSiteGroupingCallback={
                          this.SharedComponentSiteGroupingCallback
                        }
                      />
                    </div>
        </Grid>
        <Grid item md={mediumSize}xs={12}>
        <div>
                      <UsersInClinicsComponent
                        UsersInClinicCallback={this.UsersInClinicCallback}
                        parentState={this.state}
                      />
                    </div>
        </Grid>
        <Grid item md={mediumSize}xs={12}>
        <div>
                      <UsersToCohortsComponent
                        UsersToCohortsCallback={this.UsersToCohortsCallback}
                        parentState={this.state}
                      />
                    </div>
        </Grid>     
        <Grid container style={{ gap:"5REM", width:"120%" }}>
        <Grid item md={mediumSize}xs={12}>
        </Grid>
        <Grid item md={mediumSize}xs={12} >

        {Configs.SiteConfigurationMapping[window.location.hostname].ApplyAllTorchCohortSimilarTrials == "true"? 
          <Box marginTop={2} display="flex" justifyContent="center">
            <FormGroup>
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked = {this.state.applyToCohortsSimilarTrials}
                    onChange = {() => this.handleUpdateApplyToCohortsSimilarTrials()}
                  />
                } 
                label="Applies to all cohorts in a similar trial" 
              />
            </FormGroup>
          </Box>
        :<div></div>}

        <Box marginTop={2} display="flex" justifyContent="center">
        <Button 
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.saveButtonDisabled}
                onClick={() => this.btnSavePatientToCohortMapping()}
              >
                Save patient to cohort mapping
              </Button>
        </Box>
        </Grid>
        <Grid item md={mediumSize}xs={12}>
        <Paper elevation={2} style={{ marginTop: "1rem", marginLeft: "auto", height: "fit-content" }}>
                <Box padding={1} textAlign="right">
                  <Typography variant="body1">
                    <strong>Black</strong>: Enabled existing user/cohort
                  </Typography>
                  <Typography variant="body1">
                    <strong style={{ color: "gray", fontStyle: "italic" }}>Grey</strong>: User/cohort does not exist across selected options (not selectable)
                  </Typography>
                </Box>
              </Paper> 
              </Grid>
              </Grid>
      </Grid>
</div>
      </Grid>
    );
  }
}

export default MapClinicUserToCohortsGrid;
