// Description: This file is responsible for rendering the API summary page.
// Status: Ready

import React, { Component } from "react";
import FetchApi from "../../../Service/FetchApi";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Configs from "../../../Service/Configuration";
import { utcToLocal, verifyHours, utcToAusLocal } from "../../HelperFunctions";
import SiteScriptsSummaryCellRender from "./SiteScriptsSummaryCellRender";

class SiteScriptsSummary extends Component {
  constructor(props) {
    super();
    this.state = {
      DisplayTable: null,
      TableCol: [],
      isLoaded: false,
      TableData: [],
    };
    this.pageOptions = {
      search: true,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportAllData: true,
      grouping: false,
      sorting: true,
      maxBodyHeight: 'calc(100vh - 11.5REM)',
      // selection: true,
      headerStyle: {
        zIndex: 200,
      },
      // tableLayout: 'auto'
    };
  }

  formatHeaderStyling(styleInt) {
    var headerStyling = {
      minWidth: styleInt,
      maxWidth: styleInt,
    }
    return headerStyling;
  }

  componentDidMount = async () => {
    const api = FetchApi(Configs.api.getSiteScriptsSummary);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      for (var i = 0; i < response.tableColumnsList.length; i++) {
        if (response.tableColumnsList[i]["field"] == "SiteName") {
          response.tableColumnsList[i]["cellStyle"] = {
            width: 300,
            minWidth: 300,
            position: "sticky",
            left: "0REM",
            background: "white",
            fontSize: 12,
            zIndex: 100,
          };
        } else {
          response.tableColumnsList[i]["headerStyle"] = this.formatHeaderStyling(150);
        }
      }
      for (var i = 0; i < response.tableRowDataList.length; i++) {
        for (const [key, value] of Object.entries(response.tableRowDataList[i])) {
          if (key == "SiteName")
            continue;
          response.tableRowDataList[i][key] = <SiteScriptsSummaryCellRender scriptStatus={value} />
        }
      }
      this.setState({
        TableCol: response.tableColumnsList,
        TableData: response.tableRowDataList,
        isLoaded: true,
      })
    })
  };

  render() {
    const { isLoaded, DisplayTable } = this.state;
    if (isLoaded) {
      return (
        <div>
          <MaterialTable
            columns={this.state.TableCol}
            data={this.state.TableData}
            options={this.pageOptions}
            title="Site Scripts Summary"
          ></MaterialTable>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }
}

export default SiteScriptsSummary;
