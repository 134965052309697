// DG2
// Description: This file is responsible for rendering the FHT home page, which is the
//    very first page you see after logging in. I would say this is the most complicated file so far.
//    I have already made several extractions to make this file as simple as possible.
//    You can follow the existing patterns to add cards in the future.
// Recommendation: I would prefer keep this file clean, just for navigation only
// Caution: The FHT/SC4C switchTab is more complicated than it looks, adjust with caution.
// Status: Deliver ready(03/08)

import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { POCCard, APICard, ALGCard, LatestFhtLogCard, RecommendationQueries } from "./summaryCards";
import OverviewCard from "./OverviewCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {
  countPCs,
  countValues,
  verifyWeekly,
  verifyDaily,
  manageVersions,
  verifyHours,
  removeArrayDuplicates,
  utcToAusLocal,
  utcToAESTLocal
} from "./HelperFunctions";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
class FHTHome extends Component {
  constructor(props) {
    super();
    this.state = {
      heartbeats: props.heartbeats,
      allTests: props.allTests,
      localVersion: props.localVersion,
      serverSummary: props.serverSummary,
      latestFhtLog : props.latestFhtLog,
      serverHistory:[],
      numDayUsers: 0,
      numWeekUsers: 0,
      numUniqueUsers: 0,
      POCActiveNum: 0,
      POCActive: [],
      isLoaded: false,
      numActiveALG: 0,
      numALGRecords: 0,
      loadingMessage: "Setting up FHT...",
    };
  }

  getPOCSummary = () =>{
    // Note that FHTInfo still uses the old code to determine poc
    const api = FetchApi("getPOCSummary");
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response=>{
      console.log("getPOCSummary response", response);
      this.setState({
        POCSummary : response,
      });
    })
  }
  
  getPOCDeactiveNum = ()=>{
    const api = FetchApi(Configs.api.getNumPOCDeactivatedSites)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response=>{
      console.log("deactiveNum",response);
      this.setState({
        numPOCDeactivatedSites : response.numDeactiveSites,
        numPOCConfigParsingIssue : response.numPOCConfigIssues,
        siteIDsWithPOCConfigIssues : response.siteIDsWithPOCConfigIssues,
        siteIDsThatAreDeactivedInPOCConfig : response.siteIDsThatAreDeactivated
      });
    })
  }
  
  componentDidMount = async () => {
    this.getPOCSummary();
    this.getPOCDeactiveNum();
    
    const machineVersions = manageVersions(this.state.localVersion);

    const api2 = FetchApi(Configs.api.getSummaryWebAPIData);
    let body2 = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api2.post(body2).then((response) => {
      this.setState({
        APIVer: response.apiVersionCount,
        numAPISites: response.totalSites,
        numAPIAlert: response.totalInactiveSites,
      })
    })

    const api3 = FetchApi(Configs.api.getSummaryALGData);
    let body3 = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api3.post(body3).then((response) => {
      this.setState({
        ALGVer: response.algorithmVersionCount,
        numALGRecords: response.maxLocalVersionAlgList,
        numActiveALG: response.activeSitesList.length
      })
    })

   const api4 = FetchApi(Configs.api.getSiteSummaryData);
   let body4 = {
     SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
   };
   await api4.post(body4).then((response) => {
      this.setState({
        FHTInfo: response.fhtInfo,
      })
   })

   const api5 = FetchApi(Configs.api.getAlgorithmDescriptions);
   let body5 = {
     SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
   };
   await api5.post(body5).then((response) => {
     this.setState({
       ALGTestsInfo: response.fhtRecommendationDescriptions,
     });
   }).catch((err) => {
      console.log("postSiteAlgoMap err", err);
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    });

   setTimeout(() => {
    console.log("this.state.FHTInfo", this.state.FHTInfo)
    this.setState({
     isLoaded: true
    })
  }, 1000);
}

  render() {
    const {
      numUniqueUsers,
      numDayUsers,
      numWeekUsers,
      POCActive,
      POCActiveNum,
      POCVer,
      APIVer,
      numAPISites,
      numAPIAlert,
      ALGTestsInfo,
      numActiveALG,
      numALGRecords,
      ALGVer,
      FHTInfo,
      loadingMessage,
      latestFhtLog,
      isLoaded,
      POCSummary,
    } = this.state;

    if (isLoaded && POCSummary !== undefined 
        && APIVer !== undefined && ALGVer !== undefined
        && FHTInfo !== undefined) {
      return (
        <div>
          <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={4} xs={12}>
              <POCCard
                numUniqueUsers={POCSummary.numUsers}
                numDayUsers={POCSummary.dailyPopup}
                numWeekUsers={POCSummary.weeklyPopup}
                POCActiveNum={POCSummary.numActiveSite}
                POCInactiveNum={POCSummary.numInactiveSite}
                POCVer={POCSummary.numMachineByVersion}
                POCDeactivatedNum={this.state.numPOCDeactivatedSites}
                POCConfigParsingIssueNum={this.state.numPOCConfigParsingIssue}
                siteIDsWithPOCConfigIssues = {this.state.siteIDsWithPOCConfigIssues}
                siteIDsThatAreDeactivedInPOCConfig = {this.state.siteIDsThatAreDeactivedInPOCConfig}
              />
            </Grid>
            
            <Grid item md={4} xs={12}>
              <APICard
                APIVer={APIVer}
                numAPISites={numAPISites}
                numAPIAlert={numAPIAlert}
              ></APICard>
            </Grid>

            <Grid item md={4} xs={12}>
              <ALGCard
                ALGTestsInfo={ALGTestsInfo}
                numActiveALG={numActiveALG}
                numALGRecords={numALGRecords}
                ALGVer={ALGVer}
              />
            </Grid>

            <Grid item md = {6} xs = {12} >
              <LatestFhtLogCard
                latestFhtLog = {latestFhtLog}
              ></LatestFhtLogCard>
            </Grid>

            <Grid item md = {6} xs = {12}>
              <RecommendationQueries
                              ALGTestsInfo={ALGTestsInfo}
                              numActiveALG={numActiveALG}
                              numALGRecords={numALGRecords}
                              ALGVer={ALGVer}
                              recommendationQueryParentData = {this.props.recommendationQueryParentData} 
                              changeDashboardToRecommendationQueryPage = {this.props.changeDashboardToRecommendationQueryPage}
              ></RecommendationQueries>
            </Grid>
          </Grid>

          <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={8} xs={12}>
              <OverviewCard
                type={"FHT"}
                siteWiki={FHTInfo}
                ALGTestsInfo={ALGTestsInfo}
              ></OverviewCard>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          {loadingMessage}
        </div>
      );
    }
  }
}

export default FHTHome;
