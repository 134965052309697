import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

class EncryptDecryptString extends Component {
  constructor(props) {
    super();   
    this.state = { 
      encryptionString: "",
      encryptedString: "",

      decryptionString: "",
      decryptedString: "",
    }
  }

  encryptString = () => {
    const api = FetchApi(`${Configs.api.encryptString}`);
    api.post(this.state.encryptionString).then((response) => {
      this.setState({
        encryptedString: response
      })
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        err,
        Configs.snackbarVariants.error
      );
    });
  }
  decryptString = () => {
    const api = FetchApi(`${Configs.api.decryptString}`);
    api.post(this.state.decryptionString).then((response) => {
      this.setState({
        decryptedString: response
      })
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        err,
        Configs.snackbarVariants.error
      );
    });
  }

  renderEncryptDecryptString = () => {
    return (
      <div>
          <Grid container justify="center"><h3>Encrypt string</h3></Grid>
        <br/>
        <Grid container justify="center">
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={this.state.encryptionString}
              label="Encrypt"
              onChange={(event) => {
                this.setState({ 
                  encryptionString: event.target.value
                })
              }}
            /> <br/>
            <TextField 
              variant="outlined"
              multiline={true}
              value={this.state.encryptedString}
              label="Encryption result"
            />
          </FormControl>
          </Grid>
          <div style = {{marginLeft: "4.5REM"}}>
            <Button
              variant="contained"
              onClick={() => this.encryptString()}>
                Encrypt
            </Button>
          </div>

          <br/>
            <Grid container justify="center"><h3>Decrypt string</h3></Grid>
          <br/>
          <Grid container justify="center">
          <FormControl variant="outlined" style = {{width: "90%", paddingBottom: "2REM"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={this.state.decryptionString}
              label="Decrypt"
              onChange={(event) => {
                this.setState({ 
                  decryptionString: event.target.value
                })
              }}
            /> <br/>
            <TextField 
              variant="outlined"
              multiline={true}
              value={this.state.decryptedString}
              label="Decryption result"
            />
          </FormControl><br/><br/>
        </Grid>    
        <div style = {{marginLeft: "4.5REM"}}>
          <Button
            variant="contained"
            onClick={() => this.decryptString()}>
              Decrypt
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEncryptDecryptString()} <br/>
      </div>
    )
  }
}

export default EncryptDecryptString;