import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import MaterialTable, { MTableToolbar } from "material-table";
import { CSVLink } from "react-csv";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  utcToLocal,
  compareDateTimes,
  backgroundColourFromLetter,
} from "../HelperFunctions";
import { Card } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CDialog from "Component/CDialog";
import FolderShared from "@material-ui/icons/FolderShared";


const tablecolumns =
  [
    { title: 'drqMainId', field: 'drqMainId', hidden: true, type: 'numeric' },
    { title: 'Query Name', field: 'drqQueryName', type: 'string' },
    { title: 'Date Created', field: 'dateCreated', type: 'date' },
    { title: 'Last Updated', field: 'lastUpdated', type: 'date' },
    { title: 'Sites Responded', field: 'sitesResponded', type: 'numeric' },
    { title: 'Sites Not Responded', field: 'sitesNotResponded', type: 'numeric' },
    { title: 'Sites Failed', field: 'sitesFailed', type: 'numeric' },
    { title: 'Total', field: 'total', type: 'numeric' },

  ];

const detailedTableColumns =
  [
    { title: 'siteId', field: 'siteId', hidden: true, type: 'numeric' },
    { title: 'Site Name', field: 'siteName', type: 'string' },
    { title: 'Date Created', field: 'dateCreated', type: 'date' },
    { title: 'Last Updated', field: 'lastUpdated', type: 'date' },
    { title: 'Site Status', field: 'siteStatus', type: 'string' },
    { title: 'Total', field: 'total', type: 'numeric' },
  ];

class DrqResults extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedQuery: -1,
      datagridMainData: [],
      datagridDetailedData: {},
      isLoaded: false,
      tableData: null,
      TableCol: [],
    };
    this.secondaryPageOptions = {
      search: true,
      pageSize: 10,
      pageSizeOptions: [10, 20, 50],
      exportButton: true,
      exportButton: {
        csv: true,
      }
    };
    //initial loading of DRQ query list
    this.LoadDRQlist();
  }

  componentDidMount = async () => {
    console.log("componentdidmount async");
  };
  LoadDRQlist = () => {
    const api = FetchApi("GetDRQMainList");
    api
      .get()
      .then((response) => {
        console.log("DRQ list received: ", response);
        this.setState({ datagridMainData: response, isLoaded: true });
        //proceed to massage data to fit tableData
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          "Retrieve query list failed",
          Configs.snackbarVariants.error
        );
      });
  };
  ProcessRowDetails = (rowData) => {
    if (!this.state.datagridDetailedData[rowData.drqMainId]) {
      console.log("Rowdata:", rowData);

      const api = FetchApi("GetDetailedDRQMain");
      let body = rowData.drqMainId;
      api
        .post(body)
        .then((response) => {
          console.log("DRQ details received: ", response);
          const datagridDetailedData = this.state.datagridDetailedData;
          datagridDetailedData[rowData.drqMainId] = response;
          this.setState({ datagridDetailedData });
        })
        .catch((err) => {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            "Retrieve query list failed",
            Configs.snackbarVariants.error
          );
          return <div>Failed to return detailed view</div>;
        });
    }

    return (
      <div style={{ width: "70%", marginLeft: '10REM' }}>
        <MaterialTable style={{ width: "70%" }}
          title="Detailed site results"
          columns={detailedTableColumns}
          data={this.state.datagridDetailedData[rowData.drqMainId]}
          style={{ backgroundColor: '#EEE', zIndex: 10 }}
        />
      </div>
    );

  };
  render() {
    let maxSize = 12;
    let table =
      <div>
        <CircularProgress />
        Loading...
      </div>;
    if (this.state.isLoaded) {
      table =
        <div>
          <MaterialTable
            title="Dynamic Report Query Results"
            columns={tablecolumns}
            data={this.state.datagridMainData}
            options={this.secondaryPageOptions}
            style={{ backgroundColor: '#EEE', zIndex: 10 }}
            detailPanel={rowData => {
              return (this.ProcessRowDetails(rowData));
              //return (<div>test3</div>);
            }}
          />
          {/*
          <CSVLink
            data={algSummaryData}
            filename="Query Summary.csv"
            className="hidden"
            ref={downloadMaterialTableData}
            onClick={(event) => this.downloadMaterialTableDataClickRerender(event)}
            target="_blank"
          />
          */}
        </div>;
    }
    return (
      <Grid container justify="center">
        <div style={{ marginLeft: "1REM", marginRight: "1REM", width: "90%" }}>
          <Grid container>
            <Grid item xs={12} style={{ height: "6REM" }}>
              {" "}
              <br />
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                View DRQ results
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ gap: "5REM", width: "120%" }}>
            <Grid item md={maxSize} xs={12} spacing={1}>
              <h4>Summary of DRQ results</h4>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ gap: "5REM", width: "100%" }}
          >
            <Grid item md={maxSize} xs={12} spacing={1}>
              {table}
            </Grid>

          </Grid>
        </div>
      </Grid>
    );
  }
}

export default DrqResults;
