import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class SoftwareRegister extends Component {
  constructor(props) {
    super();   
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.state = {   
      softwareTable: [
        { title: "Software Name", field: "fhtSystemName" },
        { title: "Exe File Name", field: "fhtSystemExe" },
        {
          title: "Software is a Service",
          field: "fhtSystemIsService",
          lookup: Configs.isService,
        },
      ], 
      software: [],
    }
  }

  componentDidMount() {
    this.getSoftware();
  }

  getSoftware = () => {
    // Software mappping, software
    const api = FetchApi(Configs.api.getSystem);
    api.get().then((response) => {
      this.setState({ software: response }, () => {});
    });
  };

  handleSoftwareUpdate = (data) => {
    //Software Register
    let software = {
      fhtSystemId: data.fhtSystemId,
      fhtSystemName: data.fhtSystemName,
      fhtSystemExe: data.fhtSystemExe || "",
      fhtSystemUpdateDate: new Date(),
      fhtSystemIsService: data.fhtSystemIsService === "1" ? "1" : "0",
    };
    const api = FetchApi(Configs.api.updateFhtSystem);
    api.post(software)
      .then((response) => {
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.updateSuccess,
            Configs.snackbarVariants.success
          );
          this.setState({
            software: response,
          });
        }
      })
      .catch((err) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateFail,
          Configs.snackbarVariants.error
        );
      });
  };  

  handleSoftwareDelete = (data) => {
    //Software Register
    const api = FetchApi(Configs.api.deleteFhtSystem);
    api
      .del(data.fhtSystemId)
      .then((response) => {
        if (response.unauthorized != undefined && response.unauthorized == 401) {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.unauthorised,
            Configs.snackbarVariants.error
          );
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            Configs.snackbarMessages.deleteSuccess,
            Configs.snackbarVariants.success
          );
        }
      })
      .catch((error) => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.deleteFail,
          Configs.snackbarVariants.error
        );
        this.getSoftware();
      });
  };

  renderSoftware = () => {
    // software
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Software Register</h3></Grid>
        <br/>
        <Grid container justify="center">
          <MaterialTable
            style = {{width: "90%"}}
            columns={this.state.softwareTable}
            data={this.state.software}
            options={this.pageOptions}
            title=""
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const data = this.state.software.map((x) =>
                      Object.assign({}, x)
                    );
                    if (
                      newData.fhtSystemName === undefined ||
                      newData.fhtSystemName.trim() === ""
                    ) {
                      this.props.GlobalFunctionDisplaySnackbarMessage(
                        Configs.snackbarMessages.systemDetails,
                        Configs.snackbarVariants.error
                      );
                      reject();
                      return;
                    }
                    resolve();
                    this.handleSoftwareUpdate(newData);
                    // this.setState({ software: data }, () => {
                    //   this.handleSoftwareUpdate(newData);
                    // });
                  }, 500);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (
                      newData.fhtSystemName === undefined ||
                      newData.fhtSystemName.trim() === ""
                    ) {
                      this.props.GlobalFunctionDisplaySnackbarMessage(
                        Configs.snackbarMessages.systemDetails,
                        Configs.snackbarVariants.error
                      );
                      reject();
                      return;
                    }
                    resolve();
                    const data = this.state.software.slice();
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.setState({ software: data }, () => {
                      this.handleSoftwareUpdate(newData);
                    });
                  }, 500);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve();
                    const data = this.state.software.slice();
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    this.setState({ software: data }, () => {
                      this.handleSoftwareDelete(oldData);
                    });
                  }, 500);
                }),
            }}
          ></MaterialTable>
        </Grid>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderSoftware()} <br/>
      </div>
    )
  }
}

export default SoftwareRegister;