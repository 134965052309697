import React, { Component } from "react";
import SiteModulesGeneralLayout from "../SharedComponents/SiteModulesGeneralLayout";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReorderListGeneralComponent from "../GeneralPages/ReorderListGeneralComponent";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CircularProgress, Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

class FHTRemoteCohortCreation extends Component {
  constructor(props) {
    super();
    this.state = {
      AlgoConfigMap: [],
      recAlgos: [],
      selectedAlgos: [],
      selectedSites: [],
      cohortName: "",
      doesCohortAlreadyExist: false,
      cohortCreationOption: 1,
      defaultTodayDateCohortCreation: "",
      cohortCreateDate: "",
      isDeleteCheckboxClicked: true,
      deleteCohortAfterSuccessCreation: 1,
      currentlySelectedDiseaseID: "0",
      sitesAndActiveCohortsDictionary: {},
    }
    this.selectedAlgoritmsParentFunction = this.selectedAlgoritmsParentFunction.bind(this);
    this.handleClearAllConfigurationsSelected = this.handleClearAllConfigurationsSelected.bind(this);
  }

  componentDidMount() {
    this.getConfigAlgoMap();
    this.getSitesAndActiveCohortNames();
    this.generateDefaultDateCohortCreation();
  }

  // Get all configuration algorithms.
  getConfigAlgoMap = () => {
    //Site Algo
    console.log("getting all algorithm map")
    const api = FetchApi(Configs.api.getConfigAlgoMap);

    var RecOrChkSaveType = Configs.RecOrChkSaveType.Rec;
    let body = {
      RecOrChkSaveType: RecOrChkSaveType,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    };
    api.post(body).then((response) => {
      console.log("getConfigAlgoMap", response);
      // combine the two dictionaries chk and rec
      this.setState({
        AlgoConfigMap: response.algoByDiseaseID,
        recAlgos: response.recAlgos,
      });
    });
  }

  getSitesAndActiveCohortNames() {
    const api = FetchApi(Configs.api.getSitesAndActiveCohortNames)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      console.log("getSitesAndActiveCohortNames response", response)
      this.setState({
        sitesAndActiveCohortsDictionary: response
      })
    })

    if (this.props.configType == Configs.configurationEditingPageTypes.createCohortMonitoring) {
      this.setState({
        isLoaded: true
      })
    }
  }

  updateRemoteCohortCreationConfigsForSites() {
    var selectedSitesDictionaryList = JSON.parse(JSON.stringify(this.state.selectedSites));
    var selectedSitesIDArray = []
    for (var i = 0; i < selectedSitesDictionaryList.length; i++) {
      selectedSitesIDArray.push(selectedSitesDictionaryList[i].fhtSiteId);
    }
    
    var body = {
      
      cohortName: this.state.cohortName,
      cohortCreationOption: this.state.cohortCreationOption,
      cohortCreateDate: this.state.cohortCreateDate,
      cohortSelectedSites: selectedSitesIDArray,
      cohortRecommendations: this.state.selectedAlgos.filter(x => this.state.recAlgos.includes(x)),
      cohortSelectedDiseaseID: this.state.currentlySelectedDiseaseID,
      cohortDeleteAfterCreation: this.state.deleteCohortAfterSuccessCreation,
      StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
    }

    const api = FetchApi(Configs.api.updateRemoteCohortCreationConfig);
    api.post(body).then((response) => {
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized");
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
      // Refresh the cohort creation data back to default.
      this.setState({
        cohortName: "",
        deleteCohortAfterSuccessCreation: 1,
        selectedAlgos: [],
        isDeleteCheckboxClicked: true,
        currentlySelectedDiseaseID: "0",
      })
      this.generateDefaultDateCohortCreation();
    }).catch((err) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })
  }

  // To show to users the frontend date to be used.
  generateDefaultDateCohortCreation() {
    var isoStringDate = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
    var isoDateNoSecondMillisecond = `${isoStringDate.slice(0, isoStringDate.lastIndexOf(":"))}`;
    this.setState({
      defaultTodayDateCohortCreation: isoDateNoSecondMillisecond,
      cohortCreateDate: isoDateNoSecondMillisecond,
    })
  }

  SharedComponentSiteGroupingCallback = (selectedSites) => {
    console.log("Callback received from site grouping:", selectedSites);
    this.setState({
      selectedSites: selectedSites
    })
    // Check if selected site has cohorts already exist. If it does, do not allow option to save.
    this.doesCohortNameExistInSites("", selectedSites)
  };

  // If cohortName exists in any sites, disable create cohort.
  doesCohortNameExistInSites(cohortName, selectedRows) {
    var cohortNameToSearch = "";
    var sitesWithCohort = [];
    if (cohortName == "")
      cohortNameToSearch = this.state.cohortName
    else
      cohortNameToSearch = cohortName

    var doesCohortExistLocalVar = false;
    this.setState({
      doesCohortAlreadyExist: doesCohortExistLocalVar
    })

    for (var i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i].fhtSiteId in this.state.sitesAndActiveCohortsDictionary) {
        if (cohortNameToSearch in this.state.sitesAndActiveCohortsDictionary[selectedRows[i].fhtSiteId]) {
          doesCohortExistLocalVar = true
          sitesWithCohort.push(<div>{selectedRows.filter(x => x.fhtSiteId == selectedRows[i].fhtSiteId)[0].fhtSiteName}</div>)
        }
      }
    }
    this.setState({
      doesCohortAlreadyExist: doesCohortExistLocalVar,
      sitesWithAlreadyGeneratedCohort: sitesWithCohort,
    })
  }

  selectedAlgoritmsParentFunction(updatedSelectedRecommendations) {
    this.setState({
      selectedAlgos: updatedSelectedRecommendations
    })
    var latestSelectedRecommendation = updatedSelectedRecommendations.slice(-1)[0] 
    var diseaseID = "0";
    for (const [key, value] of Object.entries(this.state.AlgoConfigMap)) {
      if (value.includes(latestSelectedRecommendation)) {
        diseaseID = key
      }
    }
    this.setState({
      currentlySelectedDiseaseID: diseaseID
    })
  }

  handleClearAllConfigurationsSelected() {
    this.setState({
      selectedAlgos: []
    })
  }

  renderRemoteCohortCreateOptions() {
    return (
      <div style={{ width: "100%" }}>
        <TextField
          variant="outlined"
          required
          fullWidth
          name="cohortName"
          label="Cohort name"
          id="cohortName"
          value={this.state.cohortName}
          onChange={(event) => {
            this.setState({ cohortName: event.target.value })
            this.doesCohortNameExistInSites(event.target.value, this.state.selectedSites)
          }}
        />
        {this.state.doesCohortAlreadyExist == true && this.state.cohortCreationOption != Configs.RemoteCohortCreationTypes.RecreateCohort ?
          <div>
            <p style={{ fontSize: "10px" }}><ErrorOutlineIcon style={{ fill: "red" }} /> Cannot create this cohort as some sites already have it (choose recreate cohort if you want to recreate a cohort with the same name): {this.state.sitesWithAlreadyGeneratedCohort}</p>
          </div> :
          <div></div>}
        <br />
        <Card>
          <Grid container justify="center">
            <TextField
              id="datetime-local"
              label="Cohort date creation"
              type="datetime-local"
              defaultValue={this.state.defaultTodayDateCohortCreation}
              value={this.state.cohortCreateDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => this.setState({ cohortCreateDate: event.target.value })}
            />
          </Grid>
        </Card>
        <Grid container justify="center">
          <p style={{ fontSize: "10px" }}>By default, today's date will be used for the cohort creation date.</p>
        </Grid>
      </div>
    )
  }

  handleDeleteCohortAfterSuccessCheckbox(currentDeleteCohortCheckbox) {
    if (currentDeleteCohortCheckbox == true)
      this.setState({
        isDeleteCheckboxClicked: currentDeleteCohortCheckbox,
        deleteCohortAfterSuccessCreation: 1
      })
    else
      this.setState({
        isDeleteCheckboxClicked: currentDeleteCohortCheckbox,
        deleteCohortAfterSuccessCreation: 0
      })
  }

  renderFHTRemoteCohortCreation = () => {
    // account
    const { classes } = this.props;

    return (
      <div> <br/>
        <SiteModulesGeneralLayout
          selectedAlgoritmsParentFunction={this.selectedAlgoritmsParentFunction}
          AlgoConfigMap={this.state.AlgoConfigMap}
          SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
          selectedAlgos={this.state.selectedAlgos}
          uniqueActivatedAlgos={[]}
          handleClearAllConfigurationsSelected={this.handleClearAllConfigurationsSelected}
          pageTitle={"Remote Create Cohorts"}
        />
        <Grid container justify="center">
          <Grid container spacing={10} style={{ width: "100%" }}>
            <Grid item xs={4}>
              {/* Empty rendering space for ReorderList to be on right hand side. */}
            </Grid>
            <Grid item xs={7}>
              <Box
                bgcolor="primary.main"
                style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                height="50px"
              >
                <Typography variant="h5">
                  Cohort parameters
                </Typography>
              </Box>
              <br />
              <Accordion
                expanded={this.state.cohortCreationOption == Configs.RemoteCohortCreationTypes.CreateCohort}
                onChange={() => this.setState({ cohortCreationOption: Configs.RemoteCohortCreationTypes.CreateCohort })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Create cohort</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {this.renderRemoteCohortCreateOptions()}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={this.state.cohortCreationOption == Configs.RemoteCohortCreationTypes.RecreateCohort}
                onChange={() => this.setState({ cohortCreationOption: Configs.RemoteCohortCreationTypes.RecreateCohort })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Recreate cohort</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {this.renderRemoteCohortCreateOptions()}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Advanced options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    style={{ display: "table" }}
                    control={
                      <div style={{ display: "table-cell" }}>
                        <Checkbox
                          checked={this.state.isDeleteCheckboxClicked}
                          onChange={(event) => { this.handleDeleteCohortAfterSuccessCheckbox(event.target.checked) }}
                        /></div>}
                    label={
                      "Delete cohort in config after successful creation"
                    }
                  />
                </AccordionDetails>
              </Accordion>

              <br />
              <ReorderListGeneralComponent
                listToUse={this.state.selectedAlgos.filter(x => this.state.recAlgos.includes(x))}
                reorderListHeading={"Recommendations for cohort"}
                reorderListType={"remoteCohortCreation"}
              />
              <Button
                variant="contained"
                disabled = {(this.state.currentlySelectedDiseaseID == "0" 
                            || (this.state.doesCohortAlreadyExist == true 
                                && this.state.cohortCreationOption == Configs.RemoteCohortCreationTypes.CreateCohort))}
                onClick={() => {
                  this.updateRemoteCohortCreationConfigsForSites();
                }}
              >
                {"Create this cohort for selected sites"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
       
        {this.renderFHTRemoteCohortCreation()} <br />
        
      </div>
    )
  }
}

export default FHTRemoteCohortCreation;