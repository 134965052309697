// Description: This file is responsible for rendering the Algorithms summary page.
// Status: Ready
// 3/31/2023 - Page can be deleted as ALGSummaryHistory performs all of these functions.
// Related ticket: FHTSUP-266.

import React, { Component } from "react";

import MaterialTable, { MTableToolbar } from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { verifyHours, utcToLocal, utcToAusLocal, removeArrayDuplicates, convertALGDataToCorrectCSV } from "../../HelperFunctions";
import { getFHTAlgorithmHistory } from "../../FetchFunctions";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import SearchIcon from "@material-ui/icons/Search"
import FetchApi from "../../../Service/FetchApi";
import Configs from "../../../Service/Configuration";
import { CSVLink } from "react-csv";

import { Grid, Paper } from "@material-ui/core";

const styling = {
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10
  },
  width1em: {
    width: "1em",
  },
};

var algSummaryData = []
var downloadMaterialTableData = React.createRef();

class ALGSummary extends Component {
  constructor(props) {
    super();
    this.state = {
      DisplayTable: null,
      status: props.match.params.Status,
      TableCol: [
        {
          title: "Logs",
          render: (rowData) =>
            <Link to={{
              pathname: "/FhtLogsSelections",
              state: {
                Keyword: rowData.fhtSiteName,
              },
            }}>
              <SearchIcon />
            </Link>,
          cellStyle: {
            position: "sticky",
            left: "0REM",
            background: "white",
            fontSize: 12,
          }
        },
        {
          title: "Site Name", field: "fhtSiteName",
          cellStyle: {
            position: "sticky",
            left: "3REM",
            background: "white",
            fontSize: 12,
          }
        },
        // { title: "Last Extracted", field: "fhtHeartbeatLastlog" },
        { title: "Last attempted run", field: "fhtLogDateLogReceived" },
        { title: "Last successful run", field: "fhtLastSuccessfulLog" },
        {
          title: "Status",
          field: "status",
        },
        { title: "New Recommendations", field: "numRecommendation" },
        { title: "Total Patients", field: "numPatients" },
        { title: "Version", field: "fhtHeartbeatVersionNumber" },

      ],
      testWiki: null,
      isLoaded: false,
      maxValidALGHours: 24,
    };

    this.pageOptions = {
      search: true,
      searchText:
        props.location.state !== undefined
          ? props.location.state.Keyword
          : props.match.params.Status === undefined
            ? null
            : props.match.params.Status,

      pageSize: 30,
      pageSizeOptions: [10, 20, 30],
      exportButton: {
        csv: true,
      },
      exportCsv: (header, columns) => this.exportCSVData(header, columns),
      maxBodyHeight: 'calc(100vh - 13REM)',
    };
  }

  exportCSVData(header, data) {
    algSummaryData = convertALGDataToCorrectCSV(header, data, false)
    // Introduce slight delay before downloading data, to allow for global variable to be set.
    setTimeout(function () {
      downloadMaterialTableData.current.link.click();
    }.bind(this), 500)
  }

  componentDidMount = async () => {

    var siteIDAndActiveDisplayRecommendations = []
    const api = FetchApi(Configs.api.getActiveDisplayAlgorithmsForSites);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      siteIDAndActiveDisplayRecommendations = response.siteIDsAndActiveDisplayAlgorithms
    });

    let fhtAlgorithmHistoryAndColumnData = await getFHTAlgorithmHistory();

    const fullTable_testIDs = fhtAlgorithmHistoryAndColumnData;
    let newColumns = this.state.TableCol;
    fullTable_testIDs["newColumnsArray"].forEach((testID) => {
      var columnTestID = testID;
      columnTestID["cellStyle"] = {
        width: 250,
        minWidth: 250
      }
      columnTestID["headerStyle"] = {
        width: 250,
        minWidth: 250
      }
      newColumns.push(columnTestID);
    });

    // Loop through entire summary data table.
    for (var i = 0; i < fullTable_testIDs["table"].length; i++) {
      var siteID = fullTable_testIDs["table"][i].siteID
      // Identify the siteID as key, check if in DisplayRecommendations dictionary.
      if (siteID in siteIDAndActiveDisplayRecommendations) {
        // Loop through the siteIDAndActiveDisplayRecommendations dictionary values.
        // Identify which testIDs are active in fullTable_testIDs data for specific site.
        for (var j = 0; j < siteIDAndActiveDisplayRecommendations[siteID].length; j++) {
          var displayAlgorithmToShow = "testID" + String(siteIDAndActiveDisplayRecommendations[siteID][j])
          Object.entries(fullTable_testIDs["table"][i]).map(([key, value]) => {
            if (key == displayAlgorithmToShow) {
              fullTable_testIDs["table"][i][key] = <p key={value} style={{ background: "palegreen", display: "inline", padding: "2.5%" }}>{value}</p>
            }
          })
        }
      }
    }

    this.setState({
      // filterValue: filterValue,
      originalTable: fullTable_testIDs["table"],
      testIDs: fullTable_testIDs["testIDs"],
      DisplayTable: fullTable_testIDs["table"],
    });

    this.changeMaxStatus({ target: { value: 24 } }); // apply status filter

    this.setState({ isLoaded: true });
  };

  // Front-end filtering of which sites are active/inactive when maximum hours is changed.
  // Therefore, can leave this on client-side, not server-side.
  changeMaxStatus = (event) => {
    const newMax = parseInt(event.target.value);
    const filterValue = this.state.filterValue;
    const oldTable = this.state.originalTable;
    const newTable = [];

    oldTable.forEach((record) => {
      if (verifyHours(record["fhtLogDateLogReceivedLocal"], newMax) && record.fhtLogProcessSuccessful == 1) {
        record["status"] = "active";
      } else {
        record["status"] = "fail";
      }

      if (record["status"] === filterValue) {
        newTable.push(record);
      } else if (filterValue === record["fhtHeartbeatVersionNumber"]) {
        newTable.push(record);
      } else if (filterValue === undefined) {
        newTable.push(record);
      }
    });

    this.setState({
      DisplayTable: newTable,
      maxValidALGHours: newMax,
    });
  };

  // "Refresh" the CSV download global variable
  downloadMaterialTableDataClickRerender(event) {
    this.setState({});
  }

  render() {
    const { isLoaded, DisplayTable } = this.state;

    if (isLoaded) {
      return (
        <div style={{ paddingTop: "1REM" }}>
          <MaterialTable
            columns={this.state.TableCol}
            data={DisplayTable}
            options={this.pageOptions}
            title="Algorithm Summary"
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style = {{position: "absolute", left: "33%", top: 10, zIndex: 1}}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 10
                      }}
                    >
                      *Status: A FHT Algorithms site is active if its last log was received within the last
                      <TextField
                        type="number"
                        defaultValue={this.state.maxValidALGHours}
                        onChange={this.changeMaxStatus}
                        value={this.state.maxValidALGHours}
                        style={{ width: "3REM", paddingLeft: "0.5REM", paddingRight: "0.5REM" }}
                      />
                      hours, and it contains no fail messages.
                    </div>
                    <div style={styling.center}>
                      <Link to="/ALGSummaryHistory">Click here </Link>
                      to view algorithm history for the past 60 days.
                    </div>
                  </div>
                </div>
              )
            }}
          />
          <CSVLink
            data={algSummaryData}
            filename="Algorithm Summary.csv"
            className="hidden"
            ref={downloadMaterialTableData}
            onClick={(event) => this.downloadMaterialTableDataClickRerender(event)}
            target="_blank"
          />
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }
}

export default ALGSummary;
