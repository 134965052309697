//import things

import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
import { Card } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  buttonMargin: {
    marginLeft: 20,
  },
  tip: {
    fontSize: "smaller",
    textAlign: "left",
    marginButtom: 10,
    color: "#cccccc",
  },
});

export class MyAccount extends React.Component {
  //initialize state
  constructor(props) {
    super(props);
    
    this.a = [];
    this.b = [];
    this.state = {
      VIEWS : {
        PROFILE : 0,
        RESET_PASSWORD : 1,
        EDIT_INFO : 2,
      },
      selectedView : 0,
      waitingForServer : false,
      //User info
      userName: "",
      firstName : "",
      lastName : "",
      email : "",
      role : "",
      roleDescription : "",
      //Rest password
      oldPassword : "",
      newPassword : "",
      retypeNewPassword : ""
    };
    
    //bind the buttons
  }
  componentDidMount = ()=>{
    this.getUserInformation();
  }

  async getUserInformation(){
    const api = FetchApi(Configs.api.getUserInformation);
    api.get().then((response)=>{
      this.setState({
        username: response.data.username,
        firstName : response.data.firstname,
        lastName : response.data.lastname,
        email : response.data.email,
        role : response.data.role,
        roleDescription : response.data.roleDescription
      });
    });
  }

  // define the functions
  changePassword = () =>{
    this.postChangePassword();
    this.setState({waitingForServer : false});
  }

  async postChangePassword(){
    let data = {
      Password : this.state.oldPassword,
      NewPassword : this.state.newPassword,
      RetypePassword : this.state.retypeNewPassword,
    };
    const api = FetchApi(Configs.api.changePassword);
    api.post(data).then((response) =>{
      //Error message
      if(response.errorMessage !== undefined){
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.errorMessage,
          Configs.snackbarVariants.error
        );
      }else{
        //Success message 
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
        // return to profile
        this.setState({
          oldPassword : "",
          newPassword : "",
          selectedView : this.state.VIEWS.PROFILE,
        });
      }
    });
  }

  editInfo = () =>{
    this.postEditInfo();
    this.setState({waitingForServer : false});
  }

  // NOTE :
  // Not sure how to determine account enabled
  async postEditInfo (){
    let data = {
      UserName : this.state.username,
      Email : this.state.email,
      FirstName : this.state.firstName,
      LastName : this.state.lastName,
      AccountEnabled : 1,
    };
    const api = FetchApi(Configs.api.editUsers);
    api.post(data).then((response) =>{
      //Error message
      if(response.errorMessage !== undefined){
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.errorMessage,
          Configs.snackbarVariants.error
        );
      }else{
        //Success message 
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.updateSuccess,
          Configs.snackbarVariants.success
        );
        // return to profile
        this.setState({
          selectedView : this.state.VIEWS.PROFILE,
        });
      }
    });
  }

  renderProfile = () => {
    const { classes } = this.props;
    return(
      <div>
        <div className = {classes.root}>
          <h1>Profile</h1>
        </div>
        <Card>
          <div className={classes.content}>

            <h3> Details </h3>
            <p>Username : {this.state.username}</p>
            <p>Name : {this.state.firstName}  {this.state.lastName}</p>
            <p>Email : {this.state.email}</p>
            <p>Role : {this.state.role}</p>
            <p>Role description : {this.state.roleDescription}</p>
            <Button
              id = "Edit"
              variant="contained"
              onClick={() => {
                this.setState({
                  selectedView : this.state.VIEWS.EDIT_INFO,
                });
              }}>
                Edit
            </Button>
            {'\xa0\xa0\xa0'}
            <Button
              variant="contained"
              id = "ChangePassword"
              onClick={() => {
                this.setState({
                  selectedView : this.state.VIEWS.RESET_PASSWORD,
                });
              }}>
                Change password
            </Button>
              
          </div>
        </Card>
      </div>
    )
  }

  renderChangePassword = ()=> {
    const { classes } = this.props;
    return(
      <div>
        <h3>Change password</h3>
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="Old password"
            label="Old password"
            id="Old password"
            type = 'password'
            value = {this.state.oldPassword}
            onChange={(event) => {
                this.setState({ oldPassword: event.target.value })
            }}
          />
        </FormControl>
        <br></br>
        
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="New password"
            label="New password"
            id="New password"
            type = 'password'
            value={this.state.newPassword}
            onChange={(event) => {
                this.setState({ newPassword: event.target.value })
            }}
          />
          
        </FormControl>
        <br></br>
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="Confirm new password"
            label="Confirm new password"
            id="Confirm new password"
            type = 'password'
            value={this.state.retypeNewPassword}
            onChange={(event) => {
                this.setState({ retypeNewPassword: event.target.value })
            }}
          />
        </FormControl>
        <br></br>
        <Button
          id = "SubmitButt_changePassword"
          disabled = {this.state.waitingForServer}
          variant="contained"
          onClick={() => {
            this.setState({waitingForServer : true});
            this.changePassword();
          }}>
            Submit
        </Button>
        <Button
          className={classes.buttonMargin}
          variant="contained"
          onClick={() => {
            this.setState({
              oldPassword : "",
              newPassword : "",
              selectedView : this.state.VIEWS.PROFILE,
            });
          }}>
            cancel
        </Button>
        
      </div>
    );
  }

  renderEditInfo = ()=>{
    const { classes } = this.props;
    return(
      <div>
        <h3>Edit profile</h3>
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="First name"
            label="First name"
            id="First name"
            value = {this.state.firstName}
            onChange={(event) => {
                this.setState({ firstName: event.target.value })
            }}
          />
        </FormControl>
        <br></br>
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="Last name"
            label="Last name"
            id="Last name"
            value = {this.state.lastName}
            onChange={(event) => {
                this.setState({ lastName: event.target.value })
            }}
          />
        </FormControl>
        <br></br>
        <FormControl variant="outlined" className={classes.formDetail}>
          <TextField
            variant="outlined"
            required
            name="Email"
            label="Email"
            id="Email"
            value = {this.state.email}
            onChange={(event) => {
                this.setState({ email: event.target.value })
            }}
          />
        </FormControl>
        <br></br>
        <Button
          id="EditInfoSubmitButt"
          disabled = {this.state.waitingForServer}
          variant="contained"
          onClick={() => {
            this.setState({waitingForServer : true});
            this.editInfo();
          }}>
            Submit
        </Button>
        <Button
          className={classes.buttonMargin}
          variant="contained"
          onClick={() => {
            this.setState({
              oldPassword : "",
              newPassword : "",
              selectedView : this.state.VIEWS.PROFILE,
            });
          }}>
            cancel
        </Button>
        
      </div>
    );
  }

  // draw things
  render(){
    const { classes } = this.props;
    //html
    return(
      <div className={classes.content}>
        {this.state.selectedView == this.state.VIEWS.PROFILE && this.renderProfile()}
        {this.state.selectedView == this.state.VIEWS.RESET_PASSWORD && this.renderChangePassword()}
        {this.state.selectedView == this.state.VIEWS.EDIT_INFO && this.renderEditInfo()}
      </div>
    )
  }
}
/*

//*/
// Make it visible to other files
export default withStyles(useStyles)(MyAccount);