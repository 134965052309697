import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import Grid from "@material-ui/core/Grid";

class AdminDBActions extends Component {
  constructor(props) {
    super();    
    this.state = {
    };
  }

  cleanUpCohortRecommendationTable() {
    const api = FetchApi(Configs.api.cleanAWSLoggingCohortRecommendationTable);
    api.get().then((response) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err)=>{
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    });
  }
  encryptAllSiteAlgConfigs() {
    const api = FetchApi(Configs.api.encryptAllSiteAlgConfigs);
    api.get().then((response) => {
      console.log("encryptAllSiteAlgConfigs response", response)
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err)=>{
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    });
  }

  saveAllCohortHistoricalStatus() {
    const api = FetchApi(Configs.api.saveAllCohortHistoricalStatus);
    api.get().then((response) => {
      console.log("saveAllCohortHistoricalStatus response", response)
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err)=>{
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    });
  }

  render() {
    return (
      <div><br/>
        <Grid container justify="center">
          <Button
              variant="contained"
              color="primary"
              onClick={() =>{this.cleanUpCohortRecommendationTable()}}
            >
              Production DB Cleanup
            </Button>
          </Grid><br/>
        <Grid container justify="center">
          <Button
              variant="contained"
              color="primary"
              onClick={() =>{this.encryptAllSiteAlgConfigs()}}
            >
              Encrypt all site algorithm configurations
            </Button>
        </Grid><br/>
        {/* <Grid container justify="center">
          <Button
              variant="contained"
              color="primary"
              onClick={() =>{this.saveAllCohortHistoricalStatus()}}
            >
              Store all cohort historical status
            </Button>
        </Grid> */}
      </div>
    )
  }
}

export default AdminDBActions;