import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";

class AccountCreation extends Component {
  constructor(props) {
    super();   
    this.state = { 
      isFHTSupportAccount: false,
      fhtSupportRoleID: 0,
      showCommand: false,
      accountId: "",
      showRePassword: false,
      confirmPassword: "",
      password: "",
      showPassword: false,
      email:"",
      formErrors: { email: '', password: '', confirmPassword: '' },
      firstName: "",
      lastName: "",
      username: "",
      formValid: false,
      emailValid: false,
      passwordValid: false,
      command: "",
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let passwordMatch = false;
    switch (fieldName) {
        case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
          break;
        case 'password':
          let passwordlength = value.length >= 5;
          passwordMatch = this.state.confirmPassword === value;
          passwordValid = passwordlength && passwordMatch;
          fieldValidationErrors.password = passwordlength ? passwordMatch ? '' : 'Passwords not matching' : ' is too short';
          fieldValidationErrors.confirmPassword = passwordMatch ? '' : 'Passwords not matching';
          break;
        case 'confirmPassword':
          passwordMatch = this.state.password === value;
          passwordValid = passwordMatch;
          fieldValidationErrors.confirmPassword = passwordMatch ? '' : 'Passwords not matching';
          fieldValidationErrors.password = passwordMatch ? '' : 'Passwords not matching';
          break;
        default:
            break;
    }
    let roleValid = this.state.isFHTSupportAccount
                    ? this.state.fhtSupportRoleID > 0
                    : true;
    this.setState({
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        roleValid : roleValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid  && this.state.roleValid});
  }

  reset = () => {
    // handle menuclick, reset password, create account
    this.setState({
      selectedSite: {},
      selectedSoftware: {},
      selectedMachine: {},
      machineOnSite: [],
      softwareMappingData: [],
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      formErrors: { email: '', password: '', confirmPassword: '' },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      showRePassword: false,
      showPassword: false,
      command: "",
      showCommand: "",
      accountId: "",
      fhtpath: "",
    })
  }

  handleUserInput = (e) => {
    //update password
    //create account
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {this.validateField(name, value) });
  }

  handleFHTSupportAccountCreation = (event) => {
    this.setState({
      isFHTSupportAccount: event.target.checked
    })

    if (event.target.checked == false) {
      this.setState({
        fhtSupportRoleID: 0
      })
    }else{
      this.setState({
        fhtSupportRoleID: 1
      })
    }
  }

  handleFHTSupportRole = (event) => {
    this.setState({
      fhtSupportRoleID: event.target.value
    })
  }

  handleCreateNewAccount = () => {
    // create account
    if(!this.state.formValid){
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.userRegistrationDetails,
        Configs.snackbarVariants.error
      );
      return;
    }
    
    let data = {
      Username: this.state.username,
      Firstname: this.state.firstName,
      Lastname: this.state.lastName,
      Email: this.state.email,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmPassword,
      FHTSupportUserRole: this.state.fhtSupportRoleID
    };
    const api = FetchApi(Configs.api.userRegistration);
    api.post(data)
    .then(response => {
      //console.log(response);
     
      if (response.unauthorized != undefined && response.unauthorized == 401) {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.unauthorised,
          Configs.snackbarVariants.error
        );
      } else if (response.accountId === undefined){        
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.errorMessage,
          Configs.snackbarVariants.error
        );
      } else {
        this.setState({
          showCommand: true,
          command: "",
          accountId: response.accountId
        });
        this.props.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.userRegistrationSuccess,
          Configs.snackbarVariants.success
        );
      }
    })
    .catch(err => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.userRegistrationFail,
        Configs.snackbarVariants.error
      );
    })
  }

  renderCreateAccount = () => {
    return (
      <div>
        <br/>
          <Grid container justify="center"><h3>Create account</h3></Grid>
        <br/>
        <Grid container justify="center">
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <TextField
              variant="outlined"
              required
              name="firstName"
              label="First Name"
              id="firstName"
              value={this.state.firstName}
              onChange={(event) => {
                  this.setState({ firstName: event.target.value })
              }}
            />
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
          </FormControl>
            <TextField
              variant="outlined"
              required
              name="lastName"
              label="Last Name"
              id="lastName"
              value={this.state.lastName}
              onChange={(event) => {
                  this.setState({ lastName: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="username"
              label="Username"
              id="username"
              value={this.state.username}
              onChange={(event) => {
                  this.setState({ username: event.target.value })
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <TextField
              variant="outlined"
              required
              error={this.state.formErrors.email.length === 0 ? false : true}
              helperText={this.state.formErrors.email}
              name="email"
              label="Email"
              id="email"
              value={this.state.email}
              onChange={this.handleUserInput}
              disabled={this.state.loading}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <TextField
              variant="outlined"
              required
              error={this.state.formErrors.password.length === 0 ? false : true}
              helperText={this.state.formErrors.password}
              name="password"
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              id="password"
              value={this.state.password}
              onChange={this.handleUserInput}
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={()=>{ this.setState({ showPassword: !this.state.showPassword})}}
                          >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <TextField
              variant="outlined"
              required
              fullWidth
              error={this.state.formErrors.confirmPassword.length === 0 ? false : true}
              helperText={this.state.formErrors.confirmPassword}
              name="confirmPassword"
              label="Confirm Password"
              type={this.state.showRePassword ? 'text' : 'password'}
              id="confirmPassword"
              value={this.state.confirmPassword}
              onChange={this.handleUserInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={()=>{ this.setState({ showRePassword: !this.state.showRePassword})}}
                    >
                      {this.state.showRePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {this.state.showCommand && 
            <div>
              <FormControl variant="outlined" style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
                <TextField 
                  variant="outlined"
                  value={this.state.accountId}
                  label="Account ID"
                />
              </FormControl>
            </div>
          }
          <div style = {{marginBottom: 20, width: "90%", textAlign: "left"}}>
            <FormControlLabel
              control={<Checkbox />}
              onChange={this.handleFHTSupportAccountCreation}
              label="Is account a FHT support user account?" 
            /> 
            {this.renderFHTSupportAccountRoles()}
            <br/>
            <Button
              variant="contained"
              onClick={() => this.handleCreateNewAccount()}>
                create new account
            </Button>
            <Button style = {{marginLeft: 20,}}
              variant="contained"
              onClick={() => this.reset()}>
                clear
            </Button>
          </div>
        </Grid>
      </div>
    )
  }

  renderFHTSupportAccountRoles() {
    // account roles
    if (this.state.isFHTSupportAccount == false) {
      return (
        <div></div>
      )
    } else {
      return(
        <div>
          <FormGroup>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.fhtSupportRoleID}
            label="Age"
            onChange={this.handleFHTSupportRole}
          >
            <MenuItem value={1}>FHT Admin and Support</MenuItem>
            <MenuItem value={2}>I&E team</MenuItem>
            {/* 3 is used for disabled user role */}
            <MenuItem value={4}>I&E Coordinator</MenuItem>
          </Select>
          </FormGroup>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderCreateAccount()}
      </div>
    )
  }
}

export default AccountCreation;