// Description: This file is responsible for rendering the Algorithms summary history for the past 30 days.
// Status: Ready 

import React, { Component } from "react";

import { verifyHours, utcToLocal, utctoAEDT, convertALGDataToCorrectCSV, compareDateTimes } from "../../HelperFunctions";
import { getTestDescriptions, getVersions, getSitesAlgorithmCountIssuesData } from "../../FetchFunctions";
import FetchApi from "../../../Service/FetchApi";
import Configs from "../../../Service/Configuration";
import moment from "moment";

import Button from "@material-ui/core/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";
import { Grid, Paper } from "@material-ui/core";

import ALGSummaryHistoryGraph from "./AlgorithmSummaryComponents/ALGSummaryHistoryGraph";
import Dialog from "@material-ui/core/Dialog";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import SelectSiteGroupingsSelectionOnly from "../../SharedComponents/SelectSiteGroupingsSelectionOnly";

const styling = {
  styleCountConcernOnly: {
    background: "red",
    color: "white",
    display: "inline",
    padding: "2.5%"
  },
  styleDisplayAlgsOnly: {
    textDecoration: "underline palegreen",
    textDecorationThickness: 5,
    padding: "2.5%"
  },
  styleCountAndDisplayAlg: {
    background: "red",
    color: "white",
    display: "inline",
    padding: "2.5%",
    textDecoration: "underline palegreen",
    textDecorationThickness: 5
  },
};

var algSummaryData = []
var downloadMaterialTableData = React.createRef();

class CustomeDetailPanel extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTime: 24,
      isLoaded: false,
      DisplayTableDetail: null,
      TableCol: [],
      nivoLineDataDict: []
    }

    this.secondaryPageOptions = {
      search: true,
      pageSize: 30,
      pageSizeOptions: [10, 30, 60],
      exportButton: true,
      exportButton: {
        csv: true,
      },
      exportCsv: (header, columns) => this.exportCSVData(header, columns),
      grouping: false,
      sorting: true,
      cellStyle: {
        fontSize: 12,
      },
      headerStyle: {
        backgroundColor: '#EEE'
      },
      // maxBodyHeight: 'calc(100vh - 13REM)',
    };
  }

  componentDidMount = async () => {
    // Generate dropdown specific columns (i.e remove Logs and set Site Name column cellStyle to be empty)
    var filteredTableCols = this.props.tableCol.filter(x => x.title != "Logs");
    filteredTableCols.filter(x => x.title == "Site Name")[0].cellStyle = {}
    this.setState({
      TableCol: filteredTableCols
    })

    // Need to set SiteNames background colour.
    try {
      this.props.tableCol[0].cellStyle.background = "inherit"
    } catch {
      // Do nothing
    }

    this.LoadSiteDetail();
  }

  // "Refresh" the CSV download global variable
  downloadMaterialTableDataClickRerender(event) {
    this.setState({});
  }

  exportCSVData(header, data) {
    algSummaryData = convertALGDataToCorrectCSV(header, data, true)
    console.log("algSummaryData", algSummaryData)
    // Introduce slight delay before downloading data, to allow for global variable to be set.
    setTimeout(function () {
      downloadMaterialTableData.current.link.click();
    }.bind(this), 500)
  }

  LoadSiteDetail = async () => {
    console.log("Loading site detail : " + this.props.siteName);
    const siteSpecificAlgorithmCountIssuesData = await getSitesAlgorithmCountIssuesData(this.props.siteID);
    var parsedSiteIDGraphData = siteSpecificAlgorithmCountIssuesData.siteIDToTestIDGraphData

    const api = FetchApi(Configs.api.getSiteAlgorithmHistory);
    var data = {
      siteName: this.props.siteName
    }
    api.post(data).then((response) => {
      console.log("site detail :", response);

      response.siteLogInformationList.map(r => {
        r.fhtLogDateLogReceivedAESTConverted = utcToLocal(r.fhtLogDateLogReceived);
        r.fhtLastSuccessfulLogAESTConverted = utcToLocal(r.fhtLastSuccessfulLog);
      })

      // Loop through the alg summary history to identify display test IDs for front-end green highlight.
      for (var i = 0; i < response.siteLogInformationList.length; i++) {
        if (response.siteLogInformationList[i].tests == null) {
          continue
        }
        Object.entries(response.siteLogInformationList[i].tests).map(([key, value]) => {
          var keyToNumber = Number(key)
          if (response.convertedActiveDisplayAlgorithmTestIDs.includes(keyToNumber)) {
            response.siteLogInformationList[i].tests[key] = <p key={value} style={styling.styleDisplayAlgsOnly}>{value}</p>
          }
        })
      }

      console.log("response.siteLogInformationList", response.siteLogInformationList);

      // Create a new NivoGraphData row, it is inserted at the start of the table via unshift function.
      var testIDGraphDict = {};
      if (this.props.siteID in parsedSiteIDGraphData) {
        for (var i = 0; i < this.props.tableCol.length; i++) {
          if ("testID" in this.props.tableCol[i]) {
            var testIDTableCol = JSON.parse(JSON.stringify(this.props.tableCol[i]));
            if (testIDTableCol["testID"] in parsedSiteIDGraphData[this.props.siteID]
              && (testIDTableCol["testID"] in testIDGraphDict) == false) {
              var nivoGraphData = parsedSiteIDGraphData[this.props.siteID][testIDTableCol["testID"]];
              testIDGraphDict[testIDTableCol["testID"]] = <ALGSummaryHistoryGraph nivoGraphData={nivoGraphData} />
            }
          }
        }
      }
      response.siteLogInformationList.unshift({
        fhtHeartbeatVersionNumber: "",
        fhtLastSuccessfulLog: "",
        fhtLastSuccessfulLogAESTConverted: "",
        fhtLogDateLogReceived: "",
        fhtLogDateLogReceivedAESTConverted: "",
        fhtLogDateLogReceivedSuccess: "",
        fhtLogId: "",
        fhtLogJson: "",
        fhtLogJsonSuccess: "",
        fhtSiteName: "",
        fhtlogtextinfomation: "",
        numPatients: "",
        numRecommendation: "",
        siteID: "",
        status: "",
        tests: testIDGraphDict,
      })

      this.setState({
        DisplayTableDetail: response.siteLogInformationList,
        isLoaded: true,
      })
    })
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div>
          <MaterialTable
            title={this.props.siteName}
            columns={this.state.TableCol}
            data={this.state.DisplayTableDetail}
            options={this.secondaryPageOptions}
            style={{ backgroundColor: '#EEE', zIndex: 10 }}
          />
          <CSVLink
            data={algSummaryData}
            filename="Algorithm Summary.csv"
            className="hidden"
            ref={downloadMaterialTableData}
            onClick={(event) => this.downloadMaterialTableDataClickRerender(event)}
            target="_blank"
          />
        </div>
      )
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      )
    }
  }
}

class ALGSummaryHistory extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedSiteGroupings: 0,
      activeTime: 24,
      downloadLogMonths: 1,
      DisplayTableLatest: null,
      DisplayTableLatestOriginal: null,
      DisplayTableDetail: {},
      status: props.match.params.Status,
      TableCol: [
        {
          title: "Logs",
          render: (rowData) =>
            <Link to={{
              pathname: "/FhtLogsSelections",
              state: {
                Keyword: rowData.fhtSiteName,
              },
            }}>
              <SearchIcon />
            </Link>,
        },
        {
          title: "Site Name", field: "fhtSiteName",
          cellStyle: {
            position: "sticky",
            left: "0",
            background: "white",
            fontSize: 12,
            zIndex: 1,
          }
        },
        // { title: "Last Extracted", field: "fhtHeartbeatLastlog" },
        {
          title: "Last attempted run", field: "fhtLogDateLogReceivedAESTConverted", type: "datetime",
          customSort: (a, b) => compareDateTimes(a.fhtLogDateLogReceived, b.fhtLogDateLogReceived)
        },
        {
          title: "Last successful run", field: "fhtLastSuccessfulLogAESTConverted", type: "datetime",
          customSort: (a, b) => compareDateTimes(a.fhtLastSuccessfulLog, b.fhtLastSuccessfulLog)
        },
        {
          title: "Status",
          field: "status",
        },
        { title: "New Recommendations", field: "numRecommendation" },
        { title: "Total Patients", field: "numPatients" },
        { title: "Version", field: "fhtHeartbeatVersionNumber" },
      ],
      testWiki: null,
      isLoaded: false,
      isLoadingALGCSV: false,
      algorithmSiteCountIssues: {},
      isAlgorithmSiteCountIssuesDialogOpen: false,
      summarySiteIssuesBackgroundColor: "rgba(149, 212, 173, 0.5)",
      maxValidALGHours: 24,
    };

    this.pageOptions = {
      search: true,
      searchText:
        props.location.state !== undefined
          ? props.location.state.Keyword
          : props.match.params.Status === undefined
            ? null
            : props.match.params.Status,
      pageSize: 30,
      pageSizeOptions: [10, 20, 30],
      exportButton: true,
      exportAllData: true,
      grouping: false,
      sorting: true,
      maxBodyHeight: 'calc(100vh - 13REM)',
      cellStyle: {
        fontSize: 12,
      }
    };
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.SharedComponentSelectedSiteGroupingIDCallback = this.SharedComponentSelectedSiteGroupingIDCallback.bind(this);
  }

  componentDidMount = async () => {
    this.getLatestAlgorithmStatus();
  };

  getLatestAlgorithmStatus = async () => {
    const filterValue = Object.values(this.props.match.params)[0];
    let testDescriptions = await getTestDescriptions();
    const testWiki = testDescriptions;
    const versionDict = await getVersions();
    const siteVersions = versionDict.siteVers;

    console.log("testDescriptions", testDescriptions)
    // Provide null to fetch since we want all site algorithm count issues data.
    const allSitesAlgorithmCountIssuesData = await getSitesAlgorithmCountIssuesData(null);

    var siteIDAndActiveDisplayRecommendations = []
    const apiDisplayAlgs = FetchApi(Configs.api.getActiveDisplayAlgorithmsForSites);
    let bodyDisplayAlgs = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await apiDisplayAlgs.post(bodyDisplayAlgs).then((response) => {
      siteIDAndActiveDisplayRecommendations = response.siteIDsAndActiveDisplayAlgorithms
    });

    // obtain 1 entry of data for each site
    const api_test = FetchApi(Configs.api.getLatestAlgorithmHistoryHourFiltering);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      HourFilterCheck: this.state.activeTime,
    };
    await api_test.post(body).then((response) => {
      response.map(r => {
        r.fhtLogDateLogReceivedAESTConverted = utcToLocal(r.fhtLogDateLogReceived);
        r.fhtLastSuccessfulLogAESTConverted = utcToLocal(r.fhtLastSuccessfulLog);
      })

      // Loop through response data and update testIDs that have count drops in the response.
      for (var i = 0; i < response.length; i++) {
        if (response[i].tests == undefined)
          continue;
        var recCountDict = response[i].tests;
        var siteID = response[i].siteID;
        if (siteID in allSitesAlgorithmCountIssuesData.siteIDToTestIDGraphData) {
          for (const [key, value] of Object.entries(recCountDict)) {

            // Assign the cell style based on if:
            // 1. No counts of concern, and display 
            // isTestIDCountOfConcern == false, isTestIDDisplayed == true
            // 2. Counts of concern and display 
            // isTestIDCountOfConcern == true, isTestIDDisplayed == true, 
            // 3. Count of concern and not display
            // isTestIDCountOfConcern == true, isTestIDDisplayed == false, 
            var isTestIDDisplayed = false;
            if (siteID in siteIDAndActiveDisplayRecommendations) {
              if (siteIDAndActiveDisplayRecommendations[siteID].includes(Number(key))) {
                isTestIDDisplayed = true;
              }
            }

            var isTestIDCountOfConcern = false;
            if (key in allSitesAlgorithmCountIssuesData.siteIDToTestIDGraphData[siteID]) {
              var nivoGraphData = allSitesAlgorithmCountIssuesData.siteIDToTestIDGraphData[siteID][key];
              if (nivoGraphData.recCountChangeOfConcern == true && isTestIDDisplayed == true) {
                isTestIDCountOfConcern = true;
                recCountDict[key] = <div style={styling.styleCountAndDisplayAlg}> {value} </div>
              } else if (nivoGraphData.recCountChangeOfConcern == true) {
                isTestIDCountOfConcern = true;
                recCountDict[key] = <div style={styling.styleCountConcernOnly}> {value} </div>
              }
            }

            if (isTestIDDisplayed && !isTestIDCountOfConcern) {
              recCountDict[key] = <div style={styling.styleDisplayAlgsOnly}> {value} </div>
            }
          }
        }
      }


      // Button summarising all alg count drops for all sites.
      if (Object.keys(allSitesAlgorithmCountIssuesData.algorithmSiteCountIssues).length > 0) {
        this.setState({
          summarySiteIssuesBackgroundColor: "rgb(193, 66, 66, 0.5)"
        })
      }

      this.setState({
        DisplayTableLatest: response,
        DisplayTableLatestOriginal: response,
        algorithmSiteCountIssues: allSitesAlgorithmCountIssuesData.algorithmSiteCountIssues
      });

      console.log("table latest", this.state.DisplayTableLatest)

      // build the columns
      let Col = this.state.TableCol;
      var refSite = response.find((site) => 'tests' in site);
      console.log("refSite", refSite);
      if (refSite && refSite["tests"] != null) {
        for (const [testID, value] of Object.entries(refSite["tests"])) {
          // This assists us in identifying any missing TestIDs in the FhtAlgorithmClassNames that we need to add in.
          var title = "TestID: " + String(testID) + "; undefined short description";
          if (testWiki[testID] != undefined)
            title = testWiki[testID]
          Col.push({
            title: title,
            testID: testID,
            field: "tests[" + testID + "]",
            tooltip: title,
            cellStyle: {
              width: 250,
              minWidth: 250,
            },
            headerStyle: {
              width: 250,
              minWidth: 250,
            },
          });
        };
      }
      this.setState({
        filterValue: filterValue,
        TableCol: Col,
        isLoaded: true,
      }, this.filterSitesIfRequired(this.state.selectedSiteIDs, this.state.selectedSiteGroupings));
    });
  }

  //xx
  downloadLogClicked = React.createRef();
  getAllSiteALGHistory = async (months) => {
    this.setState({ isLoadingALGCSV: true });
    const api = FetchApi("GetAllAlgorithmHistory/months/" + months);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };

    await api.post(body).then((response) => {
      console.log(response);
      this.setState({ AllSiteALGHistory: { data: response } })
    })
    this.downloadLogClicked.current.link.click();

    this.setState({ isLoadingALGCSV: false });
  }

  // Not required as we generate it as dictionary now.
  buildTestWiki = (testDescriptions) => {
    let testWiki = {};
    testDescriptions.forEach((test) => {
      testWiki[test.fhtEnabledAnalysisTestId] =
        test.fhtEnabledAnalysisDescription;
    });
    return testWiki;
  };

  setAlgorithmSiteCountIssuesDialog() {
    this.setState({
      isAlgorithmSiteCountIssuesDialogOpen: !this.state.isAlgorithmSiteCountIssuesDialogOpen
    })
  }

  SharedComponentSiteGroupingCallback(siteGroupingsSiteIDs) {
    this.setState({
      selectedSiteIDs: siteGroupingsSiteIDs
    })
    this.filterSitesIfRequired(siteGroupingsSiteIDs, this.state.selectedSiteGroupings);
  }

  filterSitesIfRequired(siteGroupingsSiteIDs, selectedSiteGroupings) {
    if (selectedSiteGroupings != 0) {
      var filteredSiteIDs = [];
      var displayTableLatestRef = this.state.DisplayTableLatestOriginal
      for (var i = 0; i < displayTableLatestRef.length; i++) {
        if (siteGroupingsSiteIDs.includes(displayTableLatestRef[i].siteID)) {
          displayTableLatestRef[i]["key"] = i;
          filteredSiteIDs.push(displayTableLatestRef[i]);
        }
      }
      this.setState({
        DisplayTableLatest: filteredSiteIDs
      })
    } else {
      this.setState({
        DisplayTableLatest: this.state.DisplayTableLatestOriginal
      })
    }
  }

  SharedComponentSelectedSiteGroupingIDCallback(siteGroupingID) {
    this.setState({
      selectedSiteGroupings: siteGroupingID
    })
  }

  renderAlgorithmSiteCountIssues() {
    // Sort by Key (Site name)
    var renderArray = []
    // Key is SiteName, Value is array of testIDs.
    var siteCountIssues = JSON.parse(JSON.stringify(this.state.algorithmSiteCountIssues))
    Object.keys(siteCountIssues)
      .sort()
      .forEach(function (v, i) {
        renderArray.push(
          <div>
            {v}, TestIDs: {JSON.stringify(siteCountIssues[v]).replace(/,/g, " ").substring(1, JSON.stringify(siteCountIssues[v]).length - 1)}
          </div>
        )
      })
    return (
      <div>
        {renderArray}
      </div>
    )
  }

  changeMaxStatus = (event) => {
    const newMax = parseInt(event.target.value);
    this.setState({ activeTime: newMax });

    setTimeout(function () {
      this.getLatestAlgorithmStatus()
    }.bind(this), 1000)
  }

  render() {
    const {
      isLoaded,
      isLoadingALGCSV,
      DisplayTableLatest,
      downloadLogMonths,
      AllSiteALGHistory = {
        data: [],
        filename: "ALGHistory_1m.csv"
      },
    } = this.state;

    if (isLoaded) {
      return (
        <div style={{ paddingTop: "1REM" }}>
          <MaterialTable
            columns={this.state.TableCol}
            data={DisplayTableLatest}
            options={this.pageOptions}
            doubleHorizontalScroll={true}
            title={
              <div>
                <span style={{ fontSize: 22, fontWeight: "bold" }}>Algorithm summary history</span>
                <SelectSiteGroupingsSelectionOnly
                  selectedSiteGroupings={this.state.selectedSiteGroupings}
                  SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
                  SharedComponentSelectedSiteGroupingIDCallback={this.SharedComponentSelectedSiteGroupingIDCallback}
                />
              </div>
            }
            localization={{
              toolbar: {
                exportTitle: "Please ungroup before exporting",
              },
            }}
            detailPanel={[
              {
                tooltip: "See history",
                render: (rowData) => {
                  return (
                    <CustomeDetailPanel
                      siteName={rowData["fhtSiteName"]}
                      siteID={rowData["siteID"]}
                      tableCol={this.state.TableCol}
                    />
                  )
                }
              }
            ]}
            onRowClick={(event, rowData, togglePanel) => {
              togglePanel()
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ position: "absolute", left: "20%", top: 20, zIndex: 1 }}>
                    <Tooltip title="*Status: A FHT Algorithms site is active if its last log was received within the last selected hours, and it contains no fail messages.">
                      <TextField
                        size="small"
                        label={<div style={{ fontSize: 12 }}>Algorithms active within selected hours</div>}
                        type="number"
                        variant="outlined"
                        value={this.state.activeTime}
                        onChange={(e) => this.changeMaxStatus(e)}
                      />
                    </Tooltip>
                    <span> &nbsp; </span>
                    <TextField
                      size="small"
                      label="Months of data from today"
                      type="number"
                      variant="outlined"
                      value={downloadLogMonths}
                      onChange={(event) => {
                        this.setState({ downloadLogMonths: event.target.value });
                      }}
                    />
                    <span> &nbsp; </span>
                    <Button
                      variant="contained"
                      style={{ maxHeight: '30px', minHeight: '30px' }}
                      disabled={isLoadingALGCSV}
                      onClick={() => { this.getAllSiteALGHistory(downloadLogMonths) }}>
                      {(isLoadingALGCSV)
                        ? <CircularProgress />
                        : "Download all sites ALG logs"
                      }

                    </Button>
                    <span> &nbsp; </span>
                    <Tooltip title="All sites with algorithm count changes of concern.">
                      <Button
                        variant="contained"
                        style={{ maxHeight: '30px', minHeight: '30px', backgroundColor: this.state.summarySiteIssuesBackgroundColor }}
                        onClick={() => { this.setAlgorithmSiteCountIssuesDialog() }}>
                        Site algorithm count issues:{Object.keys(this.state.algorithmSiteCountIssues).length}
                      </Button>
                    </Tooltip>
                    <CSVLink
                      data={AllSiteALGHistory.data}
                      filename={"ALGHistory_" + downloadLogMonths + "m.csv"}
                      className="hidden"
                      ref={this.downloadLogClicked}
                      target="_blank"
                    />
                  </div>
                </div>
              )
            }}
          />
          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.state.isAlgorithmSiteCountIssuesDialogOpen}
            onBackdropClick={(event) => this.setAlgorithmSiteCountIssuesDialog()}
          >
            <div style={{ padding: "5%" }}>
              {Object.keys(this.state.algorithmSiteCountIssues).length == 0 ?
                "No sites with algorithm count issues"
                : this.renderAlgorithmSiteCountIssues()
              }
            </div>
          </Dialog>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }
}

export default ALGSummaryHistory;
