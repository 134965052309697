import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FetchApi from "../../Service/FetchApi";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as _ from 'underscore';
import Button from "@material-ui/core/Button";

class SelectSitesGrouping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSiteGroupings: 0,
      siteGroupingsList: [],
      siteTable: [
        { title: "Site Name", field: "fhtSiteName" },
        { title: "Site ID", field: "fhtSiteId", hidden: true, width: 0 },
      ],
      sitesWithoutConfigs: [],
    };
    this.pageOptions = {
      pageSize: 10,
      search: true,
      paging: false,
      selection: true,
      selectionProps: (rowData) => {
        // Disable sites if they do not have a configuration.
        if (this.state.sitesWithoutConfigs.includes(rowData.fhtSiteId)) {
          rowData.tableData.disabled = this.state.sitesWithoutConfigs.includes(rowData.fhtSiteId);
          return {
            disabled: rowData.fhtSiteId === rowData.fhtSiteId,
            color: "primary",
          };
        } else {
          // Do nothing
        }
      },
    };
    this.onComponentInitialise();
    this.handleThrottleSelectSites = _.debounce(this.handleSelectSites, 500, false);
  }

  onComponentInitialise = () => {
    this.getSiteGroupings();
    this.getSites();
  };

  handleClearAllSelectedSites = () => {
    if (this.state.siteCheckBox != undefined) {
      var siteCheckBox = this.state.siteCheckBox;
      siteCheckBox.map((checkBox) => {
        checkBox.tableData.checked = false;
      });
      this.setState({
        siteCheckBox: siteCheckBox,
      });
    }
  };

  handleClearAllConfigurationsSelected = () => {
    if (
      this.props.configType ==
        Configs.configurationEditingPageTypes.accountChkAlgConfig ||
      this.props.configType ==
        Configs.configurationEditingPageTypes.accountRecAlgConfig
    ) {
      this.setState({
        selectedAlgos: [],
      });
    } else if (
      this.props.configType ==
      Configs.configurationEditingPageTypes.accountDisplayAlgConfig
    ) {
      this.setState({
        selectDisplayAlgos: [],
      });
    } else if (
      this.props.configType ==
      Configs.configurationEditingPageTypes.pocModuleConfig
    ) {
      if (this.state.pocModuleActiveInactive != undefined) {
        var pocModuleActiveInactive = this.state.pocModuleActiveInactive;
        pocModuleActiveInactive.map((checkBox) => {
          checkBox.checkedBox = false;
        });
        this.setState({
          pocModuleActiveInactive: pocModuleActiveInactive,
        });
      }
    } else if (
      this.props.configType ==
      Configs.configurationEditingPageTypes.siteModulesConfig
    ) {
      if (this.state.updateSiteModuleConfigCheckboxList != undefined) {
        var updateSiteModuleConfigCheckboxList = this.state
          .updateSiteModuleConfigCheckboxList;
        updateSiteModuleConfigCheckboxList.map((checkBox) => {
          checkBox.checkedBox = false;
        });
        this.setState({
          updateSiteModuleConfigCheckboxList: updateSiteModuleConfigCheckboxList,
          siteModuleListOrder: [],
        });
      }
    }
  };

  getSiteGroupings() {
    const api = FetchApi(Configs.api.getCurrentSiteGroupings);
    let body = {
      SiteTypeIDs:
        Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response[0].text = "Select site grouping";
      this.setState({
        siteGroupingsList: response,
      });
    });
  }

  getSites = (keepSiteSelection = false) => {
    //Site Algo
    const api = FetchApi(Configs.api.getConfigEditSiteList);
    let body = {
      SiteTypeIDs:
        Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      // Front-end - update text to be disabled
      for (var i = 0; i < response.sitesWithoutConfigs.length; i++) {
        var sitesToDisable = response.sites.filter(x => x.fhtSiteId == response.sitesWithoutConfigs[i]);
        if (sitesToDisable.length > 0) {
          sitesToDisable[0].fhtSiteName = <div style = {{color: "rgb(220,220,220)", display: "inline" }}>{sitesToDisable[0].fhtSiteName}</div>
        }
      }

      if (!keepSiteSelection) {
        this.setState({
          siteCheckBox: response.sites,
          sitesWithoutConfigs: response.sitesWithoutConfigs
        });
      }
    });
  };

  updateCommonSelectedSiteGroupings = (event) => {
    this.handleClearAllSelectedSites();
    this.handleClearAllConfigurationsSelected();

    this.setState({
      selectedSiteGroupings: event.target.value,
    });
    var selectedSitesFromSiteGroup = JSON.parse(
      this.state.siteGroupingsList.filter(
        (a) => a.key == String(event.target.value)
      )[0].siteIDs
    );
    var checkedBoxSelectedSitesArray = [];
    for (var i = 0; i < selectedSitesFromSiteGroup.length; i++) {
      try {
        var siteCheckBoxFiltered = this.state.siteCheckBox.filter(
          (a) =>
            a.fhtSiteId == selectedSitesFromSiteGroup[i].fhtSiteGroupingsSiteID
        )[0];
        siteCheckBoxFiltered.tableData.checked = true;
        checkedBoxSelectedSitesArray.push(siteCheckBoxFiltered);
      } catch {
        // Site doesn't exist in the siteCheckBox variable now.
        // Could have been removed, disabled.
      }
    }
    this.handleThrottleSelectSites(checkedBoxSelectedSitesArray);
  };

  handleSelectSites = (selectedRows) => {
    // Remove any sites that do not have configs.
    var filteredSitesWithConfigs = selectedRows.filter(x => !this.state.sitesWithoutConfigs.includes(x.fhtSiteId))

    // Front-end - remove any sites without a config from being automatically checked.
    for (var i = 0; i < this.state.sitesWithoutConfigs.length; i++) {
      var siteCheckBoxFilter = this.state.siteCheckBox.filter(x => x.fhtSiteId == this.state.sitesWithoutConfigs[i]);
      if (siteCheckBoxFilter.length > 0) {
        siteCheckBoxFilter[0].tableData.checked = false;
      }
    }

    this.props.SharedComponentSiteGroupingCallback(filteredSitesWithConfigs);
  };

  updateCommonSelectedSiteGroupings = (event) => {
    this.handleClearAllSelectedSites();
    this.handleClearAllConfigurationsSelected();

    this.setState({
      selectedSiteGroupings: event.target.value,
    });
    var selectedSitesFromSiteGroup = JSON.parse(
      this.state.siteGroupingsList.filter(
        (a) => a.key == String(event.target.value)
      )[0].siteIDs
    );
    var checkedBoxSelectedSitesArray = [];
    for (var i = 0; i < selectedSitesFromSiteGroup.length; i++) {
      try {
        var siteCheckBoxFiltered = this.state.siteCheckBox.filter(
          (a) =>
            a.fhtSiteId == selectedSitesFromSiteGroup[i].fhtSiteGroupingsSiteID
        )[0];
        siteCheckBoxFiltered.tableData.checked = true;
        checkedBoxSelectedSitesArray.push(siteCheckBoxFiltered);
      } catch {
        // Site doesn't exist in the siteCheckBox variable now.
        // Could have been removed, disabled.
      }
    }
    this.handleThrottleSelectSites(checkedBoxSelectedSitesArray);
  };

  render() {
    return (
      <div>
        <Grid container style={{ gap: "1REM", width: "100%" }}>
          <Grid item xs={12}>
            <Box
              bgcolor="primary.main"
              style={{ color: "white" }}
              height="4REM"
            >
              <FormControl variant="outlined">
                <Select
                  label="Select grouping"
                  value={this.state.selectedSiteGroupings}
                  style={{ color: "white" }}
                  onChange={(event) => {
                    this.updateCommonSelectedSiteGroupings(event);
                  }}
                >
                  {this.state.siteGroupingsList.map((grouping) => (
                    <MenuItem
                      key={Number(grouping.key)}
                      value={Number(grouping.key)}
                      style={{ color: "black" }}
                    >
                      {grouping.text}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
              <div>
                <MaterialTable style={{ height: "40REM", overflow: "auto", whiteSpace: "nowrap" }}
                  columns={this.state.siteTable}
                  data={this.state.siteCheckBox}
                  options={this.pageOptions}
                  title=""
                  onSelectionChange={(selectedRows) => {
                    this.handleThrottleSelectSites(selectedRows);
                  }}
                ></MaterialTable> <br />
                <Button
                  variant="contained"
                  onClick={() => {
                    this.handleClearAllSelectedSites();
                    this.props.SharedComponentSiteGroupingCallback([]);
                  }}>Clear</Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default SelectSitesGrouping;
