import React, { Component } from "react";
import Configs from "../../Service/Configuration";
import MaterialTable from "material-table";
import FetchApi from "../../Service/FetchApi";
import Grid from "@material-ui/core/Grid";
import SelectedRecChkDisplayConfigurations from "../SharedComponents/SelectedRecChkDisplayConfigurations";
import Box from '@material-ui/core/Box';
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SiteModulesGeneralLayout from "../SharedComponents/SiteModulesGeneralLayout";
import ReorderListGeneralComponent from "./ReorderListGeneralComponent";
import SelectedModuleConfigurations from "../SharedComponents/SelectedModuleConfigurations";
import SelectSitesGrouping from "../SharedComponents/SelectSitesGrouping";

class ConfigSitePOCModuleEdit extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedSites: [],
      pocModuleActiveInactive: [],
      pocSitesAndActiveInactiveModules: [],
    }
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
  }

  componentDidMount() {
    this.getPOCModules();
  }

  getPOCModules(updateSiteActiveListOnly) {
    const api = FetchApi(Configs.api.getActivePOCModulesAndPOCModules)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      if (updateSiteActiveListOnly) {
        this.setState({
          pocSitesAndActiveInactiveModules: response.activePOCModulesForSitesDictionary,
          isLoaded: true
        })
      } else {
        this.setState({
          pocModuleActiveInactive: response.allPOCModules,
          pocSitesAndActiveInactiveModules: response.activePOCModulesForSitesDictionary,
          isLoaded: true
        })
      }
    })
  }

  // Send data to API to update all sites selected with what modules are active.
  updateMultipleSitePOCModuleConfigs = async () => {
    // Obtain deep copy of pocModuleActiveInactive to remove only tableData of selected sites.
    var removedTableDataColumn = JSON.parse(JSON.stringify(this.state.pocModuleActiveInactive));
    delete removedTableDataColumn.tableData;

    var deepCopyCheckBox = JSON.parse(JSON.stringify(this.state.selectedSites))
    var body = {
      "selectedSitePOCModuleList": deepCopyCheckBox,
      "ActiveAndInactivePOCModules": removedTableDataColumn
    };

    const api = FetchApi(Configs.api.updatePOCModules);
    await api.post(body).then((response) => {
      console.log("updateMultipleSitePOCModuleConfigs response", response);
      if (response.unauthorized != undefined && response.unauthorized == 401)
        throw new Error("Unauthorized"); 
      this.props.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.updateSuccess,
        Configs.snackbarVariants.success
      );
    }).catch((err) => {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        "Update fail",
        Configs.snackbarVariants.error
      );
    })

    this.getPOCModules(true);
  }

  updateCommonModuleCheckbox = (event) => {
    var moduleSelectionUpdateCheckArray = []
    var descriptionKey = ""
    var reorderListArray = []
    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      moduleSelectionUpdateCheckArray = JSON.parse(JSON.stringify(this.state.commonSiteModuleCheckboxList))
      descriptionKey = "fhtConfigModuleMapFileName"
    } else if (this.props.configType == Configs.configurationEditingPageTypes.pocModuleConfig) {
      moduleSelectionUpdateCheckArray = JSON.parse(JSON.stringify(this.state.pocModuleActiveInactive))
      descriptionKey = "pocModuleName"
    }

    var updatedCheckBoxForPOCModule = JSON.parse(JSON.stringify(moduleSelectionUpdateCheckArray))
    var checkBoxToUpdate = updatedCheckBoxForPOCModule.filter(a => a[descriptionKey] == event.target.value)[0]
    checkBoxToUpdate.checkedBox = event.target.checked

    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      var userSelectedSiteModuleOrder = JSON.parse(JSON.stringify(this.state.siteModuleListOrder))
      var unTickedModuleArray = moduleSelectionUpdateCheckArray.filter(a => a.checkedBox == false)
      reorderListArray = userSelectedSiteModuleOrder

      // Push new value if it's clicked on else pop the last element to remove it.
      if (unTickedModuleArray.filter(a => a.fhtConfigModuleMapFileName == event.target.value).length > 0) {
        reorderListArray.push(event.target.value)
      } else {
        reorderListArray = reorderListArray.filter(x => x !== event.target.value);
      }
    }

    if (this.props.configType == Configs.configurationEditingPageTypes.siteModulesConfig) {
      this.setState({
        commonSiteModuleCheckboxList: updatedCheckBoxForPOCModule,
        siteModuleListOrder: reorderListArray
      })
    } else if (this.props.configType == Configs.configurationEditingPageTypes.pocModuleConfig) {
      this.setState({
        pocModuleActiveInactive: updatedCheckBoxForPOCModule
      })
    }
  }

  SharedComponentSiteGroupingCallback = (selectedSites) => {
    var allPOCModules = this.state.pocModuleActiveInactive
    var allPOCSitesAndModules = this.state.pocSitesAndActiveInactiveModules
  
    // If no rows selected, set all checkedBox to false
    if (selectedSites.length == 0) {
      for (var j = 0; j < allPOCModules.length; j++) {
        allPOCModules[j].checkedBox = false
      }
    }
  
    // Loop through all sites and modules to determine which are active.
    var allPOCModulesForSites = new Set()
    for (var j = 0; j < allPOCModules.length; j++) {
      for (var i = 0; i < selectedSites.length; i++) {
        if (selectedSites[i].fhtSiteId in allPOCSitesAndModules) {
          if (allPOCModules[j].pocModuleName in allPOCSitesAndModules[selectedSites[i].fhtSiteId]) {
            allPOCModulesForSites.add(allPOCModules[j].pocModuleName)
            // Always break if we find 1 site with true for the poc module.
            if (allPOCSitesAndModules[selectedSites[i].fhtSiteId][allPOCModules[j].pocModuleName] == true) {
              allPOCModules.filter(a => a.pocModuleName == allPOCModules[j].pocModuleName)[0].checkedBox = true
              break
            }
            allPOCModules.filter(a => a.pocModuleName == allPOCModules[j].pocModuleName)[0].checkedBox = allPOCSitesAndModules[selectedSites[i].fhtSiteId][allPOCModules[j].pocModuleName]
          }
        }
      }
    }
  
    // As POC modules is not uniform across sites, we need to obtain a list of all POC modules for all sites.
    // This is then used to turn off modules that are not in currently chosen sites.
    for (var j = 0; j < allPOCModules.length; j++) {
      if (allPOCModulesForSites.has(allPOCModules[j].pocModuleName) == false) {
        allPOCModules.filter(a => a.pocModuleName == allPOCModules[j].pocModuleName)[0].checkedBox = false
      }
    }
  
    this.setState({
      pocModuleActiveInactive: allPOCModules,
      selectedSites: selectedSites,
    })
  };
  
  renderConfigSitePOCModuleEdit = () => {
    var descriptionKey = "pocModuleName";
    return (
      <div> <br/>
        <Grid container justify="center" style={{ gap: "8px", width: "100%" }}>
          <Grid item xs={3}>
            {/** Left side of the page **/}
            <SelectSitesGrouping
              SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
            />
            <br />
          </Grid>
          <Grid item xs={7}>
            {/** Right side of the page **/}
            <Box
              bgcolor="primary.main"
              style={{ color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', }}
              height="50px"
            >
              <Typography variant="h5">
                {"POC Modules"}
              </Typography>
            </Box>
            <div className="nsExtraTallScrollBoxConfigEdit">
              <SelectedModuleConfigurations
                descriptionKey={descriptionKey}
                commonSiteModuleCheckboxList={this.state.pocModuleActiveInactive}
                updateCommonModuleCheckbox={this.updateCommonModuleCheckbox}
              />
            </div><br /><br />
          </Grid>
        </Grid> <br />
        <Grid container justify="center">
          <Grid container spacing={10} style={{ width: "100%" }}>
            <Grid item xs={4}>
              {/* Empty rendering space for ReorderList to be on right hand side. */}
            </Grid>
            <Grid item xs={7}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    this.updateMultipleSitePOCModuleConfigs();
                  }}
                >
                  Update POC modules
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfigSitePOCModuleEdit()} <br />
      </div>
    )
  }
}

export default ConfigSitePOCModuleEdit;