import React from "react";
import { withStyles } from "@material-ui/core/styles/";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import FetchApi from "../Service/FetchApi";
import Configs from "../Service/Configuration";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { utcToLocal, utctoAEDT, compareDateTimes } from "./HelperFunctions";
import { getHeartbeats } from "./FetchFunctions";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarComponent from "../Component/SnackbarComponent"

const useStyles = (theme) => ({
  root: {
    margin: 50,
    textAlign: "center",
  },
  content: {
    padding: 50,
    paddingTop: 20,

    flexGrow: 1,
    // marginBottom: 100
  },
  formDetail: {
    marginBottom: 20,
    width: "100%",
    textAlign: "left",
  },
  buttonMargin: {
    marginLeft: 20,
  },
  tip: {
    fontSize: "smaller",
    textAlign: "left",
    marginButtom: 10,
    color: "#cccccc",
  },
});

class MachinesOnSite extends React.Component {
  constructor(props) {
    super(props);
    this.user = props.user;
    this.software = [];
    this.site = [];
    this.machines = [];
    this.snackbarMessage = "";
    this.snackbarVariant = "success";
    this.localSystems = [];
    this.pageOptions = {
      pageSize: 10,
      search: true,
      pageSizeOptions: [5, 10, 20, 30],
    };
    this.selectionOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      selection: true,
    };

    this.state = {

      machineTable : [],
      machineTable_FHT: [
        { title: "Machine Id", field: "machineId" },
        { title: "Machine Name", field: "machineName" },
        { title: "Server/Client", field: "serverClient"},
        { title: "Last PoC Heartbeat Received", field: "lastLog_converted", type:"datetime",
          customSort : (a,b) => compareDateTimes(a.lastLog, b.lastLog)},
        { title: "API Status", field: "apiStat" },
        {title: "SSL Certificate", field: "sslCert"},
        {title: "Http Connection", field: "httpStat"},
        { title: "PoC Version", field: "version"},
        { title: "Last Autoupdater Heartbeat Received", field: "autoUpdaterLastLog_converted", type:"datetime",
          customSort : (a,b) => compareDateTimes(a.autoUpdaterLastLog, b.autoUpdaterLastLog)},
        { title: "SiteID", field: "siteId", hidden: true},
        {title:"", render:rowData=><Link to={`/machinelogs/${rowData["machineName"]},${rowData["siteId"]}`}>ShowLogs</Link>},
      ],
      machineTable_SC4C: [
        { title: "Machine Id", field: "machineId" },
        { title: "Machine Name", field: "machineName" },
        { title: "Server/Client", field: "serverClient"},
        { title: "Last SC4C Heartbeat Received", field: "lastLog_converted", type:"datetime"},
        { title: "SC4C Version", field: "version"},
        { title: "Last Autoupdater Heartbeat Received", field: "autoUpdaterLastLog_converted", type:"datetime"},
        { title: "SiteID", field: "siteId", hidden: true},
        {title:"", render:rowData=><Link to={`/machinelogs/${rowData["machineName"]},${rowData["siteId"]}`}>ShowLogs</Link>},
      ],
      machineTable_Torch: [
        { title: "Machine Id", field: "machineId" },
        { title: "Machine Name", field: "machineName" },
        { title: "Server/Client", field: "serverClient"},
        { title: "Last API Heartbeat Received", field: "lastLog_converted", type:"datetime"},
        {title: "SSL Certificate", field: "sslCert"},
        { title: "API Version", field: "version"},
        { title: "Last Autoupdater Heartbeat Received", field: "autoUpdaterLastLog_converted", type:"datetime"},
        { title: "SiteID", field: "siteId", hidden: true},
        {title:"", render:rowData=><Link to={`/machinelogs/${rowData["machineName"]},${rowData["siteId"]}`}>ShowLogs</Link>},
      ],
      machines: [],
      sites: [],
      localSystems: [],
      selectedSite: -1,
      machineOnSite: [],
      snackbarMessage: "",
      snackbarVariant: "",
      openSnackbar: false,
    };
  }

  // Copy and pasted the GlobalFunctionDisplaySnackbarMessage, due to difficulties passing it into a prop
  // for this specific page, as we pass in a Keyword "search term" to auto-select site if page selected from
  // dashboard. 
  GlobalFunctionDisplaySnackbarMessage = (msg, variant) => {
    this.setState({
      snackbarMessage: msg,
      snackbarVariant: variant,
      openSnackbar: true,
    });
  };

  getSite = async () => {
    //softwqare mapiing, site grouping, machineOnsite
    console.log("Fetching sites...")
    const api = FetchApi(Configs.api.getSite);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then(response => {
      this.setState({ sites: response });
      console.log("sites", response);

      if (this.props.location != undefined) {
        var selectedSite = response.filter(x => x.fhtSiteName == this.props.location.state.Keyword)[0]
        this.setState({
          selectedSite: selectedSite
        })
        this.getMachineOnsite();
      }
    })
  }

  getMachineOnsite = () => {
    //Machine on site
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    console.log("fetching machine on site" + this.state.selectedSite.fhtSiteId);
    const api = FetchApi(Configs.api.getMachineOnSiteV2 + "/" + this.state.selectedSite.fhtSiteId);
    api.post(body).then((response) => {
      console.log("Machine on site", response);
      response.map(r => {
        let lastLog_converted = new Date(r.lastLog.replace("+11:00", ""));
        lastLog_converted = lastLog_converted.toLocaleDateString('en-GB') + ", " + lastLog_converted.toLocaleTimeString();
        r.lastLog_converted = lastLog_converted;


        let autoUpdaterLastLog = new Date(r.autoUpdaterLastLog.replace("+11:00", ""));
        autoUpdaterLastLog = autoUpdaterLastLog.toLocaleDateString('en-GB') + ", " + autoUpdaterLastLog.toLocaleTimeString();
        r.autoUpdaterLastLog_converted = autoUpdaterLastLog;
      })

      this.setState({
        machineOnSite: response
      });
    });
  }

  componentDidMount = async () => {
    this.getSite();
    this.getMachineOnsite();

        // machine on site
        switch(Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID[0]){
          case 1 : //FHT
            this.setState({machineTable : this.state.machineTable_FHT});
            break;
          case 2 : //Torch
            this.setState({machineTable : this.state.machineTable_Torch});
            break;
          case 3 : //SC4C
            this.setState({machineTable : this.state.machineTable_SC4C});
            break;
          default:
            this.setState({machineTable : this.state.machineTable_FHT});
            break;
        }

  }

  handleMachineSiteChange = (event) => {
    //Machine on site
    this.setState({ selectedSite: event.target.value }, () => {
      this.getMachineOnsite();
    });
  }

  handleDeleteMachineOnSite = (data) => {
    //Machine on site
    const api = FetchApi(Configs.api.deleteMachineOnSite + "/" + data["machineId"]);
    api.get().then((response) => {
      this.GlobalFunctionDisplaySnackbarMessage(
        Configs.snackbarMessages.deleteSuccess,
        Configs.snackbarVariants.success
      );
      this.getMachineOnsite();
    })
      .catch((error) => {
        this.GlobalFunctionDisplaySnackbarMessage(
          Configs.snackbarMessages.deleteFail,
          Configs.snackbarVariants.error
        );
      });
  }

  renderMachine = () => {
    //Machine on site
    const { classes } = this.props;
    return (
      <div>
        <h3>Machine on site</h3>
        <FormControl variant="outlined" className={classes.formDetail}>
          <InputLabel>Site</InputLabel>
          <Select
            label="Site"
            value={this.state.selectedSite}
            onChange={this.handleMachineSiteChange}
          // onChange={(e) => this.setState({selectedSite: e.target.value})}
          >
            {this.state.sites.map((s) => (
              <MenuItem key={s.fhtSiteId} value={s}>
                {s.fhtSiteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.state.selectedSite.fhtSiteId &&
          <MaterialTable
            columns={this.state.machineTable}
            data={this.state.machineOnSite}
            options={this.pageOptions}
            title=""
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve();
                    const data = this.state.machineOnSite.slice();
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    this.setState({ machineOnSite: data }, () => {
                      this.handleDeleteMachineOnSite(oldData);
                    });
                  }, 500);
                }),
            }}
          ></MaterialTable>
        }
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.openSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={this.state.openSnackbar}
            autoHideDuration={4000}
            onClose={() => this.setState({ openSnackbar: false })}
          >
            <SnackbarComponent
              onClose={() => this.setState({ openSnackbar: false })}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        )}

        {this.renderMachine()} <br/>
      </div>
    );
  }
}
export default withStyles(useStyles)(MachinesOnSite);